import React from "react";
// import logoImg from "../../../assets/img/AdminLTELogo.png";
import aqua_white from "../../../assets/img/index2_logo.png";
import Accordion from "react-bootstrap/Accordion";
import {
  // FaApper,
  FaBook,
  // FaBookMedical,
  FaMoneyBill,
  // FaDochub,
  FaTachometerAlt,
  FaUser,
  FaHouseUser,
  // FaTicketAlt,
  FaSearch,
} from "react-icons/fa";
import { FaCog, FaUserCog } from "react-icons/fa";
import { Link } from "react-router-dom";
import adminlogoImg from "../../../assets/img/logo-white.png";
import { useSelector } from "react-redux";
import {
  BsFillClipboard2DataFill,
  // BsBuildingsFill,
  // BsQrCode,
  BsQrCodeScan,
  BsFileEarmarkMedicalFill,
} from "react-icons/bs";
import { useState } from "react";

const Sidebar = () => {
  const isAdmin = useSelector((state) => state.login.isAdmin);
  const roleId = useSelector((state) => state.login.roleId);
  const userId = useSelector((state) => state.login.userId);
  const isLogin = useSelector((state) => state.login.isLogin);
  const ManagementCo = useSelector((state) => state.login.ManagementCo);
  const propertyData = useSelector((state) => state.login.propertyData);
  const [activeButton, setActiveButton] = useState(null);

  return (
    <>
      <aside
        className={`main-sidebar sidebar-dark-primary elevation-4 position-fixed ${isAdmin || Number(atob(roleId)) === 4 ? "bg-dark" : "cambg-primary"
          }`}
      >
        <Link to="/user#" className="brand_img_side_bar">
          {isAdmin || Number(atob(roleId)) === 4 ? (
            <img
              src={adminlogoImg}
              alt="AdminLTE Logo"
              className="brand-image  "
              style={{ opacity: ".8" }}
            />
          ) : (
            <img
              src={
                propertyData?.logo
                  ? process.env.REACT_APP_AWS_S3_BUCKET_URL +
                  "images/" +
                  propertyData?.logo
                  : aqua_white
              }
              alt="AdminLTE Logo"
              className="brand-image w-50  "
              style={{ opacity: ".8" }}
            />
          )}
        </Link>

        <div className="sidebar">
          <nav className="mt-2">
            <ul className="nav nav-pills nav-sidebar flex-column">
              <li className="nav-item menu-open">
                <Link to="/user" className="nav-link ">
                  <span className="pr-2 fs-5">
                    <FaTachometerAlt />
                  </span>
                  <p>Dashboard</p>
                </Link>
              </li>

              <Accordion defaultActiveKey="" flush>
                {isAdmin && atob(roleId) ? (
                  <>
                    <li className="nav-item menu-open">
                      <Link
                        to="/user/onboardingFrom"
                        className={`nav-link ${activeButton === "onboardingFrom" ? "active" : ""
                          }`}
                        onClick={() => setActiveButton("onboardingFrom")}
                      >
                        {" "}
                        <span className="pr-2 fs-5">
                          <BsFillClipboard2DataFill className="text-light" />
                        </span>{" "}
                        <p className="text-light">Onboarding</p>
                      </Link>
                    </li>

                    <Accordion.Item eventKey="8">
                      <Accordion.Header>
                        {" "}
                        <span className="pr-2 fs-5">
                          <FaCog />
                        </span>{" "}
                        <p className="m-0">Manage</p>
                      </Accordion.Header>
                      <Accordion.Body>
                        <ul className="nav nav-pills nav-sidebar flex-column">

                          <li className="nav-item">
                            <Link
                              to="/user/PropertyListing"
                              className={`nav-link ${activeButton === "PropertyListing"
                                ? "active"
                                : ""
                                }`}
                              onClick={() => setActiveButton("PropertyListing")}
                            >
                              <i className="nav-icon fas fa-tachometer-alt"></i>
                              <p> Property</p>
                            </Link>
                          </li>

                          <li className="nav-item">
                            <Link
                              to="/user/CamListing"
                              className={`nav-link ${activeButton === "cam" ? "active" : ""
                                }`}
                              onClick={() => setActiveButton("cam")}
                            >
                              <i className="nav-icon fas fa-tachometer-alt"></i>
                              <p> CAM</p>
                            </Link>
                          </li>

                          <li className="nav-item">
                            <Link
                              to="/user/ManagementCompanyListing"
                              className={`nav-link ${activeButton === "ManagementCompanyListing"
                                ? "active"
                                : ""
                                }`}
                              onClick={() =>
                                setActiveButton("ManagementCompanyListing")
                              }
                            >
                              <i className="nav-icon fas fa-tachometer-alt"></i>
                              <p> Management Co</p>
                            </Link>
                          </li>

                          <li className="nav-item">
                            <Link
                              to="/user/TowCompanyListing"
                              className={`nav-link ${activeButton === "TowCompanyListing"
                                ? "active"
                                : ""
                                }`}
                              onClick={() =>
                                setActiveButton("TowCompanyListing")
                              }
                            >
                              <i className="nav-icon fas fa-tachometer-alt"></i>
                              <p> Tow Company</p>
                            </Link>
                          </li>

                          <li className="nav-item">
                            <Link
                              to="/user/SubAdminListing"
                              className={`nav-link ${activeButton === "SubAdminListing"
                                ? "active"
                                : ""
                                }`}
                              onClick={() => setActiveButton("SubAdminListing")}
                            >
                              <i className="nav-icon fas fa-tachometer-alt"></i>
                              <p> Sub-Admin</p>
                            </Link>
                          </li>

                          <li className="nav-item">
                            <Link
                              to="/user/EmailTemplateListing"
                              className={`nav-link ${activeButton === "EmailTemplateListing"
                                ? "active"
                                : ""
                                }`}
                              onClick={() =>
                                setActiveButton("EmailTemplateListing")
                              }
                            >
                              <i className="nav-icon fas fa-tachometer-alt"></i>
                              <p> Email Templates</p>
                            </Link>
                          </li>

                          {/* <li className="nav-item">
                            <Link
                              to="/user/SamplePassListing"
                              className={`nav-link ${activeButton === "SamplePassListing"
                                  ? "active"
                                  : ""
                                }`}
                              onClick={() =>
                                setActiveButton("SamplePassListing")
                              }
                            >
                              <i className="nav-icon fas fa-tachometer-alt"></i>
                              <p> Sample Passes</p>
                            </Link>
                          </li> */}
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>

                    <li className="nav-item menu-open">
                      <Link
                        to="/user/LookupUnit"
                        className={`nav-link ${activeButton === "LookupUnit" ? "active" : ""
                          }`}
                        onClick={() => setActiveButton("LookupUnit")}
                      >
                        {" "}
                        <span className="pr-2 fs-5">
                          <FaSearch className="text-light" />
                        </span>{" "}
                        <p className="text-light">Look-up</p>
                      </Link>
                    </li>

                    <li className="nav-item menu-open">
                      <Link
                        to="/user/activity"
                        className={`nav-link ${activeButton === "activity" ? "active" : ""
                          }`}
                        onClick={() => setActiveButton("activity")}
                      >
                        <span className="pr-2 fs-5">
                          <BsFileEarmarkMedicalFill className="text-light" />
                        </span>{" "}
                        <p className="text-light">Activity</p>
                      </Link>
                    </li>
                    {/* <li className="nav-item menu-open">
                      <Link to="/user/activity"
                        className={`nav-link ${activeButton === "activity" ? "active" : ""}`}
                        onClick={() => setActiveButton("activity")}
                      >

                        <span className="pr-2 fs-5">
                          <BsFileEarmarkMedicalFill className="text-light" />
                        </span>{" "}
                        <p className="text-light">Scanned QR Location</p>
                      </Link>
                    </li> */}
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>
                        <span className="pr-2 fs-5">
                          <FaBook />
                        </span>{" "}
                        <p className="m-0">Resources</p>
                      </Accordion.Header>
                      <Accordion.Body>
                        <ul className="nav nav-pills nav-sidebar flex-column">
                          <li className="nav-item">
                            <Link
                              to="/user/Resources"
                              className={`nav-link ${activeButton === "Resources" ? "active" : ""
                                }`}
                              onClick={() => setActiveButton("Resources")}
                            >
                              <i className="nav-icon fas fa-tachometer-alt"></i>
                              <p>Resources</p>
                            </Link>
                          </li>
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>
                        <span className="pr-2 fs-5">
                          <FaMoneyBill />
                        </span>{" "}
                        <p className="m-0">Payment</p>
                      </Accordion.Header>
                      <Accordion.Body>
                        <ul className="nav nav-pills nav-sidebar flex-column">
                          <li className="nav-item">
                            <Link
                              to="/user/PaymentHistory"
                              className={`nav-link ${activeButton === "PaymentHistory"
                                ? "active"
                                : ""
                                }`}
                              onClick={() => setActiveButton("PaymentHistory")}
                            >
                              <i className="nav-icon fas fa-tachometer-alt"></i>
                              <p>Payment/Pass History</p>
                            </Link>
                          </li>
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                  </>
                ) : (
                  <>
                    {Number(atob(roleId)) === 4 ? (
                      <>
                        {/* <Accordion.Item eventKey="0">
                          <Accordion.Header>
                            {" "}
                            <span className="pr-2 fs-5">
                              <FaCog />
                            </span>{" "}
                            <p className="m-0">Manage</p>
                          </Accordion.Header>
                          <Accordion.Body>
                            <ul className="nav nav-pills nav-sidebar flex-column"> */}
                        <li className="nav-item">
                          <Link
                            to={`/user/ManagementCoPropertyList/${btoa(
                              userId
                            )}`}
                            className={`nav-link ${activeButton === "PropertyList"
                              ? "active"
                              : ""
                              }`}
                            onClick={() =>
                              setActiveButton("PropertyList")
                            }
                          >
                            <span className="pr-2 fs-5">
                              <FaHouseUser className="text-light" />
                            </span>{" "}
                            <p className="text-light"> Managing Property</p>

                          </Link>
                        </li>
                        {/* </ul> */}
                        {/* <ul className="nav nav-pills nav-sidebar flex-column"> */}
                        {/* <li className="nav-item">
                          <Link
                            to={`/user/ManageUnit/${btoa(userId)}`}
                            className={`nav-link ${activeButton === "ManageUnit"
                              ? "active"
                              : ""
                              }`}
                            onClick={() => setActiveButton("ManageUnit")}
                          >
                            <span className="pr-2 fs-5">
                              <FaUser className="text-light" />
                            </span>{" "}
                            <p className="text-light"> Managing Unit</p>

                          </Link>
                        </li> */}
                        {/* </ul> */}

                        {/* <ul className="nav nav-pills nav-sidebar flex-column">
                              <li className="nav-item">
                                <Link
                                  to={`/user/SubAdminManagListing/${btoa(
                                    userId
                                  )}`}
                                  className={`nav-link ${activeButton === "SubAdminManagListing"
                                    ? "active"
                                    : ""
                                    }`}
                                  onClick={() =>
                                    setActiveButton("SubAdminManagListing")
                                  }
                                >
                                  Manage Sub Admin
                                </Link>
                              </li>
                            </ul> */}
                        {/* </Accordion.Body>
                        </Accordion.Item> */}

                        <Accordion.Item eventKey="1">
                          <Accordion.Header>
                            {" "}
                            <span className="pr-2 fs-5">
                              <FaCog />
                            </span>{" "}
                            <p className="m-0">Manage Requests</p>
                          </Accordion.Header>
                          <Accordion.Body>
                            <ul className="nav nav-pills nav-sidebar flex-column">

                            </ul>

                            <ul className="nav nav-pills nav-sidebar flex-column">
                              <li className="nav-item">
                                <Link
                                  to={`/user/UnitRequest`}
                                  className={`nav-link ${activeButton === "UnitRequest"
                                    ? "active"
                                    : ""
                                    }`}
                                  onClick={() => setActiveButton("UnitRequest")}
                                >
                                  Unit Requests
                                </Link>
                              </li>
                            </ul>
                          </Accordion.Body>
                        </Accordion.Item>

                        <li className="nav-item menu-open">
                          <Link
                            to={`/user/PassRequest/${btoa(userId)}`}
                            className={`nav-link ${activeButton === "PassRequest" ? "active" : ""
                              }`}
                            onClick={() => setActiveButton("PassRequest")}
                          >
                            <span className="pr-2 fs-5">
                              <FaMoneyBill className="text-light" />
                            </span>{" "}
                            <p className="text-light">Passes List </p>
                          </Link>
                        </li>

                      </>
                    ) : (
                      <>
                        {/* CAM */}

                        <li className="nav-item menu-open">
                          <Link
                            to="/user/LookupUnit"
                            className={`nav-link ${activeButton === "LookupUnit" ? "active" : ""
                              }`}
                            onClick={() => setActiveButton("LookupUnit")}
                          >
                            {" "}
                            <span className="pr-2 fs-5">
                              <FaSearch className="text-light" />
                            </span>{" "}
                            <p className="text-light">Look-up</p>
                          </Link>
                        </li>

                        <Accordion.Item eventKey="0">
                          <Accordion.Header>
                            {" "}
                            <span className="pr-2 fs-5">
                              <FaCog />
                            </span>{" "}
                            <p className="m-0">Manage</p>
                          </Accordion.Header>
                          <Accordion.Body>
                            <ul className="nav nav-pills nav-sidebar flex-column">
                              <li className="nav-item menu-open">
                                <Link
                                  to="/user/PropertyForm"
                                  state={{
                                    id: propertyData?.id,
                                    isEdit: true,
                                  }}
                                  className={`nav-link ${activeButton === "PropertyForm"
                                    ? "active"
                                    : ""
                                    }`}
                                  onClick={() =>
                                    setActiveButton("PropertyForm")
                                  }
                                >
                                  Property Details
                                </Link>
                              </li>

                              <li className="nav-item">
                                <Link
                                  to={`/user/VendorListing/${btoa(
                                    propertyData?.id
                                  )}`}
                                  className={`nav-link ${activeButton === "VendorListing"
                                    ? "active"
                                    : ""
                                    }`}
                                  onClick={() =>
                                    setActiveButton("VendorListing")
                                  }
                                >
                                  Vendor
                                </Link>
                              </li>

                              <li className="nav-item">
                                <Link
                                  to={`/user/OwnerListing/${btoa(
                                    propertyData?.id
                                  )}`}
                                  className={`nav-link ${activeButton === "OwnerListing"
                                    ? "active"
                                    : ""
                                    }`}
                                  onClick={() =>
                                    setActiveButton("OwnerListing")
                                  }
                                >
                                  Unit
                                </Link>
                              </li>
                              <li className="nav-item">
                                <Link
                                  to={`/user/SecurityListing/${btoa(
                                    propertyData?.id
                                  )}`}
                                  className={`nav-link ${activeButton === "SecurityListing"
                                    ? "active"
                                    : ""
                                    }`}
                                  onClick={() =>
                                    setActiveButton("SecurityListing")
                                  }
                                >
                                  Security Guard
                                </Link>
                              </li>
                              <li className="nav-item">
                                <Link
                                  to={`/user/PaymentHistory/${btoa(
                                    propertyData?.id
                                  )}`}
                                  className={`nav-link ${activeButton === "PaymentHistory"
                                    ? "active"
                                    : ""
                                    }`}
                                  onClick={() =>
                                    setActiveButton("PaymentHistory")
                                  }
                                >
                                  Payment/Pass History
                                </Link>
                              </li>
                              <li className="nav-item">
                                <Link
                                  to={`/user/PassPricingListing/${btoa(
                                    propertyData?.id
                                  )}`}
                                  className={`nav-link ${activeButton === "PassPricingListing"
                                    ? "active"
                                    : ""
                                    }`}
                                  onClick={() =>
                                    setActiveButton("PassPricingListing")
                                  }
                                >
                                  Pass Prices
                                </Link>
                              </li>
                              <li className="nav-item">
                                <Link
                                  to={`/user/ManageRequest`}
                                  className={`nav-link ${activeButton === "ManageRequest"
                                    ? "active"
                                    : ""
                                    }`}
                                  onClick={() =>
                                    setActiveButton("ManageRequest")
                                  }
                                >
                                  Mgmt Co Unit Request
                                </Link>
                              </li>
                              <li className="nav-item">
                                <Link
                                  to={`/user/ManagePassRequest`}
                                  className={`nav-link ${activeButton === "ManagePassRequest"
                                    ? "active"
                                    : ""
                                    }`}
                                  onClick={() =>
                                    setActiveButton("ManagePassRequest")
                                  }
                                >
                                  Pass Date Change
                                </Link>
                              </li>
                              {/* <li className="nav-item">
                                <Link
                                  to={`/user/SamplePassListing/${btoa(
                                    propertyData.id
                                  )}`}
                                  className={`nav-link ${activeButton === "SamplePassListing"
                                    ? "active"
                                    : ""
                                    }`}
                                  onClick={() =>
                                    setActiveButton("SamplePassListing")
                                  }
                                >
                                  Sample Passes
                                </Link>
                              </li> */}
                              <li className="nav-item">
                                <Link
                                  to={`/user/ManageQRLocation`}
                                  className={`nav-link ${activeButton === "ManageQRLocation"
                                    ? "active"
                                    : ""
                                    }`}
                                  onClick={() =>
                                    setActiveButton("ManageQRLocation")
                                  }
                                >
                                  QR Location
                                </Link>
                              </li>

                            </ul>
                          </Accordion.Body>
                        </Accordion.Item>
                      </>
                    )}
                  </>
                )}

                <Accordion.Item eventKey="4">
                  <Accordion.Header>
                    <span className="pr-2 fs-5">
                      <FaUserCog />
                    </span>{" "}
                    <p className="m-0">Account Setting</p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <ul className="nav nav-pills nav-sidebar flex-column">
                      <li className="nav-item">
                        <Link
                          to="/user/EditProfile"
                          className={`nav-link ${activeButton === "EditProfile" ? "active" : ""
                            }`}
                          onClick={() => setActiveButton("EditProfile")}
                        >
                          <i className="fa fa-users nav-icon"></i>
                          <p>Update Profile</p>
                        </Link>
                      </li>

                      <li className="nav-item">
                        <Link
                          to="/user/ChangePassword"
                          className={`nav-link ${activeButton === "ChangePassword" ? "active" : ""
                            }`}
                          onClick={() => setActiveButton("ChangePassword")}
                        >
                          <i className="fa fa-users nav-icon"></i>
                          <p>Change Password</p>
                        </Link>
                      </li>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
                {Number(atob(roleId)) === 2 ? (
                  <li className="nav-item menu-open">
                    <Link
                      to="/Security"
                      state={{
                        id: propertyData?.id,
                        isEdit: true,
                      }}
                      className={`nav-link ${activeButton === "Security" ? "active" : ""
                        }`}
                      onClick={() => setActiveButton("Security")}
                    >
                      {" "}
                      <span className="pr-2 fs-5">
                        <BsQrCodeScan className="text-light" />
                      </span>{" "}
                      <p className="text-light">Scan The Pass</p>
                    </Link>
                  </li>

                ) : ""}
              </Accordion>
            </ul>
          </nav>
        </div>
      </aside>
    </>
  );
};

export default Sidebar;
