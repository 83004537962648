import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Select from "react-select";
import Api from "../../../Config/Api";
import { FaSearch } from "react-icons/fa";
import { toast } from "react-toastify";
import { defaultTheme } from 'react-autosuggest/dist/theme';
import Autosuggest from "react-autosuggest";
const Lookupunit = () => {
  const isAdmin = useSelector((state) => state.login.isAdmin);
  const property_Id = useSelector((state) => state.login.propertyId);
  const [propertyId, setPropertyId] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);
  const [propertyList, setPropertyList] = useState([]);
  const [PropertyAutoSuggestList, setPropertyAutoSuggestList] = useState([]);
  const [searchType, setSearchType] = useState("");
  const [searchName, setSearchName] = useState({
    text: "",
    name: "",
  });


  const navigate = useNavigate();
  const handleChange = (selectedOption, searchType) => {
    // console.log('selectedOption.value', selectedOption.value);
    setPropertyId(selectedOption.value); // selected value
    getPropertyUnit(selectedOption.value);
    setSearchType(searchType);
  };


  useEffect(() => {
    if (!!property_Id) {
      getPropertyUnit(property_Id);
      setPropertyId(property_Id);
    }
  }, [property_Id]);


  const handleSearchBy = (e, searchValue) => {
    const hiii = e.target.value;
    setSearchType(searchValue)
    switch (e.target.value) {
      case "Unit_name":
        setSearchName({
          ...searchName,
          text: "Number",
          name: "unit_name",
        });
        break;
      case "firstname":
        setSearchName({ ...searchName, text: "Name", name: "firstname" });
        break;
      case "name":
        setSearchName({ ...searchName, text: "Name", name: "name" });
        break;
      case "email":
        setSearchName({ ...searchName, text: "Email", name: "email" });
        break;
      case "company_name":
        setSearchName({
          ...searchName,
          text: "Company Name",
          name: "company_name",
        });
        break;
      case "phone":
        setSearchName({ ...searchName, text: "Phone", name: "phone" });
        break;
      case "point_of_fname":
        setSearchName({
          ...searchName,
          text: "Point of Contact Name",
          name: "point_of_fname",
        });
        break;
      case "poc_email":
        setSearchName({
          ...searchName,
          text: "Point of Contact Email",
          name: "poc_email",
        });
        break;
      case "passId":
        setSearchName({ ...searchName, text: "PassId", name: "passId" });
        break;

      default:
    }
    // console.log('searchBy ', searchBy );
  };


  console.log('searchName', searchName?.name);
  const handleSubmit = async (e) => {
    e.preventDefault();
    var proId = isAdmin ? propertyId : property_Id;
    const req = {
      unit: value,
      searchBy: searchName.name,
      propertyId: proId,
    };
    if (searchName.name && value) {
      const res = await Api("SearchUnit", "POST", req);
      if (res?.data?.id) {
        navigate("/user/GraphOwnerListing", {
          state: {
            unit: value,
            searchBy: searchName.name,
            propertyId: proId,
          },
        });
      } else {
        toast.error("No unit found.");
      }
    } else {
      toast.error("Please enter valid details.");
    }
  };

  const handleVendorSubmit = async (e) => {
    e.preventDefault();
    var proId = isAdmin ? propertyId : property_Id;
    const req = {
      vendor: value,
      searchBy: searchName.name,
      propertyId: proId,
    };

    if (searchName.name && value) {
      // if (propertyId) {
      // const res = await Api("SearchVendor", "POST", req);
      // console.log("res", res);
      // if (res?.data?.id) {
      //   navigate("/user/VendorDetail", {
      //     state: { id: res?.data?.id, isEdit: true },
      //   });
      // } else {
      //   toast.error("No Vendor found.");
      // }
      // } else {
      const res = await Api("SearchVendor", "POST", req);
      // console.log("res", res);
      if (res?.data) {
        navigate("/user/GraphVendorListing", {

          state: {
            vendor: value,
            searchBy: searchName.name,
            propertyId: proId,
          },
        });
      } else {
        toast.error("No Vendor found.");
      }
      // }
    } else {
      toast.error("Please enter valid details.");
    }
  };

  const handleSecuritySubmit = async (e) => {
    e.preventDefault();
    var proId = isAdmin ? propertyId : property_Id;
    const req = {
      security: value,
      searchBy: searchName.name,
      property_Id: proId,
    };
    if (searchName.name && value) {
      const res = await Api("SearchSecurity", "POST", req);
      // console.log("res", res);
      if (res?.data) {
        navigate("/user/SecurityListing", {
          state: {
            security: value,
            searchBy: searchName.name,
            propertyId: proId,
          },
        });
      } else {
        toast.success("No Security found.");
      }
    } else {
      toast.error("Please enter valid details.");
    }
  };

  const handlePassesSubmit = async (e) => {
    // console.log("e", e);
    e.preventDefault();
    var proId = isAdmin ? propertyId : property_Id;
    const req = {
      passes: value,
      searchBy: searchName.name,
      property_Id: proId,
    };
    if (searchName.name && value) {
      const res = await Api("SearchPasses", "POST", req);
      // console.log("resPasses", res);
      if (res?.data) {
        navigate("/user/PaymentHistory", {
          state: {
            passes: value,
            searchBy: searchName.name,
            propertyId: proId,
          },
        });
      } else {
        toast.success("No Passes found.");
      }
    } else {
      toast.error("Please enter valid details.");
    }
  };

  var options = [];
  const getPropertyDetails = async () => {
    const { data } = await Api("get-property", "POST", "");
    if (data?.status) {
      setPropertyList(data?.data);
    }
  };

  const getPropertyUnit = async (id) => {
    try {
      const { data } = await Api("get-property", "POST", { id: id });
      // console.log("data", data);
      if (data?.status) {
        setPropertyAutoSuggestList(data?.data);
      }
    } catch (error) {
      console.error("Error fetching property:", error);
    }
  };
  useEffect(() => {
    getPropertyDetails();
  }, []);

  propertyList?.map((state, i) => {
    options?.push({
      value: state?.id,
      label: state?.name,
    });
  });
  options?.unshift({
    value: '',
    label: 'Select Option',
  })

  var users;
  console.log("PropertyAutoSuggestListgvjhjhjhg", PropertyAutoSuggestList);
  if (searchType === "unit") {
    users = propertyId
      ? PropertyAutoSuggestList?.units
      : PropertyAutoSuggestList?.map((data) => data?.units).flat();
    // console.log("users>>>>>u", users);
  } else if (searchType === "vendor") {
    users = propertyId ? PropertyAutoSuggestList?.vendors
      : PropertyAutoSuggestList?.map((data) => data?.vendors).flat();
    // console.log("users>>>>>v", users);
  } else if (searchType === "security") {
    // users = PropertyAutoSuggestList?.security;
    users = propertyId ? PropertyAutoSuggestList?.security
      : PropertyAutoSuggestList?.map((data) => data?.security).flat();
    // console.log("users>>>>>s", users);
    console.log("PropertyAutoSuggestList", PropertyAutoSuggestList);

  } else if (searchType === "passes") {
    if (searchName?.name === "passId" && propertyId) {
      users = PropertyAutoSuggestList?.vehiclepasses?.map((data) => data).flat();
    } else if (!propertyId && searchName?.name === "passId") {
      users = PropertyAutoSuggestList?.map((data) => data?.vehiclepasses).flat();
    } else if (searchName?.name !== "passId" && propertyId) {
      console.log("hlo");
      users = PropertyAutoSuggestList?.renter_data;
    } else if (!propertyId && searchName?.name !== "passId") {
      console.log("hii")
      users = PropertyAutoSuggestList?.map((data) => data?.renter_data).flat();
    }

  }
  console.log("users>>>>>p333333333", users);


  const getSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    if (inputLength === 0) {
      return [];
    } else if (searchType === "unit") {
      // console.log('users', users);
      return users?.length > 0
        ? users.filter((user) =>
          propertyId
            ? user.unit_name.toLowerCase().slice(0, inputLength) === inputValue ||
            user.email.toLowerCase().slice(0, inputLength) === inputValue ||
            user.firstname.toLowerCase().slice(0, inputLength) === inputValue
            : user.unit_name.toLowerCase().slice(0, inputLength) === inputValue ||
            user.email.toLowerCase().slice(0, inputLength) === inputValue ||
            user.firstname.toLowerCase().slice(0, inputLength) === inputValue
        )
        : [];
    }
    // Add conditions for other search types if needed
    else if (searchType === "vendor") {
      return users?.length > 0 ? users.filter(
        (user) =>
          propertyId ?
            user.company_name.toLowerCase().slice(0, inputLength) ===
            inputValue ||
            user.poc_email.toLowerCase().slice(0, inputLength) === inputValue ||
            user.phone.toLowerCase().slice(0, inputLength) === inputValue ||
            user.point_of_fname.toLowerCase().slice(0, inputLength) === inputValue
            : user.company_name.toLowerCase().slice(0, inputLength) ===
            inputValue ||
            user.poc_email.toLowerCase().slice(0, inputLength) === inputValue ||
            user.phone.toLowerCase().slice(0, inputLength) === inputValue ||
            user.point_of_fname.toLowerCase().slice(0, inputLength) === inputValue
      ) : [];
    } else if (searchType === "security") {
      // console.log("usersSSSSSSSS", users);
      return users?.length > 0 ? users.filter(
        (user) =>
          propertyId ?
            user.name.toLowerCase().slice(0, inputLength) === inputValue ||
            user.email.toLowerCase().slice(0, inputLength) === inputValue ||
            user.phone.toLowerCase().slice(0, inputLength) === inputValue
            : user.name.toLowerCase().slice(0, inputLength) === inputValue ||
            user.email.toLowerCase().slice(0, inputLength) === inputValue ||
            user.phone.toLowerCase().slice(0, inputLength) === inputValue
      ) : [];
    }
    else if (searchType === "passes") {
      console.log("hello ", users);

      return users?.length > 0 ? users.filter(
        (user) =>
          propertyId ?
            user?.name?.toLowerCase().slice(0, inputLength) === inputValue ||
            user?.order_uuid?.toLowerCase().slice(0, inputLength) === inputValue ||
            user?.email?.toLowerCase().slice(0, inputLength) === inputValue ||
            user?.phone?.toLowerCase().slice(0, inputLength) === inputValue
            : user?.name?.toLowerCase().slice(0, inputLength) === inputValue ||
            user?.order_uuid?.toLowerCase().slice(0, inputLength) === inputValue ||
            user?.email?.toLowerCase().slice(0, inputLength) === inputValue ||
            user?.phone?.toLowerCase().slice(0, inputLength) === inputValue

      ) : [];
    }
  };

  const getSuggestionValue = (suggestion, type) => {
    console.log("suggestion", suggestion);
    if (searchType == "unit") {
      if (type == "unit_name") {
        return suggestion.unit_name;
      } else if (type == "firstname") {
        return suggestion.firstname;
      } else {
        return suggestion.email;
      }
    } else if (searchType == "vendor") {
      if (type == "company_name") {
        return suggestion.company_name;
      } else if (type === "poc_email") {
        return suggestion.poc_email;
      } else if (type === "phone") {
        return suggestion.phone;
      } else if (type === "point_of_fname") {
        return suggestion.point_of_fname;
      }
    } else if (searchType == "security") {
      if (type == "email") {
        return suggestion.email;
      } else if (type == "phone") {
        return suggestion.phone;
      } else if (type == "name") {
        return suggestion.name;
      }
    } else if (searchType == "passes") {
      if (type == "email") {
        return suggestion.email;
      } else if (type == "phone") {
        return suggestion.phone;
      } else if (type == "name") {
        return suggestion.name;
      } else if (type == "passId") {
        return suggestion.order_uuid;
      }
    }
  };

  const renderSuggestion = (suggestion, type) => {
    // console.log("type", type);
    // console.log("suggestion", suggestion);
    let displayValue;
    if (type === "unit_name") {
      displayValue = suggestion.unit_name;
    } else if (type === "firstname") {
      displayValue = suggestion.firstname;
    } else if (type === "name") {
      displayValue = suggestion.name;
    } else if (type === "email") {
      displayValue = suggestion.email;
    } else if (type === "company_name") {
      displayValue = suggestion.company_name;
    } else if (type === "poc_email") {
      displayValue = suggestion.poc_email;
    } else if (type === "phone") {
      displayValue = suggestion.phone;
    } else if (type === "passId") {
      displayValue = suggestion.order_uuid;
    } else if (type === "point_of_fname") {
      displayValue =
        suggestion.point_of_fname + " " + suggestion.point_of_lname;
    }

    return <div className="text-dark text-bold">{displayValue}</div>;
  };

  const [value, setValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);

  const onChange = (event, { newValue }) => {
    // console.log('newValue', newValue);
    setValue(newValue);
    getPropertyUnit();

  };

  const onSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(getSuggestions(value));
    console.log("suggestions1", suggestions);
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
    console.log("suggestions2", suggestions);
  };

  const valuefor =
    searchType === "vendor"
      ? "vendor"
      : searchType === "unit"
        ? "unit"
        : searchType === "security"
          ? "security"
          : searchType === "passes"
            ? "passes"
            : "";
  const inputProps = {
    placeholder: "Search...",
    className: "form-control",
    value,
    onChange,
  };

  return (
    <>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Look-up</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to={"/user/"}>Home </Link>
                  </li>
                  <span className="breadcrumb-arrow">&gt;</span>
                  <li className="breadcrumb-item active">Look Up</li>
                </ol>
              </div>
            </div>
          </div>
        </div>

        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div
                    className={`card-header ${isAdmin ? "adminColor" : "cambg-secondary"
                      }`}
                  >
                    <div className="add_new_vendor_listing">
                      <div className="">
                        <h3 className="card-title mt-2"> Look-up </h3>
                      </div>
                    </div>
                  </div>

                  <div className="card-body">
                    <div className="alert alert-primary">
                      <h4 className="mb-3">Unit Look-up</h4>
                      <div>
                        <div className="row">
                          {isAdmin && (
                            <div className={`col-lg-${isAdmin ? 4 : 6}`}>
                              <Select
                                options={options}
                                defaultValue={selectedOption}
                                onChange={(selectedOption) =>
                                  handleChange(selectedOption, "unit")
                                }
                                placeholder="Search Property"
                              />
                            </div>
                          )}
                          <div className={`col-lg-${isAdmin ? 4 : 6}`}>
                            <Form.Select
                              aria-label="Default select example"
                              size="sm"
                              onChange={(e) =>
                                handleSearchBy(e, "unit")
                              }
                            // onChange={handleSearchBy}
                            >
                              <option>Search By </option>
                              <option value="Unit_name">Unit Number</option>
                              <option value="firstname">Owner Name</option>
                              <option value="email">Owner Email</option>
                            </Form.Select>
                          </div>
                          <div className={`col-lg-${isAdmin ? 4 : 6}`}>
                            <form onSubmit={handleSubmit}>
                              {searchType == "unit" && (
                                <InputGroup className="mb-3">
                                  <Autosuggest
                                    suggestions={suggestions}
                                    onSuggestionsFetchRequested={
                                      onSuggestionsFetchRequested
                                    }
                                    onSuggestionsClearRequested={
                                      onSuggestionsClearRequested
                                    }
                                    getSuggestionValue={(suggestion) =>
                                      getSuggestionValue(
                                        suggestion,
                                        searchName.name
                                      )
                                    }
                                    renderSuggestion={(suggestion) =>
                                      renderSuggestion(
                                        suggestion,
                                        searchName.name
                                      )
                                    }
                                    inputProps={inputProps}
                                    theme={{
                                      ...defaultTheme,
                                      container: "flex-grow-1",
                                    }
                                    }
                                  />
                                  <InputGroup.Text id="basic-addon2">
                                    <button type="submit" className="border-0">
                                      {" "}
                                      <FaSearch />
                                    </button>
                                  </InputGroup.Text>
                                </InputGroup>
                              )}
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="alert alert-success">
                      <h4 className="mb-3">Vendor Look-up</h4>
                      <div>
                        <div className="row">
                          {isAdmin && (
                            <div className={`col-lg-${isAdmin ? 4 : 6}`}>
                              <Select
                                options={options}
                                defaultValue={selectedOption}
                                onChange={(selectedOption) =>
                                  handleChange(selectedOption, "vendor")
                                }
                                placeholder="Search Property"
                              />
                            </div>
                          )}
                          <div className={`col-lg-${isAdmin ? 4 : 6}`}>
                            <Form.Select
                              aria-label="Default select example"
                              size="sm"
                              // onChange={handleSearchBy}
                              onChange={(e) =>
                                handleSearchBy(e, "vendor")
                              }
                            >
                              <option>Search By </option>
                              <option value="company_name">Company Name</option>
                              <option value="point_of_fname">
                                Point of Contact Name
                              </option>
                              <option value="poc_email">
                                Point of Contact Email
                              </option>
                              <option value="phone">
                                Point of Contact Phone
                              </option>
                            </Form.Select>
                          </div>
                          <div className={`col-lg-${isAdmin ? 4 : 6}`}>
                            <form onSubmit={handleVendorSubmit}>

                              {searchType == "vendor" && (
                                <InputGroup className="mb-3">
                                  <Autosuggest
                                    suggestions={suggestions}
                                    onSuggestionsFetchRequested={
                                      onSuggestionsFetchRequested
                                    }
                                    onSuggestionsClearRequested={
                                      onSuggestionsClearRequested
                                    }
                                    getSuggestionValue={(suggestion) =>
                                      getSuggestionValue(
                                        suggestion,
                                        searchName.name
                                      )
                                    }
                                    renderSuggestion={(suggestion) =>
                                      renderSuggestion(
                                        suggestion,
                                        searchName.name
                                      )
                                    }
                                    inputProps={inputProps}
                                    theme={{
                                      ...defaultTheme,
                                      container: "flex-grow-1",
                                    }
                                    }
                                  />
                                  <InputGroup.Text id="basic-addon2">
                                    <button type="submit" className="border-0">
                                      {" "}
                                      <FaSearch />
                                    </button>
                                  </InputGroup.Text>
                                </InputGroup>
                              )}
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="alert alert-info">
                      <h4 className="mb-3">Security Look-up</h4>
                      <div>
                        <div className="row">
                          {isAdmin && (
                            <div className={`col-lg-${isAdmin ? 4 : 6}`}>
                              <Select
                                options={options}
                                defaultValue={selectedOption}
                                onChange={(selectedOption) =>
                                  handleChange(selectedOption, "security")
                                }
                                placeholder="Search Property"
                              />
                            </div>
                          )}
                          <div className={`col-lg-${isAdmin ? 4 : 6}`}>
                            <Form.Select
                              aria-label="Default select example"
                              size="sm"
                              // onChange={handleSearchBy}
                              onChange={(e) =>
                                handleSearchBy(e, "security")
                              }
                            >
                              <option>Search By </option>
                              <option value="name">Security Name</option>
                              <option value="email">Security Email</option>
                              <option value="phone">Security Phone</option>
                            </Form.Select>
                          </div>

                          <div className={`col-lg-${isAdmin ? 4 : 6}`}>
                            <form onSubmit={handleSecuritySubmit}>
                              {searchType == "security" && (
                                <InputGroup className="mb-3">
                                  <Autosuggest
                                    suggestions={suggestions}
                                    onSuggestionsFetchRequested={
                                      onSuggestionsFetchRequested
                                    }
                                    onSuggestionsClearRequested={
                                      onSuggestionsClearRequested
                                    }
                                    getSuggestionValue={(suggestion) =>
                                      getSuggestionValue(
                                        suggestion,
                                        searchName.name
                                      )
                                    }
                                    renderSuggestion={(suggestion) =>
                                      renderSuggestion(
                                        suggestion,
                                        searchName.name
                                      )
                                    }
                                    inputProps={inputProps}
                                    theme={{
                                      ...defaultTheme,
                                      container: "flex-grow-1",
                                    }
                                    }
                                  />
                                  <InputGroup.Text id="basic-addon2">
                                    <button type="submit" className="border-0">
                                      {" "}
                                      <FaSearch />
                                    </button>
                                  </InputGroup.Text>
                                </InputGroup>
                              )}
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="alert alert-info">
                      <h4 className="mb-3">Passes Look-up</h4>
                      <div>
                        <div className="row">
                          {isAdmin && (
                            <div className={`col-lg-${isAdmin ? 4 : 6}`}>
                              <Select
                                options={options}
                                defaultValue={selectedOption}
                                onChange={(selectedOption) =>
                                  handleChange(selectedOption, "passes")
                                }
                                placeholder="Search Property"
                              />
                            </div>
                          )}
                          <div className={`col-lg-${isAdmin ? 4 : 6}`}>
                            <Form.Select
                              aria-label="Default select example"
                              size="sm"
                              // onChange={handleSearchBy}
                              onChange={(e) =>
                                handleSearchBy(e, "passes")
                              }
                            >
                              <option>Search By </option>
                              <option value="passId">Pass Id</option>
                              <option value="name">Renter Name</option>
                              <option value="email">Renter Email</option>
                              <option value="phone">Renter Phone</option>
                            </Form.Select>
                          </div>

                          <div className={`col-lg-${isAdmin ? 4 : 6}`}>
                            <form onSubmit={handlePassesSubmit}>
                              {searchType == "passes" && (
                                <InputGroup className="mb-3">
                                  <Autosuggest
                                    suggestions={suggestions}
                                    onSuggestionsFetchRequested={
                                      onSuggestionsFetchRequested
                                    }
                                    onSuggestionsClearRequested={
                                      onSuggestionsClearRequested
                                    }
                                    getSuggestionValue={(suggestion) =>
                                      getSuggestionValue(
                                        suggestion,
                                        searchName.name
                                      )
                                    }
                                    renderSuggestion={(suggestion) =>
                                      renderSuggestion(
                                        suggestion,
                                        searchName.name
                                      )
                                    }
                                    inputProps={inputProps}
                                    theme={{
                                      ...defaultTheme,
                                      container: "flex-grow-1",
                                    }
                                    }
                                  />
                                  <InputGroup.Text id="basic-addon2">
                                    <button type="submit" className="border-0">
                                      {" "}
                                      <FaSearch />
                                    </button>
                                  </InputGroup.Text>
                                </InputGroup>
                              )}
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Lookupunit;
