import swal from 'sweetalert';
import Api from '../Config/Api';
import { useState } from 'react';

const useSweetAlert = () => {
   const [isDeleted,setIsDeleted] = useState(false);
    
      const deletefun=(url,id,status="",unitId="",userId="")=>{
        swal({
            title: "Confirm?",
            // text: "Did you really initiate this action?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          })
          .then(async (willDelete) => {
            if (willDelete) {
              const { data } =await Api(url, "POST", { id,status,unitId,userId});
              console.log('data',data.status);
              if(data?.status){
                setIsDeleted(true);
              }else{
                setIsDeleted(false);
              }
              swal((data?.success ? data?.success : ""), {
                icon: "success",
              });
            } else {
              swal("Your record is safe!",{
                icon:"success"
              });
            }
          });
      }
     

      return {deletefun,isDeleted};
}

export default useSweetAlert