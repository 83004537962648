import React, { useState } from "react";
import CKEditor from "react-ckeditor-component";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { async } from "q";
import Api from "../../../Config/Api";
import { toast } from "react-toastify";
const EmailTemplateView = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();
  const isAdmin = useSelector((state) => state.login.isAdmin);
  const roleId = useSelector((state) => state.login.roleId);
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const [templateData, setTemplateData] = useState(location?.state?.templateData);
  const [htmlSource, setHtmlSource] = useState(location?.state?.templateData?.template);

  const [state, setState] = useState({
    content: location?.state?.templateData?.template,
  });
  const [templateId, setTemplateId] = useState(location?.state?.templateData?.id);
  const [subject,setSubject] = useState(location?.state?.templateData?.subject);

 

  const onChange = (event) => {
    const newContent = event.editor.getData();
    setState({
      content: newContent,
    });
  };

  const onBlur = (evt) => {
    // console.log("onBlur event called with event info: ", evt);
  };

  const afterPaste = (evt) => {
    // console.log("afterPaste event called with event info: ", evt);
  };


  const onSubmit = async (data) => {
    console.log("CKEditor Content:", state.content);
    // console.log("Form Data:", data);
    const req = {
        id:templateId,
        subject:data?.subject,
        template: state.content,
    }

    const res = await Api('updateEmailTemplate',"POST",req);
    if(Number(res?.data?.status) === 1){
        toast.success(`Successfully updated email template`);
        navigate('/user/EmailTemplateListing');
        }else{
            toast.error(`${res}`);
            }
    

  };


  return (
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0">Email Templates</h1>
            </div>

            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                  <span className="breadcrumb-arrow">&gt;</span>
                </li>
                <Link
                          to="/user/EmailTemplateListing"
                          className={
                            location.pathname.startsWith("/user/EmailTemplateListing")
                              ? "breadcrumb-active"
                              : "breadcrumb-not-active"
                          }
                        >
                          Email Template Listing
                        </Link>
                <span className="breadcrumb-arrow">&gt;</span>

                <li className="breadcrumb-item active">Email Templates</li>
              </ol>
            </div>
          </div>
        </div>
      </div>

      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card card-primary">
                <div
                  className={`card-header ${
                    isAdmin || Number(atob(roleId)) === 4
                      ? "adminColor"
                      : "cambg-secondary"
                  }`}
                >
                  <h3 className="card-title">Email Templates</h3>
                </div>

                <div className="container my-3">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label htmlFor="address">Email Subject</label>
                        <input
                          type="text"
                          className="form-control"
                          defaultValue={subject ? subject : null}
                          onChange={(e)=>e.target.value}
                          placeholder="Address of property"
                          {...register("subject", { required: true })}
                        />

                        {errors.subject && (
                          <p role="alert" className="text-danger">
                            Subject is required
                          </p>
                        )}
                      </div>
                    </div>

                    <CKEditor
                      activeClass="p10"
                      content={state.content}
                      events={{
                        blur: onBlur,
                        afterPaste: afterPaste,
                        change: onChange,
                      }}
                      config={{
                        allowedContent: true,
                      }}
                    />
                    <div className="card-footer">
                      <div className="text-end">
                        <button
                          type="submit"
                          variant="primary"
                          className={` btn btn-primary  ${
                            isAdmin ||
                            location?.state?.managementCo !== undefined
                              ? ""
                              : "cambg-secondary border-0"
                          }`}
                          disabled={isLoading}
                        >
                          {isLoading ? (
                            <>
                              <Spinner animation="border" size="sm" /> Please
                              wait...
                            </>
                          ) : (
                            "Submit"
                          )}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default EmailTemplateView;
