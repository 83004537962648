import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import beachWarning from "../../assets/img/beach-warning-flags.png";
import Form from "react-bootstrap/Form";
import { useSelector } from "react-redux";
import Select from "react-select";
import { fetchState, getState } from "../../Reducers/State";
import { getProperty } from "../../Reducers/RenterProperty";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import Api from "../../Config/Api";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { BsFillCheckCircleFill } from "react-icons/bs";
import Table from "react-bootstrap/Table";
import Card from "react-bootstrap/Card";
import Accordion from "react-bootstrap/Accordion";
import { useNavigate } from "react-router-dom";
import StripeCheckout from "react-stripe-checkout";
import Loader from "../Elements/Loader";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "../Dashboard/Stripe/CheckoutForm";
import { setUser } from "../../Reducers/Login";
import dayjs from "dayjs";

const stripePromise = loadStripe(
  "pk_test_51KprQoSIhYu4VymDDfkjl6eNS62NO2aTPuMNyknL94EsNKISVQ8ZwbtSQ1Xa7fgpCp4HpexKZjTYifr8R6e3qOl400ecMQAgHD"
);

const RenterCheckout = () => {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();
  const [selectedOption, setSelectedOption] = useState();
  const [stateCode, setStateCode] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedUnit, setSelectedUnit] = useState("");
  const [sameAddressChecked, setSameAdressChecked] = useState(false);
  const [price, setPrice] = useState(0);
  const [billingId, setBillingId] = useState(null);
  const [passInfo, setPassInfo] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [isLoadingSubmit, setLoadingSubmit] = useState(false);
  const [couponBox, setCouponBox] = useState(false);
  const stateData = useSelector(getState);
  const [couponCode, setCouponCode] = useState("");
  const RenterProperty = useSelector(getProperty);
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const location = useLocation();
  const [clientSecret, setClientSecret] = useState("");
  const [copyAddress, setCopyAddress] = useState(false);
  const handleClose = () => setShow(false);
  const [emailId, setEmailId] = useState('');
  const [passStatus, setPassStatus] = useState("");
  const { data } = location.state ? location.state : [];
  const [phone, setPhone] = useState();

  const vehiclePassId = data?.id ? atob(data?.id) : "";
  const background_image = RenterProperty?.bg_image
    ? process.env.REACT_APP_AWS_S3_BUCKET_URL + "images/" + RenterProperty?.bg_image
    : "";
  const property_logo = RenterProperty?.logo
    ? process.env.REACT_APP_AWS_S3_BUCKET_URL +
    "images/" + RenterProperty?.logo
    : "";

  const sectionStyle = {
    backgroundImage: `url(${background_image})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  };

  const handleChange = (selectedOption) => {
    setSelectedState(selectedOption.value);
    setStateCode(selectedOption["data-key"]);
  };

  const handleSameAddress = (event) => {
    setSameAdressChecked(event.target.checked);

    if (event.target.checked) {
      reset({
        country: passInfo?.country,
        address: passInfo?.address,
        address2: passInfo?.address2,
        city: passInfo?.city,
        zipcode: passInfo?.zipcode,
      });
    } else {
      reset({
        country: '',
        address: '',
        address2: '',
        city: '',
        zipcode: '',
      });
    }
  };

  useEffect(() => {
    dispatch(fetchState());
  }, []);

  const onToken = (token) => {
    console.log("onToken", token);
  };

  var options = [];
  stateData?.state?.map((state, i) => {
    options?.push({
      value: state?.name,
      label: state?.name,
      "data-key": state.code,
    });
  });

  var Unitoptions = [];
  RenterProperty?.units?.map((unit, i) => {
    Unitoptions?.push({
      value: unit?.id,
      label: unit?.unit_name,
    });
  });

  const getCheckOutDetails = async () => {
    setLoading(true);
    const res = await Api("getVehiclepassById", "POST", { id: vehiclePassId });
    console.log("res", res);
    // console.log("res<>>>>>>>>>", res);
    if (res?.data?.status) {
      setLoading(false);
      setPassInfo(res?.data?.data);
      reset(res?.data?.data?.user);
      setEmailId(res?.data?.data?.user?.email);
      setPrice(res?.data?.data?.pass_fee);
      setPhone(res?.data?.data?.user?.phone);

     



    }
  };

  console.log("passStatus", passStatus);
  useEffect(() => {
    getCheckOutDetails();
  }, [vehiclePassId]);


  const onSubmit = async (data) => {
    setLoadingSubmit(true)
    const renterRequest = {
      name: data.name,
      lastname: data.lastname,
      address: data.address,
      address2: data.address2,
      email: data.email,
      companyName: data.companyName ? data.companyName : '',
      country: data.country,
      city: data.city,
      zipcode: data.zipcode,
      phone: data.phone,
      property_id: RenterProperty?.id,
      vehiclePassId: vehiclePassId,
      created_by: passInfo?.user?.id,
      price: price,
      // pass_date_status: passStatus,
    };
    console.log("renterRequest", renterRequest);

    await fetchQRCodeData(passInfo?.order_uuid);
    setShow(true);
    fetchsecretKey(renterRequest);

    setLoadingSubmit(false)
  };

  console.log("data", data);

  const fetchQRCodeData = async (orderuuId) => {
    try {
      await Api(`generateQRCode`, "POST", { orderuuId: orderuuId });
    } catch (error) {
      console.error("Error fetching QR code data:", error);
      toast.error("Error fetching QR code data:", error, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleSubmitCoupon = (e) => {
    e.preventDefault();
    setPrice(0);
    setCouponBox(true);
  };

  const handleInputChange = (e) => {
    setCouponCode(e.target.value);
  };

  const fetchsecretKey = async (req) => {
    const res = await Api("create-payment-intent", "POST", req);
    setClientSecret(res?.data?.clientSecret);
    setBillingId(res?.data?.biilingId);
  };

  const appearance = {
    theme: "stripe",
  };
  const StripeOptions = {
    clientSecret,
    appearance,
  };

  useEffect(() => {
    setPhone(phone || ''); // Use an empty string if 'phone' is undefined
  }, [phone]);

  const handleInputChange2 = (event) => {
    const rawPhoneNumber = event.target.value.replace(/\D/g, '');
    const truncatedPhoneNumber = rawPhoneNumber.slice(0, 10);
    const formattedPhoneNumber = truncatedPhoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
    
    // Set the phone value directly using setValue from react-hook-form
    setValue('phone', formattedPhoneNumber);
  
    // Update the state with the formatted phone number
    setPhone(formattedPhoneNumber);
  };

  return (
    <>
      <main>
        <section
          className="py-5 guest_section bannersection"
          style={sectionStyle}
        >
          <div className="container-fluid" style={{
            paddingLeft: "269px"
          }}>
            <div className="new_guest_form">
              <div className="returning_heading">
                <h1 className="pb-3 text-center">Registration Completed</h1>
              </div>

              {clientSecret && (
                <Elements options={StripeOptions} stripe={stripePromise}>
                  <CheckoutForm
                    handleClose={handleClose}
                    show={show}
                    billingId={billingId}
                    emailId={emailId}
                  />
                </Elements>
              )}

              <div className="mb-3">
                {/* <form onSubmit={handleSubmitCoupon}>
                  <Accordion flush>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header
                        style={{ backgroundColor: "#000" }}
                        className="cambg-primary rounded-top"
                      >
                        Have a Coupon?{" "}
                        <span className="text-decoration-underline text-info">
                          Click here to enter your code
                        </span>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="row">
                          <div className="col-lg-6">
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>
                                if you have a coupon, Please Apply it below.
                              </Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="FXKYRN124"
                                value={couponCode}
                                onChange={handleInputChange}
                              />
                            </Form.Group>
                          </div>
                          <div className="col-lg-4">
                            <div className="pt-4 mt-2"></div>
                            <button className="btn btn-primary cambg-secondary border-0">
                              Apply Coupon
                            </button>
                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </form> */}
              </div>

              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                  <div className="col-lg-7">
                    <h3 className="pb-3">Billing Details</h3>
                    <div className="row align-items-center">
                      <div className="col-md-6">
                        <div className="form_input_guest">
                          <label className="guest_label">First Name</label>
                          <input
                            type="text"
                            {...register("name", { required: true })}
                            placeholder="Enter First Name"
                          />

                          {errors.name && (
                            <p className="text-danger">
                              First name is required
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form_input_guest">
                          <label className="guest_label">Last Name</label>
                          <input
                            type="text"
                            {...register("lastname", { required: true })}
                            placeholder="Enter Last Name"
                          />

                          {errors.lastname && (
                            <p className="text-danger">Last name is required</p>
                          )}
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="form_input_guest">
                          <label className="guest_label">
                            Company Name (Optional)
                          </label>
                          <input
                            type="text"
                            {...register("companyName")}
                            placeholder="Enter Company Name"
                          />

                          {errors.companyName && (
                            <p className="text-danger">
                              Company Name is required
                            </p>
                          )}
                        </div>
                      </div>

                      <div className="col-md-12 mx-2">
                        <Form.Group className="mb-3">
                          <Form.Check
                            label={
                              <span className="h5" style={{ fontWeight: "bold" }}>
                                Same as Guest Address
                              </span>
                            }
                            id="rentalvehicle"
                            feedback="You must agree before submitting."
                            feedbackType="invalid"
                            onClick={handleSameAddress}
                          />
                        </Form.Group>
                      </div>
                      <div className="col-md-12">
                        <div className="form_input_guest">
                          <div>
                            <label className="guest_label">
                              Country / Region
                            </label>
                          </div>
                          <input
                            type="text"
                            {...register("country", { required: true })}
                            placeholder="Enter Country Name"
                          />

                          {errors.country && (
                            <p className="text-danger">Country is required</p>
                          )}
                        </div>
                      </div>

                      <div className="row">
                        <label className="guest_label"> Street Address</label>
                        <div className="col-md-6">
                          <div className="form_input_guest">
                            <input
                              type="text"
                              {...register("address")}
                              placeholder="House number and street name"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form_input_guest">
                            <input
                              type="text"
                              {...register("address2")}
                              placeholder="Apartment, suite, unit, etc. (optional)"
                            />
                          </div>
                        </div>
                        {errors.address && (
                          <p className="text-danger">
                            Street Address is required
                          </p>
                        )}
                      </div>

                      <div className="col-md-6">
                        <div className="form_input_guest">
                          <label className="guest_label">Town / City</label>
                          <input
                            type="text"
                            {...register("city", { required: true })}
                            placeholder="Enter City Name"
                          />

                          {errors.city && (
                            <p className="text-danger">City is required</p>
                          )}
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form_input_guest">
                          <label className="guest_label">Zip Code</label>
                          <input
                            type="text"
                            {...register("zipcode", { required: true })}
                            placeholder="Enter Zip Code"
                          />

                          {errors.zipcode && (
                            <p className="text-danger">Zip Code is required</p>
                          )}
                        </div>
                      </div>

                      <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="enterNumber">Phone</label>
                              <input
                                type="text"
                                value={phone}
                                onChange={handleInputChange2}
                                className="form-control"
                                id="enterNumber"
                                placeholder="Enter Phone Number"
                                required={true}
                              />
                            </div>
                          </div>
                      <div className="col-md-6">
                        <div className="form_input_guest">
                          <label className="guest_label">E-mail Address</label>
                          <input
                            type="email"
                            {...register("email", { required: true })}
                            placeholder="E-mail Address"
                          />

                          {errors.email && (
                            <p className="text-danger">
                              E-mail Address is required
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {isLoading ? (
                    <>
                      <div className="col-lg-5">
                        <Loader />
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="col-lg-5">
                        <div className="row">
                          <div className="col-lg-12 ">
                            <h3 className="pb-4 text-center">Your Order</h3>
                            <div className="cambg-primary rounded-top text-white">
                              <div className="p-3">
                                <Table striped="columns">
                                  <tr>
                                    <th className="border-0">
                                      Parking Price :
                                    </th>
                                    <td className="border-0">
                                      ${Number(price).toFixed(2)}
                                    </td>
                                  </tr>

                                  <tr>
                                    <th>Passtype :</th>
                                    <td>{passInfo?.Passtype}</td>
                                  </tr>

                                  <tr>
                                    <th>Unit Number :</th>
                                    <td>{passInfo?.unit?.unit_name}</td>
                                  </tr>

                                  <tr>
                                    <th>Number of Guests</th>
                                    <td>{passInfo?.no_of_guests}</td>
                                  </tr>

                                  <tr>
                                    <th>Arrival Date :</th>
                                    <td>
                                      {
                                        dayjs(passInfo?.arrival_date).format("MM/DD/YYYY")}</td>
                                  </tr>

                                  <tr>
                                    <th>Departure Date :</th>
                                    <td>
                                      {
                                        dayjs(passInfo?.departure_date).format("MM/DD/YYYY")}</td>
                                  </tr>

                                  <tr>
                                    <th>License Plate Number :</th>
                                    <td>{passInfo?.license_plate}</td>
                                  </tr>

                                  <tr>
                                    <th>Full Name :</th>
                                    <td>
                                      {passInfo?.user?.name +
                                        " " +
                                        passInfo?.user?.lastname}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th>Rule & Regulations :</th>
                                    <td>
                                      <span className="text-light mx-3 ">
                                        <BsFillCheckCircleFill className="h5 mt-2" />
                                      </span>
                                    </td>
                                  </tr>

                                  <tr>
                                    <th>Each Flag Warning System :</th>
                                    <td>
                                      {" "}
                                      <span className="text-light mx-3 ">
                                        <BsFillCheckCircleFill className="h5 mt-2" />
                                      </span>
                                    </td>
                                  </tr>

                                  <tr>
                                    <th>Non-Refundable :</th>
                                    <td>
                                      {" "}
                                      <span className="text-light mx-3 ">
                                        <BsFillCheckCircleFill className="h5 mt-2" />
                                      </span>
                                    </td>
                                  </tr>
                                </Table>
                              </div>
                            </div>
                            {couponBox && (
                              <Card>
                                <Card.Body>
                                  <table className="table">
                                    <thead>
                                      <tr>
                                        <th scope="col">SubTotal</th>
                                        <th scope="col">Coupon </th>
                                        <th scope="col">Tax </th>
                                        <th scope="col">Total </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>
                                          $
                                          {Number(
                                            passInfo?.pass_fee
                                          ).toFixed(2)}
                                        </td>
                                        <td>{couponCode}</td>
                                        <td>{Number(0).toFixed(2)}</td>
                                        <td>${Number(price).toFixed(2)}</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </Card.Body>
                              </Card>
                            )}
                            {/* <Card>
                              <Card.Body>
                                Your personal data will be userd to process your
                                order, support your experience thounghout this
                                website, and for other purposes descibed in your{" "}
                                <span className="text-info">
                                  privacy policy
                                </span>
                              </Card.Body>
                            </Card> */}
                            <div className="input_form_submit_btn mt-3">
                              <button
                                type="submit"
                                className="cambg-secondary text-white"
                                disabled={isLoading}
                              >
                                {isLoadingSubmit ? (
                                  <>
                                    <Spinner animation="border" size="sm" />{" "}
                                    Please wait...
                                  </>
                                ) : (
                                  "Place Order"
                                )}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </form>
            </div>
          </div>
        </section>
      </main>
      <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/js/bootstrap.bundle.min.js"></script>
    </>
  );
};

export default RenterCheckout;
