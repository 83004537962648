import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import Api from "../../Config/Api";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { updateUser } from "../../Reducers/Login";
import { Spinner } from "react-bootstrap";
import { getProperty } from "../../Reducers/RenterProperty";
import Loader from "../Elements/Loader";

const SecurityEditProfile = () => {
  const isAdmin = useSelector((state) => state.login.isAdmin);
  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const RenterProperty = useSelector(getProperty);
  const userId = useSelector((state) => state.login.userId);
  const email = useSelector((state) => state.login.email);
  const [isLoader, setIsLoader] = useState(false);
  const [camData, setCamData] = useState([])

  const getCamData = async () => {
    setIsLoader(true);
    const { data } = await Api("cam-get-user", "POST", {
      id: userId,
    });
    console.log('data', data);
    if (data?.status) {
      setCamData(data?.data);
      setIsLoader(false);
    }
    // reset(CamData);
  };

  useEffect(() => {
    getCamData();
  }, []);

  // const onSubmit = async (d) => {
  //   try {
  //     setLoading(true);
  //     const { data } = await Api("update-user", "POST", d);
  //     if (data?.status) {
  //       setLoading(false);
  //       toast.success("Update Successfully !", {
  //         position: toast.POSITION.TOP_RIGHT,
  //       });
  //       dispatch(updateUser(data.data));
  //       navigate("/Security/Editprofile");
  //     }
  //   } catch (error) {
  //     if (error?.status == 500) console.log("error", error?.statusText);
  //     toast.error(error?.statusText, {
  //       position: toast.POSITION.TOP_RIGHT,
  //     });
  //   }
  // };

  return (
    <>
      <div className="container">
        <div className="row justify-content-center ">
          <div className="col-lg-9">
            <div className="new_guest_form text-center">
              <h3
                className={`py-2`}
              >
                Profile
              </h3>
              {/* </div> */}
              <form
                className="form-horizontal"
              // onSubmit={handleSubmit(onSubmit)}
              >
                {isLoader ? (
                  <Loader />
                ) : (
                  <div className="card-body">
                    <div className="row align-items-center">
                      <div className="col-md-6">
                        <div className="form-group">
                          <h5 htmlFor="enterPass">First Name</h5>
                          {/* <input
                          type="text"
                          className="form-control"
                          id="enterPass"
                          placeholder="Enter First Name"
                          {...register("name", { required: true })}
                        /> */}
                          <p>{camData?.name}</p>
                          {/* {errors.name && (
                          <div className="form_error text-danger">
                            name is required.
                          </div>
                        )} */}
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <h5 htmlFor="lastname">Last Name</h5>
                          <p>{camData?.lastname}</p>
                          {/* <input
                          type="text"
                          className="form-control"
                          id="lastname"
                          placeholder="Enter Last Name"
                          {...register("lastname", {
                            required: true,
                          })}
                        /> */}

                          {/* {errors.name && (
                          <div className="form_error text-danger">
                            Last Name is required.
                          </div>
                        )} */}
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <h5 htmlFor="enterEmail">Email</h5>
                          <p>{camData?.email}</p>
                          {/* <input
                          type="email"
                          className="form-control"
                          id="enterEmail"
                          placeholder="Enter Email"
                          {...register("email", { required: true })}
                        /> */}
                          {/* {errors.name && (
                          <div className="form_error text-danger">
                            Email is required.
                          </div>
                        )} */}
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <h5 htmlFor="phone">Phone</h5>
                          <p>{camData?.phone}</p>
                          {/* <input
                          type="text"
                          className="form-control"
                          id="phone"
                          placeholder="Enter Phone Number"
                          {...register("phone", { required: true })}
                        /> */}
                          {/* {errors.phone && (
                          <div className="form_error text-danger">
                            Phone is required.
                          </div>
                        )} */}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {/* <div className="card-footer">
                  <button
                    className="btn btn-outline-light text-dark mx-3 bg-light"
                    type="button"
                    onClick={() => navigate(-1)}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    variant="primary"
                    className={`btn btn-primary  ${
                      isAdmin ? "" : "cambg-secondary border-0"
                    }`}
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <>
                        <Spinner animation="border" size="sm" /> Please wait...
                      </>
                    ) : (
                      "Update"
                    )}
                  </button>
                </div> */}

              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SecurityEditProfile;
