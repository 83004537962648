import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { FaEdit, FaTrashAlt, FaPlus } from "react-icons/fa";
import { FaEye } from "react-icons/fa";
import { Tooltip } from "react-tooltip";
import Api from "../../../Config/Api";
import useSweetAlert from "../../../Hooks/useSweetAlert";
import { toast } from "react-toastify";
import { BsSearch } from "react-icons/bs";
import Loader from "../../Elements/Loader";
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import { capitalizeFirstLetter } from "../../../Utility/Helpers";

const TowCompanyListing = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const isAdmin = useSelector((state) => state.login.isAdmin);
  const [property, setProperty] = useState([]);
  const [towCompList, setTowCompList] = useState([]);
  const [camList, setCamList] = useState([]);
  const { deletefun, isDeleted } = useSweetAlert();
  const location = useLocation();
  const [isLoader, setIsLoader] = useState(true);
  const [comName, setComName] = useState();
  const [comId, setComId] = useState();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = (camData) => {
    setShow(true);
    setComId(camData?.id);
    setComName(camData?.company_name);
    console.log('data', camData);
  };

  const [propertyId, setPropertyId] = useState(null);
  const handlePropertyChange = (event) => {
    setPropertyId(event.target.value);
    getUnitDetails(event.target.value);
  };

  const [unitlist, setUnitList] = useState([]);
  const getUnitDetails = async (property_Id) => {
    const reqData = {
      propertyId: property_Id
    }
    const { data } = await Api("getunitByPropertyId", "POST", reqData);
    if (data?.status) {
      const unitrep = data?.data;
      setUnitList(unitrep);
    }
  };

  const getPropertyDetails = async () => {
    const { data } = await Api("get-property", "POST", "");
    if (data?.status) {
      const propertyList = data?.data;
      setProperty(propertyList);
    }
  };

  const getTowCompDetails = async () => {
    setIsLoader(true);
    const { data } = await Api("getTowCompanyData", "POST", {});
    if (data?.status) {
      setTowCompList(data?.data);
      setCamList(data?.data);
      setIsLoader(false);
    }
  };

  useEffect(() => {
    getTowCompDetails();
    getPropertyDetails();
  }, []);

  const handleDelete = async (idToDelete) => {

    if (isDeleted) {
    }
    if (window.confirm("Are You Sure You Want to delete it?")) {
      const { data } = await Api("deleteTowCompany", "POST", { id: idToDelete });
      if (data?.status) {
        toast.success("Tow Company has been deleted successfully!", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      getTowCompDetails();
    }
  };

  const onSubmit = async (d) => {
    const assignUnitData = {
      property_id: d?.property_id,
      tow_company_id: comId,
    };
    const { data } = await Api("AssignPropertyTOTowCompany", "POST", assignUnitData);
    if (Number(data?.status) === 0) {
      toast.success(data?.messsage, {
        position: toast.POSITION.TOP_RIGHT,
      });
      setShow(false);
    } else if (Number(data?.status) === 1) {
      toast.success("Property has been Assigned Successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setShow(false);
    }
  };

  const [searchTerm, setSearchTerm] = useState("");

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
    const search = event.target.value;
    const filtered = camList.filter((item) => {
      return item.company_name.toLowerCase().includes(search.toLowerCase()) || item.firstname.toLowerCase().includes(search.toLowerCase()) || item.email.toLowerCase().includes(search.toLowerCase()) || item.phone.toString().includes(search);
    });
    setTowCompList(filtered);
  };

  return (
    <>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                {<h1 className="m-0">Tow Company</h1>}
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/"
                      className={location.pathname === "/" ? "breadcrumb-active" : "breadcrumb-not-active"}
                    >
                      Home
                    </Link>
                  </li>
                  <span className="breadcrumb-arrow">&gt;</span>
                  <li className="breadcrumb-item active">Tow Company</li>
                </ol>
              </div>
            </div>
          </div>
        </div>

        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className={`card-header ${isAdmin ? "adminColor" : "cambg-secondary"}`}>
                    <div className="add_new_vendor_listing">
                      <h3 className="card-title">Details</h3>
                      <Link
                        to={"/user/TowCompanyForm"}
                        className="btn btn-light text-dark bg-light"
                      >
                        Add Tow Company
                      </Link>
                    </div>
                  </div>

                  <div className="d-flex justify-content-end px-4 mt-2">
                    <div className="input-group w-25">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search..."
                        value={searchTerm}
                        onChange={handleInputChange}
                      />
                      <div className="input-group-append">
                        <span
                          className="input-group-text bg-primary border-0"
                          id="basic-addon2"
                        >
                          <BsSearch />
                        </span>
                      </div>
                    </div>
                  </div>
                  {isLoader ? (
                    <Loader />
                  ) : (

                    <div className="card-body table-responsive">
                      <table className="table table-bordered">
                        <thead className="border-top">
                          <tr>
                            <th>Company Name</th>
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th>Email</th>
                            <th>Phone</th>
                            <th>Address</th>
                            <th>state</th>
                            <th>Zip</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {towCompList?.length ? (
                            towCompList?.map((data, i) => (
                              <tr key={i}>
                                <td>{data?.company_name}</td>
                                <td>{data?.firstname}</td>
                                <td>{data?.lastname}</td>
                                <td>{data?.email}</td>
                                <td>{data?.phone}</td>
                                <td>{data?.address}</td>
                                <td>{data?.state}</td>
                                <td>{data?.zip}</td>
                                <td>
                                  <div className="d-flex justify-content-center ">
                                    <Button
                                      className="btn btn-info btn-sm mr-1"
                                      onClick={() => handleShow(data)}
                                      data-tooltip-id="assign"
                                      data-tooltip-content="Assign Property">
                                      <FaPlus />
                                    </Button>
                                    <Tooltip id="assign" place="bottom" />
                                    <Link
                                      to={"/user/TowCompanyDetail"}
                                      state={{ id: data?.id, isEdit: true }}
                                      className="btn btn-success btn-sm mr-1"
                                      data-tooltip-id="detail"
                                      data-tooltip-content="Show Management Company Detail"
                                    >
                                      <FaEye />
                                    </Link>
                                    <Tooltip id="assign" place="bottom" />
                                    <Link
                                      to="/user/TowCompanyForm"
                                      state={{ id: data?.id, isEdit: true }}
                                      className="btn  btn-primary btn-sm mr-1"
                                      data-tooltip-id="edit"
                                      data-tooltip-content="Edit"
                                    >
                                      <FaEdit />
                                    </Link>
                                    <Tooltip id="assign" place="bottom" />
                                    <button
                                      onClick={() => handleDelete(data.id)}
                                      className="btn  btn-danger btn-sm mr-1"
                                      data-tooltip-id="my-tooltip"
                                      data-tooltip-content="Delete"
                                    >
                                      <FaTrashAlt />
                                    </button>
                                    <Tooltip id="assign" place="bottom" />
                                  </div>
                                </td>
                              </tr>
                            ))) : (
                            <tr>
                              <td colSpan={9}>
                                <p className="text-center">
                                  No record found.
                                </p>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Assign Property to {comName ? capitalizeFirstLetter(comName) : ""}</Modal.Title>
        </Modal.Header>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
            <div className="row">

              <div className="col-md-12">
                <div className="form-group">
                  <label>Select Property</label>
                  <select
                    className="form-control"
                    {...register("property_id", {
                      required: true,
                    })}
                    onChange={handlePropertyChange}
                  >
                    {property?.map((propertydata, i) => (
                      <option key={i} value={propertydata?.id}>
                        {propertydata?.name}
                      </option>
                    ))}
                  </select>
                  {errors.property_id && (
                    <p className="text-danger">Property is required</p>
                  )}
                </div>
              </div>


            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" type="submit">
              Save
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  )
}

export default TowCompanyListing