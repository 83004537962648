import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
// import Loader from "../../Elements/Loader";
import Card from "react-bootstrap/Card";
import { BsFillCheckCircleFill, BsPrinterFill } from "react-icons/bs";
import Api from "../../../Config/Api";
import Loader from "../../Elements/Loader";
import dayjs from "dayjs";

const PaymentHistoryView = () => {
    const location = useLocation();
    const [unitList, setUnitList] = useState([]);
    const isAdmin = useSelector((state) => state.login.isAdmin);
    // const [isLoader, setIsLoader] = useState(true);
    const [passInfo, setPassInfo] = useState([]);
    const [price, setPrice] = useState(0);
    const [isLoader, setIsLoader] = useState(true);
    const [passStatus, setPassStatus] = useState([]);

    const sectionStyle = {};


    const getCheckOutDetails = async () => {
        const res = await Api("getAllpassInfoByUserID", "POST", {
            id: location.state.id,
        });
        // console.log("data", res?.data?.passData?.billing_details?.vehiclepass);
        if (res?.data?.status) {
            setPassInfo(res?.data?.passData);
            setIsLoader(false);

            const startDate = dayjs(res?.data?.passData?.billing_details?.vehiclepass?.arrival_date);
            const endDate = dayjs(res?.data?.passData?.billing_details?.vehiclepass?.departure_date);
            const currentDate = dayjs();

            console.log("startDate", startDate);
            console.log("endDate", endDate);
            console.log("currentDate", currentDate);

            if ((res?.data?.passData?.billing_details?.vehiclepass?.issuccess) === 0 || (res?.data?.passData?.billing_details?.vehiclepass?.Is_rental_vehicle) === 0) {
                setPassStatus("Incomplete");
            } else {
                if (currentDate.isBefore(startDate)) {
                    setPassStatus("Future");
                } else if (currentDate.isAfter(endDate)) {
                    setPassStatus("Closed");
                } else {
                    setPassStatus("Current");

                }
            }

        }
    };

    useEffect(() => {
        getCheckOutDetails();
    }, []);

    return (
        <>
            <main>
                <div className="content-wrapper">
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    {<h1 className="m-0">Payment/Pass Details</h1>}
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item">
                                            <Link to="/"
                                                className={location.pathname === "/" ? "breadcrumb-active" : "breadcrumb-not-active"}
                                            >
                                                Home
                                            </Link>
                                            <span className="breadcrumb-arrow">&gt;</span>
                                            <Link to="/user/PaymentHistory"
                                                className={location.pathname.startsWith("/user/PaymentHistory") ? "breadcrumb-active" : "breadcrumb-not-active"}
                                            >
                                                Payment History
                                            </Link>
                                        </li>
                                        <span className="breadcrumb-arrow">&gt;</span>
                                        <li className="breadcrumb-item active">Payment Details</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section
                        className=""
                        style={sectionStyle}
                    >
                        <div className="container-fluid" style={{
                            paddingLeft: "20px"
                        }}>
                            <div className="new_guest_form">
                                <div className="returning_heading">
                                    <div className="d-flex justify-content-end">
                                        <div>
                                            <span className="btn btn-warning btn-sm text-light mx-1" onClick={() => window.print()}>
                                                <BsPrinterFill />
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                {isLoader ? (
                                    <Loader />
                                ) : (
                                    <>
                                        <Card>
                                            <Card.Body>
                                                <div className="row">
                                                    <div className="col-lg-5">
                                                       
                                                           <tr>
                                                            <th>
                                                                <h4>Order Details</h4>
                                                            </th>
                                                           </tr>
                                                        
                                                        <tr>
                                                            <td>
                                                                <p className="text p-0 my-1 mr-5 d-inline">
                                                                    Order Status
                                                                </p>
                                                            </td>
                                                            <th>
                                                                {passStatus === "Current" || passStatus === "Future" ? (
                                                                    <span className="text-success">{passStatus}</span>
                                                                ) : (
                                                                    <span className="text-danger">{passStatus}</span>
                                                                )}
                                                            </th>
                                                        </tr>

                                                        <tr>
                                                            <td>
                                                                <p className="text p-0 my-1 mr-5 d-inline">
                                                                    Order Number
                                                                </p>
                                                            </td>
                                                            <th>
                                                                {
                                                                    passInfo?.billing_details?.vehiclepass
                                                                        ?.order_uuid
                                                                }
                                                            </th>
                                                        </tr>

                                                        <tr>
                                                            <td>
                                                                <p className="text p-0 my-1 mr-5 d-inline">
                                                                    Date
                                                                </p>
                                                            </td>
                                                            <th>

                                                                {
                                                                    dayjs(passInfo?.billing_details?.vehiclepass
                                                                        ?.arrival_date).format("MM/DD/YYYY")}
                                                            </th>
                                                        </tr>

                                                        <tr>
                                                            <td>
                                                                <p className="text p-0 my-1 mr-5 d-inline">
                                                                    Email
                                                                </p>
                                                            </td>
                                                            <th> {passInfo?.billing_details?.user?.email}</th>
                                                        </tr>

                                                        <tr>
                                                            <td>
                                                                <p className="text p-0 my-1 mr-5 d-inline">
                                                                    Total Amount
                                                                </p>
                                                            </td>
                                                            <th>
                                                                ${Number(passInfo?.amount / 100).toFixed(2)}
                                                            </th>
                                                        </tr>
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <tr>
                                                            <th>
                                                                {" "}
                                                                <h4>Billing Address</h4>
                                                            </th>
                                                        </tr>

                                                        <tr>
                                                            {" "}
                                                            <td>Name  </td>
                                                            <th>
                                                                <span>
                                                                    {passInfo?.billing_details?.fname +
                                                                        " " +
                                                                        passInfo?.billing_details?.lname}
                                                                </span>
                                                            </th>
                                                        </tr>

                                                        <tr>
                                                            <td>Company Name  </td>{" "}
                                                            <th>
                                                                {" "}
                                                                <span>
                                                                    {passInfo?.billing_details?.companyName}
                                                                </span>
                                                            </th>
                                                        </tr>

                                                        <tr>
                                                            <td>Country  </td>{" "}
                                                            <th>
                                                                {" "}
                                                                <span>{passInfo?.billing_details?.country}</span>
                                                            </th>
                                                        </tr>

                                                        <tr>
                                                            <td>Phone  </td>
                                                            <th>
                                                                <span>{passInfo?.billing_details?.phone}</span>
                                                            </th>
                                                        </tr>

                                                        <tr>
                                                            <td>Email </td>
                                                            <th>
                                                                <span>{passInfo?.billing_details?.email}</span>
                                                            </th>
                                                        </tr>
                                                    </div>
                                                    <div className="col-lg-3">
                                                        {passInfo?.billing_details?.vehiclepass
                                                            ?.issuccess && (
                                                                <div className="text-end">
                                                                    <img
                                                                        src={
                                                                            passInfo?.billing_details?.vehiclepass
                                                                                ?.qr_code
                                                                        }
                                                                        width={160} S
                                                                        alt=""
                                                                        srcSet=""
                                                                    />
                                                                </div>
                                                            )}
                                                    </div>
                                                </div>


                                            </Card.Body>
                                        </Card>
                                        <Card>
                                            <Card.Header>
                                                <div className="d-flex justify-content-between">
                                                    <div>
                                                        <h3 className="text-dark">Order details</h3>
                                                    </div>
                                                </div>
                                            </Card.Header>
                                            <Card.Body>
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <tr>
                                                            <th>  <p className="text-uppercase">Rules & Regulations</p></th>
                                                            <td className="px-5">
                                                                {Boolean(
                                                                    passInfo?.billing_details?.vehiclepass?.rules
                                                                )
                                                                    ? JSON.parse(
                                                                        passInfo?.billing_details?.vehiclepass
                                                                            ?.rules
                                                                    ).map((data, i) => (
                                                                        <div key={i}>
                                                                            {Array.isArray(data) ? (
                                                                                data.map((item, index) => (
                                                                                    <li>
                                                                                        <span key={index}>{item}</span>
                                                                                        <span className="text-success mx-3">
                                                                                            <BsFillCheckCircleFill className="h5 mt-2" />
                                                                                        </span>
                                                                                    </li>
                                                                                ))
                                                                            ) : (
                                                                                <>
                                                                                    <li><span>{data}</span>
                                                                                        <span className="text-success mx-3">
                                                                                            <BsFillCheckCircleFill className="h5 mt-2" />
                                                                                        </span>
                                                                                    </li>
                                                                                </>
                                                                            )}

                                                                        </div>
                                                                    ))
                                                                    : "-"}
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <th> <p className="text-uppercase">Beach Flag Warning System </p></th>
                                                            <td className="px-5">
                                                                {Boolean(
                                                                    passInfo?.billing_details?.vehiclepass
                                                                        ?.warnings
                                                                )
                                                                    ? JSON.parse(
                                                                        passInfo?.billing_details?.vehiclepass
                                                                            ?.warnings
                                                                    ).map((data, i) => (
                                                                        <div key={i}>
                                                                            {Array.isArray(data) ? (
                                                                                data.map((item, index) => (
                                                                                    <li>
                                                                                        <span key={index}>{item}</span>
                                                                                        <span className="text-success mx-3">
                                                                                            <BsFillCheckCircleFill className="h5 mt-2" />
                                                                                        </span>
                                                                                    </li>
                                                                                ))
                                                                            ) : (
                                                                                <>
                                                                                    <li><span>{data}</span>
                                                                                        <span className="text-success mx-3">
                                                                                            <BsFillCheckCircleFill className="h5 mt-2" />
                                                                                        </span>
                                                                                    </li>
                                                                                </>
                                                                            )}

                                                                        </div>
                                                                    ))
                                                                    : "-"}
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <th>  <p className="text-uppercase ">Non-Refundable</p></th>
                                                            <td className="px-5">
                                                                {Array(
                                                                    passInfo?.billing_details?.vehiclepass
                                                                        ?.refundable_rules
                                                                )
                                                                    ? JSON.parse(
                                                                        passInfo?.billing_details?.vehiclepass
                                                                            ?.refundable_rules
                                                                    ).map((data, i) => (
                                                                        <div key={i}>
                                                                            {Array.isArray(data) ? (
                                                                                data.map((item, index) => (
                                                                                    <li>
                                                                                        <span key={index}>{item ? item : "-"}</span>
                                                                                        <span className="text-success mx-3">
                                                                                            <BsFillCheckCircleFill className="h5 mt-2" />
                                                                                        </span>
                                                                                    </li>
                                                                                ))
                                                                            ) : (
                                                                                <>
                                                                                    <li><span>{data ? data : "-"}</span>
                                                                                        <span className="text-success mx-3">
                                                                                            <BsFillCheckCircleFill className="h5 mt-2" />
                                                                                        </span>
                                                                                    </li>
                                                                                </>
                                                                            )}

                                                                        </div>
                                                                    ))
                                                                    : "-"}
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <th>
                                                                <p className="text-uppercase">
                                                                    Parking Price
                                                                </p>
                                                            </th>
                                                            <td className="px-5">
                                                                ${Number(passInfo?.amount / 100).toFixed(2)}
                                                            </td>
                                                        </tr>


                                                        <tr>
                                                            <th>
                                                                <p className="text-uppercase">
                                                                    Pass Code
                                                                </p>
                                                            </th>
                                                            <td className="px-5">
                                                                {
                                                                    passInfo?.billing_details?.vehiclepass
                                                                        ?.order_uuid
                                                                }
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <th>
                                                                <p className="text-uppercase">
                                                                    License Plate Number
                                                                </p>
                                                            </th>
                                                            <td className="px-5">
                                                                {
                                                                    passInfo?.billing_details?.vehiclepass
                                                                        ?.license_plate
                                                                }
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <th>
                                                                <p className="text-uppercase">
                                                                    Unit
                                                                </p>
                                                            </th>
                                                            <td className="px-5"> {
                                                                passInfo?.billing_details?.vehiclepass?.unit
                                                                    ?.unit_name
                                                            }</td>
                                                        </tr>

                                                        <tr>
                                                            <th>
                                                                <p className="text-uppercase">
                                                                    Begin Date
                                                                </p>
                                                            </th>
                                                            <td className="px-5">

                                                                {
                                                                    dayjs(passInfo?.billing_details?.vehiclepass
                                                                        ?.arrival_date).format("MM/DD/YYYY")}
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <th>
                                                                <p className="text-uppercase">
                                                                    Departure Date
                                                                </p>
                                                            </th>
                                                            <td className="px-5">

                                                                {
                                                                    dayjs(passInfo?.billing_details?.vehiclepass
                                                                        ?.departure_date).format("MM/DD/YYYY")}

                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <th>
                                                                <p className="text-uppercase">
                                                                    Full Name
                                                                </p>
                                                            </th>
                                                            <td className="px-5">
                                                                {passInfo?.billing_details?.user?.name +
                                                                    " " +
                                                                    passInfo?.billing_details?.user?.lastname}
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <th>
                                                                <p className="text-uppercase">
                                                                    Phone
                                                                </p>
                                                            </th>
                                                            <td className="px-5">
                                                                {passInfo?.billing_details?.user?.phone}
                                                            </td>
                                                        </tr>


                                                        <tr>
                                                            <th>
                                                                <p className="text-uppercase">
                                                                    SubTotal
                                                                </p>
                                                            </th>
                                                            <td className="px-5">
                                                                $
                                                                {Number(
                                                                    passInfo?.billing_details?.vehiclepass
                                                                        ?.pass_fee
                                                                ).toFixed(2)}
                                                            </td>
                                                        </tr>


                                                        <tr>
                                                            <th>
                                                                <p className="text-uppercase">
                                                                    Discount
                                                                </p>
                                                            </th>
                                                            <td className="px-5">
                                                                ${Number(0).toFixed(2)}
                                                            </td>
                                                        </tr>


                                                        <tr>
                                                            <th>
                                                                <p className="text-uppercase">
                                                                    Tax
                                                                </p>
                                                            </th>
                                                            <td className="px-5">
                                                                ${Number(0).toFixed(2)}
                                                            </td>
                                                        </tr>


                                                        <tr>
                                                            <th>
                                                                <p className="text-uppercase">
                                                                    Total Amount
                                                                </p>
                                                            </th>
                                                            <td className="px-5">
                                                                ${Number(passInfo?.amount / 100).toFixed(2)}
                                                            </td>
                                                        </tr>
                                                    </div>


                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </>
                                )}
                            </div>
                        </div>
                    </section>
                </div>
                {/* 
                        </div>
                    </div>
                </div> */}
            </main>
        </>
    )
}

export default PaymentHistoryView;