import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, Link } from "react-router-dom";
import Api from "../../../Config/Api";
import Loader from "../../Elements/Loader";
const VendorDetail = () => {
  const location = useLocation();
  const isAdmin = useSelector((state) => state.login.isAdmin);
  const [isLoader, setIsLoader] = useState(true);
  const [vendorList, setVendorList] = useState([]);
  const [assignPropertyDetail, setAssignPropertyDetail] = useState([]);

  const getModifyProperty = async () => {
    setIsLoader(true);
    const { data } = await Api("get-vendor", "POST", {
      id: location?.state?.id,
    });
    console.log("id",location?.state?.propertyId);
    if (data?.status) {
      setIsLoader(false);
      const VendorData = data?.data;
      setVendorList(VendorData);
      setAssignPropertyDetail(data?.data?.property_data);
      console.log("assignPropertyDetail",assignPropertyDetail);
    }
  };

  useEffect(() => {
    if (!!location?.state?.id) {
      getModifyProperty();
    }
  }, [location?.state?.id]);

  return (
    <>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">VENDOR DETAILS</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/"
                      className={location.pathname === "/" ? "breadcrumb-active" : "breadcrumb-not-active"}
                    >
                      Home
                    </Link>
                    {isAdmin ? (
                      <>
                        <span className="breadcrumb-arrow">&gt;</span>
                        <Link to="/user/PropertyListing"
                          className={location.pathname.startsWith("/user/PropertyListing") ? "breadcrumb-active" : "breadcrumb-not-active"}
                        >
                          Property
                        </Link>
                      </>
                    ) : ("")}

                    <span className="breadcrumb-arrow">&gt;</span>
                    <Link to={"/user/VendorListing/" + btoa(location?.state?.propertyId)}
                      className={location.pathname.startsWith("/user/VendorListing/" + btoa(location?.state?.propertyId)) ? "breadcrumb-active" : "breadcrumb-not-active"}
                    >
                      Vendor
                    </Link>
                  </li>
                  <span className="breadcrumb-arrow">&gt;</span>
                  <li className="breadcrumb-item active">Vendor Details</li>
                </ol>
              </div>
            </div>
          </div>
        </div>

        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card card-primary">
                  <div
                    className={`card-header ${isAdmin ? "adminColor" : "cambg-secondary"
                      }`}
                  >

                    <div className="d-flex justify-content-between">
                      <div>
                        <h3 className="card-title">Vendor Details</h3>
                      </div>
                      <div className="">
                        <Link
                          className="btn btn-light text-dark btn-sm bg-light mx-2"
                          to={"/user/VendorListing/" + btoa(assignPropertyDetail?.id)}>
                          Back
                        </Link>
                        <Link
                          className="btn btn-light btn-sm text-dark bg-light"
                          to={"/user/VendorFrom"} state={{ id: vendorList.id, isEdit: true }}>
                          Edit
                        </Link>
                      </div>

                    </div>

                  </div>

                  <div className="bg-white p-3 rounded p-3 shadow">
                    <div>
                      <div className="mb-3">
                        <div className="row">
                          <div className="col-lg-6">
                            <form>
                              <div className="d-flex gap-2 justify-content-end">
                                <div className="flex-shrink-0">
                                  <span className="badge bg-warning">
                                  </span>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                      <div className="table-responsive">
                        <div>
                          <div
                            style={{
                              width: "100%",
                              padding: "0px 40px",
                              borderRadius: "10px",
                              backgroundColor: " rgb(255, 255, 255)",
                              margin: "0px auto",
                              border: "1px solid rgb(221, 221, 221)",
                              fontSize: "14px",
                              color: "rgb(0, 0, 0)",
                            }}
                          >
                            <div>
                              <table
                                style={{ width: "100%", marginBottom: "0px" }}
                              >
                                <tbody>
                                  <tr>
                                    <td>
                                      {isLoader ? (
                                        <Loader />
                                      ) : (
                                        <div className="mt-3">
                                          <table
                                            className="m-0"
                                            style={{ width: "100%" }}
                                          >
                                            <tbody>
                                              <tr>
                                                <td>
                                                  <div
                                                    style={{
                                                      fontStyle: "italic",
                                                      marginBottom: "10px",
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    Vendor Details:
                                                  </div>
                                                  <table
                                                    className="table table-bordered table-striped table-sm m-0"
                                                    style={{ width: "100%" }}
                                                  >
                                                    <tbody>
                                                      <tr>
                                                        <th>1. Company Name</th>
                                                        <td>
                                                          {
                                                            vendorList?.company_name
                                                          }
                                                        </td>
                                                        <td
                                                          rowSpan="24"
                                                          style={{
                                                            textAlign: "center",
                                                            verticalAlign:
                                                              "top",
                                                          }}
                                                        >
                                                          {/* <div
                                                            style={{
                                                              border:
                                                                "1px solid rgb(221, 221, 221)",
                                                              padding: "10px",
                                                              width: "150px",
                                                              textAlign:
                                                                "center",
                                                              margin:
                                                                "0px auto",
                                                            }}
                                                          > */}
                                                          {/* <img
                                                            className="w-100"
                                                            alt="Applicant"
                                                            src="https://developerteam.in/nsdmis-api/public/images/sdrf/118566703-1679303318.png"
                                                          /> */}
                                                          {/* </div> */}
                                                        </td>
                                                      </tr>
                                                      <tr>
                                                        <th
                                                          style={{
                                                            width: "270px",
                                                          }}
                                                        >
                                                         2. APT
                                                        </th>
                                                        <td>
                                                            {vendorList?.apt}
                                                        </td>
                                                      </tr>
                                                      <tr>
                                                        <th
                                                          style={{
                                                            width: "270px",
                                                          }}
                                                        >
                                                          3. Point of Contact First
                                                          Name
                                                        </th>
                                                        <td>{
                                                              vendorList?.point_of_fname
                                                            }</td>
                                                      </tr>
                                                      <tr>
                                                        <th>
                                                          {" "}
                                                          4. Point of Contact Last
                                                          Name
                                                        </th>
                                                        <td>{
                                                              vendorList?.point_of_lname
                                                            }</td>
                                                      </tr>
                                                      <tr>
                                                        <th>
                                                          5. Point of Contact Email
                                                        </th>
                                                        <td>
                                                        {
                                                              vendorList?.poc_email
                                                            }
                                                            </td>
                                                      </tr>

                                                      <tr>
                                                        <th>
                                                          6. Point of Contact Phone
                                                        </th>
                                                        <td>{vendorList?.phone}</td>
                                                      </tr>

                                                      <tr>
                                                        <th>
                                                          7. Service Category Name
                                                        </th>
                                                        <td>{
                                                              vendorList
                                                                ?.ser_category
                                                                ?.service_category_name
                                                            }</td>
                                                      </tr>
                                                      <tr>
                                                        <th>8. Address</th>
                                                        <td>{
                                                              vendorList?.address
                                                            }</td>
                                                      </tr>
                                                      <tr>
                                                        <th>9. State</th>
                                                        <td>{vendorList?.state}</td>
                                                      </tr>
                                                      <tr>
                                                        <th>10. City</th>
                                                        <td>{vendorList?.city}</td>
                                                      </tr>
                                                      <tr>
                                                        <th>11. Pincode</th>
                                                        <td>{vendorList?.zip}</td>
                                                      </tr>
                                                      <tr>
                                                        <th>12. Added Date</th>
                                                        <td>{dayjs(
                                                              vendorList?.created_at
                                                            ).format(
                                                              "MM/DD/YY"
                                                            )}</td>
                                                      </tr>
                                                    </tbody>
                                                  </table>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </div>
                                      )}

                                      <div className="my-3">
                                        <div
                                          className="mb-2"
                                          style={{
                                            fontStyle: "italic",
                                            fontWeight: "bold",
                                          }}
                                        >
                                          Properties
                                        </div>
                                        <table className="table table-bordered table-striped m-0">
                                          <thead>
                                            <tr>
                                              <th>Property Name</th>
                                              <th>Assigned Date</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {assignPropertyDetail ? (
                                              <tr>
                                                <td>
                                                  {assignPropertyDetail?.name}
                                                </td>

                                                <td>
                                                  {dayjs(
                                                    assignPropertyDetail?.created_at
                                                  ).format("MM/DD/YYYY")}
                                                </td>
                                              </tr>
                                            ) : (
                                              <tr>
                                                <td colSpan={4}>
                                                  <p className="text-center">
                                                    No Assign Property Yet.
                                                  </p>
                                                </td>
                                              </tr>
                                            )}
                                          </tbody>
                                        </table>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <div className="mt-4 text-center">
                        <div className="mb-3">
                          <button
                            type="button"
                            className="btn btn-success px-5"
                          >
                            <svg
                              stroke="currentColor"
                              fill="currentColor"
                              strokeWidth="0"
                              viewBox="0 0 512 512"
                              height="1em"
                              width="1em"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M448 192V77.25c0-8.49-3.37-16.62-9.37-22.63L393.37 9.37c-6-6-14.14-9.37-22.63-9.37H96C78.33 0 64 14.33 64 32v160c-35.35 0-64 28.65-64 64v112c0 8.84 7.16 16 16 16h48v96c0 17.67 14.33 32 32 32h320c17.67 0 32-14.33 32-32v-96h48c8.84 0 16-7.16 16-16V256c0-35.35-28.65-64-64-64zm-64 256H128v-96h256v96zm0-224H128V64h192v48c0 8.84 7.16 16 16 16h48v96zm48 72c-13.25 0-24-10.75-24-24 0-13.26 10.75-24 24-24s24 10.74 24 24c0 13.25-10.75 24-24 24z"></path>
                            </svg>{" "}
                            Print
                          </button>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default VendorDetail;
