import React from 'react'

const PrintPass = () => {
  return (
    <>
     <div className="content-wrapper">
    <div className="content-header">
      <div className="container-fluid">
        <div className="row mb-2">
          <div className="col-sm-6">
            <h1 className="m-0">PRINT PASS</h1>
          </div>
          <div className="col-sm-6">
            <ol className="breadcrumb float-sm-right">
              <li className="breadcrumb-item"><a href="#">Home</a></li>
              <li className="breadcrumb-item active">Print Pass</li>
            </ol>
          </div>
        </div>
      </div>
    </div>
   
    <section className="content">
      <div className="container-fluid">
        <div className="row">
            <div className="col-md-12">
                <div className="card card-info">
                  <div className="card-header">
                    <h3 className="card-title">Enter Pass Detail</h3>
                  </div>
                 
                  <form className="form-horizontal">
                    <div className="card-body">
                      <div className="row align-items-center">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="enterPass">Pass#</label>
                            <input type="text" className="form-control" id="enterPass" placeholder="Enter Pass"/>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="enterEmail">Email</label>
                            <input type="email" className="form-control" id="enterEmail" placeholder="Enter Email"/>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    <div className="card-footer">
                      <a href="verify_pass_to_print.php" className="btn btn-info">Verify Pass</a>
                    </div>
                   
                  </form>
                </div>
               
    
              </div>
        </div>
      
      </div>
    </section>
  </div>
    </>
  )
}

export default PrintPass