import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Api from "../../../Config/Api";
import dayjs from "dayjs";
import Loader from "../../Elements/Loader";

const SubAdminManagDetail = () => {
    const isAdmin = useSelector((state) => state.login.isAdmin);
    const location = useLocation();
    const [compMangData, setCompMangData] = useState([]);
    const [isLoader, setIsLoader] = useState(true);

    const getCamData = async () => {
        setIsLoader(true);
        const { data } = await Api("getAssignedCompanyData", "POST", {
            user_id: location?.state?.id,
        });
        if (data?.status) {
            const VendorData = data?.data;
            setCompMangData(VendorData);
            setIsLoader(false);
        }
    };

    useEffect(() => {
        getCamData();
    }, [location?.state?.id]);

    return (
        <>
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0">Management Company Details </h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item">
                                        <Link to="/"
                                            className={location.pathname === "/" ? "breadcrumb-active" : "breadcrumb-not-active"}
                                        >
                                            Home
                                        </Link>
                                        <span className="breadcrumb-arrow">&gt;</span>
                                        <Link to="/user/ManagementCompanyListing"
                                            className={location.pathname.startsWith("/user/ManagementCompanyListing") ? "breadcrumb-active" : "breadcrumb-not-active"}
                                        >
                                            Management Company
                                        </Link>
                                    </li>
                                    <span className="breadcrumb-arrow">&gt;</span>

                                    <li className="breadcrumb-item active">Management Company Details</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card card-primary">
                                    <div className={`card-header ${isAdmin ? "adminColor" : "cambg-secondary"}`}>
                                        <h3 className="card-title">Details</h3>
                                    </div>

                                    <div className="bg-white p-3 rounded p-3 shadow">
                                        <div>
                                            <div className="mb-3">
                                                <div className="row">
                                                    <div className="col-lg-6">
                                                        <form>
                                                            <div className="d-flex gap-2 justify-content-end">
                                                                <div className="flex-shrink-0">
                                                                    <span className="badge bg-warning">
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>


                                            <div className="table-responsive">
                                                <div>
                                                    <div
                                                        style={{
                                                            width: "100%",
                                                            padding: "0px 40px",
                                                            borderRadius: "10px",
                                                            backgroundColor: " rgb(255, 255, 255)",
                                                            margin: "0px auto",
                                                            border: "1px solid rgb(221, 221, 221)",
                                                            fontSize: "14px",
                                                            color: "rgb(0, 0, 0)",
                                                        }}
                                                    >
                                                        <div>
                                                            <table
                                                                style={{ width: "100%", marginBottom: "0px" }}
                                                            >
                                                                <tbody>
                                                                    <tr>
                                                                        <td>
                                                                            {isLoader ? (
                                                                                <Loader />
                                                                            ) : (
                                                                                <>
                                                                                    <div className="mt-3">
                                                                                        <table
                                                                                            className="m-0"
                                                                                            style={{ width: "100%" }}
                                                                                        >
                                                                                            <tbody>
                                                                                                <tr>
                                                                                                    <td>
                                                                                                        <div
                                                                                                            style={{
                                                                                                                fontStyle: "italic",
                                                                                                                marginBottom: "10px",
                                                                                                                fontWeight: "bold",
                                                                                                            }}
                                                                                                        >
                                                                                                            Basic Details Of Sub Admin Management Company:
                                                                                                        </div>
                                                                                                        <table
                                                                                                            className="table table-bordered table-striped table-sm m-0"
                                                                                                            style={{ width: "100%" }}
                                                                                                        >
                                                                                                            <tbody>

                                                                                                                <tr>

                                                                                                                    <td>Sub Admin Management Company Name</td>
                                                                                                                    <th>
                                                                                                                        {compMangData?.name}

                                                                                                                    </th>


                                                                                                                </tr>
                                                                                                                <tr>

                                                                                                                    <td style={{
                                                                                                                        width: "300px",
                                                                                                                    }}> Sub Admin Management Company Email</td>

                                                                                                                    <th>

                                                                                                                        {compMangData?.email}

                                                                                                                    </th>
                                                                                                                </tr>

                                                                                                            </tbody>
                                                                                                        </table>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            </tbody>
                                                                                        </table>
                                                                                    </div>
                                                                                    <div className="mt-3">
                                                                                        <div
                                                                                            className="mb-2"
                                                                                            style={{
                                                                                                fontStyle: "italic",
                                                                                                fontWeight: "bold",
                                                                                            }}
                                                                                        >
                                                                                            Managing Units
                                                                                        </div>
                                                                                        <table className="table table-bordered table-striped m-0 mb-3">
                                                                                            <thead>
                                                                                                <tr>

                                                                                                    <th>Unit Name</th>
                                                                                                    <th>Property Name</th>
                                                                                                    <th>Owner Name</th>
                                                                                                    <th>Owner Email</th>
                                                                                                    <th>Owner Contact No.</th>
                                                                                                    <th>Assigned Date</th>
                                                                                                </tr>
                                                                                            </thead>
                                                                                            <tbody>
                                                                                                {compMangData?.mangement_units ? (
                                                                                                    compMangData?.mangement_units?.map((assignedData, i) => (
                                                                                                        <tr key={i}>

                                                                                                            <td>
                                                                                                                {
                                                                                                                    assignedData?.unit_name
                                                                                                                }
                                                                                                            </td>

                                                                                                            <td>
                                                                                                                {
                                                                                                                    assignedData?.unit_name
                                                                                                                }
                                                                                                            </td>

                                                                                                            <td>
                                                                                                                {
                                                                                                                    assignedData?.firstname + ' ' + assignedData?.lastname
                                                                                                                }
                                                                                                            </td>
                                                                                                            <td>
                                                                                                                {
                                                                                                                    assignedData?.email
                                                                                                                }
                                                                                                            </td>

                                                                                                            <td>
                                                                                                                {
                                                                                                                    assignedData?.phone
                                                                                                                }
                                                                                                            </td>

                                                                                                            <td>
                                                                                                                {dayjs(
                                                                                                                    assignedData?.property?.created_at
                                                                                                                ).format("MM/DD/YYYY")}
                                                                                                            </td>
                                                                                                        </tr>

                                                                                                    ))


                                                                                                ) : (
                                                                                                    <tr>
                                                                                                        <td colSpan={4}>
                                                                                                            <p className="text-center">
                                                                                                                No Assign Property Yet.
                                                                                                            </p>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                )}
                                                                                            </tbody>
                                                                                        </table>
                                                                                    </div>
                                                                                </>
                                                                            )}
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default SubAdminManagDetail