import { useNavigate, Outlet } from "react-router-dom";
import SecurityHeader from "../Views/Elements/Header/SecurityHeader";
import Footer from "../Views/Elements/Footer/Footer";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchProperty, getProperty } from "../Reducers/RenterProperty";
import Loader from "../Views/Elements/Loader";
import RenterSidebar from "../Views/Elements/Sidebar/RenterSidebar";

const RenterLayout = () => {
  const dispatch = useDispatch();
  const { property_name } = useParams();
  const [isLoading, setLoading] = useState(false);
  const RenterProperty = useSelector(getProperty);
  const isLogin = useSelector((state) => state.login.isLogin);
  const propertyData = useSelector((state) => state.login.propertyData);

  const background_image = propertyData?.bg_image
    ? process.env.REACT_APP_AWS_S3_BUCKET_URL+
                        "images/" + propertyData?.bg_image
    : "";
  const sectionStyle = {
    backgroundImage: `url(${background_image})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    height: "100%",
    width: "100%",
  };

  return (
    <>
      <style>
        {`
        :root{
          --primary:${propertyData?.primary_color};
          --secondary:${propertyData?.secondary_color};
        }
        `}
      </style>

      <div className="wrapper d-flex flex-column min-vh-100">
        {isLoading ? (
          <>
            <Loader type={"full"} />
          </>
        ) : (
          <>
            <SecurityHeader propertyData={propertyData} />
            <section
              className="flex-grow-1 py-5 guest_section bannersection d-flex align-items-center overflow-auto"
              style={sectionStyle}
            >
              <Outlet />
            </section>
            <Footer IsSecurity={true} propertyData={propertyData}  />
          </>
        )}
      </div>
    </>
  );
};

export default RenterLayout;
