export function getRoleName(roleNumber) {
  let roleName;
  switch (Number(roleNumber)) {
    case 1:
      roleName = "admin";
      break;
    case 2:
      roleName = "user";
      break;
    case 3:
      roleName = "guest";
      break;
    case 4:
      roleName = "Management Co";
      break;
    case 5:
      roleName = "Security";
      break;
    default:
      roleName = "unknown";
      break;
  }
  return roleName;
}

// export function getBreadcrumbLinkOwnerForm(roleNumber) {
//   let breadcrumbLink;
//   switch (Number(roleNumber)) {
//     case 1:
//       breadcrumbLink = "/user/OwnerListing/MTIx";
//       break;
//     case 2:
//       breadcrumbLink = "/user/ManageOwnerListing/MTIx/MTEz";
//       break;
//     case 3:
//       breadcrumbLink = "/user/ManageOwnerListing/MTIx/MTEz";
//       break;
//     case 4:
//       breadcrumbLink = "Management Co";
//       break;
//     case 5:
//       breadcrumbLink = "Security";
//       break;
//     default:
//       breadcrumbLink = "unknown";
//       break;
//   }
//   return breadcrumbLink;
// }

export function capitalizeFirstLetter(sentence) {
  return sentence.replace(/\b\w/g, (c) => c.toUpperCase());
}
