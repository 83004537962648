import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
import { capitalizeFirstLetter } from "../../../Utility/Helpers";
import { useParams } from "react-router-dom";
import { BsSearch } from "react-icons/bs";
import Api from "../../../Config/Api";
import Loader from "../../Elements/Loader";
import Button from "react-bootstrap/Button";
import { FaEdit, FaEye, FaTrashAlt, FaPlus, FaMoneyBill } from "react-icons/fa";
import { Tooltip } from "react-tooltip";
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import ownerSample from "../../../sampleCSV/unitSample.csv";
// import InfiniteScroll from "react-infinite-scroll-component";

const ManageOwnerListing = () => {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    const {
        register: register1,
        handleSubmit: handleSubmit1,
        // reset: reset1,
        formState: { errors: errors1 },
    } = useForm();
    const param = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const isAdmin = useSelector((state) => state.login.isAdmin);
    const roleId = useSelector((state) => state.login.roleId);
    const [propertyIsLoader, setPropertyIsLoader] = useState(true);
    const [ownerList, setOwnerList] = useState([]);
    const [camList, setCamList] = useState([]);
    const [isLoader, setIsLoader] = useState(true);
    const [propertyDetail, setPropertyDetail] = useState([]);
    const { id, ManagementCo } = useParams();
    const { user_id } = useParams();

    const [userId, setUserId] = useState(user_id ? atob(user_id) : "");
    const [propertyId, setPropertyId] = useState(id ? atob(id) : "");
    const [managementCo, setManagementCoId] = useState(
        ManagementCo ? atob(ManagementCo) : ""
    );
console.log("userId",userId);
    const [perPage] = useState(10);
    const [page, setPage] = useState(1);
    const [comId, setComId] = useState();
    const [isLoading, setLoading] = useState(false);

    const [show, setShow] = useState(false);
    const handleClose = () => {
        setShow(false);
    };

    const handleShow = (camData) => {
        setShow(true);
        setComId(camData?.id);
    };

    const handlePropertyChange = (event) => {
        setPropertyId(event.target.value);
        getUnitDetails(event.target.value);
    };

    const [file, setFile] = useState(null);
    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    const [unitlist, setUnitList] = useState([]);
    const getUnitDetails = async (property_Id) => {
        setIsLoader(true);
        const reqData = {
            propertyId: property_Id,
            managementCo: managementCo,
        }

        const { data } = await Api("getunitByPropertyId", "POST", reqData);
        if (data?.status) {
            const unitrep = data?.data;
            setUnitList(unitrep);
            setIsLoader(false);
        }
    };

    const [property, setProperty] = useState([]);
    const getPropertyDetails = async () => {
        const { data } = await Api("get-property", "POST", "");
        if (data?.status) {
            const propertyList = data?.data;
            setProperty(propertyList);
        }
    };


    const getOwnerDetails = async () => {
        setIsLoader(true);
        const reqProData = {
            propertyId: propertyId,

            managementCo: userId,
        };

        // console.log("managementCo????????????",userId);
        if (propertyId !== "") {
            const { data } = await Api("get-unit", "POST", reqProData);
            if (data?.status) {
                setPage(1);
                var temparr = data?.data.slice(0, perPage);
                console.log('temparr', temparr);
                setPropertyDetail(data?.property);
                setPropertyIsLoader(false);
                setIsLoader(false);

                const filtermanagementdata = temparr.filter((managementUnit) => parseInt(managementUnit?.users?.id) === parseInt(userId))
                console.log('filtermanagementdata', filtermanagementdata);
                // setOwner(data?.data);
                setOwnerList(filtermanagementdata);
                setCamList(filtermanagementdata);
            }
        }
    };

    useEffect(() => {
        getOwnerDetails();
        getPropertyDetails();

    }, []);

    const handleDelete = async (idToDelete) => {
        if (window.confirm("Are You Sure You Want to delete it?")) {
            const { data } = await Api("delete-unit", "POST", { id: idToDelete });
            if (data?.status) {
                toast.success("Unit has been Deleted Successfully!", {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
            getOwnerDetails();
        }
    };


    const onSubmit = async (d) => {
        setLoading(true);
        const assignUnitData = {
            unit_id: d?.unit_id,
            user_id: comId,
        };
        const { data } = await Api("mgmt_co_managed_unit", "POST", assignUnitData);
        if (Number(data?.status) === 0) {
            toast.success(data?.messsage, {
                position: toast.POSITION.TOP_RIGHT,
            });
            setShow(false);
            setLoading(false);
        } else if (Number(data?.status) === 1) {
            toast.success("Unit has been Assigned Successfully", {
                position: toast.POSITION.TOP_RIGHT,
            });
            setShow(false);
            setLoading(false);
        }
    };

    const [searchTerm, setSearchTerm] = useState("");
    const handleInputChange = (event) => {
        setSearchTerm(event.target.value);
        const search = event.target.value.toLowerCase();

        const filtered = camList.filter((item) => {
            if (Boolean(item)) {
                return (
                    item.unit_name.toLowerCase().includes(search) ||
                    item.firstname.toLowerCase().includes(search) ||
                    item.lastname.toLowerCase().includes(search) ||
                    item.email.toLowerCase().includes(search) ||
                    item.phone.toLowerCase().includes(search)
                );
            } else {
                return false;
            }
        });
        setOwnerList(filtered);
    };


    return (
        <>
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0">Units & Owners</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item">
                                        <Link
                                            to="/"
                                            className={
                                                location.pathname === "/"
                                                    ? "breadcrumb-active"
                                                    : "breadcrumb-not-active"
                                            }
                                        >
                                            Home
                                        </Link>
                                        {isAdmin ? (
                                            <>
                                                <span className="breadcrumb-arrow">&gt;</span>
                                                <Link
                                                    to="/user/ManagementCompanyListing"
                                                    className={
                                                        location.pathname.startsWith("/user/ManagementCompanyListing")
                                                            ? "breadcrumb-active"
                                                            : "breadcrumb-not-active"
                                                    }
                                                >
                                                    Management Co
                                                </Link>

                                            </>
                                        ) : ("")}
                                        <span className="breadcrumb-arrow">&gt;</span>
                                        <Link
                                            to="/user/ManagementCoPropertyList/MTEz"
                                            className={
                                                location.pathname.startsWith("/user/ManagementCoPropertyList/MTEz")
                                                    ? "breadcrumb-active"
                                                    : "breadcrumb-not-active"
                                            }
                                        >
                                            Property
                                        </Link>
                                    </li>
                                    <span className="breadcrumb-arrow">&gt;</span>
                                    <li className="breadcrumb-item active">Unit</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div
                                        className={`card-header ${isAdmin || Number(atob(roleId)) === 4 ? "adminColor" : "cambg-secondary"
                                            }`}
                                    >
                                        <div className="add_new_vendor_listing">
                                            <div className="">
                                                <h3 className="card-title mt-2">
                                                    Property :
                                                    {propertyIsLoader ? (
                                                        <Spinner
                                                            as="span"
                                                            animation="grow"
                                                            size="sm"
                                                            role="status"
                                                            aria-hidden="true"
                                                        />
                                                    ) : propertyDetail && managementCo === "" ? (
                                                        capitalizeFirstLetter(
                                                            propertyDetail?.name +
                                                            ", " +
                                                            propertyDetail?.city +
                                                            ", " +
                                                            propertyDetail?.state
                                                        )
                                                    ) : (
                                                        " Units & Owners"
                                                    )}
                                                </h3>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="d-flex justify-content-end px-4 mt-2">
                                        <div className="input-group w-25">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Search..."
                                                value={searchTerm}
                                                onChange={handleInputChange}
                                            />
                                            <div className="input-group-append">
                                                <span
                                                    className="input-group-text bg-primary border-0"
                                                    id="basic-addon2"
                                                >
                                                    <BsSearch />
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                    {isLoader ? (
                                        <Loader />
                                    ) : (
                                        <div className="card-body table-responsive">
                                            {/* <InfiniteScroll
                                            dataLength={ownerList.length}
                                            next={handleNext}
                                            hasMore={camList.length > ownerList.length}
                                            > */}

                                            <table className="table table-bordered">
                                                <thead className="border-top">
                                                    <tr>
                                                        <th>Unit Name</th>
                                                        <th>Owner First Name</th>
                                                        <th>Owner Last Name</th>
                                                        <th>Owner Email</th>
                                                        <th>Owner Phone No</th>
                                                        {
                                                            managementCo === "" ? (
                                                                <th>Management Co</th>
                                                            ) : (<></>)
                                                        }

                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {ownerList.length ? (
                                                        ownerList?.map((ownerData, i) => (

                                                            <tr key={i}>
                                                                <td>{ownerData?.unit_name}</td>
                                                                <td>{ownerData?.firstname}</td>
                                                                <td>{ownerData?.lastname}</td>
                                                                <td>{ownerData?.email}</td>
                                                                <td>{ownerData?.phone}</td>
                                                                <td>{ownerData?.users ? ownerData?.users?.name + " (" + ownerData?.users?.email + ")" : "-"}</td>
                                                                <td><div className="d-flex justify-content-evenly">

                                                                    <Button
                                                                        className="btn btn-info btn-sm mr-1"
                                                                        onClick={() => handleShow(ownerData)}
                                                                        data-tooltip-id="assign"
                                                                        data-tooltip-content="Property"
                                                                    >
                                                                        <FaPlus />
                                                                    </Button>
                                                                    <Tooltip id="assign" place="bottom" />

                                                                    <Link
                                                                        to={"/user/OwnerDetail"}
                                                                        state={{
                                                                            id: ownerData?.id,
                                                                            propertyId: ownerData?.property_id,
                                                                            userId: ownerData?.users?.id,
                                                                            isEdit: true,
                                                                        }}
                                                                        className="btn btn-success btn-sm mr-1"
                                                                        data-tooltip-id="detail"
                                                                        data-tooltip-content="Show Unit Detail"
                                                                    >
                                                                        <FaEye />
                                                                    </Link>
                                                                    <Tooltip id="detail" place="bottom" />

                                                                    <Link
                                                                        to="/user/OwnerForm"
                                                                        state={{
                                                                            id: ownerData.id,
                                                                            isEdit: true,
                                                                        }}
                                                                        className="btn  btn-primary btn-sm mr-1"
                                                                        data-tooltip-id="edit"
                                                                        data-tooltip-content="Edit"
                                                                    >
                                                                        <FaEdit />
                                                                    </Link>
                                                                    <Tooltip id="edit" place="bottom" />

                                                                    <button
                                                                        onClick={() =>
                                                                            handleDelete(ownerData.id)
                                                                        }
                                                                        className="btn  btn-danger btn-sm mr-1"
                                                                        data-tooltip-id="delete"
                                                                        data-tooltip-content="Delete"
                                                                    >
                                                                        <FaTrashAlt />
                                                                    </button>
                                                                    <Tooltip id="delete" place="bottom" />

                                                                </div></td>
                                                            </tr>
                                                        ))) : (
                                                        <tr>
                                                            <td colSpan={7}>
                                                                <p className="text-center">No Data Found.</p>
                                                            </td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                            {/* </InfiniteScroll> */}
                                        </div>
                                    )}

                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>

            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Assign Property to Unit</Modal.Title>
                </Modal.Header>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Modal.Body>
                        <div className="row">

                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>Select Property</label>
                                    <select
                                        className="form-control"
                                        {...register("property_id", {
                                            required: true,
                                        })}
                                        onChange={handlePropertyChange}
                                    >  {" "}
                                        <option value="">Select Property</option>
                                        {property?.map((propertydata, i) => (
                                            <option key={i} value={propertydata?.id}>
                                                {propertydata?.name}
                                            </option>
                                        ))}
                                    </select>
                                    {errors.property_id && (
                                        <p className="text-danger">Property is required</p>
                                    )}
                                </div>
                            </div>
                            {unitlist.length > 0 && (
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>Select Unit</label>
                                        <select
                                            className="form-control"
                                            {...register("unit_id", {
                                                required: true,
                                            })}
                                        >
                                            <option value={""}>Select Unit</option>
                                            {unitlist?.map((unitData, i) => (
                                                <option key={i} value={unitData?.id}>
                                                    {unitData?.unit_name}
                                                </option>
                                            ))}
                                        </select>
                                        {errors.unit_id && (
                                            <p className="text-danger">Unit is required</p>
                                        )}

                                    </div>
                                </div>
                            )}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="primary" type="submit" disabled={isLoading}>
                            {isLoading ? (
                                <>
                                    <Spinner animation="border" size="sm" /> Please wait...
                                </>
                            ) : (
                                "Save"
                            )}
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    )
}

export default ManageOwnerListing