import React from "react";
import { useSelector } from "react-redux";
import AdminDashboard from "../Components/AdminDashboard";
import MgmtDashboard from "../Components/MgmtDashboard";
import UserDashboard from "../Components/UserDashboard";

const Index = () => {
  const roleId = useSelector((state) => state.login.roleId);
  switch (Number(atob(roleId))) {
    case 1:
      return <AdminDashboard />;
    case 2:
      return <UserDashboard />;
    case 4:
      return <MgmtDashboard />;
    default:
      return <UserDashboard />;
  }
};

export default Index;
