import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FaEdit, FaEye, FaTrashAlt } from "react-icons/fa";
import Api from "../../../Config/Api";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { Tooltip } from "react-tooltip";
import Loader from "../../Elements/Loader";
import { Spinner } from "react-bootstrap";

const EmailTemplateListing = () => {
  const [isLoader, setIsLoader] = useState(true);
  const [emailTemplate, setEmailTemplate] = useState([]);
  const isAdmin = useSelector((state) => state.login.isAdmin);
  const roleId = useSelector((state) => state.login.roleId);

  const getOwnerDetails = async () => {
    // setIsLoader(true);
    const { data } = await Api("getEmailTemplates", "POST", {});
    if (Number(data?.status) === 1) {
      setIsLoader(false);
      setEmailTemplate(data?.data);
    } else {
      setIsLoader(false);
      toast.error(`${data?.message}`);
    }
  };

  const handleDelete = ()=> {
    alert('delete');
  }

  useEffect(() => {
    getOwnerDetails();
  }, []);

  return (
    <>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Email Templates</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <span className="breadcrumb-arrow">&gt;</span>
                  <li className="breadcrumb-item active">
                  Email Templates
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div
                    className={`card-header ${
                      isAdmin || Number(atob(roleId)) === 4
                        ? "adminColor"
                        : "cambg-secondary"
                    }`}
                  >
                    <div className="add_new_vendor_listing">
                      <div className="">
                        <h3 className="card-title mt-2">
                          Email Template Listing
                        </h3>
                      </div>
                    </div>
                  </div>

                  {isLoader ? (
                    <Loader />
                  ) : (
                    <div className="card-body table-responsive">
                      <table className="table table-bordered">
                        <thead className="border-top">
                          <tr>
                            <th>S. No.</th>
                            <th>Name</th>
                            <th>Subject</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {Number(emailTemplate.length) > 0 ? (
                            emailTemplate?.map((templateData,i)=>(
                            <tr key={i}>
                                <td>{i+1}</td>
                                 <td>{templateData?.name}</td>
                                 <td>{templateData?.subject}</td>
                                 <td>
                                 <Link
                                          to="/user/EmailTemplateView"
                                          state={{
                                            templateData,
                                            isEdit: true,
                                          }}
                                          className="btn mr-2 btn-primary btn-sm"
                                          data-tooltip-id="edit"
                                          data-tooltip-content="Edit"
                                        >
                                          <FaEdit />
                                        </Link>

                                        <button
                                          onClick={() =>
                                            handleDelete(templateData?.id)
                                          }
                                          className="btn  btn-danger btn-sm"
                                          data-tooltip-id="delete"
                                          data-tooltip-content="Delete"
                                        >
                                          <FaTrashAlt />
                                        </button>
                                        <Tooltip id="delete" place="bottom" />
                                 </td>
                            </tr>
                            ))
                          ) : (
                            <tr></tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default EmailTemplateListing;
