import React from 'react'
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom'
import SecuritySample from "../../../sampleCSV/SecuritySample.csv";
import vendorSample from "../../../sampleCSV/vendorSample.csv";
import unitSample from "../../../sampleCSV/unitSample.csv";
import { BsDownload } from "react-icons/bs";
const Index = () => {
  const isAdmin = useSelector((state) => state.login.isAdmin);
  const location = useLocation();
  return (
    <>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                {<h1 className="m-0">Resources</h1>}
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/"
                      className={location.pathname === "/" ? "breadcrumb-active" : "breadcrumb-not-active"}
                    >
                      Home
                    </Link>
                  </li>
                  <span className="breadcrumb-arrow">&gt;</span>
                  <li className="breadcrumb-item active">Resources</li>
                </ol>
              </div>
            </div>
          </div>
        </div>

        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className={`card-header ${isAdmin ? "adminColor" : "cambg-secondary"}`}>
                    <div className="add_new_vendor_listing">
                      <h3 className="card-title">CSV Samples</h3>
                      {/* <Link
                        to={"/user/CamFrom"}
                        className="btn btn-light text-dark bg-light"
                      >
                        Add CAM
                      </Link> */}
                    </div>
                  </div>

                  <div className="card-body table-responsive">
                    <table className="table table-bordered">
                      <thead className="border-top">
                        <tr>
                          <th>Title</th>
                          <th>Description</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Unit & Owner Sample CSV</td>
                          <td>-</td>
                          <td><a href={unitSample} className='btn btn-primary' download={'Unit-sample'}><BsDownload /></a></td>
                        </tr>

                        <tr>
                          <td>Vendor  Sample CSV</td>
                          <td>-</td>
                          <td><a href={vendorSample} className='btn btn-primary' download={'Vendor-sample'}><BsDownload /></a></td>
                        </tr>


                        <tr>
                          <td>Security  Sample CSV</td>
                          <td>-</td>
                          <td><a href={SecuritySample} className='btn btn-primary' download={'Security-sample'}><BsDownload /></a></td>
                        </tr>

                      </tbody>
                    </table>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </section>
      </div>


    </>
  )
}

export default Index