import React, { useEffect, useState } from "react";

import Cookies from "js-cookie";
// import beachWarning from "../../assets/img/beach-warning-flags.png";
// import Form from "react-bootstrap/Form";
import { useSelector } from "react-redux";
import Select from "react-select";
import { getProperty } from "../../Reducers/RenterProperty";
import { useDispatch } from "react-redux";
import Api from "../../Config/Api";
// import { toast } from "react-toastify";
// import { Spinner } from "react-bootstrap";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { BsFillCheckCircleFill } from "react-icons/bs";
import Card from "react-bootstrap/Card";
import { PaymentSuccess } from "../../Reducers/CartSlice";
import Loader from "../Elements/Loader";
import dayjs from "dayjs";

const SuccessPayment = () => {

  const dispatch = useDispatch();
  const [price, setPrice] = useState(0);
  const [passInfo, setPassInfo] = useState([]);
  // const [isLoading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();

  const [orderId, setOrderId] = useState(Cookies.get("billingId"));
  const [paymentIntent, setPaymentIntent] = useState(Cookies.get("intentId"));
  const navigate = useNavigate();
  const RenterProperty = useSelector(getProperty);
  const [passStatus, setPassStatus] = useState([]);

  const location = useLocation();
  const [isLoader, setIsLoader] = useState(true);
  const background_image = RenterProperty?.bg_image
    ? process.env.REACT_APP_AWS_S3_BUCKET_URL + "images/" + RenterProperty?.bg_image
    : "";
  const property_logo = RenterProperty?.logo
    ? process.env.REACT_APP_AWS_S3_BUCKET_URL +
    "images/" + RenterProperty?.logo
    : "";

  const sectionStyle = {
    backgroundImage: `url(${background_image})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  };


  useEffect(() => {
    dispatch(PaymentSuccess());
    const paymentIntentParam = searchParams.get("payment_intent");
    const success = searchParams.get("redirect_status");
    const order_id = searchParams.get("billingId");
    if (paymentIntentParam) {
      Cookies.set('billingId', searchParams.get("billingId"));
      Cookies.set('intentId', searchParams.get("payment_intent"));
      setPaymentIntent(paymentIntentParam);
      setOrderId(order_id);
      console.log('paymentIntentParam', paymentIntentParam);
    }
    navigate("/SuccessPayment");
  }, []);


  const getCheckOutDetails = async () => {
    const res = await Api("getBillingDetailsById", "POST", { id: orderId });
    console.log('res', res);
    if (res?.data?.status) {
      console.log('res', res);
      setPassInfo(res?.data?.data);
      setPrice(res?.data?.data?.vehiclepass?.pass_fee);
      setIsLoader(false);

      const startDate = dayjs(res?.data?.data?.vehiclepass?.arrival_date);
      const endDate = dayjs(res?.data?.data?.vehiclepass?.departure_date);
      const currentDate = dayjs();

      console.log("startDate", startDate);
      console.log("endDate", endDate);
      console.log("currentDate", currentDate);

      if ((res?.data?.data?.vehiclepass?.issuccess) === 0 || (res?.data?.data?.vehiclepass?.Is_rental_vehicle) === 0){
        setPassStatus("incomplete");
        console.log("incomplete");

      } else {
        if (currentDate.isBefore(startDate)) {
          // status = 'future';
          setPassStatus("future");
          console.log("future");
        } else if (currentDate.isAfter(endDate)) {
          // status = 'closed';
          setPassStatus("closed");
          console.log("closed");
        } else {
          // status = 'current';
          setPassStatus("current");
          console.log("current");

        }
      }
    }
  };

  useEffect(() => {
    if (paymentIntent !== null) {
      SavePaymentUsingPaymentIntent();
      getCheckOutDetails();
    }
  }, [paymentIntent]);

  const SavePaymentUsingPaymentIntent = async () => {
    const req = {
      paymentIntentId: paymentIntent,
      billingId: orderId
    }

    const retrievePaymentIntent = await Api('retrievePaymentIntent', 'POST', req);
  }



  return (
    <>
      <main>
        <section
          className="py-5 guest_section bannersection"
          style={sectionStyle}
        >
          <div className="container-fluid" style={{
            paddingLeft: "269px"
          }}>
            <div className="new_guest_form">
              <div className="returning_heading">
                <h1 className="pb-3 text-center">Order Received</h1>
              </div>

              {isLoader ? (
                <Loader />
              ) : (
                <>

                  {/* <Card>
                    <Card.Body>
                      Thank You, Your order has been received.{" "}
                      <span className="text-uppercase text-danger text-decoration-underline">
                        {" "}
                        click Here to VIEW/PRINT Your Parking pass
                      </span>
                    </Card.Body>
                  </Card> */}

                  <Card>
                    <Card.Body>
                      <div className="row">
                        <div className="col-lg-5">
                          <tr>
                            <td>
                              <p className="text-uppercase p-0 my-1 mr-5 d-inline">
                                order status
                              </p>
                            </td>
                            <th>
                              {passStatus === "current" || passStatus === "future" ? (
                                <span className="text-success">{passStatus}</span>
                              ) : (
                                <span className="text-danger">{passStatus}</span>
                              )}
                            </th>
                          </tr>

                          <tr>
                            <td>
                              <p className="text-uppercase p-0 my-1 mr-5 d-inline">
                                order number
                              </p>
                            </td>
                            <th>
                              {passInfo?.vehiclepass?.order_uuid}
                            </th>
                          </tr>

                          <tr>
                            <td>
                              <p className="text-uppercase p-0 my-1 mr-5 d-inline">
                                Date
                              </p>
                            </td>
                            <th>

                              {
                                dayjs(passInfo?.vehiclepass?.arrival_date).format("MM/DD/YYYY")}
                            </th>
                          </tr>

                          <tr>
                            <td>
                              <p className="text-uppercase p-0 my-1 mr-5 d-inline">
                                Email
                              </p>
                            </td>
                            <th>{passInfo?.user?.email}</th>
                          </tr>

                          <tr>
                            <td>
                              <p className="text-uppercase p-0 my-1 mr-5 d-inline">
                                Total Amount
                              </p>
                            </td>
                            <th> ${Number(price).toFixed(2)}
                            </th>
                          </tr>
                        </div>
                        <div className="col-lg-4">
                          <tr>
                            <th>
                              {" "}
                              <h4>Billing Address</h4>
                            </th>
                          </tr>

                          <tr>
                            {" "}
                            <td>Name : </td>
                            <th>
                              <span>
                                {passInfo?.fname + " " + passInfo?.lname}
                              </span>
                            </th>
                          </tr>

                          <tr>
                            <td>Company Name : </td>{" "}
                            <th>
                              {" "}
                              <span>
                                {passInfo?.companyName}
                              </span>
                            </th>
                          </tr>

                          <tr>
                            <td>Country : </td>{" "}
                            <th>
                              {" "}
                              <span>{passInfo?.country}</span>
                            </th>
                          </tr>

                          <tr>
                            <td>Phone : </td>
                            <th>
                              <span>{passInfo?.phone}</span>
                            </th>
                          </tr>

                          <tr>
                            <td>Email : </td>
                            <th>
                              <span>{passInfo?.email}</span>
                            </th>
                          </tr>
                        </div>
                        <div className="col-lg-3">
                          {passInfo?.vehiclepass
                            ?.issuccess && (
                              <div className="text-end">
                                <img
                                  src={
                                    passInfo?.vehiclepass
                                      ?.qr_code
                                  }
                                  width={160}
                                  alt=""
                                  srcSet=""
                                />
                              </div>
                            )}
                        </div>
                      </div>


                    </Card.Body>
                  </Card>

                  <h3 className="pb-3">Order details</h3>

                  <Card>
                    <Card.Header>
                      <div className="d-flex justify-content-between">
                        <div>
                          <h3 className="text-dark">Order details</h3>
                        </div>
                      </div>
                    </Card.Header>
                    <Card.Body>
                      <div className="row">
                        <div className="col-lg-12">
                          <tr>
                            <th>  <p className="text-uppercase">Rules & Regulations</p></th>
                            <td className="px-5">
                              {
                                Boolean(passInfo?.vehiclepass?.rules) ? (
                                  JSON.parse(passInfo?.vehiclepass?.rules).map((data, i) => (


                                    <div key={i}>
                                      {Array.isArray(data) ? (
                                        data.map((item, index) => (
                                          <li>
                                            <span key={index}>{item}</span>
                                            <span className="text-success mx-3">
                                              <BsFillCheckCircleFill className="h5 mt-2" />
                                            </span>
                                          </li>
                                        ))
                                      ) : (
                                        <>
                                          <li><span>{data}</span>
                                            <span className="text-success mx-3">
                                              <BsFillCheckCircleFill className="h5 mt-2" />
                                            </span>
                                          </li>

                                        </>
                                      )}

                                    </div>
                                  ))
                                ) : (
                                  "-"
                                )}
                            </td>
                          </tr>

                          <tr>
                            <th> <p className="text-uppercase">Beach Flag Warning System </p></th>
                            <td className="px-5">
                              {
                                Boolean(passInfo?.vehiclepass?.warnings) ? (
                                  JSON.parse(passInfo?.vehiclepass?.warnings).map((data, i) => (
                                    <div key={i}>
                                      {Array.isArray(data) ? (
                                        data.map((item, index) => (
                                          <li>
                                            <span key={index}>{item}</span>
                                            <span className="text-success mx-3">
                                              <BsFillCheckCircleFill className="h5 mt-2" />
                                            </span>
                                          </li>
                                        ))
                                      ) : (
                                        <>
                                        <li><span>{data}</span>
                                            <span className="text-success mx-3">
                                              <BsFillCheckCircleFill className="h5 mt-2" />
                                            </span>
                                          </li>
                                        </>
                                      )}

                                    </div>
                                  ))
                                ) : (
                                  "-"
                                )
                              }
                            </td>
                          </tr>

                          <tr>
                            <th>  <p className="text-uppercase ">Non-Refundable</p></th>
                            <td className="px-5">
                              {
                                Boolean(passInfo?.vehiclepass?.refundable_rules) ? (
                                  JSON.parse(passInfo?.vehiclepass?.refundable_rules)?.map((data, i) => (
                                    <div key={i}>
                                      {Array.isArray(data) ? (
                                        data?.map((item, index) => (
                                          <li>
                                            <span key={index}>{item}</span>
                                            <span className="text-success mx-3">
                                              <BsFillCheckCircleFill className="h5 mt-2" />
                                            </span>
                                          </li>
                                        ))
                                      ) : (
                                        <>
                                        <li><span>{data}</span>
                                            <span className="text-success mx-3">
                                              <BsFillCheckCircleFill className="h5 mt-2" />
                                            </span>
                                          </li>
                                        </>
                                      )}

                                    </div>
                                  ))
                                ) : (
                                  "-"
                                )
                              }
                            </td>
                          </tr>

                          <tr>
                            <th>
                              <p className="text-uppercase">
                                Parking Price
                              </p>
                            </th>
                            <td className="px-5">
                              <span className="text-end">
                                ${Number(price).toFixed(2)}{" "}
                              </span>
                            </td>
                          </tr>


                          <tr>
                            <th>
                              <p className="text-uppercase">
                                Pass Code
                              </p>
                            </th>
                            <td className="px-5">
                              {passInfo?.vehiclepass?.order_uuid}
                            </td>
                          </tr>



                          <tr>
                            <th>
                              <p className="text-uppercase">
                                Unit
                              </p>
                            </th>
                            <td className="px-5">{passInfo?.vehiclepass?.unit?.unit_name}</td>
                          </tr>

                          <tr>
                            <th>
                              <p className="text-uppercase">
                                Begin Date
                              </p>
                            </th>
                            <td className="px-5">
                              {
                                dayjs(passInfo?.vehiclepass?.arrival_date).format("MM/DD/YYYY")}
                            </td>
                          </tr>

                          <tr>
                            <th>
                              <p className="text-uppercase">
                                Departure Date
                              </p>
                            </th>
                            <td className="px-5">
                              {
                                dayjs(passInfo?.vehiclepass?.departure_date).format("MM/DD/YYYY")}
                            </td>
                          </tr>

                          <tr>
                            <th>
                              <p className="text-uppercase">
                                Full Name
                              </p>
                            </th>
                            <td className="px-5">
                              {" "}
                              {passInfo?.user?.name +
                                " " +
                                passInfo?.user?.lastname}
                            </td>
                          </tr>

                          <tr>
                            <th>
                              <p className="text-uppercase">
                                Phone
                              </p>
                            </th>
                            <td className="px-5">
                              {passInfo?.user?.phone}
                            </td>
                          </tr>


                          <tr>
                            <th>
                              <p className="text-uppercase">
                                SubTotal
                              </p>
                            </th>
                            <td className="px-5">
                              ${Number(passInfo?.vehiclepass?.pass_fee).toFixed(2)}
                            </td>
                          </tr>


                          <tr>
                            <th>
                              <p className="text-uppercase">
                                Discount
                              </p>
                            </th>
                            <td className="px-5">
                              ${Number(0).toFixed(2)}
                            </td>
                          </tr>


                          <tr>
                            <th>
                              <p className="text-uppercase">
                                Tax
                              </p>
                            </th>
                            <td className="px-5">
                              ${Number(0).toFixed(2)}
                            </td>
                          </tr>


                          <tr>
                            <th>
                              <p className="text-uppercase">
                                Total Amount
                              </p>
                            </th>
                            <td className="px-5">
                              ${Number(price).toFixed(2)}
                            </td>
                          </tr>
                        </div>


                      </div>
                    </Card.Body>
                  </Card>
                </>
              )}
            </div>
          </div>
        </section>


      </main>
    </>
  );
};

export default SuccessPayment;
