import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import Api from "../../Config/Api";
import { getProperty } from "../../Reducers/RenterProperty";
const EditProfile = () => {
  const isAdmin = useSelector((state) => state.login.isAdmin);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const RenterProperty = useSelector(getProperty);
  const background_image = RenterProperty?.bg_image
    ? process.env.REACT_APP_AWS_S3_BUCKET_URL+ "images/" + RenterProperty?.bg_image
    : "";
  const property_logo = RenterProperty?.logo
    ? process.env.REACT_APP_AWS_S3_BUCKET_URL+
                        "images/" + RenterProperty?.logo
    : "";

  const sectionStyle = {
    backgroundImage: `url(${background_image})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  };

  const onSubmit = async (d) => {
    setLoading(true);
    if (d.new_confirm_password === d?.new_password) {
      const { data } = await Api("change-password", "POST", d);
      if (data?.status) {
        setLoading(false);
        toast.success("Your password has been successfully changed. !", {
          position: toast.POSITION.TOP_RIGHT,
        });
        navigate("/RenterChangePassword");
      } else {
        setLoading(false);
        toast.success(data?.message?.current_password[0], {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } else {
      setLoading(false);
      toast.success("Incorrect Password !", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  return (
    <>
      <main>
        <section
          className="py-5 guest_section bannersection"
          style={sectionStyle}
        >
          <div className="container-fluid" style={{
    paddingLeft:"269px"
}}>
            <div className="new_guest_form" style={{marginBottom:"225px"}}>
              <section className="content">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="">
                        <h5
                          className={`py-2 px-4 text-light rounded-3 ${
                            isAdmin ? "adminColor" : "cambg-secondary"
                          }`}
                        >
                          Change Password
                        </h5>
                        <form
                          className="form-horizontal"
                          onSubmit={handleSubmit(onSubmit)}
                        >
                          <div className="card-body">
                            <div className="row align-items-center">
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label htmlFor="current_password">
                                    Current Password
                                  </label>
                                  <input
                                    type="password"
                                    className="form-control"
                                    id="current_password"
                                    placeholder="Enter current Password"
                                    {...register("current_password", {
                                      required: true,
                                    })}
                                  />
                                  {errors.current_password && (
                                    <div className="form_error text-danger">
                                      Current Password is required.
                                    </div>
                                  )}
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className="form-group">
                                  <label htmlFor="new_password">New Password</label>
                                  <input
                                    type="password"
                                    className="form-control"
                                    id="new_password"
                                    placeholder="Enter Password"
                                    {...register("new_password", {
                                      required: true,
                                    })}
                                  />
                                  {errors.new_password && (
                                    <div className="form_error text-danger">
                                      New Password is required.
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label htmlFor="new_confirm_password">
                                    Confirm Password
                                  </label>
                                  <input
                                    type="password"
                                    className="form-control"
                                    id="new_confirm_password"
                                    placeholder="Enter Confirm Password"
                                    {...register("new_confirm_password", {
                                      required: true,
                                    })}
                                  />
                                  {errors.new_confirm_password && (
                                    <div className="form_error text-danger">
                                      Confirm Password is required.
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="card-footer">
                            <button
                              className="btn btn-outline-light text-dark mx-3 bg-light"
                              onClick={() => navigate(-1)}
                              type="button"
                            >
                              Cancel
                            </button>
                            <button
                              type="submit"
                              variant="primary"
                              className={`btn btn-primary  ${
                                isAdmin ? "" : "cambg-secondary border-0"
                              }`}
                              disabled={isLoading}
                            >
                              {isLoading ? (
                                <>
                                  <Spinner animation="border" size="sm" />{" "}
                                  Please wait...
                                </>
                              ) : (
                                "Update"
                              )}
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default EditProfile;
