import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { toast } from "react-toastify";
import Api from "../../../Config/Api";
import { Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import Loader from "../../Elements/Loader";

const CamFrom = () => {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const isAdmin = useSelector((state) => state.login.isAdmin);
  const [isLoader, setIsLoader] = useState(location?.state?.isEdit ? true : false);
  const roleId = useSelector((state) => state.login.roleId);
  const [phone, setPhone] = useState();
  const [propertyId, setpropertyId] = useState(
    location?.state?.propertyId ? location?.state?.propertyId : ""
  );
  const onSubmit = async (d) => {
    setLoading(true);
    const userfromdata = new FormData();
    userfromdata.append("name", d.name);
    userfromdata.append("lastname", d.lastname);
    userfromdata.append("email", d.email);
    userfromdata.append("password", d.password);
    userfromdata.append("role_id", 5);
    userfromdata.append("property_id", propertyId);
    userfromdata.append("phone", d.phone);
    userfromdata.append("login_status",d.login_status);


    if (!Boolean(location?.state?.id) && !location?.state?.id) {
      try {
        var { data } = await Api("add-security-user", "POST", userfromdata);
        if (data?.status) {
          setLoading(false);
          toast.success(data?.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          navigate(`/user/SecurityListing/${btoa(propertyId)}`);
        }
      } catch (error) {
        if (Boolean(error?.data?.errors)) {
          toast.error(error?.data?.errors?.email[0]);
          setValue("email", "");
        }
      }
    } else {
      userfromdata.append("id", location?.state?.id);
      var res = await Api("update-security-user", "POST", userfromdata);
      if (res?.data?.status) {
        setLoading(false);
        toast.success(res?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        navigate(`/user/SecurityListing/${btoa(propertyId)}`);
        reset();
      }
    }
  };

  const getModifyCamData = async () => {
    const { data } = await Api("get-security-user", "POST", {
      id: location?.state?.id,
    });
    const CamData = data?.data;
    setPhone(data?.data?.phone);
    reset(CamData);
    setIsLoader(false);
  };

  useEffect(() => {
    if (!!location?.state?.id) {
      getModifyCamData();
    }
  }, [location?.state?.id]);

  const [selectedImage, setSelectedImage] = useState(null);
  const [Image, setImage] = useState(null);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImage(file);
    const reader = new FileReader();

    reader.onload = (event) => {
      setSelectedImage(event.target.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };


  useEffect(() => {
    setPhone(phone || ''); // Use an empty string if 'phone' is undefined
  }, [phone]);

  const handleInputChange = (event) => {
    const rawPhoneNumber = event.target.value.replace(/\D/g, '');
    const truncatedPhoneNumber = rawPhoneNumber.slice(0, 10);
    const formattedPhoneNumber = truncatedPhoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
    
    // Set the phone value directly using setValue from react-hook-form
    setValue('phone', formattedPhoneNumber);
  
    // Update the state with the formatted phone number
    setPhone(formattedPhoneNumber);
  };

  return (
    <>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">
                  {" "}
                  {location?.state?.isEdit ? "Edit SECURITY" : "Add Security Guards"}{" "}
                </h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/"
                      className={location.pathname === "/" ? "breadcrumb-active" : "breadcrumb-not-active"}
                    >
                      Home
                    </Link>
                    {isAdmin ? (
                      <>
                        <span className="breadcrumb-arrow">&gt;</span>
                        <Link to="/user/PropertyListing"
                          className={location.pathname.startsWith("/user/PropertyListing") ? "breadcrumb-active" : "breadcrumb-not-active"}
                        >
                          Property
                        </Link>
                      </>
                    ) : ("")}
                    <span className="breadcrumb-arrow">&gt;</span>
                    <Link to={isAdmin || Number(atob(roleId)) === 2 ? ("/user/SecurityListing/" +  btoa(propertyId)) : ("/user/SecurityListing/MTI0")}
                      className={isAdmin || Number(atob(roleId)) === 2 ? (location.pathname.startsWith("/user/SecurityListing/" +  btoa(propertyId)) ? "breadcrumb-active" : "breadcrumb-not-active") : (location.pathname.startsWith("/user/SecurityListing/MTI0") ? "breadcrumb-active" : "breadcrumb-not-active")}
                    >
                      Security
                    </Link>
                  </li>
                  <span className="breadcrumb-arrow">&gt;</span>
                  <li className="breadcrumb-item active">
                    {" "}
                    {location?.state?.isEdit ? "Edit Security" : "Add Security"}{" "}
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>

        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card card-primary">
                  <div className={`card-header ${isAdmin ? "adminColor" : "cambg-secondary"}`}>
                    <h3 className="card-title">
                      {" "}
                      {location?.state?.isEdit
                        ? "Edit Security"
                        : "Add Security"}{" "}
                    </h3>
                  </div>

                  {isLoader ? (
                    <Loader />
                  ) : (

                    <form
                      onSubmit={handleSubmit(onSubmit)}
                      encType="multipart/form-data"
                    >
                      <div className="card-body">
                        <div className="row align-items-center">
                          {/* <div className="col-md-12">
                          <div className="form-group">
                            <div  className="small-12 medium-2 large-2 columns">
                              <div className="row">
                                <div className="col-lg-2  d-flex align-items-center">
                                  <label htmlFor="image">Select Profile</label>
                                </div>
                                <div className="col-lg-6">
                                  <label htmlFor="image">
                                    <div  className="user-circle">
                                      {selectedImage ? (
                                        <img
                                           className="user-profile-pic user-img"
                                          src={selectedImage}
                                        />
                                      ) : (
                                        <img
                                           className="user-profile-pic user-img"
                                          src="https://t3.ftcdn.net/jpg/03/46/83/96/360_F_346839683_6nAPzbhpSkIpb8pmAwufkC7c5eD7wYws.jpg"
                                        />
                                      )}
                                    </div>
                                  </label>
                                  <div  className="user-p-image">
                                    <input
                                       className="user-file-upload"
                                      id="image"
                                      type="file"
                                      accept="image/*"
                                      onChange={handleImageChange}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div> */}

                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="fname">First Name</label>
                              <input
                                type="text"
                                className="form-control"
                                id="fname"
                                placeholder="Enter First Name"
                                {...register("name", { required: true })}
                              />

                              {errors.name && (
                                <p className="text-danger">
                                  First Name is required
                                </p>
                              )}
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="lastname">Last Name</label>
                              <input
                                type="text"
                                className="form-control"
                                id="lastname"
                                placeholder="Last Name"
                                {...register("lastname", { required: true })}
                              />

                              {errors.lastname && (
                                <p className="text-danger">
                                  Last Name is required
                                </p>
                              )}
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="email">Email</label>
                              <input
                                type="email"
                                className="form-control"
                                id="email"
                                placeholder="Enter Email"
                                {...register("email", { required: true })}
                              />

                              {errors.email && (
                                <p className="text-danger">Email is required</p>
                              )}
                            </div>
                          </div>



                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="enterNumber">Phone</label>
                              <input
                                type="text"
                                value={phone}
                                onChange={handleInputChange}
                                className="form-control"
                                id="enterNumber"
                                placeholder="Enter Phone Number"
                                required={true}
                              />
                            </div>
                          </div>


                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="password">Password</label>
                              <input
                                type="password"
                                className="form-control"
                                id="password"
                                placeholder="Enter Password"
                                {...register("password", { required: true })}
                              />

                              {errors.password && (
                                <p className="text-danger">
                                  Password is required
                                </p>
                              )}
                            </div>
                          </div>


                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="status">Status</label>
                              <select
                                className="form-select"
                                id="status"
                                {...register("login_status", { required: true })}
                              >
                                <option value="">Select Status</option>
                                <option value="1">Activate</option>
                                <option value="0">Deactivate</option>
                              </select>

                              {errors.status && (
                                <p role="alert" className="text-danger">
                                  Status is required
                                </p>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="card-footer">
                          <button
                            type="submit"
                            variant="primary"
                            className={`btn btn-primary  ${isAdmin ? "" : "cambg-secondary border-0"}`}
                            disabled={isLoading}
                          >
                            {isLoading ? (
                              <>
                                <Spinner animation="border" size="sm" /> Please
                                wait...
                              </>
                            ) : (
                              "Submit"
                            )}
                          </button>
                          <button
                            className="btn btn-outline-light text-dark mx-3 bg-light"
                            type="button"
                            onClick={() => navigate(-1)}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </form>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default CamFrom;
