import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation, Link } from "react-router-dom";
import Api from "../../../Config/Api";
import Loader from "../../Elements/Loader";
const PropertyDetail = () => {
  const location = useLocation();
  const isAdmin = useSelector((state) => state.login.isAdmin);
  const [isLoader, setIsLoader] = useState(true);
  const [propertyList, setPropertyList] = useState([]);
  const [camDetails, setAssignCamDetails] = useState([]);
  const [images, setImages] = useState([]);
  const [propertyData, setPropertyData] = useState([]);
  const [selectedLogo, setSelectedLogo] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const userId = useSelector((state) => state.login.userId);

  const getPropertyDetails = async () => {
    setIsLoader(true);
    const { data } = await Api("get-property", "POST", {
      id: location?.state?.id,
    });
    console.log('propertyList', location?.state?.id);
    if (data?.status) {
      setIsLoader(false);
      const propertyList = data?.data;
      setPropertyList(propertyList);
      console.log('propertyList', propertyList);
      // var rulearr = propertyList?.rules_and_regulations
      //   ?.filter((a) => a.for_whom === 1)
      //   .map((b) => {
      //     return {
      //       type: b?.type,
      //       value: b?.rules,
      //       ruleId: b?.id,
      //     };
      //   });
      // console.log('data', rulearr);

      // var warnarr = propertyList?.rules_and_regulations
      //   ?.filter((a) => a.for_whom === 3)
      //   .map((b) => {
      //     return {
      //       type: b?.type,
      //       value: b?.rules,
      //       image: b?.images,
      //       ruleId: b?.id,
      //     };
      //   });
      // console.log('data', warnarr);

      // var refundarr = propertyList?.rules_and_regulations
      //   ?.filter((a) => a.for_whom === 2)
      //   .map((b) => {
      //     return {
      //       type: b?.type,
      //       value: b?.rules,
      //       ruleId: b?.id,
      //     };
      //   });
      // console.log('data', refundarr);

      // var parkRulearr = data?.data?.rules_and_regulations
      //   ?.filter((a) => a.for_whom === 4)
      //   .map((b) => {
      //     return {
      //       type: b?.type,
      //       value: b?.rules,
      //       ruleId: b?.id,
      //     };
      //   });
      // console.log('data', parkRulearr);

      // warnarr?.forEach((item) => {
      //   const image = item.image; // Assuming the image property is called "image"
      //   if (image) {
      //     setImages((prevImages) => [...prevImages, image]);
      //   }
      // });
      // setPropertyData(propertyList);
      // setSelectedImage(
      //   propertyList?.bg_image
      //     ? process.env.REACT_APP_AWS_S3_BUCKET_URL+
      // "images/" + propertyList?.bg_image
      //     : ""
      // );
      // setSelectedLogo(

      //   propertyList?.logo
      //     ? process.env.REACT_APP_AWS_S3_BUCKET_URL+
      // "images/" + propertyList?.logo
      //     : ""
      // )

    }
  };

  useEffect(() => {

    if (!!location?.state?.id) {
      getPropertyDetails();
    }
  }, [location?.state?.id]);

  return (
    <>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">PROPERTY DETAILS</h1>
              </div>

              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/"
                      className={location.pathname === "/" ? "breadcrumb-active" : "breadcrumb-not-active"}
                    >
                      Home
                    </Link>
                    <span className="breadcrumb-arrow">&gt;</span>
                    <Link to={isAdmin ? ("/user/PropertyListing") : ("/user/ManagementCoPropertyList/" + btoa(userId))}
                      className={isAdmin ? Api(location.pathname.startsWith("/user/PropertyListing") ? "breadcrumb-active" : "breadcrumb-not-active") : (location.pathname.startsWith("/user/ManagementCoPropertyList/" + btoa(userId)) ? "breadcrumb-active" : "breadcrumb-not-active")}
                    >
                      Property
                    </Link>
                  </li>
                  <span className="breadcrumb-arrow">&gt;</span>
                  <li className="breadcrumb-item active">Property Details</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card card-primary">
                  <div className={`card-header ${isAdmin ? "adminColor" : "cambg-secondary"}`}>
                    <h3 className="card-title">Property Details</h3>
                  </div>

                  <div className="bg-white p-3 rounded p-3 shadow">
                    <div>
                      <div className="mb-3">
                        <div className="row">
                          <div className="col-lg-6">
                            <form>
                              <div className="d-flex gap-2 justify-content-end">
                                <div className="flex-shrink-0">
                                  <span className="badge bg-warning">
                                  </span>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                      <div className="table-responsive">
                        <div>
                          <div
                            style={{
                              width: "100%",
                              padding: "0px 40px",
                              borderRadius: "10px",
                              backgroundColor: " rgb(255, 255, 255)",
                              margin: "0px auto",
                              border: "1px solid rgb(221, 221, 221)",
                              fontSize: "14px",
                              color: "rgb(0, 0, 0)",
                            }}
                          >
                            <div>
                              <table
                                style={{ width: "100%", marginBottom: "0px" }}
                              >
                                <tbody>
                                  <tr>
                                    <td>
                                      {isLoader ? (
                                        <Loader />
                                      ) : (
                                        <div className="mt-3">
                                          <table
                                            className="m-0"
                                            style={{ width: "100%" }}
                                          >
                                            <tbody>
                                              <tr>
                                                <td>
                                                  <div
                                                    style={{
                                                      fontStyle: "italic",
                                                      marginBottom: "10px",
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    Property Details:
                                                  </div>
                                                  <table
                                                    className="table table-bordered table-striped table-sm m-0"
                                                    style={{ width: "100%" }}
                                                  >
                                                    <tbody>

                                                      <tr>
                                                        <th
                                                          // className="table-striped table-sm m-0"
                                                        >
                                                          Property Name
                                                        </th>
                                                        <td> {propertyList?.name}
                                                        </td>
                                                        <td
                                                          rowSpan="24"
                                                          style={{
                                                            textAlign: "center",
                                                            verticalAlign:
                                                              "top",
                                                          }}
                                                        ></td>
                                                      </tr>
                                                      <tr>
                                                        <th
                                                          // className="table-striped table-sm m-0"
                                                        >
                                                          Address
                                                        </th>
                                                        <td>{propertyList?.address} 
                                                        </td>
                                                       
                                                      </tr>
                                                      <tr>
                                                        <th
                                                          // className="table-striped table-sm m-0"
                                                        >
                                                          City
                                                        </th>
                                                        <td>{
                                                              propertyList?.city
                                                            }
                                                        </td>
                                                        
                                                      </tr>
                                                      <tr>
                                                        <th  
                                                        // className="table-striped table-sm m-0"
                                                        >
                                                          {" "}
                                                          State
                                                        </th>
                                                        <td>
                                                            {
                                                              propertyList?.state
                                                            }
                                                        </td>
                                                      </tr>
                                                      <tr>
                                                      <th  
                                                      // className="table-striped table-sm m-0"
                                                      >
                                                          {" "}
                                                          State code
                                                        </th>
                                                        <td>
                                                            {
                                                              propertyList?.state_code
                                                            }
                                                        </td>
                                                      </tr>
                                                      <tr>
                                                      <th  
                                                      // className="table-striped table-sm m-0"
                                                      >
                                                          {" "}
                                                          Zip code
                                                        </th>
                                                        <td>{
                                                              propertyList?.zip
                                                            }</td>
                                                      </tr>
                                                      <tr>
                                                      <th  
                                                      // className="table-striped table-sm m-0"
                                                      >Added Date</th>
                                                        <td>{dayjs(
                                                              propertyList?.created_at
                                                            ).format("MM/DD/YYYY")}</td>
                                                      </tr>
                                                      <tr>
                                                      <th  
                                                      // className="table-striped table-sm m-0"
                                                      >Renewal Date </th>
                                                        <td>
                                                          {dayjs(
                                                              propertyList?.expiration_date
                                                            ).format("MM/DD/YYYY")}</td>
                                                      </tr>
                                                      <tr>
                                                      <th  
                                                      // className="table-striped table-sm m-0"
                                                      >CAM Details</th>
                                                        <td>
                                                          {Boolean(propertyList?.cam_data) &&
                                                            propertyList?.cam_data ? (
                                                            <p>
                                                              {"Name: " +
                                                                propertyList?.cam_data?.name +
                                                                " " +
                                                                propertyList?.cam_data?.lastname}{" "}
                                                              <br />
                                                              {"Email: " +
                                                                propertyList?.cam_data?.email}
                                                              <br />
                                                              {"Phone: " +
                                                                propertyList?.cam_data?.phone}
                                                            </p>
                                                          ) : (
                                                            <b>-</b>
                                                          )}
                                                        </td>
                                                      </tr>

                                                      <tr>
                                                      <th  
                                                      // className="table-striped table-sm m-0"
                                                      >General Rule</th>
                                                        <td>

                                                          {propertyList?.rules_and_regulations
                                                            ?.filter((s) => s.for_whom === 1)
                                                            .map((rules, i) => (
                                                              <div key={i}>
                                                                <li>{rules?.rules}</li>
                                                              </div>
                                                            ))}
                                                        </td>
                                                      </tr>

                                                      <tr>
                                                      <th  
                                                      // className="table-striped table-sm m-0"
                                                      > Beach Flag Warning Rule</th>
                                                        <td>

                                                          {propertyList?.rules_and_regulations
                                                            ?.filter((p) => p.for_whom === 3)
                                                            .map((rules, i) => (
                                                              <div key={i}>

                                                               <li>{rules?.rules}</li>
                                                              </div>
                                                            ))}
                                                        </td>
                                                      </tr>

                                                      <tr>
                                                      <th  
                                                      // className="table-striped table-sm m-0"
                                                      >  Non Refundable Rule</th>
                                                        <td>

                                                          {propertyList?.rules_and_regulations
                                                            ?.filter((p) => p.for_whom === 2)
                                                            .map((rules, i) => (
                                                              <div key={i}>
                                                               <li>{rules?.rules}</li>
                                                              </div>
                                                            ))}
                                                        </td>
                                                      </tr>

                                                      <tr>
                                                      <th  
                                                      // className="table-striped table-sm m-0"
                                                      > Parking Rule</th>
                                                        <td>

                                                          {
                                                            propertyList?.rules_and_regulations
                                                              ?.filter((p) => p.for_whom === 4)
                                                              .map((rules, i) => (
                                                                <div key={i}>
                                                                  <li>{rules?.rules}</li>
                                                                </div>
                                                              ))}
                                                        </td>
                                                      </tr>

                                                      <tr>
                                                      <th  
                                                      // className="table-striped table-sm m-0"
                                                      >No. Security</th>
                                                        <td>
                                                          {Boolean(propertyList?.security) &&
                                                            propertyList?.security ? (
                                                            propertyList?.security.length
                                                          ) : (
                                                            <b>-</b>
                                                          )}
                                                        </td>
                                                      </tr>
                                                      <tr>
                                                      <th  
                                                      // className="table-striped table-sm m-0"
                                                      >No. Units</th>
                                                        <td>
                                                          {Boolean(propertyList?.units) &&
                                                            propertyList?.units ? (
                                                           propertyList?.units.length
                                                          ) : (
                                                            <b>-</b>
                                                          )}
                                                        </td>
                                                      </tr>
                                                      <tr>
                                                      <th 
                                                      //  className="table-striped table-sm m-0"
                                                       >No. Vendor</th>
                                                        <td>
                                                          {Boolean(propertyList?.vendors) &&
                                                            propertyList?.vendors ? 
                                                              (propertyList?.vendors.length)
                                                            : (
                                                            <b>-</b>
                                                          )}
                                                        </td>
                                                      </tr>
                                                    </tbody>
                                                  </table>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </div>
                                      )}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <div className="mt-4 text-center">
                        <div className="mb-3">
                          <button
                            type="button"
                            className="btn btn-success px-5"
                          >
                            <svg
                              stroke="currentColor"
                              fill="currentColor"
                              strokeWidth="0"
                              viewBox="0 0 512 512"
                              height="1em"
                              width="1em"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M448 192V77.25c0-8.49-3.37-16.62-9.37-22.63L393.37 9.37c-6-6-14.14-9.37-22.63-9.37H96C78.33 0 64 14.33 64 32v160c-35.35 0-64 28.65-64 64v112c0 8.84 7.16 16 16 16h48v96c0 17.67 14.33 32 32 32h320c17.67 0 32-14.33 32-32v-96h48c8.84 0 16-7.16 16-16V256c0-35.35-28.65-64-64-64zm-64 256H128v-96h256v96zm0-224H128V64h192v48c0 8.84 7.16 16 16 16h48v96zm48 72c-13.25 0-24-10.75-24-24 0-13.26 10.75-24 24-24s24 10.74 24 24c0 13.25-10.75 24-24 24z"></path>
                            </svg>{" "}
                            Print
                          </button>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default PropertyDetail;
