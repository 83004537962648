import React, {  useEffect, useState } from "react";
import { useForm} from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Api from "../../../Config/Api";
import { Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import SecuritySample from "../../../sampleCSV/SecuritySample.csv";
import Cookies from "js-cookie";
const SecurityFrom = ({ propertyId, setcurrentStep }) => {
  // const onboardingdata  =  Cookies.get('currentStep') ? JSON.parse(Cookies.get('currentStep')) : '';

  const onboardingLocaldata  =  localStorage.getItem('currentStep') ? JSON.parse(localStorage.getItem('currentStep')) : '';
  const property_Id = onboardingLocaldata ? onboardingLocaldata.propertyId : propertyId;
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const isAdmin = useSelector((state) => state.login.isAdmin);
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [file, setFile] = useState(null);
  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const onSubmit = async (d) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("csv_file", file);
    formData.append("propertyId", property_Id);
    formData.append("role_id", 5);
    if(property_Id){
    try{
    const { data } = await Api("store-security-csv", "POST", formData);
      if(Boolean(data?.status)){
        setLoading(false);
        toast.success('Security has been imported', {
          position: toast.POSITION.TOP_RIGHT,
        });
        Cookies.remove("currentStep", {
          expires: new Date("May 16, 1970"),
          path: "/",
        });
        navigate('/user/PropertyListing');
      }else{
        setLoading(false);
        toast.error('Something went wrong.', {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }catch(error){
        setLoading(false);
      toast.error('Something went wrong.', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
}else{
    setLoading(false);
    toast.error('Property Not Found.', {
        position: toast.POSITION.TOP_RIGHT,
      });
}

  };

  useEffect(()=>{
    const OnboardingData = {
      currentStep:5,
      propertyId:property_Id
    }
    // Cookies.set("currentStep", JSON.stringify(OnboardingData));
    localStorage.setItem('currentStep', JSON.stringify(OnboardingData));
  },[])

  return (
    <>
      <div className="card card-primary py-3 px-3">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <label>Select CSV File</label>
                <input
                  type="file"
                  className="form-control mb-1"
                  {...register("csv_file")}
                  accept="csv"
                  onChange={handleFileChange}
                />
                {errors.csv_file && (
                  <p className="text-danger">Property is required</p>
                )}

                <a
                  href={SecuritySample}
                  download={"Security-Sample"}
                  className="btn-sm "
                >
                  Download the Security Sample CSV
                </a>
              </div>

             <div className="text-end">
             <button variant="primary"  className={`btn btn-primary  ${isAdmin ? "" : "cambg-secondary border-0"}`}  type="submit" disabled={isLoading}
              >
                {isLoading ? (
                  <>
                    <Spinner animation="border" size="sm" /> Please
                    wait...
                  </>
                ) : (
                  "Submit"
                )}
            </button>
             </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default SecurityFrom;
