import {configureStore} from "@reduxjs/toolkit";
import LoginReducer from "./Reducers/Login";
import StateReducer from "./Reducers/State";
import propertyReducer from "./Reducers/RenterProperty";
import CartSlice from "./Reducers/CartSlice";
export const store = configureStore({
    reducer:{
        login: LoginReducer,
        state: StateReducer,
        property:propertyReducer,
        cart:CartSlice
    }
})