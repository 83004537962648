import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Button, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import Api from "../../../Config/Api";
import Loader from "../../Elements/Loader";


const TowCompanyForm = () => {
    const {
        register,
        handleSubmit,
        reset,
        setValue,
        formState: { errors },
    } = useForm();

    const location = useLocation();
    const isAdmin = useSelector((state) => state.login.isAdmin);
    const [isLoading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [isLoader, setIsLoader] = useState(location?.state?.isEdit ? true : false);
    const [phone, setPhone] = useState();
    const onSubmit = async (d) => {
        const userfromdata = new FormData();
        
        userfromdata.append("company_name", d.company_name);
        userfromdata.append("firstname", d.firstname);
        userfromdata.append("lastname", d.lastname);
        userfromdata.append("email", d.email);
        userfromdata.append("phone", d.phone);
        userfromdata.append("address", d.address);
        userfromdata.append("city", d.city);
        userfromdata.append("state", d.state);
        userfromdata.append("zip", d.zip);
        
        if (!Boolean(location?.state?.id) && !location?.state?.id) {
            try {
                var { data } = await Api("addUpdateTowCompany", "POST", userfromdata);
                if (data?.status) {
                    setLoading(false);
                    toast.success('Tow Company has been successfully created!', {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    navigate("/user/TowCompanyListing");
                }
            } catch (error) {
                if (Boolean(error?.data?.errors)) {
                    toast.error(error?.data?.errors?.email[0])
                    setValue('email', '');
                }
            }
        } else {
            userfromdata.append("id", location?.state?.id);
            var res = await Api("addUpdateTowCompany", "POST", userfromdata);
            if (res?.data?.status) {
                setLoading(false);
                toast.success('Tow Company has been successfully updated!', {
                    position: toast.POSITION.TOP_RIGHT,
                });
                navigate("/user/TowCompanyListing");
                reset();
            }
        }
    };

    const getModifyCamData = async () => {
        const { data } = await Api("getTowCompanyData", "POST", {
            id: location?.state?.id,
        });
        console.log('data', data);
        setPhone(data?.data?.phone);
        reset(data?.data);
        setIsLoader(false);
    };

    useEffect(() => {
        if (!!location?.state?.id) {
            getModifyCamData();
        }
    }, [location?.state?.id]);

    useEffect(() => {
        setPhone(phone || ''); // Use an empty string if 'phone' is undefined
      }, [phone]);
    
      const handleInputChange = (event) => {
        const rawPhoneNumber = event.target.value.replace(/\D/g, '');
        const truncatedPhoneNumber = rawPhoneNumber.slice(0, 10);
        const formattedPhoneNumber = truncatedPhoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
        
        // Set the phone value directly using setValue from react-hook-form
        setValue('phone', formattedPhoneNumber);
      
        // Update the state with the formatted phone number
        setPhone(formattedPhoneNumber);
      };

    return (
        <>
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0">
                                    {" "}
                                    {location?.state?.isEdit ? "Edit Tow Company " : "Add Tow Company "}{" "}
                                </h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item">
                                        <Link to="/"
                                            className={location.pathname === "/" ? "breadcrumb-active" : "breadcrumb-not-active"}
                                        >
                                            Home
                                        </Link>
                                        <span className="breadcrumb-arrow">&gt;</span>
                                        <Link to="/user/TowCompanyListing"
                                            className={location.pathname.startsWith("/user/TowCompanyListing") ? "breadcrumb-active" : "breadcrumb-not-active"}
                                        >
                                            Tow Company
                                        </Link>
                                    </li>
                                    <span className="breadcrumb-arrow">&gt;</span>
                                    <li className="breadcrumb-item active">
                                        {" "}
                                        {location?.state?.isEdit ? "Edit Toy Company " : "Add Tow Company "}{" "}
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card card-primary">
                                    <div className={`card-header ${isAdmin ? "adminColor" : "cambg-secondary"}`}>
                                        <h3 className="card-title">
                                            {" "}
                                            {location?.state?.isEdit ? "Edit Tow Company " : "Add Tow Company "}{" "}
                                        </h3>
                                    </div>

                                    {isLoader ? (
                                        <Loader />
                                    ) : (

                                        <form
                                            onSubmit={handleSubmit(onSubmit)}
                                            encType="multipart/form-data"
                                        >
                                            <div className="card-body">
                                                <div className="row align-items-center">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="company_name">Tow Company</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="company_name"
                                                                placeholder="Enter Tow Company"
                                                                {...register("company_name", { required: true })}
                                                            />

                                                            {errors.company_name && (
                                                                <p className="text-danger">
                                                                    Tow Company is required
                                                                </p>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="firstname">First Name</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="firstname"
                                                                placeholder="Enter First Name"
                                                                {...register("firstname", { required: true })}
                                                                autoComplete="off"
                                                            />

                                                            {errors.firstname && (
                                                                <p className="text-danger">First Name is required</p>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="lastname">Last Name</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="lastname"
                                                                placeholder="Enter Last Name"
                                                                {...register("lastname", { required: true })}
                                                                autoComplete="off"
                                                            />

                                                            {errors.lastname && (
                                                                <p className="text-danger">Last Name is required</p>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="email">Email</label>
                                                            <input
                                                                type="email"
                                                                className="form-control"
                                                                id="email"
                                                                placeholder="Enter Email"
                                                                {...register("email", { required: true })}
                                                                autoComplete="off"
                                                            />

                                                            {errors.email && (
                                                                <p className="text-danger">Email is required</p>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="enterNumber">Phone</label>
                              <input
                                type="text"
                                value={phone}
                                onChange={handleInputChange}
                                className="form-control"
                                id="enterNumber"
                                placeholder="Enter Phone Number"
                                required={true}
                              />
                            </div>
                          </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="address">Address</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="address"
                                                                placeholder="Enter address"
                                                                {...register("address", { required: true })}
                                                                autoComplete="off"
                                                            />

                                                            {errors.address && (
                                                                <p className="text-danger">address is required</p>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="state">State</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="state"
                                                                placeholder="Enter State"
                                                                {...register("state", { required: true })}
                                                                autoComplete="off"
                                                            />

                                                            {errors.state && (
                                                                <p className="text-danger">State is required</p>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="zip">Zip</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="zip"
                                                                placeholder="Enter Zip"
                                                                {...register("zip", { required: true })}
                                                                autoComplete="off"
                                                            />

                                                            {errors.zip && (
                                                                <p className="text-danger">Zip is required</p>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="card-footer">
                                                        <button className="btn btn-outline-light text-dark mx-3 bg-light" type="button" onClick={() => navigate(-1)}>Cancel</button>
                                                        <button
                                                            type="submit"
                                                            variant="primary"
                                                            className={`btn btn-primary`}
                                                            disabled={isLoading}
                                                        >
                                                            {isLoading ? (
                                                                <>
                                                                    <Spinner animation="border" size="sm" /> Please
                                                                    wait...
                                                                </>
                                                            ) : (
                                                                "Submit"
                                                            )}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default TowCompanyForm