import React from 'react'

const LocateOwner = () => {
  return (
    <>
      <div className="content-wrapper">
    <div className="content-header">
      <div className="container-fluid">
        <div className="row mb-2">
          <div className="col-sm-6">
            <h1 className="m-0">LOCATE OWNER</h1>
          </div>
          <div className="col-sm-6">
            <ol className="breadcrumb float-sm-right">
              <li className="breadcrumb-item"><a href="#">Home</a></li>
              <li className="breadcrumb-item active">Locate Owner</li>
            </ol>
          </div>
        </div>
      </div>
    </div>
   
    <section className="content">
      <div className="container-fluid">
        <div className="row">
            <div className="col-md-12">
                
                <div className="card card-info">
                  <div className="card-header">
                    <h3 className="card-title">Enter the Information</h3>
                  </div>
                 
                  <form className="form-horizontal">
                    <div className="card-body">
                      <div className="row align-items-center">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="enterCondo">Condo#</label>
                            <input type="text" className="form-control" id="enterCondo" placeholder="Enter Condo"/>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="enterEmail">Email</label>
                            <input type="email" className="form-control" id="enterEmail" placeholder="Enter Email"/>
                          </div>
                        </div>
                      </div>
                     
                    </div>
                    <div className="card-footer">
                      <a href="/ModifyOwnerInfo" className="btn btn-info">Locate</a>
                    </div>
                  </form>
                </div>
    
              </div>
        </div>
      
      </div>
    </section>
  </div>
    </>
  )
}

export default LocateOwner