import React from "react";
import { BiMenu } from "react-icons/bi";
import Dropdown from "react-bootstrap/Dropdown";
import userImg from "../../../assets/img/user.svg";
import { useSelector, useDispatch } from "react-redux";
import { removeUser } from "../../../Reducers/Login";
import { toast } from "react-toastify";
import { getRoleName } from "../../../Utility/Helpers";
import { useNavigate } from "react-router-dom";
const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userName = useSelector((state) => state.login.name);
  const roleId = useSelector((state) => state.login.roleId);
  const text = userName;
  const splitName = text?.split(" ");
  const result = splitName?.length > 0 ? splitName[0] : userName;

  const toggleSidebar = () => {
    var body = document.getElementsByTagName("BODY")[0];
    body.classList.toggle("sidebar-mini");
    body.classList.toggle("layout-fixed");
    body.classList.toggle("sidebar-collapse");
    body.classList.toggle("sidebar-img");
  };

  const logout = () => {
    dispatch(removeUser());
    toast.success("Logout Successfully!", {
      position: toast.POSITION.TOP_RIGHT,
    });
    navigate("/Login");
  };

  return (
    <>
      <nav className="main-header navbar navbar-expand navbar-white navbar-light">
        <ul className="navbar-nav">
          <li className="nav-item">
            <a
              className="nav-link"
              data-widget="pushmenu"
              href="#"
              role="button"
              onClick={toggleSidebar}
            >
              <BiMenu />
            </a>
          </li>
        </ul>

        <ul className="navbar-nav ml-auto pr-4 navbard_profile_box">
          <li className="nav-item">
            <Dropdown align="end" className="border-0">
              <Dropdown.Toggle bsPrefix variant="" className="p-0  border-0">
                <div className="row">
                  
                  <div className="col-lg-5">
                    <img src={userImg} height={40} alt="user" />
                  </div>
                  <div className="col-lg-4">
                    <span> {result} </span>

                    <small className="d-flex" style={{ width: "100px" }}>
                      {getRoleName(atob(roleId))}
                    </small>
                  </div>
                </div>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item as="button" onClick={logout}>
                  Logout
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </li>
        </ul>
      </nav>
    </>
  );
};

export default Header;
