import React from "react";
import Routing from "./Navigation";
// import "bootstrap/dist/css/bootstrap.min.css";
import { useSelector } from "react-redux";
// import { useParams } from "react-router-dom";
import {
  QueryClient,
  QueryClientProvider,
  // useQuery,
} from 'react-query'

const queryClient = new QueryClient()
const App = () => {
  const propertyData = useSelector((state) => state.login.propertyData);
  return (
    <>
      <style>
        {`
        :root{
          --primary:${propertyData?.primary_color};
          --secondary:${propertyData?.secondary_color};
        }
        `}
      </style>
      <QueryClientProvider client={queryClient}>
      <Routing />
      </QueryClientProvider>
    </>
  );
};

export default App;
