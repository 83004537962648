import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Api from "../../Config/Api";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import Loader from "../Elements/Loader";
import { Spinner } from "react-bootstrap";
import { capitalizeFirstLetter } from "../../Utility/Helpers";
import dayjs from "dayjs";
import { FcExpired } from "react-icons/fc";
import towreques from "../../assets/img/towreques.png";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const SearchResult = () => {
  const {
    register,
    // handleSubmit,
    reset,
    // formState: { errors },
  } = useForm();
  const location = useLocation();
  const navigate = useNavigate();
  const propetyId = useSelector((state) => state.login.propertyId);
  const userId = useSelector((state) => state.login.userId);
  const [renterInfo, setRenterInfo] = useState([]);
  const [expire, setExpired] = useState("");
  const [searchBy, setSearchBy] = useState(location?.state?.searchBy);
  const [isLoader, setIsLoader] = useState(true);
  const [vehiclePassId, setVehiclePassId] = useState("");
  const [sendTowReqLoading, setSendTowReqLoading] = useState(false);
  const [sendWarningTextLoading, setSendWarningTextLoading] = useState(false);
  const [searchCount, setSearchCount] = useState("");
  const [isPassFound, setIsPassFound] = useState(false);
  const [show, setShow] = useState(false);
  const [towMessage, setTowMessage] = useState("");
  const [renterMessage, setRenterMessage] = useState("");

  const getResultData = async () => {
    const res = await Api("getVehicleInfoByUUID", "POST", {
      UUID: location?.state?.UUID,
      searchBy: searchBy,
      userId: location?.state?.userId,
      propetyId: propetyId,
    });
    if (res?.data?.status) {
      setSearchCount(res?.data?.logscount);
      const searchData = { ...res?.data?.data };
      setVehiclePassId(searchData?.id);
      setRenterInfo(searchData);
      setIsLoader(false);
      const departureDate = new Date(searchData?.departure_date);
      const currentDate = new Date();
      const isExpired = departureDate < currentDate;
      setExpired(isExpired);
      reset({
        name: searchData?.user?.name,
        email: searchData?.user?.email,
        phone: searchData?.user?.phone,
        ...searchData,
      });
    } else {
      toast.error(
        "I apologize, but there is no record available related to the Pass ID you entered. Please double-check the Pass ID you provided and make sure it is correct"
      );
      setIsPassFound(true);
      // navigate("/Security");
      return;
    }
  };

  const [alertShow, setAlertShow] = useState(false);
  const handleAlertClose = () => setAlertShow(false);
  const handleAlertShow = () => setAlertShow(true);

  const handleClose = () => {
    setShow(false);
    setTowMessage("");
  };
  const handleShow = () => {
   console.log('handleShow');
    setShow(true);
  }

  const sendTowRequest = async () => {
    setSendTowReqLoading(true);
    const { data } = await Api("sendTowRequest", "POST", {
      vehiclePassId,
      userId,
      towMessage,
 
    });
    if (Number(data.status) === 1) {
      setSendTowReqLoading(false);
      setShow(false);
      toast.success("Request Sent Successfully.");
    } else if (Number(data.status) === 2) {
      setSendTowReqLoading(false);
      toast.error("No Tow Company Found for This Property.");
    } else {
      setSendTowReqLoading(false);
      toast.error("Something went wrong.");
    }
  };

  const sendWarningTextRequest = async () => {
    setSendWarningTextLoading(true);
    const { data } = await Api("sendWaringTextRequest", "POST", {
      vehiclePassId,
      userId,
    });
    if (Number(data.status) === 1) {
      setAlertShow(false);
      setSendWarningTextLoading(false);
      toast.success("Request Sent Successfully.");
    } else {
      setSendWarningTextLoading(false);
      toast.error("Something went wrong.");
    }
  };

  useEffect(() => {
    if (location?.state?.UUID) {
      getResultData();
    }
  }, []);

  return (
    <>
      <div className="container">
        {Boolean(isPassFound) ? (
          <>
            <div className="card  p-4">
              <h4 className="py-3">
                I apologize, but there is no record available related to the
                Pass ID you entered. Please double-check the Pass ID you
                provided and make sure it is correct.
              </h4>
              <div className="d-flex justify-content-between">
                      <div className="">
                       
                            <button
                              className="btn btn-danger text-md-center  mr-3"
                              type="button"
                              onClick={handleShow}
                            >
                             Send Tow Request
                            </button>
                         
                        {/* <Button variant="primary" onClick={handleAlertShow}>
                          Send Warning
                        </Button> */}
                      </div>
                      <div className="">
                        <button
                          className="btn btn-secondary"
                          type="button"
                          onClick={() => navigate(-1)}
                        >
                          Back
                        </button>
                      </div>
                    </div>
            </div>
          </>
        ) : (
          <>
        

        {isLoader ? (
          <Loader />
        ) : (
          <>
            <div className="invoice_detail">
              <div className="row">
                <div className="col-sm-12">
                  <div className="d-flex justify-content-between g-3 align-items-center mb-3 mb-md-5">
                    <div className="z-2">
                      <h3>Pass Details</h3>
                    </div>

                    <div className={`d-flex gap-3 align-items-center border ${expire  ? 'border-danger' : 'border-success' } p-3 rounded-pill`}>
                      {" "}
                      {expire ? (
                        <>
                          <h5 className="mb-0 text-danger">Expired</h5>
                          <span
                            className=" rounded-circle bg-danger "
                            style={{ width: "30px", height: "30px" }}
                          ></span>
                        </>
                      ) : (
                        <>
                          <h5 className="mb-0 text-success">Valid</h5>
                              <p
                                className=" rounded-circle bg-success mb-0"
                                style={{ width: "30px", height: "30px" }}
                              />
                        </>
                      )}
                    </div>
                  </div>
                  <div className="border rounded p-4">
                    <div className="row g-md-3">
                      <div className="col-sm-6">
                        <div>
                          <h5 className="fs-md-4">
                            Arrival Date :{" "}
                            {dayjs(renterInfo?.arrival_date).format(
                              "MM/DD/YYYY"
                            )}
                          </h5>
                          <h5 className="fs-md-4 d-block d-md-none">
                            Departure Date :{" "}
                            {dayjs(renterInfo?.departure_date).format(
                              "MM/DD/YYYY"
                            )}
                          </h5>

                          <ul className="list-unstyled ">
                            <li>
                              <p>
                                <b>Name : </b>
                                {
                                  renterInfo?.user ?
                                  capitalizeFirstLetter(
                                  renterInfo?.user?.name +
                                    " " +
                                    renterInfo?.user?.lastname
                                ) : ""}
                              </p>
                            </li>
                            <li>
                              <p>
                                <b>Email : </b>
                                {renterInfo?.user ? capitalizeFirstLetter(renterInfo?.user?.email) :""}
                              </p>
                            </li>
                            <li>
                              <p>
                                {" "}
                                <b>Phone : </b> {renterInfo?.user?.phone}
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="">
                          <h5 className="fs-md-4 d-none d-md-block">
                            Departure Date :{" "}
                            {dayjs(renterInfo?.departure_date).format(
                              "MM/DD/YYYY"
                            )}
                          </h5>
                          <ul className="list-unstyled ">
                            <li>
                              <p>
                                {" "}
                                <b>Passtype : </b> {renterInfo?.Passtype}
                              </p>
                            </li>
                            <li>
                              <p>
                                {" "}
                                <b>Order No. : </b> {renterInfo?.order_uuid}
                              </p>
                            </li>
                            <li>
                              <p>
                                {" "}
                                <b>License Plate No. : </b>{" "}
                                {renterInfo?.license_plate}
                              </p>
                            </li>
                            <li>
                              <p>
                                {" "}
                                <b>Is Rental Vehicle : </b>{" "}
                                {renterInfo?.Is_rental_vehicle ? "Yes" : "No"}
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row g-3 p-4">
                {Boolean(expire) && Number(searchCount) === 1 ? (
                  <>
                    <div className="d-flex justify-content-between">
                      <div className="">
                        <button
                          type="button"
                          className="btn btn-danger"
                          onClick={handleAlertShow}
                        >
                          {/* <FcExpired className="h3" /> */}
                           Send Warning
                        </button>
                      </div>
                      <div className="">
                        <button
                          className="btn btn-secondary"
                          type="button"
                          onClick={() => navigate(-1)}
                        >
                          Back
                        </button>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="d-flex justify-content-between">
                      <div className="">
                        {expire ? (
                          <>
                            <button
                              className="btn btn-danger text-md-center  mr-3 mb-2"
                              type="button"
                              onClick={handleShow}
                            >
                             Send Tow Request
                            </button>
                          </>
                        ) : (
                          <>
                            <button
                              className="btn btn-danger text-md-center  mr-3 mb-2"
                              type="button"
                              onClick={handleShow}
                            >
                             Send text message to renter
                            </button>
                          </>
                        )}

                        {/* <Button variant="primary" onClick={handleAlertShow}>
                          Send Warningss
                        </Button> */}
                      </div>
                      <div className="">
                        <button
                          className="btn btn-secondary"
                          type="button"
                          onClick={() => navigate(-1)}
                        >
                          {" "}
                          Back
                        </button>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
            </>
        )}
          
          </>
        )}
            <Modal show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>{expire ? ("Send Tow Request") : ("Send text message to renter")}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {" "}
                <form>
                  <div className="row">
                    <div className="col-lg-12">
                      <div class="form-group">
                        <label for="exampleInputEmail1">Enter Message</label>
                        <textarea
                          className="form-control"
                          aria-describedby="emailHelp"
                          placeholder="type here..."
                          cols="30"
                          rows="6"
                          onChange={(e) => setTowMessage(e.target.value)}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </form>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  type="button"
                  variant="primary"
                  onClick={sendTowRequest}
                  disabled={sendTowReqLoading}
                >
                  {sendTowReqLoading ? (
                    <>
                      <Spinner animation="border" size="sm" /> Please wait...
                    </>
                  ) : (
                    <>
                      <img
                        src={towreques}
                        width={40}
                        alt="send tow"
                        srcSet=""
                      />{" "}
                      {"Send Request"}
                    </>
                  )}
                </Button>
              </Modal.Footer>
            </Modal>

            <Modal show={alertShow} onHide={handleAlertClose}>
              <Modal.Header closeButton></Modal.Header>
              <Modal.Body>
                <h4>Are you sure you want to send a warning message?</h4>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="button"
                  className="btn btn-danger h3 mb-0"
                  onClick={sendWarningTextRequest}
                  disabled={sendWarningTextLoading}
                >
                  {sendWarningTextLoading ? (
                    <>
                      <Spinner animation="border" size="sm" /> Please wait...
                    </>
                  ) : (
                    <>
                      <FcExpired className="h2" />
                      {"Send Warning"}
                    </>
                  )}
                </button>
              </Modal.Footer>
            </Modal>
         
      </div>
    </>
  );
};
export default SearchResult;
