import React, { useEffect, useState } from 'react';
import { Link, useLocation, useParams } from "react-router-dom";
// import { Spinner } from "react-bootstrap";
import { BsSearch } from "react-icons/bs";
import { useSelector } from "react-redux";
import { FaEdit, FaTrashAlt, FaEye, FaUserCog, } from "react-icons/fa";
import { Tooltip } from "react-tooltip";
import Api from '../../../Config/Api';
import Loader from "../../Elements/Loader";
import InfiniteScroll from "react-infinite-scroll-component";
import { toast } from "react-toastify";
import useSweetAlert from "../../../Hooks/useSweetAlert";

const SubAdminManagListing = () => {

    const { id } = useParams();
    const location = useLocation();
    const { deletefun, isDeleted } = useSweetAlert();
    const [isLoader, setIsLoader] = useState(true);
    const isAdmin = useSelector((state) => state.login.isAdmin);
    const roleId = useSelector((state) => state.login.roleId);
    const [camList, setCamList] = useState([]);
    const [subAdminManagCompList, setSubAdminManagCompList] = useState([]);
    const [perPage] = useState(10);
    const [page, setPage] = useState(1);

    const getSAManagementCompanyDetails = async () => {
        setIsLoader(true);

        const { data } = await Api("get-management-co", "POST", { "created_by": atob(id) });

        if (data?.status) {
            setPage(1);
            var temparr = data?.data.slice(0, perPage);
            setCamList(data?.data);
            setSubAdminManagCompList(temparr);
            setIsLoader(false);
        }
    };

    const handleNext = () => {
        const start = page * perPage;
        const end = start + perPage;
        const temArr = subAdminManagCompList.concat(camList.slice(start, end));
        setPage((prev) => prev + 1);
        setSubAdminManagCompList(temArr);
    };

    useEffect(() => {
        getSAManagementCompanyDetails();
    }, [id]);

    const handleDelete = async (idToDelete) => {

        if (isDeleted) {
        }
        if (window.confirm("Are You Sure You Want to delete it?")) {
            const { data } = await Api("delete-management-co", "POST", { id: idToDelete });
            if (data?.status) {
                toast.success("Sub Admin Management Company has been deleted successfully!", {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
            getSAManagementCompanyDetails();
        }
    };

    const [searchTerm, setSearchTerm] = useState("");

    const handleInputChange = (event) => {
        setSearchTerm(event.target.value);
        const search = event.target.value;
        const filtered = camList.filter((item) => {
            return item.name.toLowerCase().includes(search.toLowerCase()) || item.email.toLowerCase().includes(search.toLowerCase());
        });
        setSubAdminManagCompList(filtered);
    };

    return (
        <>
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0">Sub Admin Management</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item">
                                        <Link
                                            to="/"
                                            className={
                                                location.pathname === "/"
                                                    ? "breadcrumb-active"
                                                    : "breadcrumb-not-active"
                                            }
                                        >
                                            Home
                                        </Link>
                                        {isAdmin ? (
                                            <>
                                                <span className="breadcrumb-arrow">&gt;</span>
                                                <Link to="/user/ManagementCompanyListing"
                                                    className={location.pathname.startsWith("/user/ManagementCompanyListing") ? "breadcrumb-active" : "breadcrumb-not-active"}
                                                >
                                                    Management Co
                                                </Link>
                                            </>
                                        ) : ("")}
                                    </li>

                                    <span className="breadcrumb-arrow">&gt;</span>
                                    <li className="breadcrumb-item active">SubAdmin Management</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div
                                        className={`card-header ${isAdmin || Number(atob(roleId)) === 4 ? "adminColor" : "cambg-secondary"
                                            }`}
                                    >
                                        <div className="add_new_vendor_listing">
                                            <div className="">
                                                <h3 className="card-title mt-2">
                                                    Management Co :
                                                    {/* {propertyIsLoader ? (
                                                        <Spinner
                                                            as="span"
                                                            animation="grow"
                                                            size="sm"
                                                            role="status"
                                                            aria-hidden="true"
                                                        />
                                                    ) : propertyDetail && managementCo === "" ? (
                                                        capitalizeFirstLetter(
                                                            propertyDetail?.name +
                                                            ", " +
                                                            propertyDetail?.city +
                                                            ", " +
                                                            propertyDetail?.state
                                                        )
                                                    ) : (
                                                        " Units & Owners"
                                                    )} */}
                                                </h3>
                                            </div>
                                            <div className="">

                                                <Link
                                                    className="btn btn-light text-dark bg-light"
                                                    to={"/user/SubAdminManagForm/" + id}

                                                >
                                                    Add SubAdmin Management
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-end px-4 mt-2">
                                        <div className="input-group w-25">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Search..."
                                                value={searchTerm}
                                                onChange={handleInputChange}
                                            />
                                            <div className="input-group-append">
                                                <span
                                                    className="input-group-text bg-primary border-0"
                                                    id="basic-addon2"
                                                >
                                                    <BsSearch />
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    {isLoader ? (
                                        <Loader />
                                    ) : (
                                        <div className="card-body table-responsive">
                                            <InfiniteScroll
                                                dataLength={subAdminManagCompList.length}
                                                next={handleNext}
                                                hasMore={camList.length > subAdminManagCompList.length}
                                            >
                                                <table className="table table-bordered">
                                                    <thead className="border-top">
                                                        <tr>
                                                            <th>Sub Admin Management Co</th>
                                                            <th>Email</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {subAdminManagCompList.length ? (
                                                            subAdminManagCompList?.map((data, i) => (
                                                                <tr key={i}>
                                                                    <td>{data?.name}</td>
                                                                    <td>{data?.email}</td>
                                                                    <td>
                                                                        <div className="d-flex justify-content ">

                                                                            <Link
                                                                                to="/user/SubAdminManagForm/MTEz"
                                                                                state={{ id: data?.id, isEdit: true }}
                                                                                className="btn  btn-primary btn-sm mr-1"
                                                                                data-tooltip-id="edit"
                                                                                data-tooltip-content="Edit"
                                                                            >
                                                                                <FaEdit />
                                                                            </Link>
                                                                            <Tooltip id="edit" place="bottom" />

                                                                            <button
                                                                                onClick={() => handleDelete(data?.id)}
                                                                                className="btn  btn-danger btn-sm mr-1"
                                                                                data-tooltip-id="my-tooltip"
                                                                                data-tooltip-content="Delete"
                                                                            >
                                                                                <FaTrashAlt />
                                                                            </button>
                                                                            <Tooltip id="my-tooltip" place="bottom" />


                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            ))) : (
                                                            <tr>
                                                                <td colSpan={7}>
                                                                    <p className="text-center">No Record Found.</p>
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </InfiniteScroll>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default SubAdminManagListing