import React from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import Api from "../../../Config/Api";
import { updateUser } from "../../../Reducers/Login";
import { useDispatch } from "react-redux";
import { Spinner } from "react-bootstrap";

function ResetPasswordModal(props) {
  const dispatch = useDispatch();
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState(""); 

  const handleSubmit = async () => {
    setLoading(true);
    if (password && password === confirmPassword) { 
      const res = await Api("change-password", "POST", {
        new_password: password,
        afterLogin: "yes",
      });

      if (res?.data?.status) {
        props.onHide();
        toast.success("Your password was successfully changed.");
        setLoading(false);
      }
    } else {
      setError(true);
      setErrorMsg("Please enter a valid Password and make sure the passwords match.");
      setLoading(false);
    }
  };

  const handleSkip = async () => {
    const res = await Api("skip-reset-password", "POST", {
      isSkip: "yes",
    });
    if (res?.data?.status) {
      props.onHide();
    }
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Reset Password
          
      <div><h6 className="less-bold-text">You can reset your password!</h6></div>
        </Modal.Title>
      
      </Modal.Header>
      <Modal.Body>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="form-group">
                <label htmlFor="new_password">Enter New Password</label>
                <input
                  type="password"
                  className="form-control"
                  id="new_password"
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                    setErrorMsg("");
                  }}
                />
                {error && !password && (
                  <div className="form_error text-danger">
                    New Password is required.
                  </div>
                )}
              </div>
            </div>

            <div className="col-lg-12">
              <div className="form-group">
                <label htmlFor="new_confirm_password">Confirm Password</label>
                <input
                  type="password"
                  className="form-control"
                  id="new_confirm_password"
                  value={confirmPassword}
                  onChange={(e) => {
                    setConfirmPassword(e.target.value);
                    setErrorMsg("");
                  }}
                />
                {error && password && confirmPassword !== password && (
                  <div className="form_error text-danger">
                    Confirm Password should match the New Password.
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-warning" onClick={handleSkip}>Skip</button>
        <Button className="btn btn-primary" type="submit" disabled={isLoading} onClick={handleSubmit}>
          {isLoading ? (
            <>
              <Spinner animation="border" size="sm" /> Please wait...
            </>
          ) : (
            "Reset"
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}



export default ResetPasswordModal;
