import React, { useEffect, useState } from "react";
import { FaEdit, FaTrashAlt, FaPlus, FaEllipsisV } from "react-icons/fa";
import { Link, useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import Api from "../../../Config/Api";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { FaEye } from "react-icons/fa";
import { useSelector } from "react-redux";
import { Tooltip } from "react-tooltip";
import Loader from "../../Elements/Loader";
import { BsSearch } from "react-icons/bs";
import InfiniteScroll from "react-infinite-scroll-component";
import Dropdown from "react-bootstrap/Dropdown";
import useSweetAlert from "../../../Hooks/useSweetAlert";
import dayjs from "dayjs";

const UnitRequest = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const { deletefun, isDeleted } = useSweetAlert();
  const userId = useSelector((state) => state.login.userId);
  const isAdmin = useSelector((state) => state.login.isAdmin);
  const [propertyId, setPropertyId] = useState(null);
  const [unitlist, setUnitList] = useState([]);
  const [property, setProperty] = useState([]);
  const [isLoader, setIsLoader] = useState(true);
  const [isBtnLoader, setBtnIsLoader] = useState(true);
  const [cList, setCList] = useState([]);
  const location = useLocation();
  const [perPage] = useState(10);
  const [page, setPage] = useState(1);
  const [changeUnitData, setChangeUnitData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const camId = userId;

 

  const getRequestDetails = async () => {
    setIsLoader(true);
    const { data } = await Api("get_all_property_request", "POST", {camId:userId });
    if (Boolean(data?.status)) {
      setPage(1);
      var temparr = data?.data.slice(0, perPage);
      setChangeUnitData(data?.data);
      setIsLoader(false);
      setCList(temparr);
    }else{
      setIsLoader(false);
    }
  };


 
  const handleNext = () => {
    const start = page * perPage;
    const end = start + perPage;
    const temArr = cList.concat(changeUnitData.slice(start, end));
    setPage((prev) => prev + 1);
    setCList(temArr);
  };
  console.log('isDeleted',isDeleted);
  const handleDelete = (id, url,status="",unitId="",userId="") => {
    deletefun(id, url,status,unitId,userId);
  };


  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
    const search = event.target.value;
    const filtered = changeUnitData.filter((item) => {
      return (
        item?.name?.toLowerCase()?.includes(search?.toLowerCase()) ||
        item?.lastname?.toLowerCase()?.includes(search?.toLowerCase()) ||
        item?.email?.toLowerCase()?.includes(search?.toLowerCase()) ||
        item?.phone?.toLowerCase()?.includes(search?.toLowerCase())
      );
    });
    setCList(filtered);
  };

  useEffect(()=>{
    console.log('inside the useeffect');
    if(isDeleted){
        getRequestDetails();
    }
  },[isDeleted])

  useEffect(() => {
    getRequestDetails();
  }, [userId]);



  return (
    <>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6"><h1 className="m-0">Unit Request</h1></div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link
                      to="/"
                      className={
                        location.pathname === "/"
                          ? "breadcrumb-active"
                          : "breadcrumb-not-active"
                      }
                    >
                      Home
                    </Link>
                  </li>
                  <span className="breadcrumb-arrow">&gt;</span>
                  <li className="breadcrumb-item active">Unit Request</li>
                </ol>
              </div>
            </div>
          </div>
        </div>

        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div
                    className={`card-header ${
                      isAdmin ? "adminColor" : "cambg-secondary"
                    }`}
                  >
                    <div className="add_new_vendor_listing">
                      <h3 className="card-title">Request</h3>
                    </div>
                  </div>

                  <div className="d-flex justify-content-end px-4 mt-2">
                    <div className="input-group w-25">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search..."
                        value={searchTerm}
                        onChange={handleInputChange}
                      />
                      <div className="input-group-append">
                        <span
                          className="input-group-text bg-primary border-0"
                          id="basic-addon2"
                        >
                          <BsSearch />
                        </span>
                      </div>
                    </div>
                  </div>

                  {isLoader ? (
                    <Loader />
                  ) : (
                    <div className="card-body table-responsive">
                      <InfiniteScroll
                        dataLength={cList.length}
                        next={handleNext}
                        hasMore={changeUnitData.length > cList.length}
                      >
                        <table className="table table-bordered">
                          <thead className="border-top">
                            <tr>
                              <th>Management Co Name</th>
                              <th>Management Co Email</th>
                              <th>Unit Name</th>
                              <th>Property Name</th>
                              <th>Changeover Date</th>
                              <th>Status</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {cList.length > 0 ? (
                              cList?.map((data, i) => (
                                <tr key={i}>
                                  <td>{data?.user?.name}</td>
                                  <td>{data?.user?.email}</td>
                                  <td>{data?.unit?.unit_name}</td>

                                  <td>
                                    {data?.property?.name
                                      ? data?.property?.name
                                      : "-"}
                                  </td>
                                  <td>{data?.changeover_date ? dayjs(data?.changeover_date).format("MM/DD/YYYY") : ""}</td>
                                  <td>
                                    {Number(data?.status) === 0 ? (
                                      <span className="badge badge-warning">
                                        Under Review
                                      </span>
                                    ) : Number(data?.status) === 1 ? (
                                      <span className="badge badge-success">
                                        Approved
                                      </span>
                                    ) : (
                                      <span className="badge badge-danger">
                                        Rejected
                                      </span>
                                    )}
                                  </td>

                                  {/* <td>

                                  <Dropdown>
                                        <Dropdown.Toggle
                                          bsPrefix
                                          variant="primary"
                                          className="btn-sm"
                                        >
                                          <FaEllipsisV />
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                          <Dropdown.Item   onClick={() => handleDelete('CAM_unit_request_actions',data?.id,2)}>
                                             <span className="text-primary" >
                                              Reject
                                            </span>
                                          </Dropdown.Item>

                                         
                                          <Dropdown.Item
                                           onClick={() => handleDelete('CAM_unit_request_actions',data?.id,1,data?.unit?.id,data?.user?.id)}
                                          >
                                          <span className="text-primary"
                                          >
                                                Approved
                                            </span>
                                          </Dropdown.Item>

                                        
                                        </Dropdown.Menu>
                                      </Dropdown>

                                  </td> */}
                                  <td>
                                  <div className="d-flex justify-content-center">
                                    <Dropdown>
                                      <Dropdown.Toggle
                                        bsPrefix
                                        variant="primary"
                                        className="btn-sm"
                                      >
                                        <FaEllipsisV />
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu style={{position:'absolute', marginTop:'-90px',marginLeft: "-60px",}}>
                                        <Dropdown.Item
                                         onClick={() => handleDelete('CAM_unit_request_actions',data?.id,2)}
                                        >
                                          <span className="text-primary">
                                            Reject
                                          </span>
                                        </Dropdown.Item>

                                        <Dropdown.Item
                                          onClick={() => handleDelete('CAM_unit_request_actions',data?.id,1,data?.unit?.id,data?.user?.id)}
                                        >
                                          <span className="text-primary">
                                            Approved
                                          </span>
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>

                                    {/* <FaStickyNote
                                      className="ml-2 text-primary"
                                      onClick={() => handleShowNotes(data?.id, data?.message)}
                                    /> */}
                                  </div>
                                </td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan={8}>
                                  <p className="text-center">
                                    No record found.
                                  </p>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </InfiniteScroll>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

   
    </>
  );
};

export default UnitRequest;
