import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import Api from "../../../Config/Api";
import { Spinner } from "react-bootstrap";
// import { useSelector } from "react-redux";
// import Cookies from "js-cookie";
const CamFrom = ({ propertyId, setcurrentStep }) => {

  // const onboardingdata  =  Cookies.get('currentStep') ? JSON.parse(Cookies.get('currentStep')) : '';
  const onboardingLocaldata = localStorage.getItem('currentStep') ? JSON.parse(localStorage.getItem('currentStep')) : '';
  const property_Id = onboardingLocaldata ? onboardingLocaldata.propertyId : propertyId;
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();
  const [camList, setCamList] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const location = useLocation();

  const onSubmit = async (d) => {
    setLoading(true);
    const userfromdata = new FormData();
    userfromdata.append("name", d.name);
    userfromdata.append("lastname", d.lastname);
    userfromdata.append("email", d.email);
    userfromdata.append("password", d.password);
    userfromdata.append("phone", d.phone);
    userfromdata.append("role_id", 2);
    userfromdata.append("property_id", property_Id);

    if (!Boolean(d.camID)) {
      try {
        var { data } = await Api("cam-add-user", "POST", userfromdata);
        if (data?.status) {
          setLoading(false);
          toast.success(data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          reset(data?.data);
          setcurrentStep(3);
        }
      } catch (error) {
        if (Boolean(error?.data?.errors)) {
          toast.error(error?.data?.errors?.email[0]);
          setValue("email", "");
        }
      }
    } else {
      const camassignData = {
        cam_managing_property_id: property_Id,
        id: d.camID
      }

      if (Boolean(property_Id)) {
        var res = await Api("assign-property-to-cam", "POST", camassignData);
        if (res?.data?.status) {
          setLoading(false);
          toast.success(res?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setcurrentStep(3);
        }
      } else {
        toast.error("Property Not Found.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }

    }
  };

  const getModifyCamData = async () => {
    const { data } = await Api("cam-get-user", "POST", {
      id: location?.state?.id,
    });
    const CamData = data?.data;
    reset(CamData);
  };

  useEffect(() => {
    if (!!location?.state?.id) {
      getModifyCamData();
    }
  }, [location?.state?.id]);

  const [selectedImage, setSelectedImage] = useState(null);
  const [Image, setImage] = useState(null);

  const handleImageChange = (e) => {
    const file = e.target.files[0]; // Get the selected file from input
    setImage(file);
    const reader = new FileReader(); // Create a FileReader instance

    reader.onload = (event) => {
      // On successful reading of file
      setSelectedImage(event.target.result); // Set the selected image data as state
    };

    if (file) {
      reader.readAsDataURL(file); // Read the file as Data URL
    }
  };

  const [selectedOption, setSelectedOption] = useState("option2");

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const getCamDetails = async () => {
    const { data } = await Api("cam-get-user", "POST", "");
    if (data?.status) {
      setCamList(data?.data);
    }
  };

  useEffect(() => {
    const OnboardingData = {
      currentStep: 2,
      propertyId: property_Id
    }
    // Cookies.set("currentStep", JSON.stringify(OnboardingData));
    localStorage.setItem('currentStep', JSON.stringify(OnboardingData));
  }, [])

  useEffect(() => {
    getCamDetails();
  }, []);
 const [phone, setPhone] = useState();
  useEffect(() => {
    setPhone(phone || ''); // Use an empty string if 'phone' is undefined
  }, [phone]);

  const handleInputChange = (event) => {
    const rawPhoneNumber = event.target.value.replace(/\D/g, '');
    const truncatedPhoneNumber = rawPhoneNumber.slice(0, 10);
    const formattedPhoneNumber = truncatedPhoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
    
    // Set the phone value directly using setValue from react-hook-form
    setValue('phone', formattedPhoneNumber);
  
    // Update the state with the formatted phone number
    setPhone(formattedPhoneNumber);
  };

  return (
    <>
      <div className="card card-primary">
        <div className="card-body ">
          <div className="d-flex">

            <div className="form-group">
              <label>
                <input
                  type="radio"
                  className="mx-3"
                  value="option2"
                  checked={selectedOption === "option2"}
                  onChange={handleOptionChange}
                />
                Create & assign a new CAM
              </label>
            </div>
            <div className="form-group">
              <label>
                <input
                  type="radio"
                  value="option1"
                  className="mx-3"
                  checked={selectedOption === "option1"}
                  onChange={handleOptionChange}
                />
                Choose from existing CAM
              </label>
            </div>
          </div>
          {selectedOption === "option1" && (
            <form
              onSubmit={handleSubmit(onSubmit)}
              encType="multipart/form-data"
            >
              <div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="camID">Select CAM</label>
                    <select
                      className="form-select"
                      {...register("camID", { required: true })}
                    >
                      <option value="">Select CAM</option>
                      {camList?.map((CamData, i) => (

                        <option key={i} value={CamData?.id}>
                          {CamData?.name + " (" + CamData?.email + ")"}
                        </option>
                      ))}
                    </select>

                    {errors.camID && (
                      <p role="alert" className="text-danger">
                        CAM is required
                      </p>
                    )}
                  </div>

                  <div className="text-end my-2">
                    <button
                      type="submit"
                      variant="primary"
                      className={`btn btn-primary`}
                      disabled={isLoading}
                    >
                      {isLoading ? (
                        <>
                          <Spinner animation="border" size="sm" /> Please wait...
                        </>
                      ) : (
                        "Submit"
                      )}
                    </button>

                  </div>
                </div>

              </div>
            </form>
          )}
        </div>
        {selectedOption === "option2" && (
          <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
            <div className="card-body">
              <div className="row align-items-center">
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="fname">First Name</label>
                    <input
                      type="text"
                      className="form-control"
                      id="fname"
                      placeholder="Enter First Name"
                      {...register("name", { required: true })}
                    />

                    {errors.name && (
                      <p className="text-danger">First Name is required</p>
                    )}
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="lastname">Last Name</label>
                    <input
                      type="text"
                      className="form-control"
                      id="lastname"
                      placeholder="Last Name"
                      {...register("lastname", { required: true })}
                    />

                    {errors.lastname && (
                      <p className="text-danger">Last Name is required</p>
                    )}
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      placeholder="Enter Email"
                      {...register("email", { required: true })}
                    />

                    {errors.email && (
                      <p className="text-danger">Email is required</p>
                    )}
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="enterNumber">Phone</label>
                    <input
                      type="text"
                      value={phone}
                      onChange={handleInputChange}
                      className="form-control"
                      id="enterNumber"
                      placeholder="Enter Phone Number"
                      required={true}
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="password">Password</label>
                    <input
                      type="password"
                      className="form-control"
                      id="password"
                      placeholder="Enter Password"
                      {...register("password", { required: true })}
                    />

                    {errors.password && (
                      <p className="text-danger">Password is required</p>
                    )}
                  </div>
                </div>
              </div>

              <div className="text-end my-2">
                <button
                  type="submit"
                  variant="primary"
                  className={`btn btn-primary`}
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <>
                      <Spinner animation="border" size="sm" /> Please wait...
                    </>
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>
            </div>
          </form>
        )}
      </div>
    </>
  );
};

export default CamFrom;
