import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import PropertyFrom from "./PropertyFrom";
import CamFrom from "./CamFrom";
import VendorFrom from "./VendorFrom";
import OwnerFrom from "./OwnerFrom";
import SecurityFrom from "./SecurityFrom";

import Api from "../../../Config/Api";
import { capitalizeFirstLetter } from "../../../Utility/Helpers";

const OnboardingFrom = () => {
  const currentStepdata =  localStorage.getItem('currentStep') ? JSON.parse(localStorage.getItem('currentStep')) : '';
  const location = useLocation();
  const [propertyId, setPropertyId] = useState(currentStepdata ? currentStepdata?.propertyId : null);
  const [propertyDetail, setPropertyDetail] = useState();
  const [currentStep, setcurrentStep] = useState(1);
  const isAdmin = useSelector((state)=>state.login.isAdmin);
  const _next = () => {
    setcurrentStep(currentStep >= 5 ? 6 : currentStep + 1);
  };

  const _prev = () => {
    setcurrentStep(currentStep <= 1 ? 1 : currentStep - 1);
  };

  useEffect(()=>{
   const currentStepdata =  localStorage.getItem('currentStep') ? JSON.parse(localStorage.getItem('currentStep')) : '';
    currentStepdata?.propertyId ? setcurrentStep(currentStepdata?.currentStep) : setcurrentStep(1);
  },[])

  useEffect(()=>{
    getPropertyDetails(propertyId)
  },[propertyId])

const getPropertyDetails = async(id)=>{
  if(id){
    const { data } = await Api("get-property", "POST", {
      id: id,
    });
    const property_data = data?.data;
    setPropertyDetail(property_data)
  }
  
}

  const exitOnboarding = ()=>{
    localStorage.setItem('currentStep','')
    setcurrentStep(1);
    setPropertyId('');
    setPropertyDetail('')
  }
  
  return (
    <>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Onboarding</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                  <Link to="/"
                      className={location.pathname === "/" ? "breadcrumb-active" : "breadcrumb-not-active"}
                    >
                      Home
                    </Link>
                    </li>
                  <span className="breadcrumb-arrow">&gt;</span>
                  <li className="breadcrumb-item active">
                 
                  Onboarding
                 
                  </li>
                  
                </ol>
              </div>
            </div>
          </div>
        </div>

        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                <div className={`card-header ${isAdmin ? "adminColor" : "cambg-secondary"}`}>
                    <div className="add_new_vendor_listing">
                      <div className="">
                        <h3 className="card-title mt-2"> {Boolean(propertyDetail) ? capitalizeFirstLetter("Property : "+propertyDetail?.name+', ' +propertyDetail?.city+', '+propertyDetail?.state_code) : "Enter Details" } </h3>
                      </div>
                    </div>
                  </div>

                  <div className="card-body table-responsive">
                    <div className="App">
                      {currentStep === 1 && (
                        <>
                         <div className="d-flex justify-content-between">
                            <div>
                            <h4 className="mt-2 mb-3">Property Details</h4>
                            </div>
                            <div>
                              <h4>Step {currentStep} / 5</h4>
                            </div>
                         </div>
                          <PropertyFrom
                            setPropertyId={setPropertyId}
                            setcurrentStep={setcurrentStep}
                          />
                        </>
                      )}
                      {currentStep === 2 && (
                        <>
                        <div className="d-flex justify-content-between">
                            <div>
                            <h4 className="mt-2 mb-3">Add/Assign a CAM</h4>
                            </div>
                            <div>
                              <h4>Step {currentStep} / 5</h4>
                            </div>
                         </div>
                          <CamFrom
                            propertyId={propertyId}
                            setcurrentStep={setcurrentStep}
                          />
                        </>
                      )}
                      {currentStep === 3 && (
                        <>
                        <div className="d-flex justify-content-between">
                            <div>
                            <h4 className="mt-2 mb-3"> Upload Unit CSV</h4>
                            </div>
                            <div>
                              <h4>Step {currentStep} / 5</h4>
                            </div>
                         </div>
                          <OwnerFrom
                            propertyId={propertyId}
                            setcurrentStep={setcurrentStep}
                            
                          />
                        </>
                      )}
                      {currentStep === 4 && (
                        <>
                         <div className="d-flex justify-content-between">
                            <div>
                            <h4 className="mt-2 mb-3">Upload Vendor CSV</h4>
                            </div>
                            <div>
                              <h4>Step {currentStep} / 5</h4>
                            </div>
                         </div>
                  
                          <VendorFrom
                            propertyId={propertyId}
                            setcurrentStep={setcurrentStep}
                            
                          />
                        </>
                      )}
                      {currentStep === 5 && (
                        <>
                        <div className="d-flex justify-content-between">
                            <div>
                            <h4 className="mt-2 mb-3">Upload Security CSV</h4>
                            </div>
                            <div>
                              <h4>Step {currentStep} / 5</h4>
                            </div>
                         </div>
                  
                          <SecurityFrom
                            propertyId={propertyId}
                            setcurrentStep={setcurrentStep}
                            
                          />
                        </>
                      )}
                        { propertyId && (

                       
                        <button
                          className="btn btn-primary"
                          type="button"
                          onClick={exitOnboarding}
                        >
                          Exit Onboarding
                        </button>
                         )} 
                      {/* {currentStep > 1 && (
                        <button
                          className="btn btn-secondary"
                          type="button"
                          onClick={_prev}
                        >
                          Previous
                        </button>
                      )}

                      <button
                        className="btn btn-primary float-right"
                        type="button"
                        onClick={_next}
                      >
                        Next
                      </button> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default OnboardingFrom;
