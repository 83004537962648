import React, {  useState } from "react";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import Api from "../../../Config/Api";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {  Spinner } from 'react-bootstrap';
const EditProfile = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const location = useLocation();
  const navigate = useNavigate();
  const isAdmin = useSelector((state)=>state.login.isAdmin);
  const roleId = useSelector((state) => state.login.roleId);
  const [isLoading, setLoading] = useState(false);
  const onSubmit = async (d) => {
    setLoading(true);
    if(d.new_confirm_password === d?.new_password){
      const { data } = await Api("change-password", "POST", d);
      if(data?.status){
        setLoading(false);
        toast.success("Your password has been successfully changed. !", {
          position: toast.POSITION.TOP_RIGHT,
        });
          navigate('/user/');
      }else{
        setLoading(false);
        toast.success(data?.message?.current_password[0], {
          position: toast.POSITION.TOP_RIGHT,
        });
      }

    }else{
      setLoading(false);
      toast.success("Incorrect Password !", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    
  };

  return (
    <>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Change Password</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                  <Link to="/"
                      className={location.pathname === "/" ? "breadcrumb-active" : "breadcrumb-not-active"}
                    >
                      Home
                    </Link>
                  </li>
                  <span className="breadcrumb-arrow">&gt;</span>
                  <li className="breadcrumb-item active">Change Password</li>
                </ol>
              </div>
            </div>
          </div>
        </div>

        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card card-info">
                <div className={`card-header ${isAdmin || Number(atob(roleId)) === 4  ? "adminColor" : "cambg-secondary"}`}>
                    <h3 className="card-title">Change Password</h3>
                  </div>
                  <form
                    className="form-horizontal"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <div className="card-body">
                      <div className="row align-items-center">


                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="new_password">New Password</label>
                            <input
                              type="password"
                              className="form-control"
                              id="new_password"
                              placeholder="Enter Password"
                              {...register("new_password", { required: true })}
                            />
                            {errors.new_password && (
                              <div className="form_error text-danger">
                                New Password is required.
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="new_confirm_password">Confirm Password</label>
                            <input
                              type="password"
                              className="form-control"
                              id="new_confirm_password"
                              placeholder="Enter Confirm Password"
                              {...register("new_confirm_password", { required: true })}
                            />
                            {errors.new_confirm_password && (
                              <div className="form_error text-danger">
                                Confirm Password is required.
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="current_password">Current Password</label>
                            <input
                              type="password"
                              className="form-control"
                              id="current_password"
                              placeholder="Enter current Password"
                              {...register("current_password", { required: true })}
                            />
                            {errors.current_password && (
                              <div className="form_error text-danger">
                                Current Password is required.
                              </div>
                            )}
                          </div>
                        </div>

                      </div>
                    </div>
                    <div className="card-footer">
                    <button className="btn btn-outline-light text-dark mx-3 bg-light" onClick={()=>navigate(-1)} type="button">Cancel</button>
                    <button type="submit" variant="primary" className={`btn btn-primary`} disabled={isLoading}>
                      {isLoading ? (
                                <>
                                <Spinner animation="border" size="sm" /> Please wait...
                                </>
                            ) : (
                                'Update'
                            )}
                        
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default EditProfile;
