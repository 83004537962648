// import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Api from "../../../Config/Api";
import Loader from "../../Elements/Loader";
import { BsFillEyeFill, BsPrinterFill } from "react-icons/bs";
// import { Card, ModalBody, Spinner } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import InfiniteScroll from "react-infinite-scroll-component";
import { BsSearch } from "react-icons/bs";
import dayjs from "dayjs";
import Button from "react-bootstrap/Button";
import { Tooltip } from 'react-bootstrap';
import { FaEdit } from 'react-icons/fa';

const PaymentHistory = () => {
    const [modalShow, setModalShow] = useState(false);
    const location = useLocation();
    const userId = useSelector((state) => state.login.userId);
    const isAdmin = useSelector((state) => state.login.isAdmin);
    const [editDepartureDate, setEditDepartureDate] = useState('');
    const [editRowIndex, setEditRowIndex] = useState(null);

    const [isLoading, setLoading] = useState(true);
    const [printPassLoading, setPrintPassLoading] = useState(false);
    const [passData, setPassData] = useState([]);
    const [passInfo, setPassInfo] = useState([]);
    const [camList, setCamList] = useState([]);
    const roleId = useSelector((state) => state.login.roleId);
    const { id } = useParams();
    const [searchTerm, setSearchTerm] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");

    const [perPage] = useState(10);
    const [page, setPage] = useState(1);
    const propertyData = useSelector((state) => state.login.propertyData?.id);
    const [searchType, setSearchType] = useState("");
    const { passes, searchBy, propertyId } = location.state ? location.state : '';
    // console.log("passes", passes);
    // console.log("searchBy", searchBy);
    // console.log("propertyId", propertyId);
    const handleSelectChange = (e) => {
        setSearchType(e.target.value);
        setSearchTerm('');
        setStartDate('');
        setEndDate('');
        getAllpassInfoByUserID();
    };

    const getAllpassInfoByUserID = async () => {
        setLoading(true);

        try {
            const requestData = id ? { property_id: Number(atob(id)) } : { property_id: propertyData };
            // console.log("passessssssssss", passes);
            const { data } = await Api("getAllpassInfoByUserID", "POST", requestData ? requestData : "");
            // console.log("apna data", data);
            if (data?.status) {

                setPage(1);
                if (passes && searchBy) {
                    setLoading(false);
                    if (propertyId) {
                        setLoading(false);
                        const filteredPasses = data?.passData?.filter(item => item?.billing_details?.property_id === propertyId && (item?.billing_details?.fname === passes || item?.billing_details?.email === passes || item?.billing_details?.phone === passes || item?.billing_details?.vehiclepass?.order_uuid === passes));
                        // console.log("lp-property id", filteredPasses);
                        const tempArr = filteredPasses.slice(0, perPage) || [];
                        setCamList(filteredPasses);
                        setPassData(tempArr);
                    } else {
                        // console.log("hlo")
                        const filteredPasses = data?.passData.filter(item =>
                            item?.billing_details?.fname === passes || item?.billing_details?.email === passes || item?.billing_details?.phone === passes || item?.billing_details?.vehiclepass?.order_uuid === passes
                        );
                        // console.log("lp", filteredPasses);

                        setPassData(filteredPasses);
                        setCamList(filteredPasses);
                    }
                }
                else if (id) {

                    if (startDate && endDate && searchType === '3') {
                        // console.log("serachtype 3");
                        const filteredData = data?.passData?.filter(item => item?.created_at >= startDate && item?.created_at <= endDate && item?.billing_details?.property_id === Number(atob(id)));
                        const tempArr = filteredData.slice(0, perPage) || [];
                        setCamList(filteredData);
                        setPassData(tempArr);

                        setLoading(false);
                    } else {
                        setLoading(false);
                        const filteredData = data?.passData?.filter(item => item?.billing_details?.property_id === Number(atob(id)));
                        // console.log("property");
                        const tempArr = filteredData.slice(0, perPage);
                        setCamList(filteredData);
                        setPassData(tempArr);
                    }

                } else if (startDate && endDate && searchType === '3') {
                    // console.log("serachtype 3");
                    const filteredData = data?.passData?.filter(item => item?.created_at >= startDate && item?.created_at <= endDate);
                    const tempArr = filteredData.slice(0, perPage) || [];
                    setCamList(filteredData);
                    setPassData(tempArr);

                    setLoading(false);
                } else if (searchType === '1' || searchType === '2' || searchType === '4') {
                    // console.log("serachtype 1 2 4");
                    const tempArr = data?.passData.slice(0, perPage) || [];
                    setCamList(data?.passData);
                    setPassData(tempArr);
                    setLoading(false);
                }
                else {
                    const tempArr = data?.passData.slice(0, perPage) || [];
                    // console.log("simple");
                    setCamList(data?.passData);
                    setPassData(tempArr);
                    setLoading(false);
                }
            }
        } catch (error) {
            console.error("Error fetching pass data:", error);
            setLoading(false);
        }
    };
    const handleNext = () => {
        const start = page * perPage;
        const end = start + perPage;
        const temArr = passData.concat(camList.slice(start, end));
        setPage((prev) => prev + 1);
        setPassData(temArr);
    };

    useEffect(() => {
        getAllpassInfoByUserID();
    }, []);

    const printParkingPass = async () => {
        setPrintPassLoading(true);
        const res = await Api("getAllpassInfoByUserID", "POST   ", {});

        if (res?.data?.status) {
            setPrintPassLoading(false);
            setPassInfo(res?.data?.passData);
            setModalShow(true);
        }
    };


    const handleInputChange = (event) => {
        setSearchTerm(event.target.value);
        const search = event.target.value;
        let filtered = [];

        switch (searchType) {
            case '1':
                filtered = camList.filter((item) => {
                    return item?.billing_details?.vehiclepass?.order_uuid?.toLowerCase()?.includes(search?.toLowerCase());
                });
                setCamList(filtered);
                // console.log("filtered",filtered);
                break;
            case '2':
                filtered = camList.filter((item) => {
                    return (
                        item?.billing_details?.fname?.toLowerCase()?.includes(search?.toLowerCase()) ||
                        item?.billing_details?.lname?.toLowerCase()?.includes(search?.toLowerCase())
                    );
                });
                setCamList(filtered);
                break;
            case '3':

                break;
            case '4':
                filtered = camList.filter((item) => {
                    const amount = item?.amount;
                    const amountString = amount !== undefined && amount !== null ? amount.toString() : '';

                    return amountString.toLowerCase().includes(search?.toLowerCase());
                });
                setCamList(filtered);
                break;
            default:
                filtered = camList;
        }
        setPassData(filtered);
        setLoading(false);
    };



    // const handleInputChange = (event) => {
    //     setSearchTerm(event.target.value);
    //     const search = event.target.value;
    //     const filtered = camList.filter((item) => {
    //         return item?.billing_details?.fname?.toLowerCase()?.includes(search?.toLowerCase()) || item?.billing_details?.lname?.toLowerCase()?.includes(search?.toLowerCase()) || item?.billing_details?.vehiclepass?.order_uuid?.toLowerCase()?.includes(search?.toLowerCase());
    //     });
    //     setPassData(filtered);
    //     setLoading(false);

    // };
    const getStatusForOrder = (Order) => {
        const startDate = dayjs(Order?.billing_details?.vehiclepass?.arrival_date);
        const endDate = dayjs(Order?.billing_details?.vehiclepass?.departure_date);
        const currentDate = dayjs();

        if (Order?.billing_details?.vehiclepass?.issuccess === 0 || Order?.billing_details?.vehiclepass?.Is_rental_vehicle === 0) {
            return "Incomplete";
        } else if (currentDate.isBefore(startDate)) {
            return "Future";
        } else if (currentDate.isAfter(endDate)) {
            return "Closed";
        } else {
            return "Current";
        }
    };

    const handleEditButtonClick = (index, initialDepartureDate) => {
        setEditRowIndex(index);
        setEditDepartureDate(initialDepartureDate);
    };

    const handleSaveButtonClick = async (index) => {
        try {
            if (index !== null) {
                const updatedPassData = [...passData];
                updatedPassData[index].billing_details.vehiclepass.departure_date = editDepartureDate;
                setPassData(updatedPassData);
            }
            const passIdToUpdate = passData[index]?.billing_details?.vehiclepass?.id;
            if (passIdToUpdate) {
                const requestData = {
                    passId: passIdToUpdate,
                    departureDate: editDepartureDate,
                };
                const response = await Api("cam_update_pass_departure_date", "POST", requestData);

                if (response?.data?.status) {
                } else {
                    console.error("Failed to update departure date on the backend.");
                }
            } else {
                console.error("Pass identifier not available for backend update.");
            }

            setEditRowIndex(null);
            setEditDepartureDate('');
        } catch (error) {
            console.error("Error updating departure date:", error);
        }
    };


    return (
        <>
            <main>
                <section className="">
                    <div
                        className="container-fluid"
                    // style={{
                    //     paddingLeft: "269px",
                    // }}
                    >
                        <Modal
                            size="lg"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                            show={modalShow}
                            onHide={() => setModalShow(false)}
                        >
                            <Modal.Header closeButton>
                                <Modal.Title id="contained-modal-title-vcenter">
                                    Passes & Printouts
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                            </Modal.Body>
                        </Modal>


                        <div className="content-wrapper">
                            <div className="content-header">
                                <div className="container-fluid">
                                    <div className="row mb-2">
                                        <div className="col-sm-6">
                                            {<h1 className="m-0">Payment/Pass History</h1>}
                                        </div>
                                        <div className="col-sm-6">
                                            <ol className="breadcrumb float-sm-right">
                                                <li className="breadcrumb-item">
                                                    <Link to="/"
                                                        className={location.pathname === "/" ? "breadcrumb-active" : "breadcrumb-not-active"}
                                                    >
                                                        Home
                                                    </Link>
                                                </li>
                                                {id && isAdmin ? (
                                                    <>
                                                        <span className="breadcrumb-arrow">&gt;</span>
                                                        <Link to="/user/PropertyListing"
                                                            className={location.pathname.startsWith("/user/PropertyListing") ? "breadcrumb-active" : "breadcrumb-not-active"}
                                                        >
                                                            Property
                                                        </Link>
                                                    </>
                                                ) : passes && searchBy ? (
                                                    <>
                                                        <span className="breadcrumb-arrow">&gt;</span>
                                                        <Link to="/user/LookupUnit"
                                                            className={location.pathname.startsWith("/user/LookupUnit") ? "breadcrumb-active" : "breadcrumb-not-active"}
                                                        >
                                                            Look Up
                                                        </Link>
                                                    </>
                                                ) : ("")}
                                                <span className="breadcrumb-arrow">&gt;</span>
                                                <li className="breadcrumb-item active">Payment History</li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <section className="content">
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="card">
                                                <div className={`card-header ${isAdmin ? "adminColor" : "cambg-secondary"}`}>
                                                    <div className="add_new_vendor_listing">
                                                        <h3 className="card-title">Details</h3>

                                                    </div>
                                                </div>

                                                <div className="d-flex justify-content px-4 mt-2">


                                                    <div className="input-group w-25 justify-content-start mt-2">
                                                        <select
                                                            className="form-select"
                                                            id="status"
                                                            onChange={handleSelectChange}
                                                        >
                                                            <option value="">Select Status</option>
                                                            <option value="1">Search By Pass ID</option>
                                                            <option value="2">Search By Name</option>
                                                            <option value="3">Search By Date</option>
                                                            <option value="4">Search By Amount</option>
                                                        </select>
                                                    </div>
                                                    {searchType === '3' && (
                                                        <div className="input-group w-50 d-flex justify-content-end px-4 mt-2">
                                                            <input
                                                                type="date"
                                                                className="form-control"
                                                                placeholder="From Date (MM/DD/YYYY)"
                                                                value={startDate}
                                                                onChange={(e) => setStartDate(e.target.value)}
                                                            />
                                                            <input
                                                                type="date"
                                                                className="form-control ml-2"
                                                                placeholder="To Date (MM/DD/YYYY)"
                                                                value={endDate}
                                                                onChange={(e) => setEndDate(e.target.value)}
                                                            />
                                                            <div className="input-group-append">
                                                                <span
                                                                    className="input-group-text bg-primary border-0"
                                                                    id="basic-addon2"
                                                                    onClick={getAllpassInfoByUserID}
                                                                >
                                                                    <BsSearch />
                                                                </span>
                                                            </div>
                                                        </div>
                                                    )}
                                                    {searchType === '1' && (
                                                        <div className="input-group w-25 d-flex justify-content-end px-4 mt-2">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Search..."
                                                                value={searchTerm}
                                                                onChange={handleInputChange}
                                                            />
                                                            <div className="input-group-append">
                                                                <span
                                                                    className="input-group-text bg-primary border-0"
                                                                    id="basic-addon2"
                                                                >
                                                                    <BsSearch />
                                                                </span>
                                                            </div>
                                                        </div>
                                                    )}
                                                    {searchType === '2' && (
                                                        <div className="input-group w-25 d-flex justify-content-end px-4 mt-2">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Search..."
                                                                value={searchTerm}
                                                                onChange={handleInputChange}
                                                            />
                                                            <div className="input-group-append">
                                                                <span
                                                                    className="input-group-text bg-primary border-0"
                                                                    id="basic-addon2"
                                                                >
                                                                    <BsSearch />
                                                                </span>
                                                            </div>
                                                        </div>
                                                    )}
                                                    {searchType === '4' && (
                                                        <div className="input-group w-25 d-flex justify-content-end px-4 mt-2">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Search..."
                                                                value={searchTerm}
                                                                onChange={handleInputChange}
                                                            />
                                                            <div className="input-group-append">
                                                                <span
                                                                    className="input-group-text bg-primary border-0"
                                                                    id="basic-addon2"
                                                                >
                                                                    <BsSearch />
                                                                </span>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>

                                                {isLoading ? (
                                                    <Loader />
                                                ) : (

                                                    <div className="card-body table-responsive">
                                                        <InfiniteScroll
                                                            dataLength={passData.length}
                                                            next={handleNext}
                                                            hasMore={camList?.length > passData.length}

                                                        // loader={<Loader />}

                                                        >
                                                            <table className="table table-bordered">
                                                                <thead className="border-top">
                                                                    <tr>
                                                                        <th>Pass ID</th>
                                                                        <th>Name</th>
                                                                        <th>Arrival Date</th>
                                                                        <th>Departure Date</th>
                                                                        <th>Pass State</th>
                                                                        <th>Total</th>
                                                                        <th>Action</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {passData.length > 0 ? (

                                                                        passData.map((Order, i) => (
                                                                            <tr key={i}>
                                                                                <th>
                                                                                    {(Order?.billing_details?.vehiclepass?.order_uuid) ? (Order?.billing_details?.vehiclepass?.order_uuid) : ("_")}</th>
                                                                                <td>{(Order?.billing_details) ?
                                                                                    (Order?.billing_details?.fname + " " + Order?.billing_details?.lname) : ("_")}</td>
                                                                                <td>
                                                                                    {
                                                                                        dayjs(Order?.billing_details?.vehiclepass?.arrival_date).format("MM/DD/YYYY")}</td>
                                                                                <td>
                                                                                    {Number(atob(roleId)) === 2 ? (
                                                                                        editRowIndex === i ? (
                                                                                            <input
                                                                                                type="date"
                                                                                                value={editDepartureDate}
                                                                                                onChange={(e) => setEditDepartureDate(e.target.value)}
                                                                                            />
                                                                                        ) : (
                                                                                            dayjs(Order?.billing_details?.vehiclepass?.departure_date).format("MM/DD/YYYY")
                                                                                        )
                                                                                    ) : (
                                                                                        dayjs(Order?.billing_details?.vehiclepass?.departure_date).format("MM/DD/YYYY")
                                                                                    )}</td>
                                                                                <td>
                                                                                    <span className={`badge badge-${(getStatusForOrder(Order) === "Future" || getStatusForOrder(Order) === "Current") ? "success" : "danger"}`}>
                                                                                        {getStatusForOrder(Order)}
                                                                                    </span>
                                                                                </td>
                                                                                <td>${Number(Order?.amount / 100).toFixed(2)}</td>
                                                                                <td>
                                                                                    <div className="d-flex justify-content">
                                                                                        <Link
                                                                                            to={"/user/PaymentHistoryView"}
                                                                                            state={{ id: Order?.id }}
                                                                                        >
                                                                                            <span className="btn btn-primary btn-sm mr-1">
                                                                                                <BsFillEyeFill />
                                                                                            </span>
                                                                                        </Link>

                                                                                        {Number(atob(roleId)) === 2 ? (
                                                                                            <>
                                                                                                <Button
                                                                                                    className="btn btn-info btn-sm mx-2"
                                                                                                    data-tooltip-id="assign"
                                                                                                    data-tooltip-content="Edit Pass Info"
                                                                                                    onClick={() => handleEditButtonClick(i, Order?.billing_details?.vehiclepass?.departure_date)}
                                                                                                >
                                                                                                    <FaEdit />
                                                                                                </Button>
                                                                                                <Tooltip id="assign" place="bottom" />
                                                                                            </>
                                                                                        ) : ("")}
                                                                                        {editRowIndex === i && (
                                                                                            <Button
                                                                                                className="btn btn-success btn-sm mr-1"
                                                                                                onClick={() => handleSaveButtonClick(i)}
                                                                                            >
                                                                                                Save
                                                                                            </Button>
                                                                                        )}

                                                                                        {/* <button
                                                                                    className="btn btn-primary btn-sm mx-2"
                                                                                    onClick={() =>
                                                                                        printParkingPass(Order?.id)
                                                                                    }
                                                                                >
                                                                                    {printPassLoading ? (
                                                                                        <>
                                                                                            <Spinner
                                                                                                animation="border"
                                                                                                size="sm"
                                                                                            />
                                                                                        </>
                                                                                    ) : (
                                                                                        <BsPrinterFill />
                                                                                    )}
                                                                                </button> */}
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        ))
                                                                    ) : (
                                                                        <>
                                                                            <tr>
                                                                                <td colSpan={6}>
                                                                                    <h4 className="text-center text-secondary">
                                                                                        {" "}
                                                                                        No Order Found Yet.
                                                                                    </h4>
                                                                                </td>
                                                                            </tr>
                                                                        </>
                                                                    )}

                                                                </tbody>
                                                            </table>
                                                        </InfiniteScroll>
                                                    </div>
                                                )}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </section>
            </main>
        </>
    );
};

export default PaymentHistory;
