import React, { useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import { FaUserCog } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  BsFillCameraFill,
  // BsFillCreditCard2BackFill,
  BsPrinterFill,
} from "react-icons/bs";
import Api from "../../../Config/Api";
import { updateUser } from "../../../Reducers/Login";
import { useDispatch } from "react-redux";
const RenterSidebar = ({ propertyData }) => {
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.login.userId);
  const logo = useSelector((state) => state.login.logo);
  const [selectedImage, setSelectedImage] = useState(null);
  const [Image, setImage] = useState(null);
  const [activeButton, setActiveButton] = useState(null);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImage(file);
    updateLogo(e.target.files[0]);
    const reader = new FileReader();
    reader.onload = (event) => {
      setSelectedImage(event.target.result); 
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const updateLogo = async (image) => {
    const updatelogodata = new FormData();
    updatelogodata.append("logo", image);
    updatelogodata.append("id", userId);
    const res = await Api("updateLogo", "POST", updatelogodata);
    if (res?.data?.status) {
      dispatch(updateUser(res.data?.data));
    }
  };

  return (
    <>
      <aside
        style={{
          top: "145px",
          minHeight: " 25%",
          height: "auto",
        }}
        className={`main-sidebar sidebar-dark-primary elevation-4 cambg-primary pb-4 rounded-3`}
      >
        <div className="renterProfile pt-4">
          <div className="toggle">
            <label htmlFor="view_details" title="tap here to view full profile">
              <BsFillCameraFill />
            </label>
          </div>
          <label htmlFor="image">
            <div className="user-circle">
              {logo ? (
                <img
                  className="user-profile-pic user-img"
                  src={
                    logo
                      ? process.env.REACT_APP_AWS_S3_BUCKET_URL + "images/" + logo
                      : selectedImage
                  }
                />
              ) : (
                <img className="user-profile-pic defaultImage"
                  src="https://t3.ftcdn.net/jpg/03/46/83/96/360_F_346839683_6nAPzbhpSkIpb8pmAwufkC7c5eD7wYws.jpg"
                />
              )}
            </div>
            <div className="user-p-image">
              <input
                className="user-file-upload"
                id="image"
                type="file"
                accept="image/*"
                onChange={(e) => handleImageChange(e)}
              />
            </div>
          </label>
        </div>

        <div className="sidebar">
          <nav className="mt-2">
            <ul className="nav nav-pills nav-sidebar flex-column">
              <Accordion defaultActiveKey="" flush>
                <>
                  <li className="nav-item menu-open">
                    <Link
                      to="/ViewPrintPass"

                      className={`nav-link ${activeButton === "ViewPrintPass" ? "active" : ""}`}
                      onClick={() => setActiveButton("ViewPrintPass")}>
                      <span className="pr-2 fs-5">
                        <BsPrinterFill className="text-light" />
                      </span>{" "}
                      <p className="text-light">View / Print Pass</p>
                    </Link>
                  </li>

                  <li className="nav-item menu-open">
                    <Link 
                      to="/SavedCard"
                      className={`nav-link ${activeButton === "SavedCard" ? "active" : ""}`}
                      onClick={() => setActiveButton("SavedCard")}>
                      <span className="pr-2 fs-5">
                        <BsPrinterFill className="text-light" />
                      </span>{" "}
                      <p className="text-light">Saved Card</p>
                    </Link>
                  </li>
                </>

                <Accordion.Item eventKey="4">
                  <Accordion.Header>
                    <span className="pr-2 fs-5">
                      <FaUserCog />
                    </span>{" "}
                    <p className="m-0">Account Setting</p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <ul className="nav nav-pills nav-sidebar flex-column">
                      <li className="nav-item">
                        <Link to="/RenterEditProfile"
                          className={`nav-link ${activeButton === "RenterEditProfile" ? "active" : ""}`}
                          onClick={() => setActiveButton("RenterEditProfile")}>
                          <i className="fa fa-users nav-icon"></i>
                          <p>Update Profile</p>
                        </Link>
                      </li>

                      <li className="nav-item">
                        <Link to="/RenterChangePassword" className={`nav-link ${activeButton === "RenterChangePassword" ? "active" : ""}`}
                          onClick={() => setActiveButton("RenterChangePassword")}>
                          <i className="fa fa-users nav-icon"></i>
                          <p>Change Password</p>
                        </Link>
                      </li>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </ul>
          </nav>
        </div>
      </aside>
    </>
  );
};

export default RenterSidebar;
