import React, { useEffect, useState } from "react";
// import Cookies from "js-cookie";
// import beachWarning from "../../assets/img/beach-warning-flags.png";
import Form from "react-bootstrap/Form";
import { useSelector } from "react-redux";
import Select from "react-select";
import { fetchState, getState } from "../../Reducers/State";
import { getProperty, fetchProperty } from "../../Reducers/RenterProperty";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import Api from "../../Config/Api";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { setUser } from "../../Reducers/Login";
import { addItemToCart } from "../../Reducers/CartSlice";
// import PhoneInput from "react-phone-input-2";
import dayjs from "dayjs";
import DatePicker from "react-multi-date-picker";

const Index = () => {
  const {
    register,
    handleSubmit,
    // reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();
  const [selectedOption, setSelectedOption] = useState(null);
  const [stateCode, setStateCode] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedUnit, setSelectedUnit] = useState("");
  const [rentalVehicleChecked, setRentalVehicleChecked] = useState(false);
  const [acknowledgeAllChecked, setAcknowledgeAllChecked] = useState(false);
  const [isRuleChecked, setRuleIsChecked] = useState(false);
  const [isWarnChecked, setWarnIsChecked] = useState(false);
  const [isRefundChecked, setRefundIsChecked] = useState(false);

  const [ruleData, setRuleData] = useState([]);
  const [warnData, setWarnData] = useState([]);
  const [refundData, setRefundData] = useState([]);

  const [phoneNumber, setPhoneNumber] = useState("");
  const [isLoading, setLoading] = useState(false);

  const [arrivalDate, setArrivalDate] = useState(new Date());
  const [departureDate, setDepartureDate] = useState(new Date());
  const [isFriToSun, setIsFriToSun] = useState("");

  const [price, setPrice] = useState(0);
  const [originalPrice, setOriginalPrice] = useState(0);
  const [selectedPass, setSelectedPass] = useState({
    Price: 0,
    PassName: ''
  });

  const stateData = useSelector(getState);
  const navigate = useNavigate();
  const isLogin = useSelector((state) => state.login.isLogin);
  const userId = useSelector((state) => state.login.userId);
  const email = useSelector((state) => state.login.email);
  const RenterProperty = useSelector(getProperty);
  const Numbers = Array.from(Array(10).keys()).map((num) => num + 1);
  const [phone, setPhone] = useState();



  // console.log("RenterProperty",RenterProperty);
  if (!RenterProperty) {
    toast.error(
      "We could not locate the specified name property. Please check try again."
    );
    navigate("/Login");
  }

  const background_image = RenterProperty?.bg_image
    ? process.env.REACT_APP_AWS_S3_BUCKET_URL + "images/" + RenterProperty?.bg_image
    : "";
  const sectionStyle = {
    backgroundImage: `url(${background_image})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  };
  const rentervehi = rentalVehicleChecked
    ? {
      backgroundColor: "#d9d9d9",
    }
    : {};


  // console.log('departureDate', departureDate);

  const handleChange = (selectedOption) => {
    setSelectedState(selectedOption.value); // selected value
    setStateCode(selectedOption["data-key"]); // data-key attribute
  };

  const handleRentalVehicleChange = (event) => {
    setRentalVehicleChecked(event.target.checked);
  };

  useEffect(() => {
    dispatch(fetchState());
  }, []);


  var options = [];
  stateData?.state?.map((state, i) => {
    options?.push({
      value: state?.name,
      label: state?.name,
      "data-key": state.code,
      key: i,
    });
  });

  var Unitoptions = [];
  RenterProperty?.units?.map((unit, i) => {
    Unitoptions?.push({
      value: unit?.id,
      label: unit?.unit_name,
      key: i,
    });
  });

  const onSubmit = async (data) => {
    const no_of_guests = watch("no_of_guests");
    setLoading(true);
    const renterRequest = {
      Passtype: data.Passtype,
      arrival_date: dayjs(arrivalDate).format("YYYY/MM/DD"),
      departure_date: dayjs(departureDate).format("YYYY/MM/DD"),
      email: data.email,
      phone: phone,
      lastname: data.lastname,
      name: data.name,
      password: data.password,
      address: data.address,
      address2: data.address2,
      country: data.country,
      city: data.city,
      zipcode: data.zipcode,
      license_plate: data.license_plate,
      unit_id: selectedUnit,
      Is_rental_vehicle: rentalVehicleChecked ? 1 : 0,
      pass_fee: price,
      property_id: RenterProperty?.id,
      no_of_guests: Number(no_of_guests),
      rules: JSON.stringify(ruleData),
      warnings: JSON.stringify(warnData),
      refundable_rules: JSON.stringify(refundData),

    };
    console.log("data>>>>>", data);



    if (!rentalVehicleChecked) {
      renterRequest.vehicle_color = data.vehicle_color;
      renterRequest.vehicle_make = data.vehicle_make;
      renterRequest.vehicle_model = data.vehicle_model;
      renterRequest.vehicle_tag_state = selectedState;
      renterRequest.vehicle_tag = data.vehicle_tag;
    }

    var res = await Api("CreatePass", "POST", renterRequest);
    console.log("res", res);
    if (!isLogin) {
      var loginUser = await Api("user-login", "POST", {
        email: data.email,
        password: data.password,
      });
    }
    if (res?.data?.status) {
      
      setLoading(false);
      toast.success("Successfully Add To Cart.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      if (!isLogin) {
        dispatch(setUser(loginUser?.data?.data));
      }
      const data = { id: btoa(res?.data?.data?.id) };
      dispatch(addItemToCart(res?.data?.data?.id));
      navigate("/RenterCheckout", { state: { data } });
    } else {
      setLoading(false);
      toast.success("Some Error.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  // const handleRuleCheckboxChange = (event, id) => {
  //   console.log('handleRuleCheckboxChange', id);
  //   const { checked } = event.target;
  //   const labelAttributeValue = event.target.value;
  //   console.log(" event.target.value", event.target.value);
  //   if (checked) {
  //     setRuleData((ruleData) => [...ruleData, { id, labelAttributeValue }]);
  //     setRuleIsChecked(true);
  //   } else {
  //     setRuleData((ruleData) => ruleData.filter(item => item.id !== id));
  //     setRuleIsChecked(false);
  //   }
  //   if (event.target.classList.contains('general-rule-checkbox')) {
  //     const generalRuleCheckboxes = document.querySelectorAll('.general-rule-checkbox');
  //     generalRuleCheckboxes.forEach(checkbox => {
  //       checkbox.checked = checked;
  //     });
  //   }
  // };


  const handleRuleCheckboxChange = (e) => {

    const isChecked = e.target.checked;
    const value = e.target.id;

    setRuleData((prevValue) => {
      return isChecked ? [...prevValue, value] : prevValue.filter(id => id !== value);
    });
  };


  // console.log("");
  const handleWarnCheckboxChange = (e) => {
    const isChecked = e.target.checked;
    const value = e.target.value;

    setWarnData((prevValue) => {
      return isChecked ? [...prevValue, value] : prevValue.filter(id => id !== value);
    });
  };

  const handleRefundableCheckboxChange = (e) => {
    const isChecked = e.target.checked;
    const value = e.target.value;

    setRefundData((prevValue) => {
      return isChecked ? [...prevValue, value] : prevValue.filter(id => id !== value);
    });
  };

  const handleAcknowledgeAllChange = (e) => {
    if (e.target.checked) {
      const allRules = RenterProperty?.rules_and_regulations?.filter(data => data.for_whom === 1).map(data => data.rules);
      const allWarns = RenterProperty?.rules_and_regulations?.filter(data => data.for_whom === 3).map(data => data.rules);
      const allRefund = RenterProperty?.rules_and_regulations?.filter(data => data.for_whom === 2).map(data => data.rules);

      setRuleData(allRules);
      setWarnData(allWarns);
      setRefundData(allRefund);
      setAcknowledgeAllChecked(true);
    } else {
      setRuleData([]);
      setWarnData([]);
      setRefundData([]);
      setAcknowledgeAllChecked(false);
    }
  }

  const setPricePasss = (priceData) => {
    setPrice(priceData?.base_amount);
    setOriginalPrice(priceData?.base_amount);
  };

  useEffect(() => {
    if (arrivalDate && departureDate) {
      const start = dayjs(arrivalDate);
      const end = dayjs(departureDate);
      let currentDate = start;

      let filterPrice = RenterProperty?.price
        ?.filter((value) => {
          const startDate = dayjs(value?.start_date).format("MM/DD/YYYY");
          const stopDate = dayjs(value?.stop_date).format("MM/DD/YYYY");
          const formattedEnd = dayjs(end).format("MM/DD/YYYY");
          const formattedStart = dayjs(start).format("MM/DD/YYYY");

          return (
            value?.pass_type_alias === 'date_specific' &&
            (startDate === formattedStart || stopDate === formattedEnd || startDate === formattedEnd || stopDate === formattedStart)
          );
        });

      let filterForWeeklyPrice = RenterProperty?.price
        ?.filter((value) => {
          return (value?.pass_type_alias === '7_days');
        });
      var differenceInDays = end.diff(start, 'day');
      if (differenceInDays == 6 || differenceInDays == 7) {
        setPrice(originalPrice + filterForWeeklyPrice[0]?.base_amount);
        setSelectedPass({
          Price: filterForWeeklyPrice[0]?.base_amount,
          PassName: filterForWeeklyPrice[0]?.pass_name
        });
      } else {
        setPrice(originalPrice);
        setSelectedPass({
          Price: 0,
          PassName: ""
        });
      }

      let filterPriceRange = RenterProperty?.price
        ?.filter((value) => {
          return (
            value?.pass_type_alias === "date_range"
          );
        });



      if (filterPrice?.length > 0) {
        setPrice(originalPrice + filterPrice[0]?.base_amount);
        setSelectedPass({
          Price: filterPrice[0]?.base_amount,
          PassName: filterPrice[0]?.pass_name
        });
      } else {
        setPrice(originalPrice);
        setSelectedPass({
          Price: 0,
          PassName: ""
        });
      }


      while (currentDate.isBefore(end)) {
        const dayOfWeek = currentDate.day();

        if ((dayOfWeek === 5 || dayOfWeek === 6 || dayOfWeek === 0) && filterForWeeklyPrice?.length > 0) {
          setIsFriToSun(true);
          RenterProperty?.price
            ?.filter((value) => value?.pass_type_alias === "fri_sun")
            .map((priceValue, i) => {
              setPrice(originalPrice + priceValue?.base_amount);
              setSelectedPass({
                Price: priceValue?.base_amount,
                PassName: priceValue?.pass_name
              });
            });

          break;
        } else {
          setIsFriToSun(false);

          setPrice(originalPrice);
          setSelectedPass({
            Price: 0,
            PassName: ""
          });
          break;
        }

      }

    }
  }, [arrivalDate, departureDate]);

  useEffect(() => {
    setPhone(phone || ''); // Use an empty string if 'phone' is undefined
  }, [phone]);

  const handleInputChange = (event) => {
    const rawPhoneNumber = event.target.value.replace(/\D/g, '');
    const truncatedPhoneNumber = rawPhoneNumber.slice(0, 10);
    const formattedPhoneNumber = truncatedPhoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
    
    // Set the phone value directly using setValue from react-hook-form
    // setValue('phone', formattedPhoneNumber);
  
    // Update the state with the formatted phone number
    setPhone(formattedPhoneNumber);
  };

  return (
    <>
      <main>
        <section
          className="py-5 guest_section bannersection"
          style={sectionStyle}
        >
          <div
            className={isLogin ? "container-fluid" : "container"}
            style={{
              paddingLeft: isLogin ? "269px" : 0,
            }}
          >
            <div className="new_guest_form">
              <div className="returning_heading">
                <h4 className="pb-3">Guest Registration</h4>
              </div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row align-items-center">
                  <div className="col-md-4">
                    <div className="form_input_guest">
                      <label className="guest_label">First Name</label>
                      <input
                        type="text"
                        {...register("name", { required: true })}
                        placeholder="Enter First Name"
                      />

                      {errors.name && (
                        <p className="text-danger">First name is required</p>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form_input_guest">
                      <label className="guest_label">Last Name</label>
                      <input
                        type="text"
                        {...register("lastname", { required: true })}
                        placeholder="Enter Last Name"
                      />

                      {errors.lastname && (
                        <p className="text-danger">Last name is required</p>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form_input_guest">
                      <label className="guest_label">E-mail Address</label>
                      <input
                        type="email"
                        {...register("email", { required: true })}
                        placeholder="E-mail Address"
                        readOnly={isLogin ? true : false}
                        defaultValue={isLogin ? email : ""}
                      />

                      {errors.email && (
                        <p className="text-danger">
                          E-mail Address is required
                        </p>
                      )}
                    </div>
                  </div>
                  {!isLogin && (
                    <div className="col-md-4">
                      <div className="form_input_guest">
                        <label className="guest_label">Password</label>
                        <input
                          type="password"
                          autoComplete="new-password"
                          {...register("password", { required: true })}
                          placeholder="Enter Password"
                        />

                        {errors.password && (
                          <p className="text-danger">Password is required</p>
                        )}
                      </div>
                    </div>
                  )}

                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="enterNumber">Phone</label>
                      <input
                        type="text"
                        value={phone}
                        onChange={handleInputChange}
                        className="form-control"
                        id="enterNumber"
                        placeholder="Enter Phone Number"
                        required={true}
                      />
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="form_input_guest">
                      <div>
                        <label className="guest_label">
                          Country / Region
                        </label>
                      </div>
                      <input
                        type="text"
                        {...register("country", { required: true })}
                        placeholder="Enter Country Name"
                      />
                      {errors.country && (
                        <p className="text-danger">Country is required</p>
                      )}
                    </div>
                  </div>

                  <div className="row">
                    <label className="guest_label"> Street Address</label>
                    <div className="col-md-4">
                      <div className="form_input_guest">
                        <input
                          type="text"
                          {...register("address")}
                          placeholder="House number and street name"
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form_input_guest">
                        <input
                          type="text"
                          {...register("address2")}
                          placeholder="Apartment, suite, unit, etc. (optional)"
                        />
                      </div>
                    </div>
                    {errors.address && (
                      <p className="text-danger">
                        Street Address is required
                      </p>
                    )}
                  </div>

                  <div className="col-md-4">
                    <div className="form_input_guest">
                      <label className="guest_label">Town / City</label>
                      <input
                        type="text"
                        {...register("city", { required: true })}
                        placeholder="Enter City Name"
                      />

                      {errors.city && (
                        <p className="text-danger">City is required</p>
                      )}
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="form_input_guest">
                      <label className="guest_label">Zip Code</label>
                      <input
                        type="text"
                        {...register("zipcode", { required: true })}
                        placeholder="Enter Zip Code"
                      />

                      {errors.zipcode && (
                        <p className="text-danger">Zip Code is required</p>
                      )}
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="form_input_guest">
                      <div>
                        <label className="guest_label">No of Guests</label>
                      </div>

                      <select {...register("no_of_guests", { required: true })}>
                        {Numbers?.map((num, i) => (

                          <option key={i} value={num}>
                            {num}
                          </option>

                        ))}
                      </select>
                      {errors.no_of_guests && (
                        <p className="text-danger">
                          Number of Guest is required.
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="form_input_guest">
                      <label className="guest_label">Condo#</label>
                      <Select
                        options={Unitoptions}
                        onChange={(selectedOption) =>
                          setSelectedUnit(selectedOption.value)
                        }
                      />
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="form_input_guest">
                      <div>
                        <label className="guest_label">Arrival</label>
                      </div>
                      <DatePicker value={arrivalDate} format="MM/DD/YYYY" onChange={(value) => setArrivalDate(value)} />

                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="form_input_guest">
                      <div>
                        <label className="guest_label">Departure</label>
                      </div>
                      <DatePicker value={departureDate} format="MM/DD/YYYY" onChange={(value) => setDepartureDate(value)} defaultValue={arrivalDate} minDate={arrivalDate} multiple={false} />

                    </div>
                  </div>






                  <div className="row">
                    <div className="card">
                      <div className="card-body">
                        <div className="col-md-12 col-xl-12">
                          <div className="form_input_guest pb-0">
                            <div className="main_passtype">
                              <span className="fw-semibold d-block pe-3">
                                Vehicle Type :
                              </span>
                              {RenterProperty?.price
                                ?.filter(
                                  (value) => value?.extra_info === "base_price" && Number(value?.status) === 1
                                )
                                .map((priceValue, i) => (
                                  <div key={i}>

                                    <Form.Check
                                      type={"radio"}
                                      label={
                                        <span style={{ fontWeight: "bold" }}>
                                          {priceValue?.pass_name}
                                        </span>
                                      }
                                      onClick={() => setPricePasss(priceValue)}
                                      id={`standard${i}`}
                                      {...register("Passtype", {
                                        required: true,
                                      })}
                                      className="mx-2"
                                      defaultValue={priceValue?.pass_name}
                                    />
                                  </div>
                                ))}

                              {errors.Passtype && (
                                <p className="text-danger">
                                  Passtype is required
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>



                  <div className="col-md-4">
                    <div className="form_input_guest">
                      <label className="guest_label">Vehicle Make</label>
                      <input
                        type="text"
                        style={rentervehi}
                        readOnly={rentalVehicleChecked}
                        {...register("vehicle_make", {
                          required: rentalVehicleChecked ? false : true,
                        })}
                        placeholder="Vehicle Make"
                      />

                      {errors.vehicle_make && (
                        <p className="text-danger">Vehicle Make is required</p>
                      )}
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="form_input_guest">
                      <label className="guest_label">Vehicle Model</label>
                      <input
                        type="text"
                        style={rentervehi}
                        readOnly={rentalVehicleChecked}
                        {...register("vehicle_model", {
                          required: rentalVehicleChecked ? false : true,
                        })}
                        placeholder="Vehicle Model"
                      />

                      {errors.vehicle_model && (
                        <p className="text-danger">Vehicle Model is required</p>
                      )}
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="form_input_guest">
                      <label className="guest_label">Vehicle Color</label>
                      <input
                        type="text"
                        style={rentervehi}
                        readOnly={rentalVehicleChecked}
                        {...register("vehicle_color", {
                          required: rentalVehicleChecked ? false : true,
                        })}
                        placeholder="Enter Vehicle Color"
                      />

                      {errors.vehicle_color && (
                        <p className="text-danger">Vehicle Color is required</p>
                      )}
                    </div>
                  </div>

                  {/* <div className="col-md-6">
                    <div className="form_input_guest">
                      <label className="guest_label">Vehicle Tag#</label>
                      <input
                        type="text"
                        style={rentervehi}
                        readOnly={rentalVehicleChecked}
                        {...register("vehicle_tag", {
                          required: rentalVehicleChecked ? false : true,
                        })}
                        placeholder="Enter Vehicle Tag"
                      />

                      {errors.vehicle_tag && (
                        <p className="text-danger">Vehicle Tag is required</p>
                      )}
                    </div>
                  </div> */}

                  <div className="col-md-6">
                    <div className="form_input_guest">
                      <label className="guest_label"> License Plate #</label>
                      <input
                        type="text"
                        style={rentervehi}
                        readOnly={rentalVehicleChecked}
                        {...register("license_plate", { required: rentalVehicleChecked ? false : true, })}
                        placeholder="Enter license plate"
                      />

                      {errors.license_plate && (
                        <p className="text-danger">License Plate is required</p>
                      )}
                    </div>
                  </div>


                  <div className="col-md-6">
                    <div className="form_input_guest">
                      <label className="guest_label">Vehicle Tag State</label>

                      <Select
                        style={rentervehi}
                        isDisabled={rentalVehicleChecked}
                        options={options}
                        defaultValue={selectedOption}
                        onChange={handleChange}
                        className="vehicle_tags"
                      />
                    </div>
                  </div>

                  <div className="col-md-12 mx-2">
                    <Form.Group className="mb-3">
                      <Form.Check
                        label={
                          <span className="h5" style={{ fontWeight: "bold" }}>
                            Rental Vehicle
                          </span>
                        }
                        id="rentalvehicle"
                        feedback="You must agree before submitting."
                        feedbackType="invalid"
                        onClick={handleRentalVehicleChange}
                      />
                    </Form.Group>
                  </div>

                  {console.log("ruleData", ruleData)}
                  {console.log("warnData", warnData)}
                  {console.log("refundData", refundData)}

                  <label htmlFor={`rule`} className="h5 py-2">
                    Rules & Regulations *
                  </label>
                  {RenterProperty?.rules_and_regulations
                    ?.filter((p) => Number(p.for_whom) === 1)
                    .map((rules, i) => (
                      <div key={i}>
                        {rules.type === "Text" ? (
                          <>
                            <Form.Group className="mb-3">
                              <Form.Check
                                className="general-rule-checkbox" // Add this class
                                id={`rule${i}`}
                                label={rules?.rules}
                                value={rules?.rules}
                                // checked={acknowledgeAll.includes(rules?.id)}
                                checked={ruleData.includes(rules?.rules) ? ruleData.includes(rules?.rules) : acknowledgeAllChecked}

                                onChange={(e) => {
                                  const isChecked = e.target.checked;
                                  console.log("isChecked", isChecked)
                                  if (isChecked) {
                                    console.log(
                                      "RenterProperty",
                                      RenterProperty?.rules_and_regulations?.map((data) => data?.id)
                                    );

                                    const updatedAcknowledgeAll = RenterProperty?.rules_and_regulations?.map((data) => {
                                      if (data?.id === rules?.id && data?.for_whom === 1) {
                                        return rules?.id;
                                      }
                                      return null;
                                    }).filter(Boolean);
                                    setRuleData((value) => [...value, rules?.rules]);
                                  } else {
                                    setRuleData((value) =>
                                      value.filter((id) => id !== rules?.id)
                                    );
                                  }
                                }}
                              />
                            </Form.Group>
                          </>
                        ) : (
                          <>
                            <ul>
                              <li>
                                {/* <a href={rules?.rules?.replace("http://localhost:3000/", "http://")} target="_blank" rel="noopener noreferrer">
                                  Click here 
                                </a> */}
                                <a
                                  className="pb-3"
                                  href={"http://" + rules?.rules}  // Make sure rules.rules contains the complete URL
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {rules.alias_name}
                                </a>
                              </li>
                            </ul>
                          </>
                        )}
                      </div>
                    ))}

                  <label htmlFor={`Beach`} className="h5 py-2">
                    Beach Flag Warning System *
                  </label>

                  {RenterProperty?.rules_and_regulations
                    ?.filter((p) => Number(p.for_whom) === 3)
                    .map((rules, i) => (
                      <div key={i}>

                        {rules.type === "Text" ? (
                          <>
                            <Form.Group className="mb-3" key={i}>
                              <Form.Check
                                id={`Beach${i}`}
                                label={rules?.rules}
                                value={rules?.rules} // Assuming rules.id is unique
                                checked={warnData.includes(rules?.rules) ? warnData.includes(rules?.rules) : acknowledgeAllChecked}
                                onChange={(e) => {
                                  const isChecked = e.target.checked;
                                  console.log("isChecked", isChecked)
                                  if (isChecked) {
                                    console.log(
                                      "RenterProperty",
                                      RenterProperty?.rules_and_regulations?.map((data) => data?.id)
                                    );

                                    const updatedAcknowledgeAll = RenterProperty?.rules_and_regulations?.map((data) => {
                                      if (data?.id === rules?.id && data?.for_whom === 3) {
                                        return rules?.id;
                                      }
                                      return null;
                                    }).filter(Boolean);
                                    setWarnData((value) => [...value, rules?.rules]);
                                  } else {
                                    setWarnData((value) =>
                                      value.filter((id) => id !== rules?.id)
                                    );
                                  }
                                }}
                              />
                            </Form.Group>
                          </>
                        ) : (
                          <>
                            <ul>
                              <li> <a
                                className="pb-3"
                                href={"http://" + rules?.rules}  // Make sure rules.rules contains the complete URL
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {rules.alias_name}
                              </a></li>
                            </ul>
                          </>
                        )}

                      </div>
                    ))}

                  <div className="col-lg-12">
                    <img
                      className="w-50"
                      src={
                        process.env.REACT_APP_AWS_S3_BUCKET_URL +
                        "images/" +
                        RenterProperty?.rules_and_regulations
                          ?.filter((p) => Number(p.for_whom) === 3)?.[0]?.images
                      }
                      alt=""
                    />
                  </div>

                  <label
                    htmlFor={`non_refundable`}
                    className="h5 py-2"
                  >
                    Non-Refundable *
                  </label>
                  {RenterProperty?.rules_and_regulations
                    ?.filter((p) => Number(p.for_whom) === 2)
                    .map((rules, i) => (
                      <div key={i}>

                        {rules.type === "Text" ? (
                          <Form.Group className="mb-3">
                            <Form.Check
                              id={`non_refundable${i}`}
                              label={rules?.rules}
                              value={rules?.rules}
                              checked={refundData.includes(rules?.rules) ? refundData.includes(rules?.rules) : acknowledgeAllChecked}
                              onChange={(e) => {
                                const isChecked = e.target.checked;
                                console.log("isChecked", isChecked)
                                if (isChecked) {
                                  console.log(
                                    "RenterProperty",
                                    RenterProperty?.rules_and_regulations?.map((data) => data?.id)
                                  );

                                  const updatedAcknowledgeAll = RenterProperty?.rules_and_regulations?.map((data) => {
                                    if (data?.id === rules?.id && data?.for_whom === 1) {
                                      return rules?.id;
                                    }
                                    return null;
                                  }).filter(Boolean);
                                  setRefundData((value) => [...value, rules?.rules]);
                                } else {
                                  setRefundData((value) =>
                                    value.filter((id) => id !== rules?.id)
                                  );
                                }
                              }}
                              feedback="You must agree before submitting."
                              feedbackType="invalid"
                            />
                          </Form.Group>
                        ) : (
                          <ul>
                            <li> <a
                              className="pb-3"
                              href={"http://" + rules?.rules}  // Make sure rules.rules contains the complete URL
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {rules.alias_name}
                            </a></li>
                          </ul>
                        )}

                      </div>


                    ))}
                  <div>
                    <label>
                      <Form.Group className="mb-3">
                        <Form.Check
                          type="checkbox"
                          checked={acknowledgeAllChecked}
                          onChange={(e) => handleAcknowledgeAllChange(e)}
                        />
                        I acknowledge all these rules
                      </Form.Group>
                    </label>


                    {/* Rest of your code */}
                  </div>


                  <label className="guest_label">
                    {selectedPass.PassName != "" ? (
                      <>
                        <p>Apply Pass Name : {selectedPass.PassName}</p>
                        <p>Add To BasePrice : {selectedPass.Price}</p>
                        <p>Base Price : {originalPrice}</p>
                      </>
                    ) : (
                      ''
                    )}
                    Total Pass Fee <h4 className="d-inline">${Number(price).toFixed(2)}</h4>
                  </label>

                  <div className="col-md-3">
                    <div className="input_form_submit_btn mt-3">
                      <button
                        type="submit"
                        className="btn btn-primary cambg-secondary"
                        disabled={isLoading}
                      >
                        {isLoading ? (
                          <>
                            <Spinner animation="border" size="sm" /> Please
                            wait...
                          </>
                        ) : (
                          "Payment"
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>
      </main>
      <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/js/bootstrap.bundle.min.js"></script>
    </>
  );
};

export default Index;
