import React, { useEffect, useState } from "react";

import Cookies from "js-cookie";
// import beachWarning from "../../assets/img/beach-warning-flags.png";
// import Form from "react-bootstrap/Form";
import { useSelector } from "react-redux";
import Select from "react-select";
import { getProperty } from "../../../Reducers/RenterProperty";
import { useDispatch } from "react-redux";
import Api from "../../../Config/Api";
// import { toast } from "react-toastify";
// import { Spinner } from "react-bootstrap";
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
// import { BsFillCheckCircleFill } from "react-icons/bs";
import Card from "react-bootstrap/Card";
import { PaymentSuccess } from "../../../Reducers/CartSlice";
import Loader from "../../Elements/Loader";
import dayjs from "dayjs";
import { BsFillCheckCircleFill, BsPrinterFill } from "react-icons/bs";
import { FaEye } from "react-icons/fa";

const SuccessPaymentView = () => {
  const [price, setPrice] = useState(0);
  const [passInfo, setPassInfo] = useState([]);
  const isAdmin = useSelector((state) => state.login.isAdmin);
  const navigate = useNavigate();
  const propertyData = useSelector((state) => state.login.propertyData);
  const RenterProperty = propertyData;
  const location = useLocation();
  const [isLoader, setIsLoader] = useState(true);
  const [passStatus, setPassStatus] = useState([]);

  const getCheckOutDetails = async () => {
    const res = await Api("getVehiclepassById", "POST", {
      id: location?.state?.id,
      type: "vendor",

    });
    console.log('res', res?.data?.data);
    if (res?.data?.status) {
      // console.log("res", res);
      setPassInfo(res?.data?.data);
      console.log("passInfo", passInfo);
      setIsLoader(false);

      const startDate = dayjs(res?.data?.passData?.billing_details?.vehiclepass?.arrival_date);
      const endDate = dayjs(res?.data?.passData?.billing_details?.vehiclepass?.departure_date);
      const currentDate = dayjs();

      console.log("startDate", startDate);
      console.log("endDate", endDate);
      console.log("currentDate", currentDate);

      if ((res?.data?.passData?.billing_details?.vehiclepass?.issuccess) === 0 || (res?.data?.passData?.billing_details?.vehiclepass?.Is_rental_vehicle) === 0) {
        setPassStatus("incomplete");
        console.log("incomplete");

      } else {
        if (currentDate.isBefore(startDate)) {
          // status = 'future';
          setPassStatus("future");
          console.log("future");
        } else if (currentDate.isAfter(endDate)) {
          // status = 'closed';
          setPassStatus("closed");
          console.log("closed");
        } else {
          // status = 'current';
          setPassStatus("current");
          console.log("current");

        }
      }
      
    }
  };
  console.log("location?.state?.propertyId", location?.state?.propertyId);
  useEffect(() => {
    getCheckOutDetails();
  }, [location?.state?.id]);

  return (
    <>
      {isLoader ? (
        <Loader />
      ) : (
        <div className="content-wrapper">
          <div className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1 className="m-0">
                    {parseInt(passInfo?.is_owner) === 0 ? "Owner Guest Pass" : (passInfo?.is_passes_for === 'Owner' ? "Owner Pass" : "Vendor Pass")} - {passInfo?.propety?.name}
                  </h1>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item">
                      <Link to="/"
                        className={location.pathname === "/" ? "breadcrumb-active" : "breadcrumb-not-active"}
                      >
                        Home
                      </Link>
                      <span className="breadcrumb-arrow">&gt;</span>
                      <Link to={passInfo?.is_passes_for === 'Owner' ? "/user/OwnerListing/" + btoa(location?.state?.propertyId) : ("/user/VendorListing/" + btoa(location?.state?.propertyId))}
                        className={passInfo?.is_passes_for === 'Owner' ? (location.pathname.startsWith("/user/OwnerListing/" + btoa(location?.state?.propertyId)) ? "breadcrumb-active" : "breadcrumb-not-active") : (location.pathname.startsWith("/user/VendorListing/" + btoa(location?.state?.propertyId)) ? "breadcrumb-active" : "breadcrumb-not-active")
                        }
                      >
                        {passInfo?.is_passes_for === 'Owner' ? "Units" : "Vendors"}
                      </Link>
                      <span className="breadcrumb-arrow">&gt;</span>
                      <Link to={passInfo?.is_passes_for === 'Owner' ? "/user/ManageOwnerPasses" : "/user/ManagePasses"}
                        className={passInfo?.is_passes_for === 'Owner' ? (location.pathname.startsWith("/user/ManageOwnerPasses") ? "breadcrumb-active" : "breadcrumb-not-active") : (location.pathname.startsWith("/user/ManagePasses") ? "breadcrumb-active" : "breadcrumb-not-active")
                        }
                      >
                        {passInfo?.is_passes_for === 'Owner' ? "Owner Passes" : "Vendor Passes"}
                      </Link>
                    </li>
                    <span className="breadcrumb-arrow">&gt;</span>
                    <li className="breadcrumb-item active">
                      {parseInt(passInfo?.is_owner) === 0 ? "Owner Guest Pass Details" : (passInfo?.is_passes_for === 'Owner' ? "Owner Pass Details" : "Vendor Pass Details")}
                      {/* {passInfo?.is_passes_for === 'Owner' ? "Owner Pass Details" : "Vendor Pass Details"} */}
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          <main>
            <section className="content">
              <div className="container-fluid">
                <div className="new_guest_form">
                  <div className="returning_heading">
                    <h1 className="pb-3 text-center">Order Received</h1>
                  </div>


                  <>
                    <Card>
                      <Card.Body>
                        <div className="row">
                          {/* <div className="col-lg-12">
                            <div className="text-center pt-3">
                              <h3>{passInfo?.is_passes_for === 'Owner' ? "Owner Pass" : "Vendor Pass"} - {passInfo?.propety?.name}</h3>
                            </div>
                            <div>
                              <span className="btn btn-warning btn-sm text-light mx-1" onClick={() => window.print()}>
                                <BsPrinterFill />
                              </span>
                            </div>
                            <div className="text-center py-3">
                              <h3>{passInfo?.unit?.unit_name}</h3>
                            </div>
                          </div> */}
                          <div className="returning_heading">
                            <div className="d-flex justify-content-between">
                              <div className="pb-3 text-center">
                                <h3>{parseInt(passInfo?.is_owner) === 0 ? "Owner Guest Pass" : (passInfo?.is_passes_for === 'Owner' ? "Owner Pass" : "Vendor Pass")} - {passInfo?.propety?.name}</h3>
                              </div>
                              <div>
                                <Link>
                                  <span className="btn btn-primary btn-sm mx-2" onClick={() => window.print()}>
                                    <BsPrinterFill />
                                  </span>
                                </Link>
                                <Link
                                  to={passInfo?.is_passes_for === null ? "/user/ManageOwnerPasses" :
                                    "/user/Vend_OwnPassesPrintout"}
                                  state={{ id: passInfo?.id, propertyId: passInfo?.property_id, isEdit: true }}
                                  className="btn btn-success btn-sm mr-1"
                                  data-tooltip-id="detail"
                                  data-tooltip-content="Show Pass Receipt"
                                >
                                  <FaEye />
                                </Link>
                              </div>

                            </div>
                            <div className="text-center py-3">
                              <h3>{passInfo?.unit?.unit_name}</h3>
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <tr>
                              <td>
                                <p className="text-uppercase p-0 my-1 mr-5 d-inline">
                                  order status
                                </p>
                              </td>
                              <th>
                                {passStatus === "current" || passStatus === "future" ? (
                                  <span className="text-success">{passStatus}</span>
                                ) : (
                                  <span className="text-danger">{passStatus}</span>
                                )}
                              </th>
                            </tr>
                            <tr>
                              <td>
                                <p className="text-uppercase p-0 my-1 mr-5 d-inline">
                                  order number
                                </p>
                              </td>
                              <th>{passInfo?.order_uuid}</th>
                            </tr>
                            <tr>
                              <td>
                                <p className="text-uppercase p-0 my-1 mr-5 d-inline">
                                  Vaild From
                                </p>
                              </td>
                              <th>
                                {dayjs(passInfo?.arrival_date).format(
                                  "MM/DD/YYYY"
                                )}
                              </th>
                            </tr>
                            <tr>
                              <td>
                                <p className="text-uppercase p-0 my-1 mr-5 d-inline">
                                  Vaild To
                                </p>
                              </td>
                              <th>
                                {dayjs(passInfo?.departure_date).format(
                                  "MM/DD/YYYY"
                                )}
                              </th>
                            </tr>
                            <tr>
                              <td>
                                <p className="text-uppercase p-0 my-1 mr-5 d-inline">
                                  Total Amount
                                </p>
                              </td>
                              <th> ${Number(0).toFixed(2)}</th>
                            </tr>
                          </div>
                          <div className="col-lg-4">
                            <tr>
                              <td>
                                <p className="text-uppercase p-0 my-1 mr-5 d-inline">
                                  {
                                    passInfo?.is_passes_for === 'Owner' ? "Owner Email" : "Vendor Email"
                                  }
                                </p>
                              </td>
                              <th>{passInfo?.is_passes_for === 'Owner' ? (passInfo?.unit?.email) : (passInfo?.vendor?.poc_email)}</th>
                            </tr>
                            <tr>
                              <td>
                                <p className="text-uppercase p-0 my-1 mr-5 d-inline">
                                  {
                                    passInfo?.is_passes_for === 'Owner' ? "Owner Name" : "Vendor Name"
                                  }
                                </p>
                              </td>
                              <th>{passInfo?.is_passes_for === 'Owner' ? (passInfo?.unit?.firstname + " " + passInfo?.unit?.lastname) :
                                (passInfo?.vendor?.point_of_fname + ' ' + passInfo?.vendor?.point_of_lname)}
                                { }</th>
                            </tr>
                            <tr>
                              <td>
                                <p className="text-uppercase p-0 my-1 mr-5 d-inline">
                                  {
                                    passInfo?.is_passes_for === 'Owner' ? "Owner Phone" : "Vendor Phone"
                                  }
                                </p>
                              </td>
                              <th>{passInfo?.is_passes_for === 'Owner' ? passInfo?.unit?.phone :
                                passInfo?.vendor?.phone}</th>
                            </tr>
                          </div>
                          <div className="col-lg-4">
                            {passInfo?.issuccess && (
                              <div className="text-center">
                                <img
                                  src={passInfo?.qr_code}
                                  width={160}
                                  alt=""
                                  srcSet=""
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </>

                </div>
              </div>
            </section>
          </main>
        </div>
      )}
    </>
  );
};

export default SuccessPaymentView;
