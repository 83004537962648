import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { toast } from "react-toastify";
import Api from "../../../Config/Api";
import { Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import Loader from "../../Elements/Loader";

const PriceForm = () => {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();
  const [isLoading, setLoading] = useState(false);
  // const [category, setCategory] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const isAdmin = useSelector((state) => state.login.isAdmin);
  const [isLoader, setIsLoader] = useState(location?.state?.isEdit ? true : false);

  const onSubmit = async (d) => {


    const priceData = {
      pass_name: d.pass_name,
      start_date: d.start_date,
      stop_date: d.stop_date,
      base_amount: d.base_amount,
      pass_type_alias: d.pass_type_alias,
      extra_info: d.extra_info,
      status: d.status,

      property_id:
        !Boolean(location?.state?.id) && !location?.state?.id
          ? location?.state?.propertyId
          : d?.property_id,
    };
    console.log("d", d);
    if (!Boolean(location?.state?.id) && !location?.state?.id) {
      try {

        var { data } = await Api("addUpdatePricing", "POST", priceData);
        if (data?.status) {
          setLoading(false);
          toast.success(data.messsage, {
            position: toast.POSITION.TOP_RIGHT,
          });
          navigate("/user/PassPricingListing/" + btoa(!location?.state?.id
            ? location?.state?.propertyId
            : d?.property_id));
        }
      } catch (error) {
        if (Boolean(error?.data?.errors)) {
          toast.error(error?.data?.errors?.email[0]);
        }
      }
    } else {
      priceData.id = location?.state?.id;
      var res = await Api("addUpdatePricing", "POST", priceData);
      if (res?.data?.status) {
        setLoading(false);
        toast.success(res?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        navigate("/user/PassPricingListing/" + btoa(!location?.state?.id
          ? location?.state?.propertyId
          : d?.property_id));
        reset();
      }
    }
    console.log('d', d);
  };

  const getModifyPriceData = async () => {
    const { data } = await Api("getPassPrice", "POST", {
      priceId: location?.state?.id,
    });

    const modifiedData = {
      ...data.priceData,
      // start_date: dayjs(data?.priceData?.start_date).format("MM/DD/YYYY"),
      // stop_date: dayjs(data?.priceData?.stop_date).format("MM/DD/YYYY"),
    };
    if (data?.status) {
      console.log('modifiedData', modifiedData);
      reset(modifiedData);
      setIsLoader(false);
    }
  };

  useEffect(() => {
    if (!!location?.state?.id) {
      getModifyPriceData();
    }
  }, [location?.state?.id]);

  const [selectedImage, setSelectedImage] = useState(null);
  const [Image, setImage] = useState(null);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImage(file);
    const reader = new FileReader();

    reader.onload = (event) => {
      setSelectedImage(event.target.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  return (
    <>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">
                  {location?.state?.isEdit
                    ? "Edit Pass Price"
                    : "Add Pass Price"}
                </h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/"
                      className={location.pathname === "/" ? "breadcrumb-active" : "breadcrumb-not-active"}
                    >
                      Home
                    </Link>
                    {isAdmin ? (
                      <>
                        <span className="breadcrumb-arrow">&gt;</span>
                        <Link to={isAdmin ? ("/user/PropertyListing") : ("/user/ManagementCoPropertyList/MTEz")}
                          className={isAdmin ? (location.pathname.startsWith("/user/PropertyListing") ? "breadcrumb-active" : "breadcrumb-not-active") : (location.pathname.startsWith("/user/ManagementCoPropertyList/MTEz") ? "breadcrumb-active" : "breadcrumb-not-active")}
                        >
                          Property
                        </Link>
                      </>
                    ) : ("")}
                    <span className="breadcrumb-arrow">&gt;</span>
                    <Link to={"/user/PassPricingListing/" + btoa(location?.state?.propertyId)}
                      className={location.pathname.startsWith("/user/PassPricingListing/" + btoa(location?.state?.propertyId)) ? "breadcrumb-active" : "breadcrumb-not-active"}
                    >
                      Pass Pricing
                    </Link>
                  </li>
                  <span className="breadcrumb-arrow">&gt;</span>
                  <li className="breadcrumb-item active">
                    {location?.state?.isEdit
                      ? "Edit Pass Price"
                      : "Add Pass Price"}
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>

        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card card-primary">
                  <div
                    className={`card-header ${isAdmin ? "adminColor" : "cambg-secondary"
                      }`}
                  >
                    <h3 className="card-title">
                      {location?.state?.isEdit
                        ? "Edit Pass Price"
                        : "Add Pass Price"}
                    </h3>
                  </div>

                  {isLoader ? (
                    <Loader />
                  ) : (

                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="card-body">
                        <div className="row align-items-center">

                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="pass_name">Pass Name</label>
                              <input
                                type="text"
                                className="form-control"
                                id="pass_name"
                                placeholder="Enter Pass Name"
                                {...register("pass_name", { required: true })}
                              />
                              {errors.pass_name && (
                                <p className="text-danger">
                                  Pass Price Name is required
                                </p>
                              )}
                            </div>
                          </div>

                          <div className="col-md-3">
                            <div className="form-group">
                              <label htmlFor="start_date">Start Date</label>
                              <input
                                type="date"
                                className="form-control"
                                id="start_date"
                                {...register("start_date", { required: true })}
                              />
                              {errors.start_date && (
                                <p className="text-danger">Start Date is required</p>
                              )}
                            </div>
                          </div>

                          <div className="col-md-3">
                            <div className="form-group">
                              <label htmlFor="stop_date">End Date</label>
                              <input
                                type="date"
                                className="form-control"
                                id="stop_date"
                                {...register("stop_date", { required: true })}
                              />

                              {errors.stop_date && (
                                <p className="text-danger">
                                  Stop Date is required
                                </p>
                              )}
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="form-group">
                              <label htmlFor="pass_type_alias">Unit</label>
                              <select
                                className="form-select form-select-sm"
                                {...register("pass_type_alias", {
                                  required: true,
                                })}
                              >
                                <option value="per_stay">Per Unit</option>
                                <option value="date_specific">
                                  Date Specific
                                </option>
                                <option value="fri_sun">Fri-Sun</option>
                                <option value="7_days">7 Days</option>
                                <option value="monthly">Monthly</option>
                                <option value="hour">Hour</option>
                              </select>

                              {errors.pass_type_alias && (
                                <p className="text-danger">
                                  {errors.pass_type_alias}
                                </p>
                              )}
                            </div>
                          </div>

                          <div className="col-md-3">
                            <div className="form-group">
                              <label htmlFor="base_amount">Amount</label>
                              <input
                                type="number"
                                className="form-control"
                                id="base_amount"
                                placeholder="Enter base_amount"
                                {...register("base_amount", { required: true })}
                              />

                              {errors.base_amount && (
                                <p className="text-danger">Amount is required</p>
                              )}
                            </div>
                          </div>

                          <div className="col-md-5">
                            <div className="form-group">
                              <label htmlFor="extra_info">Action</label>
                              <select
                                className="form-select form-select-sm"
                                {...register("extra_info", {
                                  required: true,
                                })}
                              >
                                <option value="base_price">Base Price</option>
                                <option value="add_to_base">Add to Base</option>
                                <option value="per_charge_appt">
                                  Per Charge Appt.
                                </option>
                              </select>
                              {errors.extra_info && (
                                <p className="text-danger">Action is required</p>
                              )}
                            </div>
                          </div>

                          <div className="col-md-5">
                            <div className="form-group">
                              <label htmlFor="status">Status</label>
                              <select
                                className="form-select form-select-sm"
                                {...register("status", {
                                  required: true,
                                })}
                              >
                                <option value="">Select Status</option>
                                <option value="1">Active</option>
                                <option value="0">Inactive</option>

                              </select>
                              {errors.status && (
                                <p className="text-danger">Action is required</p>
                              )}
                            </div>
                          </div>


                        </div>

                        <div className="card-footer">
                          <button
                            type="submit"
                            variant="primary"
                            className={`btn btn-primary`}
                            disabled={isLoading}
                          >
                            {isLoading ? (
                              <>
                                <Spinner animation="border" size="sm" /> Please
                                wait...
                              </>
                            ) : (
                              "Submit"
                            )}
                          </button>
                          <button
                            className="btn btn-outline-light text-dark mx-3 bg-light"
                            type="button"
                            onClick={() => navigate(-1)}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </form>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default PriceForm;
