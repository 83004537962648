import React, { useEffect, useState } from "react";
import { Chart } from "react-google-charts";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Api from "../../Config/Api";
// import Cookies from "js-cookie";
import ResetPasswordModal from "../Elements/Forms/ResetPasswordModal";
import dayjs from "dayjs";
import Loader from "../Elements/Loader";
import { Link } from "react-router-dom";

const MgmtDashboard = () => {
  const navigate = useNavigate();
  const isAdmin = useSelector((state) => state.login.isAdmin);
  const propertyId = useSelector((state) => state.login.propertyId);
  const isLogin = useSelector((state) => state.login.isLogin);
  const userId = useSelector((state) => state.login.userId);
  const roleId = useSelector((state) => state.login.roleId);
  const [modalShow, setModalShow] = useState(false);
  const [vendorC, setVendorC] = useState(0);
  // const [ownerC,setownerC] = useState(0);
  const [propertyC, setpropertyC] = useState(0);
  const [manageUnitCount, setManageUnitCount] = useState([]);
  const [unitCount, setUnitCount] = useState(0);
  const [passesC, setpassesC] = useState(0);
  const [isLoader, setIsLoader] = useState(true);
  const [salePassesCount, setSalePassesCount] = useState([]);
  const getCountDetails = async () => {
    setIsLoader(true);
    try {
      const vendorCount = await Api("vendor-count", "post", !isAdmin ? { property_id: propertyId } : {});
      const propertyCount = await Api("getunitByManagementId", "POST", { "managementCo": userId });
      console.log("propertyCount", propertyCount?.data?.response);
      const passesCount = await Api("get_all_passes_for_mgmt", "POST", !isAdmin ? { id: userId } : { id: userId });
      const { data } = await Api('getUnitCountByManagementId', "POST");
      console.log("data",data);
      if (data?.status) {
        setUnitCount(0)
        data?.response?.forEach((data) => setUnitCount((prevCount) => Number(prevCount) + Number(data?.count)));
        const transformedData = data?.response?.map((data) => [data?.property?.name, data?.count]);
        const headerRow = ['Property Name', 'Count'];
        transformedData.unshift(headerRow);
        setManageUnitCount(transformedData);
        setIsLoader(false);

      }

      const res = await Api('getPassSalesLastSevenDays', "POST");
      const lastSevenDaysSalePasses = res?.data;
      if (lastSevenDaysSalePasses?.status) {
        const transformedData = Object.values(lastSevenDaysSalePasses?.response).map((data, i) => {
          return [
            dayjs(data?.Vehiclepass?.[0]?.created_at).format('dddd, MMMM DD'),
            data?.count,
          ];

        });
        console.log("transformedData", transformedData);
        const headerRow = ['vehicle Pass Created', 'Passes Count'];
        transformedData.unshift(headerRow);
        transformedData.created_at = data?.Vehiclepass?.created_at;
        setSalePassesCount(transformedData);
        setIsLoader(false);
      }

      if (vendorCount?.data?.status || propertyCount?.data?.status || passesCount?.data?.status) {
        setVendorC(vendorCount?.data?.data)
        const uniquePropertyIds = new Set();

        const ProDataC = propertyCount?.data?.response.filter((data) => {
          if (data?.units?.property && !uniquePropertyIds.has(data.units.property.id)) {
            uniquePropertyIds.add(data.units.property.id);
            return true;
          }
          return false;
        });

        console.log("ProDataC", ProDataC.length);
        setpropertyC(ProDataC.length);
        setpassesC(passesCount?.data?.data?.length)
      }
    } catch (error) {
      // Handle errors
      console.error('Error fetching APIs:', error);
    }
  };

  useEffect(() => {
    if (isLogin) { getCountDetails() };
  }, []);


  const getModifyCamData = async () => {
    const { data } = await Api("cam-get-user", "POST", {
      id: userId,
    });
    const CamData = data?.data;
    setModalShow(
      Number(CamData?.isChanged) === 1 || Number(CamData?.isChanged) === 0 ? false : true
    );
  };

  useEffect(() => {
    getModifyCamData();
  }, [userId]);



  //  const data = [
  //   ["", ""],
  //   ["Saturday, July 03", 300],
  //   ["Saturday, July 03", 400],
  //   ["Saturday, July 03", 500],
  //   ["Saturday, July 03", 660],
  //   ["Saturday, July 03", 1000],
  //   ["Saturday, July 03", 800],
  //   ["Saturday, July 03", 700],
  // ];

  const options = {
    chart: {
      title: "",
      subtitle: "",
    },
    colors: ["#007bff"],
  };

  // const data2 = [
  //   ["", ""],
  //   ["Saturday, July 03, 2021", 1000],
  //   ["Saturday, July 03, 2021", 1170],
  //   ["Saturday, July 03, 2021", 660],
  //   ["Saturday, July 03, 2021", 1030],
  //   ["Saturday, July 03, 2021", 1000],
  //   ["Saturday, July 03, 2021", 1170],
  //   ["Saturday, July 03, 2021", 1030],
  //   ["Saturday, July 03, 2021", 1000],
  //   ["Saturday, July 03, 2021", 1170],
  //   ["Saturday, July 03, 2021", 660],
  //   ["Saturday, July 03, 2021", 1030],
  //   ["Saturday, July 03, 2021", 1000],
  //   ["Saturday, July 03, 2021", 1170],
  //   ["Saturday, July 03, 2021", 1030],
  // ];

  // const options2 = {
  //   chart: {
  //     title: "",
  //     subtitle: "",
  //   },
  //   colors: ["#007bff"],
  // };


  // const data3 = [
  //   // manageUnitCount?.map((data,i) => {
  //   //   [data?.count]
  //   // })
  //   // // ["", "", "", "",""],
  //   // // ["Handi Cap", 1000, 400, 200,300],
  //   // // ["Regular", 1170, 460, 250,500],
  // ];

  const options3 = {
    chart: {
      title: "",
      subtitle: "",
    },
    colors: ["#007bff"],
  };




  const handleChartClick = (event) => {
    const selection = event.chartWrapper.getChart().getSelection();
    if (selection.length === 0) return;
    const selectedItem = manageUnitCount[selection[0].row + 1];
    const propertyId = selectedItem[0];
    // console.log('Property ID clicked:', propertyId);
    navigate('/user/PropertyDetail', {
      state: {
        id: propertyId
      },
    });
  };

  const handleSalePassClick = (event) => {
    console.log("event", event);
    setIsLoader(true);
    const selection = event.chartWrapper.getChart().getSelection();
    if (selection.length === 0) return;
    console.log("salePassesCount", salePassesCount);

    const selectedItem = salePassesCount[selection[0].row + 1];
    const passDate = selectedItem[0];
    console.log("passDate", passDate);
    navigate('/user/PassDetailByDate', {
      state: {
        created_at: passDate
      },
    }
    );
    setIsLoader(false);

  };


  return (
    <>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Management Dashboard</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Dashboard v1</li>
                </ol>
              </div>
            </div>
          </div>
        </div>

        <section className="content">
          <ResetPasswordModal
            show={modalShow}
            onHide={() => setModalShow(false)}
          />

          <div className="container-fluid">
            <div className="row">

              <div className={`col-lg-${isAdmin ? 3 : 4} col-6`}>


                <div className="small-box bg-info">
                  <div className="inner">
                    <h3>{propertyC}</h3>

                    <p>Property</p>
                  </div>
                  <div className="icon">
                    <i className="ion ion-bag"></i>
                  </div>
                  <span className="small-box-footer" ><Link
                    to={`/user/ManagementCoPropertyList/${btoa(userId)}`}


                    data-tooltip-id="detail"
                    data-tooltip-content="Property Detail"
                    className="text-white"
                  >
                    More Info
                  </Link> </span>
                </div>

              </div>

              {/* <div className={`col-lg-${isAdmin ? 3 : 4} col-6`}>

                <div className="small-box bg-success">
                  <div className="inner">
                    <h3>{vendorC}</h3>

                    <p>Vendor</p>
                  </div>
                  <div className="icon">
                    <i className="ion ion-stats-bars"></i>
                  </div>
                  <span className="small-box-footer" ><Link
                    to={`/user/VendorListing/${btoa(userId)}`}


                    data-tooltip-id="detail"
                    data-tooltip-content="Property Detail"
                    className="text-white"
                  >
                    More Info
                  </Link> </span>
                </div>
              </div> */}

              <div className={`col-lg-${isAdmin ? 3 : 4} col-6`}>

                <div className="small-box bg-warning">
                  <div className="inner">
                    <h3>{unitCount}</h3>

                    <p>Units</p>
                  </div>
                  <div className="icon">
                    <i className="ion ion-person-add"></i>
                  </div>
                  <span to={'/OwnerListing'} className="small-box-footer" ><Link
                    to={`/user/GraphOwnerListing`}


                    data-tooltip-id="detail"
                    data-tooltip-content="Property Detail"
                    className="text-white"
                  >
                    More Info
                  </Link> </span>
                </div>
              </div>

              <div className={`col-lg-${isAdmin ? 3 : 4} col-6`}>

                <div className="small-box bg-danger">
                  <div className="inner">
                    <h3>{passesC}</h3>

                    <p>Vehicle Passes</p>
                  </div>
                  <div className="icon">
                    <i className="ion ion-pie-graph"></i>
                  </div>
                  <a className="small-box-footer"><Link
                    to={`/user/PassRequest/${btoa(userId)}`}

                    data-tooltip-id="detail"
                    data-tooltip-content="Property Detail"
                    className="text-white"
                  >
                    More Info
                  </Link> <i className="fas fa-arrow-circle-right"></i></a>
                </div>
              </div>

            </div>

            <div className="row">
              <section className="col-lg-6 connectedSortable">
                <div className="card">
                  <div className={`card-header ${isAdmin || Number(atob(roleId)) === 4 ? 'adminColor' : ''}`}>
                    <h5 className="card-title">
                      <i className="fas fa-chart-bar mr-1"></i>
                      Current Week
                    </h5>
                  </div>
                  <div className="card-body">
                    <div className="tab-content p-0">
                      {isLoader ? (
                        <Loader />
                      ) : (
                        <Chart
                          chartType="Bar"
                          width="100%"
                          height="400px"
                          data={salePassesCount}
                          options={options}
                          chartEvents={[
                            {
                              eventName: 'select',
                              callback: handleSalePassClick,
                            },
                          ]}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </section>

              {/* <section className="col-lg-6 connectedSortable">
                <div className="card">
                  <div className={`card-header ${isAdmin ? 'adminColor' : ''}`}>
                    <h3 className="card-title">
                      <i className="fas fa-chart-bar mr-1"></i>
                      Upcoming Two weeks
                    </h3>
                  </div>
                  <div className="card-body">
                    <div className="tab-content p-0">
                      {isLoader ? (
                        <Loader />
                      ) : (
                        <Chart
                          chartType="Bar"
                          width="100%"
                          height="400px"
                          data={data2}
                          options={options2}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </section> */}

              <section className="col-lg-6 connectedSortable">
                <div className="card">
                  <div className={`card-header ${isAdmin || Number(atob(roleId)) === 4 ? 'adminColor' : ''}`}>
                    <h5 className="card-title">
                      <i className="fas fa-chart-bar mr-1"></i>
                      Today
                    </h5>
                  </div>
                  <div className="card-body">
                    <div className="tab-content p-0">
                      {isLoader ? (
                        <Loader />
                      ) : (

                        <Chart
                          chartType="Bar"
                          width="100%"
                          height="400px"
                          data={manageUnitCount}
                          options={options3}
                          chartEvents={[
                            {
                              eventName: 'select',
                              callback: handleChartClick,
                            },
                          ]}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </section>

              {/* <section className="col-lg-6 connectedSortable">
                <div className="card">
                  <div className={`card-header ${isAdmin ? 'adminColor' : ''}`}>
                    <h3 className="card-title">
                      <i className="fas fa-chart-pie mr-1"></i>
                      Revenue
                    </h3>
                  </div>

                  {isLoader ? (
                  <Loader />
                ) : (
                  <div className="card-body">
                    <div className="tab-content p-0">
                      <div className="table_div">
                        <table className="table w-100">
                          <tbody>
                            <tr>
                              <td>This Week (Forecast)</td>
                              <td>$4,600</td>
                            </tr>
                            <tr>
                              <td>Last Week</td>
                              <td>$4,260</td>
                            </tr>
                            <tr>
                              <td>This Week (ForeCast)</td>
                              <td>$31,395</td>
                            </tr>
                            <tr>
                              <td>Month to Date</td>
                              <td>$4,600</td>
                            </tr>
                            <tr>
                              <td>Month (Forecast)</td>
                              <td>$18,040</td>
                            </tr>
                            <tr>
                              <td>Year to Date</td>
                              <td>$146,840</td>
                            </tr>
                            <tr>
                              <td>YTD vs Last Year</td>
                              <td>2.40%</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                )}
                </div>
              </section> */}
            </div>
          </div>
        </section>
      </div>
    </>
  )
}

export default MgmtDashboard