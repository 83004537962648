import React from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import Loader from "./Elements/Loader";
import { FaEdit, FaTrashAlt } from 'react-icons/fa';
import { Tooltip } from 'react-bootstrap';

const ServiceCategory = () => {
  const location = useLocation();
  const isAdmin = useSelector((state) => state.login.isAdmin);
  const roleId = useSelector((state) => state.login.roleId);
  const [isLoader, setIsLoader] = useState(false);

  return (
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              {<h1 className="m-0">Service Category</h1>}
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/"
                    className={location.pathname === "/" ? "breadcrumb-active" : "breadcrumb-not-active"}
                  >
                    Home
                  </Link>
                  <span className="breadcrumb-arrow">&gt;</span>
                  <Link to="/user/PropertyListing"
                    className={location.pathname.startsWith("/user/PropertyListing") ? "breadcrumb-active" : "breadcrumb-not-active"}
                  >
                    Property
                  </Link>
                </li>
                <span className="breadcrumb-arrow">&gt;</span>
                <li className="breadcrumb-item active">Service Category
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>

      <section className="content">
        <div className="container-fluid">

          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className={`card-header ${isAdmin || Number(atob(roleId)) === 4  ? "adminColor" : "cambg-secondary"}`}>
                  <div className="add_new_vendor_listing">
                    <div>
                      <h3 className="card-title">Service Category </h3>
                    </div>
                    <div className="">
                    {isAdmin ? (
                      <Link
                        to={"/user/PriceForm"} 
                        className="btn btn-light text-dark btn-sm bg-light mx-2"
                      >
                        Add Service Category
                      </Link>
                    ) : ("")
                    }
                    </div>
                  </div>
                </div>
                {isLoader ? (
                  <Loader />
                ) : (
                  <div className="card-body table-responsive">
                    {/* <InfiniteScroll
                      dataLength={camList.length}
                      next={handleNext}
                      hasMore={camList.length > priceData.length}
                      loader={<Loader />}
                    > */}
                      <table className="table table-bordered">
                        <thead className="border-top">
                          <tr>
                            <th>Service Category</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Contact No.</th>
                            <th>Address</th>
                            <th>City</th>
                            <th>State</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                              <tr>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>

                                  <div className="">

                                    <Link
                                      to="/user/PriceForm"
                                      state={{
                                        // id: price?.id,
                                        isEdit: true,
                                      }}
                                      className="btn  btn-primary btn-sm mr-1"
                                      data-tooltip-id="edit"
                                      data-tooltip-content="Edit"
                                    >
                                      <FaEdit />
                                    </Link>
                                    <Tooltip id="edit" place="bottom" />
                                    <button
                                      // onClick={() => handleDelete(price.id)}
                                      className="btn  btn-danger btn-sm mr-1"
                                      data-tooltip-id="my-tooltip"
                                      data-tooltip-content="Delete"
                                    >
                                      <FaTrashAlt />
                                    </button>
                                    <Tooltip id="my-tooltip" place="bottom" />
                                  </div>

                                </td>
                              </tr>
             
                            {/* <tr>
                              <td colSpan={8}>
                                <p className="text-center">
                                  No record found.
                                </p>
                              </td>
                            </tr> */}
                        

                        </tbody>
                      </table>
                    {/* </InfiniteScroll> */}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default ServiceCategory