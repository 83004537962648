import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import { getProperty } from "../../../Reducers/RenterProperty";
import Modal from "react-bootstrap/Modal";
import {
  PaymentElement,
  LinkAuthenticationElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { useSelector } from "react-redux";

export default function CheckoutForm({
  handleClose,
  show,
  billingId,
  emailId,
}) {

  const stripe = useStripe();
  const [prefilledEmail, setPrefilledEmail] = useState("example@example.com");
  const elements = useElements();
  const RenterProperty = useSelector(getProperty);
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  console.log("RenterProperty", RenterProperty);
  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );
    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);
    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url:
          process.env.REACT_APP_URL + `SuccessPayment?billingId=${billingId}`,
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    console.log("error", error);
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occurred.");
    }

    setIsLoading(false);
  };

  const paymentElementOptions = {
    layout: "tabs",
  };

  return (
    <>
      <Modal
        backdrop="static"
        keyboard={false}
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <h5 className="px-2 pb-0 mb-0">Payment Details</h5>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-lg-6">

              <div className="card p-3">
                <div className="py-3 text-center">
                  <h5>Property</h5>

                  <span>{RenterProperty?.name}</span>
                </div>
                <div className="text-center rounded-3" style={{ background: RenterProperty?.primary_color }}>
                  <img src={RenterProperty?.logo
                    ? process.env.REACT_APP_AWS_S3_BUCKET_URL +
                    "images/" + RenterProperty?.logo
                    : ""} alt="" srcset="" className="w-50" />
                </div>
              </div>

            </div>
            <div className="col-lg-6">
              <div className="p-3">
                <form id="payment-form" onSubmit={handleSubmit}>
                  <LinkAuthenticationElement
                    id="link-authentication-element"
                    defaultEmail={prefilledEmail}
                    onChange={(e) => setEmail(e?.target?.value)}
                  />
                  <PaymentElement
                    id="payment-element"
                    options={paymentElementOptions}
                  />
                  <div className="text-center">
                    <button
                      disabled={isLoading || !stripe || !elements}
                      id="submit"
                      className="btn btn-primary my-3 btn-lg"
                    >
                      <span id="button-text">
                        {isLoading ? (
                          <div className="spinner" id="spinner"></div>
                        ) : (
                          "Pay now"
                        )}
                      </span>
                    </button>
                  </div>
                  {/* Show any error or success messages */}
                  {message && <div id="payment-message">{message}</div>}
                </form>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
