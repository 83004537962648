import React from "react";
import aqua_white from "../../../assets/img/AdminLTELogo.png";
import { Dropdown } from "react-bootstrap";
import userImg from "../../../assets/img/user.svg";
import { getRoleName } from "../../../Utility/Helpers";
import { useSelector, useDispatch } from "react-redux";
import { removeUser } from "../../../Reducers/Login";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { capitalizeFirstLetter } from "../../../Utility/Helpers";
import { BsFillCartPlusFill } from "react-icons/bs";

const RenterHeader = ({ propertyData }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLogin = useSelector((state) => state.login.isLogin);
  const userName = useSelector((state) => state.login.name);
  const roleId = useSelector((state) => state.login.roleId);
  const cart = useSelector((state) => state.cart);
  const logo = useSelector((state) => state.login.logo);
  const logout = () => {
    dispatch(removeUser());
    toast.success("Logout Successfully!", {
      position: toast.POSITION.TOP_RIGHT,
    });
    navigate("/Login");
  };

  return (
    <>
      <header className="renter_header cambg-primary">
        <div className="container-fluid px-lg-5 py-2">
          <div className="row align-items-center">
            <div className="col-md-5 col-3">
              <div className={`logo py-2`}>
                <img
                  src={
                    propertyData?.logo
                      ? process.env.REACT_APP_AWS_S3_BUCKET_URL +
                        "images/" +
                        propertyData?.logo
                      : aqua_white
                  }
                  className="img-fluid"
                  width={80}
                />
              </div>
            </div>
            {isLogin ? (
              <>
                <div className="col-lg-7 col-9">
                  {/* <div className="">
                    <ul className="list-unstyled d-flex justify-content-end align-items-center gap-2 m-0">
                      <li className="nav-item">
                        <Dropdown align="end">
                          <Dropdown.Toggle variant="" className="d-flex align-items-center p-0 gap-2">
                            <div className="d-inline-flex gap-2">
                              <div className="flex-shrink-0">
                                <img
                                  src={
                                    logo
                                      ? process.env.REACT_APP_API_URL +
                                        "images/" +
                                        logo
                                      : userImg
                                  }
                                  height={40}
                                  width={40}
                                  alt="user"
                                  style={{ borderRadius: "30px" }}
                                />
                              </div>
                              <div className="flex-grow-1">
                                <div>
                                  <b> {capitalizeFirstLetter(userName)} </b>
                                </div>
                                <div className="small">
                                  {getRoleName(atob(roleId))}
                                </div>
                              </div>
                            </div>
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item as={Link} to="Editprofile">
                          
                                Update Profile
                            </Dropdown.Item>
                            <Dropdown.Item as="button" onClick={logout}>
                              Logout
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </li>
                    </ul>
                  </div> */}

                  <div className="d-flex justify-content-end align-items-center">
                    <Dropdown align="end" className="border-0">
                  
                      <Dropdown.Toggle variant="" className="p-3 rounded-pill btn btn-outline-light renter_profile_drop" id="dropdown-basic">
                        <img
                          src={
                            logo
                              ? process.env.REACT_APP_AWS_S3_BUCKET_URL+
                        "images/" + logo
                              : userImg
                          }
                          alt="User"
                          style={{
                            width: "30px",
                            height: "30px",
                            borderRadius: "50%",
                            marginRight: "10px",
                          }}
                        />
                        <b> {capitalizeFirstLetter(userName)} </b> ({" "}
                        {getRoleName(atob(roleId))})
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                      <Dropdown.Item as={Link} to={Number(atob(roleId)) === 2 ? ("/user") : ("/Security")}>
                          Home
                        </Dropdown.Item>
                         
                        <Dropdown.Item as={Link} to="Editprofile">
                          Profile
                        </Dropdown.Item>
                        <Dropdown.Item as="button" onClick={logout}>
                          Logout
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="col-md-7">
                  <div className="login_div text-end">
                    <Link to={"/Login"} className="btn btn-light">
                      Login
                    </Link>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </header>
    </>
  );
};

export default RenterHeader;
