import React, { useEffect, useState } from "react";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { toast } from "react-toastify";
import Api from "../../../Config/Api";
import { Button, Spinner } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { fetchState, getState } from "../../../Reducers/State";
import Select from "react-select";
import { BsFillCameraFill } from "react-icons/bs";
import upload_image from "../../../assets/img/upload_image.png";
import { v4 as uuidv4 } from "uuid";

const OwnerForm = ({ setPropertyId, setcurrentStep }) => {
  const defaultExpirationDate = new Date();
  defaultExpirationDate.setFullYear(defaultExpirationDate.getFullYear() + 1);
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      expiration_date: defaultExpirationDate.toISOString().slice(0, 10),
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "rule",
  });

  const {
    fields: warningFields,
    append: appendWarningFields,
    remove: removeWarningFields,
  } = useFieldArray({
    control,
    name: "warning",
  });

  const {
    fields: refundableField,
    append: appendrefundableField,
    remove: removerefundableField,
  } = useFieldArray({
    control,
    name: "refundable",
  });

  const {
    fields: parkingRuleField,
    append: appendparkingRuleField,
    remove: removeparkingRuleField,
  } = useFieldArray({
    control,
    name: "parkingRule",
  });

  const [isLoading, setLoading] = useState(false);
  //   const [camList, setCamList] = useState([]);
  const [propertyData, setPropertyData] = useState([]);
  const [stateCode, setStateCode] = useState("");
  const [selectedState, setSelectedState] = useState("");
  // const [camList, setCamList] = useState([]);
  // const [selectedOption, setSelectedOption] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedLogo, setSelectedLogo] = useState(null);
  const [Image, setImage] = useState(null);
  const [Logo, setLogo] = useState(null);
  const [images, setImages] = useState([]);
  const [ruleAndRegulations, setRuleAndRegulations] = useState([]);
  const [ruleTypes, setRuleTypes] = useState([]);
  const [warnRuleTypes, setWarnRuleTypes] = useState([]);
  const [nonRefundableRuleTypes, setNonRefundableRuleTypes] = useState([]);
  const [warnImage, setWarnImage] = useState([]);
  const [warnSelectedImage, setWarnSelectedImage] = useState(null);

  const [showInput, setShowInput] = useState(false);
  const [selectOption, setSelectOption] = useState(true);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const isAdmin = useSelector((state) => state.login.isAdmin);
  const stateData = useSelector(getState);
  const [refundable, setRefundable] = useState("");

  const expire_data =
    !Boolean(location?.state?.id) && !location?.state?.id
      ? defaultExpirationDate.toISOString().slice(0, 10)
      : propertyData?.expiration_date;

  const [expirationDate, setExpirationDate] = useState(expire_data);

  const handleExpirationDateChange = (e) => {
    const selectedDate = e.target.value;
    const expirationDate = new Date(selectedDate);
    expirationDate.setFullYear(expirationDate.getFullYear() + 1);
    setExpirationDate(expirationDate.toISOString().slice(0, 10));
  };

  const handleOptionChange2 = (events) => {
    const selectedValue = events.target.value;
    console.log("selectedValue", selectedValue);
    if (selectedValue) {
      setSelectOption(false)
      setShowInput(true);

    } else if (selectedValue === "") {
      setSelectOption(true)
      setShowInput(false);
    }
  };


  const handleChange = (selectedOption) => {
    setSelectedState(selectedOption.value); // selected value
    setStateCode(selectedOption["data-key"]); // data-key attribute
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0]; // Get the selected file from input
    setImage(file);
    const reader = new FileReader(); // Create a FileReader instance

    reader.onload = (event) => {
      // On successful reading of file
      setSelectedImage(event.target.result); // Set the selected image data as state
    };

    if (file) {
      reader.readAsDataURL(file); // Read the file as Data URL
    }
  };

  const handleWarningImageChange = (e) => {
    const file = e.target.files[0];
    setWarnImage(file);
    const reader = new FileReader();
    reader.onload = (event) => {
      setWarnSelectedImage(event.target.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleLogoChange = (e) => {
    const file = e.target.files[0]; // Get the selected file from input
    setLogo(file);
    const reader = new FileReader(); // Create a FileReader instance

    reader.onload = (event) => {
      // On successful reading of file
      setSelectedLogo(event.target.result); // Set the selected image data as state
    };

    if (file) {
      reader.readAsDataURL(file); // Read the file as Data URL
    }
  };

  const onSubmit = async (data) => {
    setLoading(true);
    const PropertyFormData = new FormData();
    PropertyFormData.append("name", data.name);
    PropertyFormData.append("status", data.status);
    PropertyFormData.append("address", data.address);
    PropertyFormData.append("city", data.city);
    PropertyFormData.append("state", data.state.value);
    PropertyFormData.append("state_code", data.state["data-key"]);
    PropertyFormData.append("zip", data.zip);

    PropertyFormData.append("buy_pass_url", data.buy_pass_url);
    if (data?.warningImg?.length) {
      PropertyFormData.append(`warningImg`, data.warningImg[0]);
    }
    if (isAdmin) {

      for (const key in data.warning) {
        for (const key2 in data.warning[key]) {
          PropertyFormData.append(
            `warning[${key}][${key2}]`,
            data.warning[key][key2]
          );
        }
      }

      PropertyFormData.append("refundable", JSON.stringify(data.refundable));
      PropertyFormData.append("rule", JSON.stringify(data.rule));
      PropertyFormData.append("status", data.status);
      PropertyFormData.append("camID", data.camID);
      PropertyFormData.append("expiration_date", data.expiration_date);
      PropertyFormData.append("isAdmin", true);
    } else {
      PropertyFormData.append("isAdmin", false);
    }
    
    // console.log("data.refund_percent", Boolean(data.refund_percent));
    if (Boolean(data.refund_percent) === true) {
      PropertyFormData.append("refund_percent", data.refund_percent);

    }

    // if (Boolean(data?.is_refund) === true) {
    //   PropertyFormData.append("is_refund", data?.is_refund);
    //   PropertyFormData.append("refund_percent", data?.refund_percent);
    // }

    PropertyFormData.append("primarycolor", data.primary_color);
    PropertyFormData.append("secondarycolor", data.secondary_color);
    if (Image) {
      PropertyFormData.append("bg_image", Image);
    }
    if (Logo) {
      PropertyFormData.append("logo", Logo);
    }
    if (!Boolean(location?.state?.id) && !location?.state?.id) {
      var res = await Api("add-property", "POST", PropertyFormData);
    } else {
      PropertyFormData.append("id", location?.state?.id);
      var res = await Api("update-property", "POST", PropertyFormData);
      // console.log("PropertyFormData", PropertyFormData);
    }
    if (res?.data?.status) {
      setLoading(false);
      setPropertyId(res?.data?.result?.id);
      const OnboardingData = {
        currentStep: 2,
        propertyId: res?.data?.result?.id
      }
      // Cookies.set("currentStep", JSON.stringify(OnboardingData));
      localStorage.setItem('currentStep', JSON.stringify(OnboardingData));
      setcurrentStep(2)
      toast.success(res?.data?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });

    } else if (Number(res?.data?.status) === 0) {
      setLoading(false);
      toast.error(res?.data?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const getModifyProperty = async () => {
    const { data } = await Api("get-property", "POST", {
      id: location?.state?.id,
    });
    // const ProData = data?.data;
    // setPropertyData(ProData);
    // if (!!ProData?.cam_data) {
    //   reset({ camID: ProData?.cam_data?.id, ...ProData });
    // } else {
    //   reset(ProData);
    // }

    // console.log('data????????????', data);
    if (data.status === 1) {
      const ProData = { ...data?.data };
      delete ProData?.rules_and_regulations;
      setRuleAndRegulations(data?.data?.rules_and_regulations);

      var rulearr = data?.data?.rules_and_regulations
        ?.filter((a) => a.for_whom === 1)
        .map((b) => {
          return {
            type: b?.type,
            value: b?.rules,
            ruleId: b?.id,
          };
        });

      var warnarr = data?.data?.rules_and_regulations
        ?.filter((a) => a.for_whom === 3)
        .map((b) => {
          return {
            type: b?.type,
            value: b?.rules,
            image: b?.images,
            ruleId: b?.id,
          };
        });

      var refundarr = data?.data?.rules_and_regulations
        ?.filter((a) => a.for_whom === 2)
        .map((b) => {
          return {
            type: b?.type,
            value: b?.rules,
            ruleId: b?.id,
          };
        });

      warnarr?.forEach((item) => {
        const image = item.image;
        if (image) {
          setImages((prevImages) => [...prevImages, image]);
        }
      });

      if (!!ProData?.cam_data && ProData?.cam_data?.role_id == 3) {
        delete ProData.state;
        reset({
          camID: ProData?.cam_data?.id,
          ...ProData,
          state: {
            label: data?.data.state,
            value: data?.data.state,
            "data-key": data?.data.state_code,
          },
          images: [],
          rule: rulearr,
          warning: warnarr,
          refundable: refundarr,
        });
      } else {
        delete ProData.state;

        reset({
          ...ProData,
          state: {
            label: data?.data.state,
            value: data?.data.state,
            "data-key": data?.data.state_code,
          },
          images: [],
          rule: rulearr,
          warning: warnarr,
          refundable: refundarr,
        });
      }
      setPropertyData(ProData);
      setSelectedImage(
        ProData?.bg_image
          ? process.env.REACT_APP_AWS_S3_BUCKET_URL +
          "images/" + ProData?.bg_image
          : ""
      );
      setSelectedLogo(
        ProData?.logo
          ? process.env.REACT_APP_AWS_S3_BUCKET_URL +
          "images/" + ProData?.logo
          : ""
      );
    }

    // console.log("data>>>>>>>>>????????", data);
  };

  useEffect(() => {
    dispatch(fetchState());
  }, []);

  useEffect(() => {
    if (!!location?.state?.id) {
      getModifyProperty();
    }
  }, [location?.state?.id]);

  var options = [];
  stateData?.state?.map((state, i) => {
    options?.push({
      value: state?.name,
      label: state?.name,
      "data-key": state.code,
    });
  });

  var options = [];
  stateData?.state?.map((state, i) => {
    options?.push({
      value: state?.name,
      label: state?.name,
      "data-key": state.code,
    });
  });

  const handleRuleTypeChange = (event, index, param) => {
    if (param === "rule") {
      const updatedRuleTypes = [...ruleTypes];
      updatedRuleTypes[index] = event.target.value;
      setRuleTypes(updatedRuleTypes);
    } else if (param === "warn") {
      const updatedRuleTypes = [...warnRuleTypes];
      updatedRuleTypes[index] = event.target.value;
      setWarnRuleTypes(updatedRuleTypes);
    } else if (param === "nonrefundable") {
      const updatedRuleTypes = [...nonRefundableRuleTypes];
      updatedRuleTypes[index] = event.target.value;
      setNonRefundableRuleTypes(updatedRuleTypes);
    }
  };


  const getRefundValue = (e) => {
    console.log(e.target.value);
    setRefundable(e.target.value);
  }


  return (
    <>
      <div className="card card-primary">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="card-body">
            <div className="row align-items-center">
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="enterEnterName">Property Name</label>

                  <input
                    type="text"
                    className="form-control"
                    {...register("name", { required: true })}
                    placeholder="Enter Property Name"
                  />

                  {errors.name && (
                    <p className="text-danger">Property name is required</p>
                  )}
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="address">Address</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Address of property"
                    {...register("address", { required: true })}
                  />

                  {errors.address && (
                    <p role="alert" className="text-danger">
                      Address is required
                    </p>
                  )}
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="enterCity">City</label>
                  <input
                    type="text"
                    className="form-control"
                    id="enterCity"
                    placeholder="City"
                    {...register("city", { required: true })}
                    aria-invalid={errors.city ? "true" : "false"}
                  />

                  {errors.city && (
                    <p role="alert" className="text-danger">
                      City is required
                    </p>
                  )}
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label>State</label>
                  <Controller
                    control={control}
                    name="state"
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={options}
                        onChange={field.onChange}
                        value={field.value}
                      />
                    )}
                  />
                  {errors.state && (
                    <p className="text-danger">State Name is required</p>
                  )}

                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="enterZip">Zip</label>
                  <input
                    type="text"
                    className="form-control"
                    id="enterZip"
                    placeholder="Zip"
                    {...register("zip", { required: true })}
                    aria-invalid={errors.zip ? "true" : "false"}
                  />

                  {errors.zip && (
                    <p role="alert" className="text-danger">
                      Zip is required
                    </p>
                  )}
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="status">Status</label>
                  <select
                    className="form-select"
                    {...register("status", { required: true })}
                  >
                    <option value="">Select Status</option>
                    <option value="1">Active</option>
                    <option value="0">InActive</option>
                  </select>

                  {errors.status && (
                    <p role="alert" className="text-danger">
                      Status is required
                    </p>
                  )}
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="status">Contract Renewable Date:</label>
                  <input
                    type="date"
                    // onChange={handleExpirationDateChange}
                    className="form-control"
                    {...register("expiration_date", { required: true })}
                  />

                  {errors.status && (
                    <p role="alert" className="text-danger">
                      Membership Renewable date is required
                    </p>
                  )}
                </div>
              </div>

              <div className="col-md-6">
                <label htmlFor="buy_pass_url">Buy Pass URL</label>

                <div className="input-group mb-2">
                  <div className="input-group-prepend">
                    <div className="input-group-text">
                      {window.location.origin}/
                    </div>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    id="inlineFormInputGroup"
                    placeholder="Enter buy pass URL"
                    {...register("buy_pass_url", { required: true })}
                  />
                </div>
                {errors.buy_pass_url && (
                  <p role="alert" className="text-danger">
                    Buy Pass URL is required
                  </p>
                )}
              </div>

              <div className="col-md-12 py-3">
                <div className="point_of_contact ">
                  Refund Status
                </div>
              </div>

              <div className="col-md-6 mx-1">
                <div className="form-group">
                  <div>
                    <label>
                      <input
                        type="radio"
                        value=""

                        checked={selectOption}
                        onChange={handleOptionChange2}
                        className="mr-1"
                      />
                      Non Refundable
                    </label>

                    <label>
                      <input
                        type="radio"
                        value="input"

                        checked={!selectOption}
                        onChange={handleOptionChange2}
                        className="ml-4 mr-1"
                      />
                      Refundable
                    </label>
                  </div>


                  {/* {errors.camID && (
                                <p role="alert" className="text-danger">
                                  Refund is required
                                </p>
                              )} */}
                </div>
              </div>

              {showInput && (
                <>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="refund">Amount (in %)</label>
                      <input
                        type="text"
                        className="form-control"
                        id="refund"
                        onKeyUp={getRefundValue}
                        placeholder="Enter refund %"
                        {...register("refund_percent", {
                          required: true,
                        })}
                      />

                      {errors.refund && (
                        <p className="text-danger">
                          Refundable amount is required
                        </p>
                      )}

                      <span className="text-secondary">
                      Your refundable amount is {refundable ? refundable : propertyData?.refund_percent} %.
                      </span>
                    </div>
                  </div>

                </>
              )}

              <div className="col-md-12 py-3">
                <div className="point_of_contact pt-1">Theme Details</div>
                <span className="text-secondary">
                  These are the colors to be used in the front-end design and
                  CAM dashboard.
                </span>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="primarycolor">Choose Primary Color</label>
                  <input
                    type="color"
                    className="form-control"
                    id="primarycolor"
                    {...register("primary_color", { required: true })}
                    defaultValue={"#1A8B93"}
                  />

                  {errors.primary_color && (
                    <p role="alert" className="text-danger">
                      Primary Color is required
                    </p>
                  )}
                </div>
              </div>

              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="secondarycolor">Choose Secondary Color</label>
                  <input
                    type="color"
                    className="form-control"
                    id="secondarycolor"
                    {...register("secondary_color", { required: true })}
                    defaultValue={"#B51717"}
                  />

                  {errors.secondary_color && (
                    <p role="alert" className="text-danger">
                      Secondary Color is required
                    </p>
                  )}
                </div>
              </div>

              <div className="row">
                <div className="col-md-3 pb-2">
                  <div className="point_of_contact pt-1">Background Image</div>
                  <span className="text-secondary">
                    This image will be used as the background for the CAM
                    dashboard and Pass creation pages.
                  </span>
                </div>

                <div className="col-md-3 pb-2">
                  <div className="point_of_contact pt-1">Logo</div>
                  <span className="text-secondary">
                    This logo will be used as the frontend for the Pass creation
                    pages.
                  </span>
                </div>
              </div>

              <div className="col-md-3">
                <div className="form-group">
                  <div className="imagebox">
                    {selectedImage ? (
                      <label htmlFor="image">
                        <img
                          className="user-profile-pic user-img"
                          src={selectedImage}
                        />
                        <div className="floatbtn">
                          <BsFillCameraFill />
                        </div>
                      </label>
                    ) : (
                      <label htmlFor="image">
                        <img
                          className="user-profile-pic user-img"
                          src={upload_image}
                        />
                      </label>
                    )}

                    <input
                      className="user-file-upload"
                      id="image"
                      type="file"
                      accept="image/*"
                      {...register("bg_image", {
                        required: location?.state?.id ? false : true,
                        onChange: (e) => handleImageChange(e),
                      })}
                    />
                  </div>
                </div>
                {errors.bg_image && (
                  <p role="alert" className="text-danger">
                    Background Image is required
                  </p>
                )}
              </div>

              <div className="col-md-3">
                <div className="form-group">
                  <div className="imagebox">
                    {selectedLogo ? (
                      <label htmlFor="logo">
                        <img
                          className="user-profile-pic user-img"
                          src={selectedLogo}
                        />
                        <div className="floatbtn">
                          <BsFillCameraFill />
                        </div>
                      </label>
                    ) : (
                      <label htmlFor="logo">
                        <img
                          className="user-profile-pic user-img"
                          src={upload_image}
                        />
                      </label>
                    )}

                    <input
                      className="user-file-upload"
                      id="logo"
                      type="file"
                      accept="image/*"
                      {...register("logo", {
                        required: location?.state?.id ? false : true,
                        onChange: (e) => handleLogoChange(e),
                      })}
                    />
                  </div>
                </div>
                {errors.logo && (
                  <p role="alert" className="text-danger">
                    Logo is required
                  </p>
                )}
              </div>
            </div>

            <div className="card p-3">
              <div className="card-header">
                <h3 className="text-dark">Rules & Regulations</h3>
              </div>
              <div className="card-body">
                <div className="card">
                  <div className="card-header">
                    <div className="col-md-12">
                      <div className="point_of_contact pt-1 text-dark">
                        General Rules
                      </div>
                      <span className="text-secondary">
                        These are the rules and regulations related to General Rules
                      </span>
                    </div>
                  </div>

                  <div className="card-body">
                    <div className="col-lg-12">
                      {fields.map((field, index) => (
                        <div key={field.id}>
                          <div className="row">
                            <div className="col-md-3">
                              <div className="form-group">
                                <label htmlFor={`rule.${index}.type`}>
                                  Choose Rules type
                                </label>
                                <select
                                  className="form-select"
                                  aria-label="Default select example"
                                  {...register(`rule.${index}.type`, {
                                    required: true,
                                  })}
                                  onChange={(event) =>
                                    handleRuleTypeChange(
                                      event,
                                      index,
                                      "rule"
                                    )
                                  }
                                >
                                  <option value="Text">Checkbox</option>
                                  <option value="Link">Link</option>
                                </select>
                                {errors[`rule.${index}.type`] && (
                                  <p role="alert" className="text-danger">
                                    Rules type is required.
                                  </p>
                                )}
                              </div>
                            </div>

                            {ruleTypes[index] === "Link" && (
                              <div className="col-md-3">
                                <div className="form-group">
                                  <label className="form-label">
                                    Enter Rule Alias Name
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control py-2"
                                    {...register(
                                      `rule.${index}.alias_name`,
                                      {
                                        required: true,
                                      }
                                    )}
                                  />

                                  {errors[`rule.${index}.alias_name`] && (
                                    <p
                                      role="alert"
                                      className="text-danger"
                                    >
                                      Rule Alias Name is required.
                                    </p>
                                  )}
                                </div>
                              </div>
                            )}

                            <div
                              className={`col-md-${ruleTypes === "Text" ? 8 : 5
                                }`}
                            >
                              <div className="form-group">
                                <label className="form-label">
                                  {ruleTypes[index] === "Link" ? 'Enter Link' : 'Enter General Rule'}
                                </label>
                                <input
                                  type="text"
                                  className="form-control py-2"
                                  placeholder={ruleTypes[index] === "Link" ? 'It should include (http:// or https://) protocol.' : 'Enter Rule'}
                                  {...register(`rule.${index}.value`, {
                                    required: true,
                                  })}
                                />
                                <input
                                  type="hidden"
                                  className="form-control py-2"
                                  {...register(`rule.${index}.ruleId`)}
                                />

                                {errors[`rule.${index}.value`] && (
                                  <p role="alert" className="text-danger">
                                    Rule heading is required.
                                  </p>
                                )}
                              </div>
                            </div>
                            <div
                              className="col-lg-1"
                              style={{ paddingTop: "31px" }}
                            >
                              <button
                                type="button"
                                className="btn btn-primary"
                                onClick={() => remove(index)}
                              >
                                Remove
                              </button>
                            </div>
                          </div>
                        </div>
                      ))}
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => append({ id: uuidv4(), name: "" })}
                      >
                        Add Rules
                      </button>
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-header">
                    <div className="col-md-12">
                      <div className="point_of_contact pt-1 text-dark">
                        Beach Flag Warning System
                      </div>
                      <span className="text-secondary">
                        These are the rules and regulations to be used on the
                        front-end "Create Pass" page.
                      </span>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="col-lg-12">
                      {warningFields.map((field, index) => (
                        <div key={field.id}>
                          <div className="row">
                            <div className="col-md-3">
                              <div className="form-group">
                                <label htmlFor={`warning.${index}.value`}>
                                  Choose Warning type
                                </label>
                                <select
                                  className="form-select"
                                  {...register(`warning.${index}.type`, {
                                    required: true,
                                  })}
                                  onChange={(event) =>
                                    handleRuleTypeChange(
                                      event,
                                      index,
                                      "warn"
                                    )
                                  }
                                >
                                  <option value="Text">Checkbox</option>
                                  <option value="Link">Link</option>
                                </select>
                                {errors[`warning.${index}.type`] && (
                                  <p role="alert" className="text-danger">
                                    Rules type is required.
                                  </p>
                                )}
                              </div>
                            </div>

                            {warnRuleTypes[index] === "Link" && (
                              <div className="col-md-3">
                                <div className="form-group">
                                  <label className="form-label">
                                    Enter Warning Alias Name
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control py-2"
                                    {...register(
                                      `warning.${index}.alias_name`,
                                      {
                                        required: true,
                                      }
                                    )}
                                  />

                                  {errors[
                                    `warning.${index}.alias_name`
                                  ] && (
                                      <p
                                        role="alert"
                                        className="text-danger"
                                      >
                                        Warning Alias Name is required.
                                      </p>
                                    )}
                                </div>
                              </div>
                            )}

                            <div
                              className={`col-md-${warnRuleTypes === "Text" ? 8 : 5
                                }`}
                            >

                              <div className="form-group">
                                <label htmlFor="rule" className="form-label">
                                  {warnRuleTypes[index] === "Link" ? 'Enter Link' : 'Enter Warning Rule'}
                                </label>
                                <input
                                  type="text"
                                  className="form-control py-2"
                                  placeholder={warnRuleTypes[index] === "Link" ? 'It should include (http:// or https://) protocol.' : 'Enter Rule'}
                                  {...register(`warning.${index}.value`, {
                                    required: true,
                                  })}
                                />
                                <input
                                  type="hidden"
                                  className="form-control py-2"
                                  {...register(`warning.${index}.ruleId`)}
                                />
                                {errors &&
                                  errors[`warning.${index}.value`] && (
                                    <p role="alert" className="text-danger">
                                      Warning is required.
                                    </p>
                                  )}
                              </div>
                            </div>
                            <div
                              className="col-lg-1"
                              style={{ paddingTop: "31px" }}
                            >
                              <button
                                type="button"
                                className="btn btn-primary"
                                onClick={() => removeWarningFields(index)}
                              >
                                Remove
                              </button>
                            </div>
                          </div>
                        </div>
                      ))}

                      <div className="mb-3">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={() =>
                            appendWarningFields({ id: uuidv4(), name: "" })
                          }
                        >
                          Add Warning
                        </button>

                        <div className="row">
                          <div className="col-md-3">
                            <div className="form-group my-3">
                              <label htmlFor="rule" className="form-label">
                                Select Warning Image
                              </label>
                              <div className="imagebox">
                                {warnSelectedImage ? (
                                  <label htmlFor="warnImage">
                                    <img
                                      className="user-profile-pic user-img"
                                      src={warnSelectedImage}
                                    />
                                    <div className="floatbtn">
                                      <BsFillCameraFill />
                                    </div>
                                  </label>
                                ) : (
                                  <label htmlFor="warnImage">
                                    <img
                                      className="user-profile-pic user-img"
                                      src={upload_image}
                                    />
                                  </label>
                                )}

                                <input
                                  className="user-file-upload"
                                  id="warnImage"
                                  type="file"
                                  accept="image/*"
                                  {...register(`warningImg`, {
                                    onChange: (e) =>
                                      handleWarningImageChange(e),
                                  })}
                                />

                              </div>
                            </div>
                          </div>
                          <p>Click on above image to choose image</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-header">
                    <div className="col-md-12">
                      <div className="point_of_contact pt-1 text-dark">
                        Non-Refundable Rules
                      </div>
                      <span className="text-secondary">
                        These are the rules and regulations related to non-refundable rules.
                      </span>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="col-lg-12">
                      {refundableField.map((field, index) => (
                        <div key={field.id}>
                          <div className="row">
                            <div className="col-md-3">
                              <div className="form-group">
                                <label htmlFor={`refundable.${index}..type`}>
                                  Choose Rules type
                                </label>
                                <select
                                  className="form-select"
                                  aria-label="Default select example"
                                  id={`refundable.${index}.type`}
                                  {...register(`refundable.${index}.type`, {
                                    required: true,
                                  })}
                                  onChange={(event) =>
                                    handleRuleTypeChange(
                                      event,
                                      index,
                                      "nonrefundable"
                                    )
                                  }
                                >
                                  <option value="Text">Checkbox</option>
                                  <option value="Link">Link</option>
                                </select>
                                {errors[`refundable.${index}.type`] && (
                                  <p role="alert" className="text-danger">
                                    Refundable type is required.
                                  </p>
                                )}
                              </div>
                            </div>

                            {nonRefundableRuleTypes[index] === "Link" && (
                              <div className="col-md-3">
                                <div className="form-group">
                                  <label className="form-label">
                                    Enter Refun.. Alias Name
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control py-2"
                                    {...register(
                                      `refundable.${index}.alias_name`,
                                      {
                                        required: true,
                                      }
                                    )}
                                  />

                                  {errors[
                                    `refundable.${index}.alias_name`
                                  ] && (
                                      <p
                                        role="alert"
                                        className="text-danger"
                                      >
                                        Refundable Alias Name is required.
                                      </p>
                                    )}
                                </div>
                              </div>
                            )}

                            <div
                              className={`col-md-${nonRefundableRuleTypes === "Text" ? 8 : 5
                                }`}
                            >

                              <div className="form-group">
                                <label
                                  htmlFor={`refundable.${index}.value`}
                                  className="form-label"
                                >
                                  {nonRefundableRuleTypes[index] === "Link" ? 'Enter Link' : 'Enter Non-Refundable Rule'}
                                </label>
                                <input
                                  type="text"
                                  className="form-control py-2"
                                  placeholder={nonRefundableRuleTypes[index] === "Link" ? 'It should include (http:// or https://) protocol.' : 'Enter Rule'}
                                  id={`refundable.${index}.value`}
                                  {...register(`refundable.${index}.value`, {
                                    required: true,
                                  })}
                                />
                                <input
                                  type="hidden"
                                  className="form-control py-2"
                                  {...register(`refundable.${index}.ruleId`)}
                                />

                              </div>
                            </div>
                            <div
                              className="col-lg-1"
                              style={{ paddingTop: "31px" }}
                            >
                              <button
                                type="button"
                                className="btn btn-primary"
                                onClick={() => removerefundableField(index)}
                              >
                                Remove
                              </button>
                            </div>
                          </div>
                        </div>
                      ))}
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() =>
                          appendrefundableField({ id: uuidv4(), name: "" })
                        }
                      >
                        Add Refundable
                      </button>
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-header">
                    <div className="col-md-12">
                      <div className="point_of_contact pt-1 text-dark">
                        Parking Rules
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="col-lg-12">
                      {parkingRuleField.map((field, index) => (
                        <div key={field.id}>
                          <div className="row">
                            <div className="col-md-8">
                              <div className="form-group">
                                <label
                                  htmlFor={`parkingRule.${index}.value`}
                                  className="form-label"
                                >
                                  Enter Parking Rule
                                </label>
                                <input
                                  type="text"
                                  className="form-control py-2"
                                  placeholder="Enter Rule"
                                  id={`parkingRule.${index}.value`}
                                  {...register(
                                    `parkingRule.${index}.value`,
                                    {
                                      required: true,
                                    }
                                  )}
                                />
                                <input
                                  type="hidden"
                                  className="form-control py-2"
                                  {...register(
                                    `parkingRule.${index}.ruleId`
                                  )}
                                />
                                {errors[`parkingRule.${index}.value`] && (
                                  <p role="alert" className="text-danger">
                                    Parking Rule is required.
                                  </p>
                                )}
                              </div>
                            </div>
                            <div
                              className="col-lg-1"
                              style={{ paddingTop: "31px" }}
                            >
                              <button
                                type="button"
                                className="btn btn-primary"
                                onClick={() =>
                                  removeparkingRuleField(index)
                                }
                              >
                                Remove
                              </button>
                            </div>
                          </div>
                        </div>
                      ))}
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() =>
                          appendparkingRuleField({
                            id: uuidv4(),
                            name: "",
                          })
                        }
                      >
                        Add Parking Rule
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="text-end">
              <div>
                <button
                  className="btn btn-outline-light text-dark mx-3 bg-light"
                  type="button"
                  onClick={() => navigate(-1)}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  variant="primary"
                  className={`btn btn-primary  ${isAdmin ? "" : "cambg-secondary border-0"
                    }`}
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <>
                      <Spinner animation="border" size="sm" /> Please wait...
                    </>
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default OwnerForm;
