import React, { useEffect, useState } from "react";
import { FaEdit, FaTrashAlt, FaPlus } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import Api from "../../../Config/Api";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { FaEye } from "react-icons/fa";
import { useSelector } from "react-redux";
import { Tooltip } from "react-tooltip";
import Loader from "../../Elements/Loader";
import { BsSearch } from "react-icons/bs";
import InfiniteScroll from "react-infinite-scroll-component";
const CamListing = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [isLoader, setIsLoader] = useState(true);
  const [cList, setCList] = useState([]);
  const location = useLocation();
  const [perPage] = useState(10);
  const [page, setPage] = useState(1);
  const [camList, setCamList] = useState([]);
  const [userId, setUserId] = useState();
  const [camName, setCamName] = useState();

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = (camData) => {
    setShow(true);
    setUserId(camData?.id);
    setCamName(camData?.name + ' ' + camData?.lastname);
  };

  const isAdmin = useSelector((state) => state.login.isAdmin);
  const [property, setProperty] = useState([]);
  const getPropertyDetails = async () => {
    const { data } = await Api("get-property", "POST", "");
    if (data?.status) {
      const propertyList = data?.data;
      setProperty(propertyList);
    }
  };

  const getVendorDetails = async () => {
    setIsLoader(true);
    const { data } = await Api("cam-get-user", "POST", "");
    if (data?.status) {
      setPage(1);
      var temparr = data?.data.slice(0, perPage);
      setCamList(data?.data);
      setIsLoader(false);
      setCList(temparr);

    }
  };

  const handleNext = () => {
    const start = page * perPage;
    const end = start + perPage;
    const temArr = cList.concat(camList.slice(start, end));
    setPage((prev) => prev + 1);
    setCList(temArr);
  };

  useEffect(() => {
    getPropertyDetails();
    getVendorDetails();
  }, []);

  const handleDelete = async (idToDelete) => {
    if (window.confirm("Are You Sure You Want to delete it?")) {
      const { data } = await Api("cam-delete-user", "POST", { id: idToDelete });
      if (data?.status) {
        toast.success(data?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }

      getVendorDetails();
    }
  };

  const onSubmit = async (d) => {
    const vendorPropertyData = {
      cam_managing_property_id: d?.property_id,
      id: userId,
    };
    const { data } = await Api("assign-property-to-cam", "POST", vendorPropertyData);
    if (Number(data?.status) === 0) {
      toast.success(data?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      setShow(false);
    } else if (Number(data?.status) === 1) {
      toast.success("Property has been Assigned Successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });
      getVendorDetails();
      setShow(false);
    }
  };

  const [searchTerm, setSearchTerm] = useState("");

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
    const search = event.target.value;
    const filtered = camList.filter((item) => {

      return item?.name?.toLowerCase()?.includes(search?.toLowerCase()) || item?.lastname?.toLowerCase()?.includes(search?.toLowerCase()) || item?.email?.toLowerCase()?.includes(search?.toLowerCase()) || item?.phone?.toLowerCase()?.includes(search?.toLowerCase()) || item?.property?.name?.toLowerCase()?.includes(search?.toLowerCase());
    });
    console.log("filtered",filtered);
    setCList(filtered);
  };



  return (
    <>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                {<h1 className="m-0">CAM</h1>}
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/"
                      className={location.pathname === "/" ? "breadcrumb-active" : "breadcrumb-not-active"}
                    >
                      Home
                    </Link>
                  </li>
                  <span className="breadcrumb-arrow">&gt;</span>
                  <li className="breadcrumb-item active">Cam
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>

        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className={`card-header ${isAdmin ? "adminColor" : "cambg-secondary"}`}>
                    <div className="add_new_vendor_listing">
                      <h3 className="card-title">Details</h3>
                      <Link
                        to={"/user/CamFrom"}
                        className="btn btn-light text-dark bg-light"
                      >
                        Add CAM
                      </Link>
                    </div>
                  </div>

                  <div className="d-flex justify-content-end px-4 mt-2">
                    <div className="input-group w-25">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search..."
                        value={searchTerm}
                        onChange={handleInputChange}
                      />
                      <div className="input-group-append">
                        <span
                          className="input-group-text bg-primary border-0"
                          id="basic-addon2"
                        >
                          <BsSearch />
                        </span>
                      </div>
                    </div>
                  </div>

                  {isLoader ? (
                    <Loader />
                  ) : (
                    <div className="card-body table-responsive">
                    <InfiniteScroll
                        dataLength={cList.length}
                        next={handleNext}
                        hasMore={camList.length > cList.length}
                      >
                      <table className="table table-bordered">
                        <thead className="border-top">
                          <tr>
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th>Email</th>
                            <th>Phone</th>
                            <th>Assigned Property</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {cList.length ? (
                            cList?.map((data, i) => (

                              <tr key={i}>
                                <td>{data?.name}</td>
                                <td>
                                  {data?.lastname}
                                </td>
                                <td>
                                  {data?.email}
                                </td>
                                <td>
                                  {data?.phone ? data?.phone : '-'}
                                </td>
                                <td>{data?.property?.name ? data?.property?.name : '-'}</td>

                                <td>
                                  <div className="d-flex justify-content-center">
                                    <Button
                                      className="btn btn-info btn-sm mr-1"
                                      onClick={() => handleShow(data)}
                                      data-tooltip-id="assign"
                                      data-tooltip-content="Assign Property"
                                    >
                                      <FaPlus />
                                    </Button>
                                    <Tooltip id="assign" place="bottom" />

                                    <Link
                                      to={"/user/CamDetail"}
                                      state={{ id: data?.id, isEdit: true }}
                                      className="btn btn-success btn-sm mr-1"
                                      data-tooltip-id="detail"
                                      data-tooltip-content="Show CAM Detail"
                                    >
                                      <FaEye />
                                    </Link>
                                    <Tooltip id="detail" place="bottom" />
                                    <Link
                                      to="/user/CamFrom"
                                      state={{ id: data?.id, isEdit: true }}
                                      className="btn  btn-primary btn-sm mr-1"
                                      data-tooltip-id="edit"
                                      data-tooltip-content="Edit"
                                    >
                                      <FaEdit />
                                    </Link>
                                    <Tooltip id="edit" place="bottom" />

                                    <button
                                      onClick={() => handleDelete(data.id)}
                                      className="btn  btn-danger btn-sm mr-1"
                                      data-tooltip-id="my-tooltip"
                                      data-tooltip-content="Delete"
                                    >
                                      <FaTrashAlt />
                                    </button>
                                    <Tooltip id="my-tooltip" place="bottom" />
                                  </div>
                                </td>
                              </tr>
                            ))) : (
                            <tr>
                              <td colSpan={8}>
                                <p className="text-center">
                                  No record found.
                                </p>
                              </td>
                            </tr>
                          )
                          }
                        </tbody>
                      </table>
                      </InfiniteScroll>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Assign Property to {camName}</Modal.Title>
        </Modal.Header>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label>Select Property</label>
                  <select
                    className="form-control"
                    {...register("property_id", {
                      required: true,
                    })}
                  >
                    {property?.map((propertydata, i) => (
                      <option key={i} value={propertydata?.id}>
                        {propertydata?.name}
                      </option>
                    ))}
                  </select>
                  {errors.property_id && (
                    <p className="text-danger">Property is required</p>
                  )}
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" type="submit">
              Save
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default CamListing;
