import React, { useEffect, useState } from 'react';
import Card from "react-bootstrap/Card";
import { Link, useLocation } from "react-router-dom";
import Api from '../../../Config/Api';
import { useSelector } from "react-redux";
import aqua_white from "../../../assets/img/index2_logo.png";
import dayjs from "dayjs";
import Loader from "../../Elements/Loader";
import { BsFillEyeFill, BsPrinterFill } from "react-icons/bs";

const PassesPrintout = () => {
    const location = useLocation();
    const isAdmin = useSelector((state) => state.login.isAdmin);
    const isLogin = useSelector((state) => state.login.isLogin);
    const [passInfo, setPassInfo] = useState([]);
    const [isLoader, setIsLoader] = useState(true);

    const getCheckOutDetails = async () => {
        const res = await Api("getAllpassInfoByUserID", "POST", {
            id: location.state.id,
        });
        console.log("data", res?.data?.passData);
        if (res?.data?.status) {
            setPassInfo(res?.data?.passData);
            setIsLoader(false);
        }
    };

    useEffect(() => {
        getCheckOutDetails();
    }, []);

    return (
        <>
            <main>
                <div className="content-wrapper">
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    {<h1 className="m-0">Passes Printout</h1>}
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item">
                                            <Link to="/"
                                                className={location.pathname === "/" ? "breadcrumb-active" : "breadcrumb-not-active"}
                                            >
                                                Home
                                            </Link>
                                            <span className="breadcrumb-arrow">&gt;</span>
                                            <Link to="/user/PaymentHistory"
                                                className={location.pathname.startsWith("/user/PaymentHistory") ? "breadcrumb-active" : "breadcrumb-not-active"}
                                            >
                                                Payment History
                                            </Link>
                                        </li>
                                        <span className="breadcrumb-arrow">&gt;</span>
                                        <li className="breadcrumb-item active">Payment Details</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    {isLoader ? (
                        <Loader />
                    ) : (
                        <>
                            <div>
                                <div className="returning_heading">
                                    <div className="d-flex justify-content-between pb-1">
                                        <div>
                                            <h3 className="text-center"></h3>
                                        </div>
                                        <div className='text-end'>
                                            <span className="btn btn-warning btn-sm text-light mx-2 pb-1" onClick={() => window.print()}>
                                                <BsPrinterFill />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className='card'>
                                    <section class="py-5">
                                        <div class="container">
                                            <div class="row g-3">
                                                <div class="col-md-6">
                                                    <div class="parking_card border border-dark p-3">
                                                        <div class="card_heading pb-3 text-center">
                                                            <h2>{passInfo?.billing_details?.vehiclepass?.Passtype === "Handicap" ? "Guest Pass" : "Parking Pass"} – {passInfo?.billing_details?.property?.name}</h2>
                                                            <h2 class="fs-1 fw-bold"> {dayjs(passInfo?.billing_details?.vehiclepass?.arrival_date).format("MM/DD/YYYY") + " - " + dayjs(passInfo?.billing_details?.vehiclepass?.departure_date).format("MM/DD/YYYY")}</h2>
                                                        </div>
                                                        <div class="car_detail">
                                                            <div class="d-sm-flex justify-content-between gap-3 pb-2">
                                                                <div>
                                                                    <p class="m-0">{passInfo?.billing_details?.vehiclepass?.vehicle_color === null ? " " : (passInfo?.billing_details?.vehiclepass?.vehicle_color + " " + passInfo?.billing_details?.vehiclepass?.vehicle_make)}</p>
                                                                </div>
                                                                <div>
                                                                    <p class="m-0">{passInfo?.billing_details?.vehiclepass?.vehicle_model}</p>
                                                                </div>
                                                                <div>
                                                                    <p class="m-0">{passInfo?.billing_details?.vehiclepass?.vehicle_tag_state === null ? " " :
                                                                        (passInfo?.billing_details?.vehiclepass?.vehicle_tag_state + " " + passInfo?.billing_details?.vehiclepass?.vehicle_tag)}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div class="d-flex flex-wrap gap-2 justify-content-between align-items-center">
                                                                <div>
                                                                    <h2 class="pb-1">Pass # {passInfo?.billing_details?.vehiclepass?.order_uuid}</h2>
                                                                    <img
                                                                        src={
                                                                            passInfo?.billing_details?.property?.logo
                                                                                ? process.env.REACT_APP_AWS_S3_BUCKET_URL +
                                                                                "images/" +
                                                                                passInfo?.billing_details?.property?.logo
                                                                                : aqua_white
                                                                        }
                                                                        className="img-fluid"
                                                                        width={80}
                                                                    />
                                                                </div>
                                                                <span>
                                                                    {passInfo?.billing_details?.vehiclepass
                                                                        ?.issuccess && (
                                                                            <img
                                                                                src={
                                                                                    passInfo?.billing_details?.vehiclepass
                                                                                        ?.qr_code
                                                                                }
                                                                                width={80} S
                                                                                alt=""
                                                                                srcSet=""
                                                                            />
                                                                        )}
                                                                </span>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="parking_card border border-dark p-3">
                                                        <div class="card_heading text-center">
                                                            <h2>{passInfo?.billing_details?.property?.name} Parking Rules</h2>
                                                        </div>
                                                        <div class="car_detail">
                                                            <p class="">{
                                                                passInfo?.billing_details?.property?.rules_and_regulations

                                                                    ? (
                                                                        passInfo?.billing_details?.property
                                                                            ?.rules_and_regulations
                                                                    ).map((data, i) => (
                                                                        <div key={i}>
                                                                            <span>{
                                                                                data?.for_whom === 4 && (
                                                                                    <span><li>{data?.rules}</li></span>
                                                                                )
                                                                            }</span>
                                                                            <br />
                                                                        </div>
                                                                    ))
                                                                    : "-"}</p>
                                                            {/* <p class="m-0">Rules</p> */}

                                                        </div>

                                                        <div>
                                                            <img
                                                                src={
                                                                    passInfo?.billing_details?.property?.logo
                                                                        ? process.env.REACT_APP_AWS_S3_BUCKET_URL +
                                                                        "images/" +
                                                                        passInfo?.billing_details?.property?.logo
                                                                        : aqua_white
                                                                }
                                                                className="img-fluid"
                                                                width={80}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="parking_card border border-dark p-3">
                                                        <div class="card_heading pb-2 text-center">
                                                            <h2>{passInfo?.billing_details?.vehiclepass?.Passtype === "Handicap" ? "Guest Pass" : "Parking Pass"} Receipt – {passInfo?.billing_details?.property?.name}</h2>
                                                            <p class="fw-bold m-0"> {dayjs(passInfo?.billing_details?.vehiclepass?.arrival_date).format("MM/DD/YYYY") + " - " + dayjs(passInfo?.billing_details?.vehiclepass?.departure_date).format("MM/DD/YYYY")}</p>
                                                        </div>
                                                        <div class="car_detail">
                                                            <div class="d-sm-flex justify-content-between gap-3 pb-2">
                                                                <div>
                                                                    <p>{passInfo?.billing_details?.vehiclepass?.vehicle_color === null ? " " :
                                                                        (passInfo?.billing_details?.vehiclepass?.vehicle_color + " " + passInfo?.billing_details?.vehiclepass?.vehicle_make)}</p>
                                                                    <p class="m-0">PAID: ${Number(passInfo?.billing_details?.vehiclepass?.pass_fee).toFixed(2)}</p>
                                                                </div>
                                                                <div>
                                                                    <p>{passInfo?.billing_details?.vehiclepass?.vehicle_model}</p>
                                                                    <p class="m-0"> {dayjs(passInfo?.billing_details?.vehiclepass?.created_at).format("MM/DD/YYYY")}</p>
                                                                </div>
                                                                <div>
                                                                    <p>{passInfo?.billing_details?.vehiclepass?.vehicle_tag_state === null ? " " :
                                                                        (passInfo?.billing_details?.vehiclepass?.vehicle_tag_state + " " + passInfo?.billing_details?.vehiclepass?.vehicle_tag)}</p>
                                                                    <p class="m-0">CC: {passInfo?.card}</p>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div>
                                                            <div class="d-flex flex-wrap gap-2 justify-content-between align-items-center">
                                                                <div>
                                                                    <p class="fs-5 fw-bold">Pass # {passInfo?.billing_details?.vehiclepass?.order_uuid}</p>
                                                                    <img
                                                                        src={
                                                                            passInfo?.billing_details?.property?.logo
                                                                                ? process.env.REACT_APP_AWS_S3_BUCKET_URL +
                                                                                "images/" +
                                                                                passInfo?.billing_details?.property?.logo
                                                                                : aqua_white
                                                                        }
                                                                        className="img-fluid"
                                                                        width={80}
                                                                    />
                                                                </div>
                                                                <span>
                                                                    {passInfo?.billing_details?.vehiclepass
                                                                        ?.issuccess && (
                                                                            <div className="text-end">
                                                                                <img
                                                                                    src={
                                                                                        passInfo?.billing_details?.vehiclepass
                                                                                            ?.qr_code
                                                                                    }
                                                                                    width={80} S
                                                                                    alt=""
                                                                                    srcSet=""
                                                                                />
                                                                            </div>
                                                                        )}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="parking_card border border-dark p-3">
                                                        <div class="card_heading pb-2 text-center">
                                                            <h2>{passInfo?.billing_details?.vehiclepass?.Passtype === "Handicap" ? "Guest Pass" : "Parking Pass"} Receipt – {passInfo?.billing_details?.property?.name}</h2>
                                                            <p class="fw-bold m-0"> {dayjs(passInfo?.billing_details?.vehiclepass?.arrival_date).format("MM/DD/YYYY") + " - " + dayjs(passInfo?.billing_details?.vehiclepass?.departure_date).format("MM/DD/YYYY")}</p>
                                                        </div>

                                                        <div class="car_detail">
                                                            <div class="d-sm-flex justify-content-between gap-3 pb-2">
                                                                <div>
                                                                    <p>{passInfo?.billing_details?.vehiclepass?.vehicle_color === null ? " " :
                                                                        (passInfo?.billing_details?.vehiclepass?.vehicle_color + " " + passInfo?.billing_details?.vehiclepass?.vehicle_make)}</p>
                                                                    <p class="mb-1">PAID: ${Number(passInfo?.billing_details?.vehiclepass?.pass_fee).toFixed(2)}</p>
                                                                    <p class="0">REFUND: - ${passInfo?.billing_details?.property?.refund_percent == null ? (
                                                                        "0.00"
                                                                    ) : Number((passInfo?.billing_details?.vehiclepass?.pass_fee * passInfo?.billing_details?.property?.refund_percent) / 100).toFixed(2)}</p>
                                                                </div>
                                                                <div>
                                                                    <p>{passInfo?.billing_details?.vehiclepass?.vehicle_model}</p>
                                                                    <p class="mb-1">{dayjs(passInfo?.billing_details?.vehiclepass?.created_at).format("MM/DD/YYYY")}</p>
                                                                    <p class="0">12/30/2023</p>
                                                                </div>
                                                                <div>
                                                                    <p>{passInfo?.billing_details?.vehiclepass?.vehicle_tag_state === null ? " " :
                                                                        (passInfo?.billing_details?.vehiclepass?.vehicle_tag_state + " " + passInfo?.billing_details?.vehiclepass?.vehicle_tag)}</p>
                                                                    <p class="mb-1">CC: {passInfo?.card}</p>
                                                                    <p class="0">CC: {passInfo?.card}</p>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div>
                                                            <div class="d-flex flex-wrap gap-2 justify-content-between align-items-center">
                                                                <div>
                                                                    <p class="fs-5 fw-bold">Pass # {passInfo?.billing_details?.vehiclepass?.order_uuid}</p>
                                                                    <img
                                                                        src={
                                                                            passInfo?.billing_details?.property?.logo
                                                                                ? process.env.REACT_APP_AWS_S3_BUCKET_URL +
                                                                                "images/" +
                                                                                passInfo?.billing_details?.property?.logo
                                                                                : aqua_white
                                                                        }
                                                                        className="img-fluid"
                                                                        width={80}
                                                                    />
                                                                </div>
                                                                <span>
                                                                    {passInfo?.billing_details?.vehiclepass
                                                                        ?.issuccess && (
                                                                            <div className="text-end">
                                                                                <img
                                                                                    src={
                                                                                        passInfo?.billing_details?.vehiclepass
                                                                                            ?.qr_code
                                                                                    }
                                                                                    width={80} S
                                                                                    alt=""
                                                                                    srcSet=""
                                                                                />
                                                                            </div>
                                                                        )}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>

                            </div>

                        </>
                    )}
                </div>
            </main>
        </>
    )
}

export default PassesPrintout