import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { BsSearch } from "react-icons/bs";
import Api from '../../../Config/Api';
import { FaEdit, FaEye, FaTrashAlt, FaPlus, FaMoneyBill } from "react-icons/fa";
import { Tooltip } from "react-tooltip";
import Button from "react-bootstrap/Button";
import Loader from "../../Elements/Loader";

const GraphOwnerListing = () => {

    const location = useLocation();
    const isAdmin = useSelector((state) => state.login.isAdmin);
    const isLogin = useSelector((state) => state.login.isLogin);
    const roleId = useSelector((state) => state.login.roleId);
    const [ownerList, setOwnerList] = useState([]);
    const [ownerListForManagement, setOwnerListForManagement] = useState([]);
    const [isLoader, setIsLoader] = useState(false);
    const [oList, setOList] = useState([]);
    const [oMgmtList, setOMgmtList] = useState([]);
    const { unit, searchBy, propertyId } = location.state ? location.state : '';
    console.log('unit:', unit);
    console.log('SearchBy:', searchBy);
    console.log('PropertyId:', propertyId);

    const getAdminOwnerDetails = async () => {
        setIsLoader(true);
        try {
            const ownerCount = await Api("unit-count", "post", { unit_name: unit ? unit : "" });
            console.log("ownerCount>>>>>>", ownerCount);
            setIsLoader(true);
            // console.log("ownerCount", ownerCount);

            if (ownerCount?.data?.status) {
                setIsLoader(false);
                if (searchBy && unit) {
                    if (propertyId) {
                        const filteredOwners = ownerCount?.data?.unit.filter(ownerItem => ownerItem.property_id === propertyId);
                        console.log("filteredOwners", filteredOwners);

                        setOwnerList(filteredOwners);
                        setOList(filteredOwners);
                    } else {
                        const filteredOwners = ownerCount?.data?.unit.filter(ownerItem => {
                            console.log("searchBy????????????", searchBy);
                            return ownerItem[searchBy] === unit;
                        });
                        console.log("filteredOwners", filteredOwners);

                        setOwnerList(filteredOwners);
                        setOList(filteredOwners);
                    }
                } else {
                    setIsLoader(false);
                    setOwnerList(ownerCount?.data?.unit);
                    // console.log("ownerList", ownerList);
                    setOList(ownerCount?.data?.unit);

                }


            }
        } catch (error) {
            console.error('Error fetching APIs:', error);
        }
    }

    const getMgmtOwnerDetails = async () => {
        try {
            const { data } = await Api('getUnitCountByManagementId', "POST");
            if (data?.status) {
                setOwnerListForManagement(Boolean(data?.response.length) ? data?.response[0]?.unit : []);
                setOMgmtList(Boolean(data?.response.length) ? data?.response[0]?.unit : []);
            }


        } catch (error) {
            console.error('Error fetching APIs:', error);
        }
    }

    useEffect(() => {
        if (isLogin) { getAdminOwnerDetails() };
    }, []);

    useEffect(() => {
        if (isLogin) { getMgmtOwnerDetails() };
    }, []);

    const [searchTerm, setSearchTerm] = useState("");

    const handleInputChange = (event) => {
        setSearchTerm(event.target.value);
        const search = event.target.value.toLowerCase();

        const filtered = (isAdmin ? oList : oMgmtList).filter((item) => {
            if (Boolean(item)) {
                return (
                    item.unit_name.toLowerCase().includes(search) ||
                    item.firstname.toLowerCase().includes(search) ||
                    item.lastname.toLowerCase().includes(search) ||
                    item.email.toLowerCase().includes(search) ||
                    item.phone.toLowerCase().includes(search)
                );
            } else {
                return false;
            }
        });
        // console.log("filtered",filtered);
        { isAdmin ? setOwnerList(filtered) : setOwnerListForManagement(filtered) }

    };

    // console.log("data>>>>>>>>>???????????", ownerListForManagement);
    return (
        <>
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0">Units & Owners</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item">
                                        <Link
                                            to="/"
                                            className={
                                                location.pathname === "/"
                                                    ? "breadcrumb-active"
                                                    : "breadcrumb-not-active"
                                            }
                                        >
                                            Home
                                        </Link>
                                        {searchBy ? (
                                            <>
                                                <span className="breadcrumb-arrow">&gt;</span>
                                                <Link to="/user/Lookupunit"
                                                    className={location.pathname.startsWith("/user/Lookupunit") ? "breadcrumb-active" : "breadcrumb-not-active"}
                                                >
                                                    Look-Up
                                                </Link>
                                            </>
                                        ) : ("")}
                                    </li>
                                    <span className="breadcrumb-arrow">&gt;</span>
                                    <li className="breadcrumb-item active">Unit</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div
                                        className={`card-header ${isAdmin || Number(atob(roleId)) === 4 ? "adminColor" : "cambg-secondary"
                                            }`}
                                    >
                                        <div className="add_new_vendor_listing">
                                            <div className="">
                                                <h3 className="card-title mt-2">
                                                    Unit Listing :

                                                </h3>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="d-flex justify-content-end px-4 mt-2">
                                        <div className="input-group w-25">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Search..."
                                                value={searchTerm}
                                                onChange={handleInputChange}
                                            />
                                            <div className="input-group-append">
                                                <span
                                                    className="input-group-text bg-primary border-0"
                                                    id="basic-addon2"
                                                >
                                                    <BsSearch />
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                    {isLoader ? (
                                        <Loader />
                                    ) : (
                                        <div className="card-body table-responsive">
                                            {/* <InfiniteScroll
                                            dataLength={ownerList.length}
                                            next={handleNext}
                                            hasMore={camList.length > ownerList.length}
                                        loader={<Loader />}
                                        endMessage={<p>No more data to load</p>}
                                        > */}
                                            <table className="table table-bordered">
                                                <thead className="border-top">
                                                    <tr>
                                                        <th>Unit Name</th>
                                                        <th>Owner First Name</th>
                                                        <th>Owner Last Name</th>
                                                        <th>Owner Email</th>
                                                        <th>Owner Phone No</th>

                                                        <th>Management Co</th>


                                                        <th>Action</th>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    {isAdmin ? (
                                                        ownerList.length ? (
                                                            ownerList?.map((ownerData, i) => (
                                                                <tr>
                                                                    <td>{ownerData?.unit_name}</td>
                                                                    <td>{ownerData?.firstname}</td>
                                                                    <td>{ownerData?.lastname}</td>
                                                                    <td>{ownerData?.email}</td>
                                                                    <td>{ownerData?.phone}</td>
                                                                    <td>{ownerData?.users ? ownerData?.users?.name + " (" + ownerData?.users?.email + ")" : "-"}</td>

                                                                    <td>
                                                                        <div className="d-flex justify-content-evenly">
                                                                            {Number(atob(roleId)) === 2 ? ("") : (
                                                                                <>
                                                                                    <Button
                                                                                        className="btn btn-info btn-sm mr-1"
                                                                                        // onClick={() => handleShow(ownerData)}
                                                                                        data-tooltip-id="assign"
                                                                                        data-tooltip-content="Property"
                                                                                    >
                                                                                        <FaPlus />
                                                                                    </Button>
                                                                                    <Tooltip id="assign" place="bottom" />

                                                                                </>
                                                                            )}

                                                                            <Link
                                                                                to={"/user/OwnerDetail"}
                                                                                // state={{
                                                                                //     id: ownerData?.id,
                                                                                //     propertyId: ownerData?.property_id,
                                                                                //     isEdit: true,
                                                                                // }}
                                                                                className="btn btn-success btn-sm mr-1"
                                                                                data-tooltip-id="detail"
                                                                                data-tooltip-content="Show Unit Detail"
                                                                            >
                                                                                <FaEye />
                                                                            </Link>
                                                                            <Tooltip id="detail" place="bottom" />

                                                                            <Link
                                                                                to="/user/OwnerForm"
                                                                                // state={{
                                                                                //     id: ownerData.id,
                                                                                //     propertyId: ownerData?.property_id,
                                                                                //     isEdit: true,
                                                                                // }}
                                                                                className="btn  btn-primary btn-sm mr-1"
                                                                                data-tooltip-id="edit"
                                                                                data-tooltip-content="Edit"
                                                                            >
                                                                                <FaEdit />
                                                                            </Link>
                                                                            <Tooltip id="edit" place="bottom" />

                                                                            {isAdmin || Number(atob(roleId)) === 2 ? (
                                                                                <>
                                                                                    <Link
                                                                                        to={"/user/ManageOwnerPasses"}
                                                                                        // state={{
                                                                                        //     id: ownerData?.id,
                                                                                        //     propertyId: ownerData?.property_id,
                                                                                        //     // type: owner,
                                                                                        // }}
                                                                                        className="btn btn-info btn-sm mr-1"
                                                                                        data-tooltip-id="detail"
                                                                                        data-tooltip-content="Manage Owner Pass"
                                                                                    >
                                                                                        <FaMoneyBill />
                                                                                    </Link>
                                                                                    <Tooltip id="detail" place="bottom" />

                                                                                </>
                                                                            ) : ("")}

                                                                            <button
                                                                                // onClick={() =>
                                                                                //     handleDelete(ownerData.id)
                                                                                // }
                                                                                className="btn  btn-danger btn-sm mr-1"
                                                                                data-tooltip-id="delete"
                                                                                data-tooltip-content="Delete"
                                                                            >
                                                                                <FaTrashAlt />
                                                                            </button>
                                                                            <Tooltip id="delete" place="bottom" />

                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        ) : (
                                                            <tr>
                                                                <td colSpan={7}>
                                                                    <p className="text-center">No Unit Yet.</p>
                                                                </td>
                                                            </tr>
                                                        )) : (
                                                        ownerListForManagement.length ? (
                                                            ownerListForManagement?.map((ownerData, i) => (
                                                                <tr key={i}>
                                                                    <td>{ownerData?.unit_name}</td>
                                                                    <td>{ownerData?.firstname}</td>
                                                                    <td>{ownerData?.lastname}</td>
                                                                    <td>{ownerData?.email}</td>
                                                                    <td>{ownerData?.phone}</td>
                                                                    <td>{ownerData?.users ? ownerData?.users?.name + " (" + ownerData?.users?.email + ")" : "-"}</td>

                                                                    <td>
                                                                        <div className="d-flex justify-content-evenly">
                                                                            {Number(atob(roleId)) === 2 ? ("") : (
                                                                                <>
                                                                                    <Button
                                                                                        className="btn btn-info btn-sm mr-1"
                                                                                        // onClick={() => handleShow(ownerData)}
                                                                                        data-tooltip-id="assign"
                                                                                        data-tooltip-content="Property"
                                                                                    >
                                                                                        <FaPlus />
                                                                                    </Button>
                                                                                    <Tooltip id="assign" place="bottom" />

                                                                                </>
                                                                            )}

                                                                            <Link
                                                                                to={"/user/OwnerDetail"}
                                                                                // state={{
                                                                                //     id: ownerData?.id,
                                                                                //     propertyId: ownerData?.property_id,
                                                                                //     isEdit: true,
                                                                                // }}
                                                                                className="btn btn-success btn-sm mr-1"
                                                                                data-tooltip-id="detail"
                                                                                data-tooltip-content="Show Unit Detail"
                                                                            >
                                                                                <FaEye />
                                                                            </Link>
                                                                            <Tooltip id="detail" place="bottom" />

                                                                            <Link
                                                                                to="/user/OwnerForm"
                                                                                // state={{
                                                                                //     id: ownerData.id,
                                                                                //     propertyId: ownerData?.property_id,
                                                                                //     isEdit: true,
                                                                                // }}
                                                                                className="btn  btn-primary btn-sm mr-1"
                                                                                data-tooltip-id="edit"
                                                                                data-tooltip-content="Edit"
                                                                            >
                                                                                <FaEdit />
                                                                            </Link>
                                                                            <Tooltip id="edit" place="bottom" />

                                                                            {isAdmin || Number(atob(roleId)) === 2 ? (
                                                                                <>
                                                                                    <Link
                                                                                        to={"/user/ManageOwnerPasses"}
                                                                                        // state={{
                                                                                        //     id: ownerData?.id,
                                                                                        //     propertyId: ownerData?.property_id,
                                                                                        //     // type: owner,
                                                                                        // }}
                                                                                        className="btn btn-info btn-sm mr-1"
                                                                                        data-tooltip-id="detail"
                                                                                        data-tooltip-content="Manage Owner Pass"
                                                                                    >
                                                                                        <FaMoneyBill />
                                                                                    </Link>
                                                                                    <Tooltip id="detail" place="bottom" />

                                                                                </>
                                                                            ) : ("")}

                                                                            <button
                                                                                // onClick={() =>
                                                                                //     handleDelete(ownerData.id)
                                                                                // }
                                                                                className="btn  btn-danger btn-sm mr-1"
                                                                                data-tooltip-id="delete"
                                                                                data-tooltip-content="Delete"
                                                                            >
                                                                                <FaTrashAlt />
                                                                            </button>
                                                                            <Tooltip id="delete" place="bottom" />

                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        ) : (
                                                            <tr>
                                                                <td colSpan={7}>
                                                                    <p className="text-center">No Unit Yet.</p>
                                                                </td>
                                                            </tr>
                                                        )
                                                    )}
                                                </tbody>
                                            </table>
                                            {/* </InfiniteScroll> */}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        </>
    )
}

export default GraphOwnerListing