import React, { useEffect, useState } from 'react';
import { Link, useLocation } from "react-router-dom";
import Card from "react-bootstrap/Card";
import Api from '../../../Config/Api';
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import aqua_white from "../../../assets/img/index2_logo.png";
import Loader from "../../Elements/Loader";
import { BsFillCheckCircleFill, BsPrinterFill } from "react-icons/bs";

const Vend_OwnPassesPrintout = () => {
  const location = useLocation();
  const [passInfo, setPassInfo] = useState([]);
  const isAdmin = useSelector((state) => state.login.isAdmin);
  const isLogin = useSelector((state) => state.login.isLogin);
  const [isLoader, setIsLoader] = useState(true);

  const getCheckOutDetails = async () => {
    const res = await Api("getVehiclepassById", "POST", {
      id: location?.state?.id,
      type: "vendor",
    });
    console.log("data", res?.data?.data);
    if (res?.data?.status) {
      setPassInfo(res?.data?.data);
      setIsLoader(false);
    }
  };

  useEffect(() => {
    getCheckOutDetails();
  }, []);

  return (
    <>
      <main>
        <div className="content-wrapper">
          <div className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  {<h1 className="m-0">Passes Printout</h1>}
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item">
                      <Link to="/"
                        className={location.pathname === "/" ? "breadcrumb-active" : "breadcrumb-not-active"}
                      >
                        Home
                      </Link>
                      <span className="breadcrumb-arrow">&gt;</span>
                      <Link to={passInfo?.is_passes_for === 'Owner' ? "/user/OwnerListing/" +  btoa(location?.state?.propertyId) : ("/user/VendorListing/" + btoa(location?.state?.propertyId))}
                        className={passInfo?.is_passes_for === 'Owner' ? (location.pathname.startsWith("/user/OwnerListing/" +  btoa(location?.state?.propertyId)) ? "breadcrumb-active" : "breadcrumb-not-active") : (location.pathname.startsWith("/user/VendorListing/"+ btoa(location?.state?.propertyId)) ? "breadcrumb-active" : "breadcrumb-not-active")
                        }
                      >
                        {passInfo?.is_passes_for === 'Owner' ? "Units" : "Vendors"}
                      </Link>
                      
                      <span className="breadcrumb-arrow">&gt;</span>
                      <Link to={passInfo?.is_passes_for === 'Owner' ? "/user/ManageOwnerPasses" : "/user/ManagePasses"}
                        className={passInfo?.is_passes_for === 'Owner' ? (location.pathname.startsWith("/user/ManageOwnerPasses") ? "breadcrumb-active" : "breadcrumb-not-active") : (location.pathname.startsWith("/user/ManagePasses") ? "breadcrumb-active" : "breadcrumb-not-active")
                        }
                      >
                        {passInfo?.is_passes_for === 'Owner' ? "Owner Passes" : "Vendor Passes"}
                      </Link>
                    </li>
                    <span className="breadcrumb-arrow">&gt;</span>
                    <li className="breadcrumb-item active">
                      {parseInt(passInfo?.is_owner) === 0 ? "Owner Guest Pass" : (passInfo?.is_passes_for === 'Owner' ? "Owner Pass" : "Vendor Pass")}
                      {/* {passInfo?.is_passes_for === 'Owner' ? "Owner Pass Details" : "Vendor Pass Details"} */}
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          {isLoader ? (
            <Loader />
          ) : (
            <><div>
              <div className="returning_heading">
                <div className="d-flex justify-content-between pb-1">
                  <div>
                    <h3 className="text-center"></h3>
                  </div>
                  <div className='text-end'>
                    <span className="btn btn-warning btn-sm text-light mx-2 pb-1" onClick={() => window.print()}>
                      <BsPrinterFill />
                    </span>
                  </div>
                </div>
              </div>
              <div className='card'>
                <section class="py-5">

                  <div class="container">
                    <div class="row g-3">
                      <div class="col-md-6">
                        <div class="parking_card border border-dark p-3">
                          <div class="card_heading pb-3 text-center">
                            <h2>{parseInt(passInfo?.is_owner) === 0 ? "Owner Guest Pass" : (passInfo?.is_passes_for === 'Owner' ? "Owner Pass" : "Vendor Pass")} - {passInfo?.propety?.name}
                            </h2>
                            <h2 class="fs-1 fw-bold">{dayjs(passInfo?.arrival_date).format("MM/DD/YYYY") + " - " + dayjs(passInfo?.departure_date).format("MM/DD/YYYY")}</h2>
                          </div>
                          <div class="car_detail">
                            {passInfo?.vehicle_color === null ? (
                              <div class="d-sm-flex justify-content-between gap-3 pb-2">
                                <div>
                                  <p class="m-0">Vehicle Color: N/A </p>
                                </div>
                                <div>
                                  <p class="m-0">Vehicle Tag: N/A </p>
                                </div>
                                <div>
                                  <p class="m-0">Vehicle Tag State: N/A </p>
                                </div>
                              </div>) : (
                              <div class="d-sm-flex justify-content-between gap-3 pb-2">
                                <div>
                                  <p class="m-0">{passInfo?.vehicle_color} {passInfo?.vehicle_make}</p>
                                </div>
                                <div>
                                  <p class="m-0">{passInfo?.vehicle_tag}</p>
                                </div>
                                <div>
                                  <p class="m-0">{passInfo?.vehicle_tag_state} {passInfo?.vehicle_tag}</p>
                                </div>
                              </div>
                            )}
                          </div>
                          <div>
                            <div class="d-flex flex-wrap gap-2 justify-content-between align-items-center">
                              <div>
                                <h2 class="pb-1">Pass # {passInfo?.order_uuid}</h2>
                                <img
                                  src={
                                    passInfo?.propety?.logo
                                      ? process.env.REACT_APP_AWS_S3_BUCKET_URL +
                                      "images/" +
                                      passInfo?.propety?.logo
                                      : aqua_white
                                  }
                                  className="img-fluid"
                                  width={80}
                                />
                              </div>
                              <span>
                                {passInfo?.issuccess && (
                                  <div className="text-end">
                                    <img
                                      src={
                                        passInfo?.qr_code
                                      }
                                      width={80} S
                                      alt=""
                                      srcSet=""
                                    />
                                  </div>
                                )}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-6">
                        <div class="parking_card border border-dark p-3">
                          <div class="card_heading text-center">
                            <h2>{passInfo?.propety?.name} Parking Rules</h2>
                          </div>

                          <div class="car_detail">
                            <p class="">{
                              passInfo?.propety?.rules_and_regulations

                                ? (
                                  passInfo?.propety
                                    ?.rules_and_regulations
                                ).map((data, i) => (
                                  <div key={i}>
                                    <span>{
                                      data?.for_whom == 4 && (
                                        <span><li>{data?.rules}</li></span>
                                      )
                                    }</span>
                                    <br />
                                  </div>
                                ))
                                : "---"}</p>
                            {/* <p class="m-0">Rules</p>
                            <p class="m-0">More rules, More of the same, Lots of rules</p>
                            <p class="m-0">Cameras, prosecution, legal stuff, blah, blah, blah</p> */}
                          </div>

                          <div>
                            <img
                              src={
                                passInfo?.propety?.logo
                                  ? process.env.REACT_APP_AWS_S3_BUCKET_URL +
                                  "images/" +
                                  passInfo?.propety?.logo
                                  : aqua_white
                              }
                              className="img-fluid"
                              width={80}
                            />
                          </div>
                        </div>
                      </div>

                      <div class="col-md-6">
                        <div class="parking_card border border-dark p-3">
                          <div class="card_heading pb-2 text-center">
                            <h2>{parseInt(passInfo?.is_owner) === 0 ? "Owner Guest Pass" : (passInfo?.is_passes_for === 'Owner' ? "Owner Pass" : "Vendor Pass")} - {passInfo?.propety?.name}</h2>
                            <p class="fw-bold m-0">{dayjs(passInfo?.arrival_date).format("MM/DD/YYYY") + " - " + dayjs(passInfo?.departure_date).format("MM/DD/YYYY")}</p>
                          </div>
                          {passInfo?.vehicle_color === null ? (
                            <div class="d-sm-flex justify-content-between gap-3 pb-2">
                              <div>
                                <p class="m-0">Vehicle Color: N/A </p>
                              </div>
                              <div>
                                <p class="m-0">Vehicle Tag: N/A </p>
                              </div>
                              <div>
                                <p class="m-0">Vehicle Tag State: N/A </p>
                              </div>
                            </div>) : (
                            <div class="d-sm-flex justify-content-between gap-3 pb-2">
                              <div>
                                <p class="m-0">{passInfo?.vehicle_color} {passInfo?.vehicle_make}</p>
                              </div>
                              <div>
                                <p class="m-0">{passInfo?.vehicle_tag}</p>
                              </div>
                              <div>
                                <p class="m-0">{passInfo?.vehicle_tag_state} {passInfo?.vehicle_tag}</p>
                              </div>
                            </div>
                          )}

                          <div>
                            <div class="d-flex flex-wrap gap-2 justify-content-between align-items-center">
                              <div>
                                <p class="fs-5 fw-bold">Pass # {passInfo?.order_uuid}</p>
                                <img
                                  src={
                                    passInfo?.propety?.logo
                                      ? process.env.REACT_APP_AWS_S3_BUCKET_URL +
                                      "images/" +
                                      passInfo?.propety?.logo
                                      : aqua_white
                                  }
                                  className="img-fluid"
                                  width={80}
                                />
                              </div>
                              <span>
                                {passInfo?.issuccess && (
                                  <div className="text-end">
                                    <img
                                      src={
                                        passInfo?.qr_code
                                      }
                                      width={80} S
                                      alt=""
                                      srcSet=""
                                    />
                                  </div>
                                )}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-6">
                        <div class="parking_card border border-dark p-3">
                          <div class="card_heading pb-2 text-center">
                            <h2>{parseInt(passInfo?.is_owner) === 0 ? "Owner Guest Pass" : (passInfo?.is_passes_for === 'Owner' ? "Owner Pass" : "Vendor Pass")} - {passInfo?.propety?.name}</h2>
                            <p class="fw-bold m-0">1/2/2023 – 1/31/2/23</p>
                          </div>
                          {/* <div class="car_detail">
                          <div class="d-sm-flex justify-content-between gap-3 pb-2">
                            <div>
                              <p>White Ford</p>
                              <p class="mb-1">PAID: $35.00</p>
                              <p class="0">REFUND: -$35.00</p>
                            </div>
                            <div>
                              <p>F-150</p>
                              <p class="mb-1">12/12/2021</p>
                              <p class="0">12/30/2022</p>
                            </div>
                            <div>
                              <p>AK ABC-123</p>
                              <p class="mb-1">CC: xxxx-xxxx-xxxx-1234</p>
                              <p class="0">CC: xxxx-xxxx-xxxx-1234</p>
                            </div>
                          </div>
                        </div> */}
                          <div>
                            <div class="d-flex flex-wrap gap-2 justify-content-between align-items-center">
                              <div>
                                <p class="fs-5 fw-bold">Pass #  {passInfo?.order_uuid}</p>
                                <img
                                  src={
                                    passInfo?.propety?.logo
                                      ? process.env.REACT_APP_AWS_S3_BUCKET_URL +
                                      "images/" +
                                      passInfo?.propety?.logo
                                      : aqua_white
                                  }
                                  className="img-fluid"
                                  width={80}
                                />
                              </div>
                              <span>
                                {passInfo?.issuccess && (
                                  <div className="text-end">
                                    <img
                                      src={
                                        passInfo?.qr_code
                                      }
                                      width={80} S
                                      alt=""
                                      srcSet=""
                                    />
                                  </div>
                                )}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </section>
              </div>
            </div>
            </>
          )}
        </div>
      </main>
    </>
  )
}

export default Vend_OwnPassesPrintout