import React from 'react'
import Api from '../../Config/Api'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
const ScanLocationQR = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const propetyId = useSelector((state) => state.login.propertyId);
    const isLogin = useSelector((state) => state.login.isLogin);
    const addSecurityHistory =async () =>{
        const data = {
            locationCode: location?.state?.UUID,
            userId: location?.state?.userId,
        }
        const res = await Api('addSecurityHistory',"POST",data);
        console.log('res',res);
    }
    if(!isLogin){
        window.location.href = 'tel:911';
      }else{
        
      }

    useEffect(()=>{
        addSecurityHistory()
    },[location?.state?.userId])
  return (
  <>
    <div className="container">
        <div className="card p-4">
            <h4 className="py-3 text-center text-success">
                 Successfully scanned
            </h4>
            <div className='text-center'>
                <Link to={'/Security'} className='btn btn-primary'>Back To Home</Link>
            </div>
        </div>
    </div>
  </>
  )
}

export default ScanLocationQR