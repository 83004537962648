import React, { useEffect, useState } from "react";
import { FaTrashAlt } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import Api from "../../../../Config/Api";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
// import { FaEye } from "react-icons/fa";
import { useSelector } from "react-redux";
import { Tooltip } from "react-tooltip";
import Loader from "../../../Elements/Loader";
import { BsSearch } from "react-icons/bs";
import InfiniteScroll from "react-infinite-scroll-component";
import { Spinner } from "react-bootstrap";
import useSweetAlert from "../../../../Hooks/useSweetAlert";
import dayjs from "dayjs";
const UnitRequest = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { deletefun, isDeleted } = useSweetAlert();
  const userId = useSelector((state) => state.login.userId);
  const isAdmin = useSelector((state) => state.login.isAdmin);
  const roleId = useSelector((state) => state.login.roleId);
  const [propertyId, setPropertyId] = useState(null);
  const [unitlist, setUnitList] = useState([]);
  const [property, setProperty] = useState([]);
  const [isLoader, setIsLoader] = useState(true);
  // const [isBtnLoader, setBtnIsLoader] = useState(true);
  const [cList, setCList] = useState([]);
  const location = useLocation();
  const [perPage] = useState(10);
  const [page, setPage] = useState(1);
  const [changeUnitData, setChangeUnitData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setLoading] = useState(false);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = (camData) => {
    setShow(true);
  };

  const getPropertyDetails = async () => {
    const { data } = await Api("get-property", "POST", "");
    if (data?.status) {
      const propertyList = data?.data;
      setProperty(propertyList);
    }
  };

  const handlePropertyChange = (event) => {
    setPropertyId(event.target.value);
    getUnitDetails(event.target.value);
  };

  const getUnitDetails = async (property_Id) => {
    setIsLoader(true);
    const reqData = {
      propertyId: property_Id,
    };
    const { data } = await Api("getunitByPropertyId", "POST", reqData);
    if (data?.status) {
      const unitrep = data?.data;
      setUnitList(unitrep);
      setIsLoader(false);
    }
  };

  const getRequestDetails = async () => {
    setIsLoader(true);
    try {
      const { data } = await Api("get_all_property_request", "POST", { userId });
      if (data?.status) {
        setPage(1);
        var temparr = data?.data.slice(0, perPage);
        setChangeUnitData(data?.data);
        setIsLoader(false);
        setCList(temparr);
      } else {
        setIsLoader(false);
      }
    } catch (error) {
      setIsLoader(false);
    }
  };

  const handleNext = () => {
    const start = page * perPage;
    const end = start + perPage;
    const temArr = cList.concat(changeUnitData.slice(start, end));
    setPage((prev) => prev + 1);
    setCList(temArr);
  };

  const handleDelete = async (idToDelete) => {
    if (window.confirm("Are You Sure You Want to delete it?")) {
      const { data } = await Api("delete_unit_request", "POST", { id: idToDelete });
      if (data?.status) {
        toast.success(data?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }

      getRequestDetails();
    }
  };

  const onSubmit = async (d) => {
    setLoading(true);
    const requestData = {
      unit_id: d?.unit_id,
      property_id: d?.property_id,
      user_id: userId,
      changeover_date: d?.changeover_date,

    };
    const { data } = await Api("send_mgmt_request_to_cam_to_change_unit","POST",requestData);
    if (data?.status == 0) {
      toast.error("Server Error", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setShow(false);
      setLoading(false);
    } else {
      toast.success("Unit Change Request Successfully Has been Sent.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      getRequestDetails();
      setShow(false);
      setLoading(false);
    }
  };

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
    const search = event.target.value;
    const filtered = changeUnitData.filter((item) => {
      return (
        item?.cam_data?.name?.toLowerCase()?.includes(search?.toLowerCase()) ||
        item?.lastname?.toLowerCase()?.includes(search?.toLowerCase()) ||
        item?.email?.toLowerCase()?.includes(search?.toLowerCase()) ||
        item?.phone?.toLowerCase()?.includes(search?.toLowerCase())
      );
    });
    setCList(filtered);
  };

  useEffect(() => {
    console.log('inside the useeffect');
    if (isDeleted) {
      getRequestDetails();
    }
  }, [isDeleted])

  useEffect(() => {
    getRequestDetails();
  }, [userId]);

  useEffect(() => {
    getPropertyDetails();
  }, []);

  return (
    <>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">{<h1 className="m-0">Unit Requests</h1>}</div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link
                      to="/"
                      className={
                        location.pathname === "/"
                          ? "breadcrumb-active"
                          : "breadcrumb-not-active"
                      }
                    >
                      Home
                    </Link>
                  </li>
                  <span className="breadcrumb-arrow">&gt;</span>
                  <li className="breadcrumb-item active">Unit Request</li>
                </ol>
              </div>
            </div>
          </div>
        </div>

        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div
                    className={`card-header ${isAdmin || Number(atob(roleId)) === 4 ? "adminColor" : "cambg-secondary"
                      }`}
                  >
                    <div className="add_new_vendor_listing">
                      <h3 className="card-title">Details</h3>
                      <button
                        type="button"
                        onClick={() => handleShow()}
                        className="btn btn-light text-dark bg-light"
                      >
                        Send Unit Manage Request
                      </button>
                    </div>
                  </div>

                  <div className="d-flex justify-content-end px-4 mt-2">
                    <div className="input-group w-25">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search..."
                        value={searchTerm}
                        onChange={handleInputChange}
                      />
                      <div className="input-group-append">
                        <span
                          className="input-group-text bg-primary border-0"
                          id="basic-addon2"
                        >
                          <BsSearch />
                        </span>
                      </div>
                    </div>
                  </div>

                  {isLoader ? (
                    <Loader />
                  ) : (
                    <div className="card-body table-responsive">
                      <InfiniteScroll
                        dataLength={cList.length}
                        next={handleNext}
                        hasMore={changeUnitData.length > cList.length}
                      >
                        <table className="table table-bordered">
                          <thead className="border-top">
                            <tr>
                              <th>CAM Name</th>
                              <th>CAM Email</th>
                              <th>Unit Name</th>
                              <th>Property Name</th>
                              <th>Chnageover Date</th>
                              <th>Status</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {Boolean(cList.length) ? (
                              cList?.map((data, i) => (
                                <tr key={i}>
                                  <td>{data?.cam_data?.name}</td>
                                  <td>{data?.cam_data?.email}</td>
                                  <td>{data?.unit?.unit_name}</td>

                                  <td>
                                    {data?.property?.name
                                      ? data?.property?.name
                                      : "-"}
                                  </td>
                                  <td>{data?.changeover_date ? dayjs(data?.changeover_date).format("MM/DD/YYYY") : "-"}</td>
                                  <td>
                                    {Number(data?.status) === 0 ? (
                                      <span className="badge badge-warning">
                                        Under Review
                                      </span>
                                    ) : Number(data?.status) === 1 ? (
                                      <span className="badge badge-success">
                                        Approved
                                      </span>
                                    ) : (
                                      <span className="badge badge-danger">
                                        Rejected
                                      </span>
                                    )}
                                  </td>

                                  <td>
                                    <div className="d-flex justify-content-center">

                                      <button
                                        onClick={() => handleDelete(data.id, 2)}
                                        className="btn  btn-danger btn-sm mr-1"
                                        data-tooltip-id="my-tooltip"
                                        data-tooltip-content="Delete"
                                      >
                                        <FaTrashAlt />
                                      </button>
                                      <Tooltip id="my-tooltip" place="bottom" />
                                    </div>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan={8}>
                                  <p className="text-center">
                                    No record found.
                                  </p>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </InfiniteScroll>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Request To Change Unit</Modal.Title>
        </Modal.Header>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label>Select Property</label>
                  <select
                    className="form-control"
                    {...register("property_id", {
                      required: true,
                    })}
                    onChange={handlePropertyChange}
                  >
                    {" "}
                    <option value="">Select Property</option>
                    {property?.map((propertydata, i) => (
                      <option key={i} value={propertydata?.id}>
                        {propertydata?.name}
                      </option>
                    ))}
                  </select>
                  {errors.property_id && (
                    <p className="text-danger">Property is required</p>
                  )}
                </div>
              </div>
              {unitlist.length > 0 && (
                <div className="col-md-12">
                  <div className="form-group">
                    <label>Select Unit</label>
                    <select
                      className="form-control"
                      {...register("unit_id", {
                        required: true,
                      })}
                    >
                      <option value={""}>Select Unit</option>
                      {unitlist?.map((unitData, i) => (
                        <option key={i} value={unitData?.id}>
                          {unitData?.unit_name}
                        </option>
                      ))}
                    </select>
                    {errors.unit_id && (
                      <p className="text-danger">Unit is required</p>
                    )}
                  </div>
                </div>
              )}
              <div className="col-md-12">
                <div className="form-group">
                  <label htmlFor="status">Changeover Date:</label>

                  <input
                    type="date"
                    className="form-control"
                    {...register("changeover_date", { required: true })}
                  />

                  {errors.status && (
                    <p role="alert" className="text-danger">
                      Membership Renewal date is required
                    </p>
                  )}
                </div>
              </div>

            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" type="submit" disabled={isLoading}>
              {isLoading ? (
                <>
                  <Spinner animation="border" size="sm" /> Please wait...
                </>
              ) : (
                "Send Request to CAM"
              )}
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default UnitRequest;
