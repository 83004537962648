import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";
// import SecuritySample from "../../../sampleCSV/SecuritySample.csv";
// import vendorSample from "../../../sampleCSV/vendorSample.csv";
// import unitSample from "../../../sampleCSV/unitSample.csv";
import { BsDownload } from "react-icons/bs";
import Api from "../../../Config/Api";
import Loader from "../../Elements/Loader";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import { BsSearch } from "react-icons/bs";

const Index = () => {
  const param = useParams();
  const propertyId = atob(param?.id);
  const isAdmin = useSelector((state) => state.login.isAdmin);
  const [isLoader, setIsLoader] = useState(false);
  const [uploadedCsv, setUploadedCSV] = useState();
  const [property, setPropertyData] = useState();
  const [camList, setCamList] = useState([]);
  const location = useLocation();
 

  const getUploadedCSV = async () => {
    setIsLoader(true);
    const req = {
      property_id: propertyId,
    };
    const res = await Api("getUploadedCsv", "POST", req);
    if (res?.data?.status) {
      setIsLoader(false);
      setUploadedCSV(res?.data?.data);
      setCamList(res?.data?.data);
      setPropertyData(res?.data?.propertyData);
    } else {
      toast.error("Somthing went Wrong");
    }
  };
  useEffect(() => {
    getUploadedCSV();
  }, [propertyId]);

  const [searchTerm, setSearchTerm] = useState("");

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
    const search = event.target.value;
    const filtered = camList.filter((item) => {
      return item.created_at.includes(search);
    });
    setUploadedCSV(filtered);
  };

  return (
    <>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                {<h1 className="m-0">Uploaded CSV</h1>}
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                  <Link to="/"
                      className={location.pathname === "/" ? "breadcrumb-active" : "breadcrumb-not-active"}
                    >
                      Home
                    </Link>
                    <span className="breadcrumb-arrow">&gt;</span>
                    <Link to="/user/PropertyListing"
                      className={location.pathname.startsWith("/user/PropertyListing") ? "breadcrumb-active" : "breadcrumb-not-active"}
                    >
                      Property
                    </Link>
                  </li>
                  <span className="breadcrumb-arrow">&gt;</span>
                  
                  <li className="breadcrumb-item active">Uploaded CSV
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>

        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div
                    className={`card-header ${isAdmin ? "adminColor" : "cambg-secondary"
                      }`}
                  >
                    <div className="add_new_vendor_listing">
                      <h3 className="card-title">
                        Uploaded CSV{" "}
                        {property?.name ? "for " + property?.name : ""}
                      </h3>
                    </div>
                  </div>
                  <div className="d-flex justify-content-end px-4 mt-2">
                    <div className="input-group w-25">
                      <input
                        type="date"
                        className="form-control"
                        placeholder="Search..."
                        value={searchTerm}
                        onChange={handleInputChange}
                      />
                      <div className="input-group-append">
                        <span
                          className="input-group-text bg-primary border-0"
                          id="basic-addon2"
                        >
                          <BsSearch />
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="card-body table-responsive">
                    {isLoader ? (
                      <>
                        <Loader />
                      </>
                    ) : (
                      <table className="table table-bordered">
                        <thead className="border-top">
                          <tr>
                            <th>Title</th>
                            <th>Uploaded Date</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            uploadedCsv?.length > 0 ?
                              uploadedCsv?.map((data, i) => (
                                <tr key={i}>
                                  <td>{data?.csv_for + " CSV"}</td>
                                  <td>
                                    {dayjs(data?.created_at).format("MM/DD/YYYY")}
                                  </td>
                                  <td>
                                    <a
                                      href={data?.csv_url}
                                      className="btn btn-primary"
                                      download={"Unit-sample"}
                                    >
                                      <BsDownload />
                                    </a>
                                  </td>
                                </tr>
                              )) : (
                                <>
                                  <tr>
                                    <td colSpan={4}><p className="text-center">There is no uploaded Csv yet.</p></td>
                                  </tr>
                                </>
                              )
                          }
                        </tbody>
                      </table>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Index;
