import React, { useEffect, useState } from "react";
import { Chart } from "react-google-charts";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Api from "../../Config/Api";
// import Cookies from "js-cookie";
import ResetPasswordModal from "../Elements/Forms/ResetPasswordModal";
import { useNavigate } from "react-router-dom";
import Loader from "../Elements/Loader";

export const options = {
  chart: {
    title: "",
    subtitle: "",
  },
  colors: ["#007bff"],
};


export const options2 = {
  title: "",
  is3D: true,
};


export const options3 = {
  chart: {
    title: "",
    subtitle: "",
  },
  colors: ["#007bff"],
};


const AdminDashboard = () => {
  const navigate = useNavigate();
  const isAdmin = useSelector((state) => state.login.isAdmin);
  const propertyId = useSelector((state) => state.login.propertyId);
  const isLogin = useSelector((state) => state.login.isLogin);
  const userId = useSelector((state) => state.login.userId);
  const [modalShow, setModalShow] = useState(false);
  const [vendorC, setVendorC] = useState(0);
  const [ownerC, setownerC] = useState(0);
  const [propertyC, setpropertyC] = useState(0);
  const [passesC, setpassesC] = useState(0);
  const [allData, setAllData] = useState(null);
  const [vehiclePassWeekCount, setVehiclePassWeekCount] = useState([]);
  const [vehiclePassTodayCount, setVehiclePassTodayCount] = useState([]);
  const [vehiclePassLMonthCount, setVehiclePassLMonthCount] = useState([]);
  const [isLoader, setIsLoader] = useState(true);


  const getCountDetails = async () => {
    setIsLoader(true);
    try {
      const vendorCount = await Api("vendor-count", "post", !isAdmin ? { property_id: propertyId } : {});
      const ownerCount = await Api("unit-count", "post", !isAdmin ? { property_id: propertyId } : {});
      const propertyCount = await Api("property-count", "get", {});
      const passesCount = await Api('passes_count', "POST", !isAdmin ? { property_id: propertyId } : {});

      const res = await Api('getAdminGrapahPassInfo', "POST", {});
      console.log('res', res);

      if (res?.data) {
        const transformedData = res?.data?.map((data) => [data?.date, data?.count]);
        const headerRow = ['Passes', 'Passes Count'];
        transformedData.unshift(headerRow);

        setVehiclePassWeekCount(transformedData);
        console.log('vehiclePassWeekCount', vehiclePassWeekCount);
        setIsLoader(false);
      }

      const data = await Api('getAdminTodayGrapahPassInfo', "POST", {});
      console.log('data', data);

      if (data?.data) {
        const transformedData = data?.data?.map((data) => [data?.date, data?.count]);
        const headerRow = ['Passes', 'Passes Count'];
        transformedData.unshift(headerRow);
        setVehiclePassTodayCount(transformedData);
        console.log('vehiclePassTodayCount', vehiclePassTodayCount);
        setIsLoader(false);
      }
      
      const data3 = await Api('getAdminLastMonthGrapahPassInfo', "POST", {});
      console.log('data3', data3);
      
      if (data3?.data) {
        const transformedData = data3?.data?.map((data) => [data?.week, data?.count]);
        const headerRow = ['Passes', 'Passes Count'];
        transformedData.unshift(headerRow);
        setVehiclePassLMonthCount(transformedData);
        console.log('vehiclePassLMonthCount', vehiclePassLMonthCount);
        setIsLoader(false);
      }

      if (vendorCount?.data?.status || ownerCount?.data?.status || propertyCount?.data?.status || passesCount?.data?.status) {
        setVendorC(vendorCount?.data?.data)
        setownerC(ownerCount?.data?.data)
        setpropertyC(propertyCount?.data?.data)
        
        setpassesC(passesCount?.data?.data)
      }
    } catch (error) {
      console.error('Error fetching APIs:', error);
    }
  };

  const handleAllDataClick = (event) => {
    setIsLoader(true);
    const selection = event.chartWrapper.getChart().getSelection();
    if (selection.length === 0) return;

    const selectedValue = allData[selection[0].row + 1][0]; // Use selection[0].row
    switch (selectedValue) {
      case 'Property':
        navigate('/user/PropertyListing');
        break;
      case 'Tow Company':
        navigate('/user/TowCompanyListing');
        break;
      case 'CAM':
        navigate('/user/CamListing');
        break;
      case 'Management Company':
        navigate('/user/ManagementCompanyListing');
        break;
      // Add cases for other unique values as needed
      default:
        break;
    }
    setIsLoader(false);
  };

  const handleCurrentWeekDataClick = (event) => {
    console.log("event", event);
    setIsLoader(true);
    const selection = event.chartWrapper.getChart().getSelection();
    if (selection.length === 0) return;
    console.log("vehiclePassWeekCount", vehiclePassWeekCount);
    const selectedItem = vehiclePassWeekCount[selection[0].row + 1];

    console.log("selectedItem", selectedItem);
    const passDate = selectedItem[0];
    console.log("passDate", passDate);
    navigate('/user/PassDetailByDate', {
      state: {
        created_at: passDate
      },
    }
    );
    setIsLoader(false);

  };

  const handleTodayDataClick = (event) => {
    console.log("event", event);
    setIsLoader(true);
    const selection = event.chartWrapper.getChart().getSelection();
    if (selection.length === 0) return;
    console.log("vehiclePassTodayCount", vehiclePassTodayCount);
    const selectedItem = vehiclePassTodayCount[selection[0].row + 1];

    console.log("selectedItem", selectedItem);
    const passDate = selectedItem[0];
    console.log("passDate", passDate);
    navigate('/user/PassDetailByDate', {
      state: {
        created_at: passDate
      },
    }
    );
    setIsLoader(false);

  };



  useEffect(() => {
    const fetchData = async () => {
      try {
        const data2 = await Api('getAllDataCount', 'POST', {});
        console.log("data2", data2?.data);

        if (data2?.data) {
          const transformedData = data2?.data?.data;
          setAllData(transformedData);
          console.log("allData", allData);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);



  useEffect(() => {
    if (isLogin) { getCountDetails() };
  }, []);


  const getModifyCamData = async () => {
    const { data } = await Api("cam-get-user", "POST", {
      id: userId,
    });
    const CamData = data?.data;
    setModalShow(
      Number(CamData?.isChanged) === 1 || Number(CamData?.isChanged) === 0 ? false : true
    );
  };

  useEffect(() => {
    getModifyCamData();
  }, [userId]);

  return (
    <>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Admin Dashboard</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Dashboard v1</li>
                </ol>
              </div>
            </div>
          </div>
        </div>

        <section className="content">
          <ResetPasswordModal
            show={modalShow}
            onHide={() => setModalShow(false)}
          />

          <div className="container-fluid">
            <div className="row">
              {isAdmin && (
                <div className="col-lg-3 col-6">

                  <div className="small-box bg-info">
                    <div className="inner">
                      <h3>{propertyC}</h3>

                      <p>Property</p>
                    </div>
                    <div className="icon">
                      <i className="ion ion-bag"></i>
                    </div>
                    <span className="small-box-footer">
                      <Link
                        to="/user/PropertyListing"

                        data-tooltip-id="detail"
                        data-tooltip-content="Property Detail"
                        className="text-white"
                      >
                        More Info
                      </Link> </span>
                  </div>
                </div>
              )}
              <div className={`col-lg-${isAdmin ? 3 : 4} col-6`}>

                <div className="small-box bg-success">
                  <div className="inner">
                    <h3>{vendorC}</h3>

                    <p>Vendor</p>
                  </div>
                  <div className="icon">
                    <i className="ion ion-stats-bars"></i>
                  </div>
                  <span className="small-box-footer" > <Link
                        to="/user/GraphVendorListing"

                        data-tooltip-id="detail"
                        data-tooltip-content="Property Detail"
                        className="text-white"
                      >
                        More Info
                      </Link> </span>
                </div>
              </div>

              <div className={`col-lg-${isAdmin ? 3 : 4} col-6`}>

                <div className="small-box bg-warning">
                  <div className="inner">
                    <h3>{ownerC}</h3>

                    <p>Owner</p>
                  </div>
                  <div className="icon">
                    <i className="ion ion-person-add"></i>
                  </div>
                  <span className="small-box-footer" > <Link
                        to="/user/GraphOwnerListing"

                        data-tooltip-id="detail"
                        data-tooltip-content="Property Detail"
                        className="text-white"
                      >
                        More Info
                      </Link> </span>
                </div>
              </div>

              <div className={`col-lg-${isAdmin ? 3 : 4} col-6`}>

                <div className="small-box bg-danger">
                  <div className="inner">
                    <h3>{passesC}</h3>

                    <p>Vehicle Passes</p>
                  </div>
                  <div className="icon">
                    <i className="ion ion-pie-graph"></i>
                  </div>
                  <a className="small-box-footer"> <Link
                    to="/user/PaymentHistory"

                    data-tooltip-id="detail"
                    data-tooltip-content="Property Detail"
                    className="text-white"
                  >
                    More Info
                  </Link>  <i className="fas fa-arrow-circle-right"></i></a>
                </div>
              </div>

            </div>

            <div className="row">
              <section className="col-lg-6 connectedSortable">
                <div className="card">
                  <div className={`card-header ${isAdmin ? 'adminColor' : ''}`}>
                    <h3 className="card-title">
                      <i className="fas fa-chart-bar mr-1"></i>
                      Current Week
                    </h3>
                  </div>
                  <div className="card-body">
                    <div className="tab-content p-0">
                      {isLoader ? (
                        <Loader />
                      ) : (
                        <Chart
                          chartType="Bar"
                          width="100%"
                          height="400px"
                          data={vehiclePassWeekCount}
                          options={options}
                          chartEvents={[
                            {
                              eventName: 'select',
                              callback: handleCurrentWeekDataClick,
                            },
                          ]}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </section>



              <section className="col-lg-6 connectedSortable">
                <div className="card">
                  <div className={`card-header ${isAdmin ? 'adminColor' : ''}`}>
                    <h3 className="card-title">
                      <i className="fas fa-chart-bar mr-1"></i>
                      Today
                    </h3>
                  </div>
                  <div className="card-body">
                    <div className="tab-content p-0">
                      {isLoader ? (
                        <Loader />
                      ) : (
                        <Chart
                          chartType="Bar"
                          width="100%"
                          height="400px"
                          data={vehiclePassTodayCount}
                          options={options3}
                          chartEvents={[
                            {
                              eventName: 'select',
                              callback: handleTodayDataClick,
                            },
                          ]}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </section>

              <section className="col-lg-6 connectedSortable">
                <div className="card">
                  <div className={`card-header ${isAdmin ? 'adminColor' : ''}`}>
                    <h3 className="card-title">
                      <i className="fas fa-chart-PieChart mr-1"></i>
                      All Data
                    </h3>
                  </div>
                  <div className="card-body">
                    <div className="tab-content p-0">
                      {isLoader ? (
                        <Loader />
                      ) : (
                        allData ? (
                          <Chart
                            chartType="PieChart"
                            width="100%"
                            height="400px"
                            data={allData}
                            options={options2}
                            chartEvents={[
                              {
                                eventName: 'select',
                                callback: handleAllDataClick,
                              },
                            ]}
                          />
                        ) : (
                          <div>Loading...</div>
                        )
                      )}
                    </div>
                  </div>
                </div>
              </section>

              <section className="col-lg-6 connectedSortable">
                <div className="card">
                  <div className={`card-header ${isAdmin ? 'adminColor' : ''}`}>
                    <h3 className="card-title">
                      <i className="fas fa-chart-bar mr-1"></i>
                      Last Month
                    </h3>
                  </div>
                  <div className="card-body">
                    <div className="tab-content p-0">
                      {isLoader ? (
                        <Loader />
                      ) : (
                        <Chart
                          chartType="Bar"
                          width="100%"
                          height="400px"
                          data={vehiclePassLMonthCount}
                          options={options3}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}

export default AdminDashboard;