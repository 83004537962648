import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
// import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { Card, Spinner } from "react-bootstrap";
import Loader from "../Elements/Loader";
import Api from "../../Config/Api";
import { getProperty } from "../../Reducers/RenterProperty";
import { BsFillEyeFill, BsPrinterFill } from "react-icons/bs";
import Modal from "react-bootstrap/Modal";
import dayjs from "dayjs";

const ViewPrintPass = () => {
  const [modalShow, setModalShow] = useState(false);
  const isAdmin = useSelector((state) => state.login.isAdmin);
  const userId = useSelector((state) => state.login.userId);

  const [isLoading, setLoading] = useState(false);
  const [printPassLoading, setPrintPassLoading] = useState(false);
  const [passData, setPassData] = useState([]);
  const [passStatus, setPassStatus] = useState([]);
  const [passInfo, setPassInfo] = useState([]);
  const RenterProperty = useSelector(getProperty);
  const background_image = RenterProperty?.bg_image
    ? process.env.REACT_APP_AWS_S3_BUCKET_URL + "images/" + RenterProperty?.bg_image
    : "";
  const property_logo = RenterProperty?.logo
    ? process.env.REACT_APP_AWS_S3_BUCKET_URL +
    "images/" + RenterProperty?.logo
    : "";

  const sectionStyle = {
    backgroundImage: `url(${background_image})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  };

  const getAllpassInfoByUserID = async () => {
    setLoading(true);
    const res = await Api("getAllpassInfoByUserID", "POST", {
      user_id: userId,
    });

    console.log("res", res);
    if (res?.data?.status) {
      setLoading(false);
      setPassData(res?.data?.passData);
    }

  };

  useEffect(() => {
    getAllpassInfoByUserID();
  }, [userId]);

  const printParkingPass = async (orderId) => {
    setPrintPassLoading(true);
    const res = await Api("getAllpassInfoByUserID", "POST", {
      id: orderId,
    });
    if (res?.data?.status) {
      setPrintPassLoading(false);
      setPassInfo(res?.data?.passData);
      setModalShow(true);
    }
  };

  const getStatusForOrder = (Order) => {
    const startDate = dayjs(Order?.billing_details?.vehiclepass?.arrival_date);
    const endDate = dayjs(Order?.billing_details?.vehiclepass?.departure_date);
    const currentDate = dayjs();

    if (Order?.billing_details?.vehiclepass?.issuccess === 0 || Order?.billing_details?.vehiclepass?.Is_rental_vehicle === 0) {
      return "incomplete";
    } else if (currentDate.isBefore(startDate)) {
      return "future";
    } else if (currentDate.isAfter(endDate)) {
      return "closed";
    } else {
      return "current";
    }
  };

  return (
    <>
      <main>
        <section
          className="py-5 guest_section bannersection"
          style={sectionStyle}
        >
          <div
            className="container-fluid"
            style={{
              paddingLeft: "269px",
            }}
          >
            <Modal
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              show={modalShow}
              onHide={() => setModalShow(false)}
            >
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                  Passes & Printouts
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {/* <div className="row">
                  <div className="col-lg-6">
                    <Card>
                      <Card.Body>
                        <h5>
                          Parking Pass -{" "}
                          {passInfo?.billing_details?.property?.name}
                        </h5>
                        <h5 className="text-center">
                          {dayjs(
                            passInfo?.billing_details?.vehiclepass?.arrival_date
                          ).format("MM/DD/YYYY")}{" "}
                          -{" "}
                          {dayjs(
                            passInfo?.billing_details?.vehiclepass
                              ?.departure_date
                          ).format("MM/DD/YYYY")}
                        </h5>
                        <Card.Text>
                          <span>
                            {" "}
                            {
                              passInfo?.billing_details?.property?.address
                            } -{" "}
                            {
                              passInfo?.billing_details?.vehiclepass?.unit
                                ?.unit_name
                            }
                          </span>

                          <h4>
                            Pass#{" "}
                            {passInfo?.billing_details?.vehiclepass?.order_uuid}
                          </h4>

                          <div className="d-flex justify-content-between">
                            <div>
                              <img
                                src={
                                  process.env.REACT_APP_API_URL +
                                  "images/" +
                                  passInfo?.billing_details?.property?.logo
                                }
                                alt="logo"
                                className="w-50 cambg-primary"
                              />
                            </div>
                            <div className="text-end">
                              <img
                                src="https://developerteam.in/akcess-parking-api/public/qr_codes/1684742367.png"
                                alt="logo"
                                className=" w-50"
                              />
                            </div>
                          </div>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </div>

                  <div className="col-lg-6">
                    <Card>
                      <Card.Body>
                        <h5>
                         
                          {passInfo?.billing_details?.property?.name} - Parking Rules
                        </h5>
                       
                        <Card.Text>
                          <span>
                            {" "}
                            {
                              passInfo?.billing_details?.property?.address
                            } -{" "}
                            {
                              passInfo?.billing_details?.vehiclepass?.unit
                                ?.unit_name
                            }
                          </span>

                          
                              {JSON.parse(passInfo?.billing_details?.vehiclepass
                              ?.rules)?.map((rule,i)=>(
                                <p>
                                  {rule.split(' ').slice(0, 12).join(' ')}...
                                </p>
                              ))}
                                <img
                                src={
                                  process.env.REACT_APP_API_URL +
                                  "images/" +
                                  passInfo?.billing_details?.property?.logo
                                }
                                alt="logo"
                                className="w-25 cambg-primary"
                              />
                            
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </div>

                      <div className="col-lg-6">
                    <Card>
                      <Card.Body>
                        <h5>
                        Parking Pass Receipt -{" "}
                          {passInfo?.billing_details?.property?.name}
                        </h5>
                        <h5 className="text-center">
                          {dayjs(
                            passInfo?.billing_details?.vehiclepass?.arrival_date
                          ).format("MM/DD/YYYY")}{" "}
                          -{" "}
                          {dayjs(
                            passInfo?.billing_details?.vehiclepass
                              ?.departure_date
                          ).format("MM/DD/YYYY")}
                        </h5>
                        <Card.Text>
                          <span>
                         
                            ${(passInfo?.amount/100).toFixed(2)}
                          </span>

                          <h4>
                            Pass#{" "}
                            {passInfo?.billing_details?.vehiclepass?.order_uuid}
                          </h4>

                          <div className="d-flex justify-content-between">
                            <div>
                              <img
                                src={
                                  process.env.REACT_APP_API_URL +
                                  "images/" +
                                  passInfo?.billing_details?.property?.logo
                                }
                                alt="logo"
                                className="w-50 cambg-primary"
                              />
                            </div>
                            <div className="text-end">
                              <img
                                src="https://developerteam.in/akcess-parking-api/public/qr_codes/1684742367.png"
                                alt="logo"
                                className=" w-50"
                              />
                            </div>
                          </div>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </div>


                    <div className="col-lg-6">
                    <Card>
                      <Card.Body>
                        <h5>
                        Parking Pass Refund -{" "}
                          {passInfo?.billing_details?.property?.name}
                        </h5>
                        <h5 className="text-center">
                          {dayjs(
                            passInfo?.billing_details?.vehiclepass?.arrival_date
                          ).format("MM/DD/YYYY")}{" "}
                          -{" "}
                          {dayjs(
                            passInfo?.billing_details?.vehiclepass
                              ?.departure_date
                          ).format("MM/DD/YYYY")}
                        </h5>
                        <Card.Text>
                          <span>
                           
                            ${( passInfo?.amount/100).toFixed(2)} 
                          </span>

                          <h4>
                            Pass#{" "}
                            {passInfo?.billing_details?.vehiclepass?.order_uuid}
                          </h4>

                          <div className="d-flex justify-content-between">
                            <div>
                              <img
                                src={
                                  process.env.REACT_APP_API_URL +
                                  "images/" +
                                  passInfo?.billing_details?.property?.logo
                                }
                                alt="logo"
                                className="w-50 cambg-primary"
                              />
                            </div>
                            <div className="text-end">
                              <img
                                src="https://developerteam.in/akcess-parking-api/public/qr_codes/1684742367.png"
                                alt="logo"
                                className=" w-50"
                              />
                            </div>
                          </div>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </div>

                </div> */}
              </Modal.Body>
            </Modal>
            <div className="new_guest_form" style={{ marginBottom: "225px" }}>

              <section className="content">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="card">
                        <div className={`card-header ${isAdmin ? "adminColor" : "cambg-secondary"}`}>
                          <div className="add_new_vendor_listing">
                            <h3 className="card-title">Details</h3>
                          </div>
                        </div>
                        {isLoading ? (
                          <Loader />
                        ) : (
                          <div className="card-body table-responsive">
                            <table className="table table-bordered">
                              <thead className="border-top">
                                <tr>
                                  {/* <th scope="col">Pass</th> */}
                                  <th scope="col">Pass Start Date</th>
                                  <th scope="col">Status</th>
                                  <th scope="col">Total</th>
                                  <th scope="col">Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {passData.length > 0 ? (
                                  passData?.map((Order, i) => (
                                    <tr key={i}>
                                      {/* <th>
                                        {console.log("passStatus", Order)}
                                      </th> */}
                                      <td>{dayjs(Order?.created_at).format("MM/DD/YYYY")}</td>
                                      <td>
                                        <span className={`badge badge-${(getStatusForOrder(Order)==="current" || getStatusForOrder(Order) ==="future") ? "success" : "danger"}`}>
                                          {getStatusForOrder(Order)}
                                        </span>
                                      </td>
                                      <td>${(Order?.amount / 100).toFixed(2)}</td>
                                      <td>
                                        <Link
                                          to={"/ViewPass"}
                                          state={{ id: Order?.id }}
                                        >
                                          <span className="btn btn-primary btn-sm mx-">
                                            <BsFillEyeFill />
                                          </span>
                                        </Link>
                                        <Link
                                          to={"/PassesPrintout"}
                                          state={{ id: Order?.id }}
                                        >
                                          <span className="btn btn-primary btn-sm mx-2">
                                            <BsPrinterFill />
                                          </span>
                                        </Link>
                                      </td>
                                    </tr>
                                  ))
                                ) : (
                                  <>
                                    <tr>
                                      <td colSpan={6}>
                                        <h4 className="text-center text-secondary">
                                          {" "}
                                          No Order Found Yet.
                                        </h4>
                                      </td>
                                    </tr>
                                  </>
                                )}
                              </tbody>
                            </table>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </section>
      </main>

    </>
  );
};

export default ViewPrintPass;
