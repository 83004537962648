import React, { useEffect, useState } from 'react';
import { Link, useLocation } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { BsSearch } from "react-icons/bs";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import dayjs from 'dayjs';
import Api from '../../../../Config/Api';
import Button from "react-bootstrap/Button";
import { Spinner } from "react-bootstrap";
// import { BsFillEyeFill, BsPrinterFill } from "react-icons/bs";
import { FaEdit, FaTrashAlt } from 'react-icons/fa';
import { Tooltip } from 'react-bootstrap';
import Loader from "../../../Elements/Loader";
import { toast } from 'react-toastify';
import useSweetAlert from "../../../../Hooks/useSweetAlert";
import Axios from 'axios';


const PassRequest = () => {
    const {
        register,
        handleSubmit,
        reset,
        // formState: { errors },
    } = useForm();
    const { id } = useParams();
    const location = useLocation();
    const [isLoading, setLoading] = useState(false);
    const [isLoader, setIsLoader] = useState(true);
    const [camList, setCamList] = useState([]);
    const isAdmin = useSelector((state) => state.login.isAdmin);
    const userId = useSelector((state) => state.login.userId);
    const roleId = useSelector((state) => state.login.roleId);
    const [unitList, setUnitList] = useState([]);
    const [unitData, setUnitData] = useState([]);
    const [manageUnitId, setManageUnitId] = useState(0);
    // const [vehiclePassOldData, setVehiclePassOldData] = useState([]);
    const [vehiclePassSendData, setVehiclePassSendData] = useState([]);
    const { ManagementCo } = useParams();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const { deletefun, isDeleted } = useSweetAlert();
    const [prop, setProp] = useState([]);
    const [msg, setmsg] = useState("");
    const [message, setMessage] = useState("");


    const handleShow = (passData, propertyUnits, mgmtUnitId, message) => {
        console.log("propertyUnits", propertyUnits);
        setManageUnitId(mgmtUnitId)
        setVehiclePassSendData(passData)
        setUnitData(propertyUnits?.units)
        reset({
            arrival_date: passData?.arrival_date,
            departure_date: passData?.departure_date,
            pass_status: passData?.status,
            unit_name: propertyUnits?.unit_name,
            unit_id: passData?.unit_id,
            old_arrival_date: passData?.arrival_date,
            old_departure_date: passData?.departure_date,
            old_pass_status: passData?.pass_status,
            old_unit_id: passData.unit_id,
            message: message,

        })
        setShow(true);
        console.log('passData', passData);
        console.log('propertyUnits', propertyUnits);
        console.log('mgmtUnitId', mgmtUnitId);
        console.log("message",message);
    };

    const getPassesDetails = async () => {
        setIsLoader(true);
        const reqData = {
            id: atob(id),
            // id: managementCo,
        };
        const { data } = await Api("get_all_passes_for_mgmt", "POST", reqData);
        // console.log('data', data);
        if (data?.status) {
            const unitrep = data?.data.filter((data) => data?.units?.vehiclepasses);
            // console.log("unitrep", unitrep.map((data) => data?.units?.vehiclepasses));
            const unitrep2 = unitrep.map((data) => data?.units).flat();

            setUnitList(unitrep2);
            setCamList(unitrep2);
            setIsLoader(false);
        }
    };
    useEffect(() => {
        getPassesDetails();
    }, [])
    // console.log("unitList", unitList);

    const handleDelete = async (idToDelete) => {
        //  deletefun(idToDelete, "delete-management-co");

        if (isDeleted) {
        }
        if (window.confirm("Are You Sure You Want to delete it?")) {
            const { data } = await Api("deleteManageCoPassList", "POST", { id: idToDelete });
            if (data?.status) {
                toast.success("Pass has been deleted successfully!", {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
            getPassesDetails();
        }
    };


    // const handleOldData = () => {

    //     setData('New data value'); // Update the "data" state with the new value
    //   };
    // console.log("vehiclePassSendData", vehiclePassSendData);

    const billing_id = vehiclePassSendData?.vehiclepasses?.map((data) => data?.billingdetail?.id);
    // console.log("billing_id>>>>>>>>", billing_id);
    // console.log('vehiclePassSendData', manageUnitId);
    const onSubmit = async (d) => {
        setLoading(true);
        console.log("hello");
        const getPassRequest = {
            mgmt_unit_table_id: manageUnitId,
            arrival_date: d?.arrival_date,
            departure_date: d?.departure_date,
            vehicle_pass_id: vehiclePassSendData?.id,
            pass_status: d?.pass_status,
            old_arrival_date: d?.old_arrival_date,
            old_departure_date: d?.old_departure_date,
            old_pass_status: d?.old_pass_status,
            old_unit_id: vehiclePassSendData?.unit_id,
            unit_id: d.unit_id,
            mgmt_id: userId,
            property_id: vehiclePassSendData?.property_id,
            billing_id: vehiclePassSendData?.billingdetail?.id,
            message: d.message,

        }


        const { data } = await Api('send_pass_detail_change_request_to_cam', "POST", getPassRequest);
        console.log("data", data);
        if (Boolean(data?.status)) {
            setShow(false)
            toast.success(data?.message)
            setMessage(data?.text);
            getPassesDetails();
            setLoading(false);
        } else {
            setShow(false)
            toast.error(data?.message)
            setLoading(false);
        }
        // console.log("dDDDDDDDDD", d);
    }
    const [searchTerm, setSearchTerm] = useState("");
    // console.log("camList",camList);
    const handleInputChange = (event) => {
        const search = event.target.value.toLowerCase();
        setSearchTerm(search);

        const filteredCamList = camList.filter((item) =>
            item?.billingdetail?.fname?.toLowerCase().includes(search.toLowerCase()) || item?.billingdetail?.lname?.toLowerCase().includes(search.toLowerCase()) || item?.billingdetail?.email?.toLowerCase().includes(search.toLowerCase())
        );


        // console.log('filteredCamList', filteredCamList);
        setUnitList(filteredCamList);
    };

    const getStatusForPass = (data) => {
        const departureDate = dayjs(data?.departure_date);
        const currentDate = dayjs();

        if (currentDate.isAfter(departureDate) || Number(data?.pass_status) === 0) {
            return "Inactive";
        } else {
            return "Active";
        }
    };

    // console.log('unitList', unitList);
    return (
        <>
            <main>
                <section className="">
                    <div
                        className="container-fluid"
                    // style={{
                    //     paddingLeft: "269px",
                    // }}
                    >


                        <div className="content-wrapper">
                            <div className="content-header">
                                <div className="container-fluid">
                                    <div className="row mb-2">
                                        <div className="col-sm-6">
                                            {<h1 className="m-0">Pass Requests</h1>}
                                        </div>
                                        <div className="col-sm-6">
                                            <ol className="breadcrumb float-sm-right">
                                                <li className="breadcrumb-item">
                                                    <Link to="/"
                                                        className={location.pathname === "/" ? "breadcrumb-active" : "breadcrumb-not-active"}
                                                    >
                                                        Home
                                                    </Link>
                                                </li>
                                                <span className="breadcrumb-arrow">&gt;</span>
                                                <li className="breadcrumb-item active">Passes Requests</li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <section className="content">
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="card">
                                                <div className={`card-header ${isAdmin || Number(atob(roleId)) === 4 ? "adminColor" : "cambg-secondary"}`}>
                                                    <div className="add_new_vendor_listing">
                                                        <h3 className="card-title">Details</h3>

                                                    </div>
                                                </div>

                                                <div className="d-flex justify-content-end px-4 mt-2">
                                                    <div className="input-group w-25">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Search..."
                                                            value={searchTerm}
                                                            onChange={handleInputChange}
                                                        />
                                                        <div className="input-group-append">
                                                            <span
                                                                className="input-group-text bg-primary border-0"
                                                                id="basic-addon2"
                                                            >
                                                                <BsSearch />
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>

                                                {isLoader ? (
                                                    <Loader />
                                                ) : (
                                                    <div className="card-body table-responsive">
                                                        <table className="table table-bordered">
                                                            <thead className="border-top">
                                                                <tr>
                                                                    <th>Pass ID</th>
                                                                    <th>Name</th>
                                                                    <th>Email</th>
                                                                    <th>Arival Date</th>
                                                                    <th>Departure Date</th>
                                                                    <th>Property</th>
                                                                    <th>Unit</th>
                                                                    <th>State</th>
                                                                    <th>Change Pass Status</th>
                                                                    <th>Total</th>
                                                                    <th>Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {unitList?.map((Order, i) => Order?.vehiclepasses?.map((data, index) => (
                                                                    Order?.property !== null ? (
                                                                        data?.billingdetail !== null ? (
                                                                            <tr key={index}>
                                                                                {/* {console.log('data', data)} */}
                                                                                <th>{data?.order_uuid}</th>
                                                                                <td>{(data?.billingdetail) ?
                                                                                    (data?.billingdetail?.fname + " " + data?.billingdetail?.lname) : ("_")}</td>
                                                                                <td>{data?.billingdetail?.email}</td>
                                                                                <td>{dayjs(data?.arrival_date).format("MM/DD/YYYY")}</td>
                                                                                <td>{dayjs(data?.departure_date).format("MM/DD/YYYY")}</td>
                                                                                <td>{Order?.property?.name}</td>
                                                                                <td>{Order?.unit_name}</td>
                                                                                <td>
                                                                                    <span className={`badge badge-${(getStatusForPass(data) === "Active") ? "success" : "danger"}`}>
                                                                                        {getStatusForPass(data)}
                                                                                    </span>
                                                                                </td>
                                                                                {/* <td>{Order?.mgmt_change_pass?.status}</td> */}
                                                                                {(data?.change_pass_status === null) ? (
                                                                                    <td>
                                                                                        <span className="badge badge-success">
                                                                                            Acitve
                                                                                        </span>
                                                                                    </td>
                                                                                ) : Number(data?.change_pass_status) === 1 ? (
                                                                                    <td>
                                                                                        <span className="badge badge-primary">
                                                                                            Approved
                                                                                        </span>
                                                                                    </td>
                                                                                ) : Number(data?.change_pass_status) === 2 ? (
                                                                                    <td>
                                                                                        <span className="badge badge-danger">
                                                                                            Rejected
                                                                                        </span>
                                                                                    </td>
                                                                                ) : (
                                                                                    <td>
                                                                                        <span className="badge badge-warning">
                                                                                            Under Review
                                                                                        </span>
                                                                                    </td>
                                                                                )
                                                                                }


                                                                                <td>${Number(data?.pass_fee)}.00</td>


                                                                                <td>

                                                                                    <div className="d-flex justify-content-center">
                                                                                    <Button
                                                                                        className="btn btn-info btn-sm mr-1"
                                                                                        onClick={() => handleShow(data, data?.id, message)}
                                                                                        data-tooltip-id="assign"
                                                                                        data-tooltip-content="Edit Pass Info"
                                                                                    >
                                                                                        <FaEdit />
                                                                                    </Button>
                                                                                    <Tooltip id="assign" place="bottom" />
                                                                                    <button
                                                                                        onClick={() => handleDelete(data, data?.units?.property, data?.id)}
                                                                                        className="btn  btn-danger btn-sm mr-1"
                                                                                        data-tooltip-id="my-tooltip"
                                                                                        data-tooltip-content="Delete"
                                                                                    >
                                                                                        <FaTrashAlt />
                                                                                    </button>
                                                                                    <Tooltip id="my-tooltip" place="bottom" />

                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        ) : ("")) : ("")
                                                                )))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </section>
            </main>



            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Request Pass Details Changes to CAM
                        <h6>(If you want to change Arrival Date, Departure Date, Status and Units then you can send request to CAM)</h6>
                    </Modal.Title>

                </Modal.Header>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Modal.Body>
                        <div className="row">

                            <div className="col-md-12">
                                <div className="form-group">
                                    <label htmlFor="arrivalDate">Arrival Date</label>
                                    <input
                                        type="date"
                                        className="form-control"
                                        {...register("arrival_date", { required: true })}
                                    />

                                </div>
                            </div>

                            <div className="col-md-12">
                                <div className="form-group">
                                    <label htmlFor="departureDate">Departure Date</label>
                                    <input
                                        type="date"
                                        className="form-control"
                                        {...register("departure_date", { required: true })}
                                    />

                                </div>
                            </div>

                            <div className="col-md-12">
                                <div className="form-group">
                                    <label htmlFor="status">Status</label>
                                    <select
                                        {...register("pass_status", { required: true })}
                                        className="form-select">
                                        <option value="1">Active</option>
                                        <option value="0">InActive</option>
                                    </select>

                                </div>
                            </div>

                            <div className="col-md-12">
                                <div className="form-group">
                                    <label htmlFor="status">Units</label>
                                    <select
                                        {...register("unit_id", { required: true })}
                                        className="form-select">
                                        {unitList?.map((Order, i) => (
                                            <option key={i} value={Order?.id}>
                                                {Order?.unit_name}
                                            </option>
                                        ))}
                                    </select>

                                </div>
                            </div>

                            <div className="col-md-12">
                                <div className="form-group">
                                    <label htmlFor="message">Enter Message</label>
                                    <textarea
                                        className="form-control"
                                        placeholder="type here..."
                                        cols="30"
                                        rows="6"
                                        {...register('message', { required: true })}
                                    ></textarea>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="primary" type="submit" disabled={isLoading}>
                            {isLoading ? (
                                <>
                                    <Spinner animation="border" size="sm" /> Please wait...
                                </>
                            ) : (
                                "Send"
                            )}
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>


        </>
    );
};

export default PassRequest