import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, Link, useNavigate } from "react-router-dom";
import Api from "../../../Config/Api";
import Loader from "../../Elements/Loader";
import { useParams } from "react-router-dom";

const VendorDetail = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const isAdmin = useSelector((state) => state.login.isAdmin);
  const roleId = useSelector((state) => state.login.roleId);
  const [isLoader, setIsLoader] = useState(true);
  const [unitList, setUnitList] = useState([]);
  const [assignPropertyDetail, setAssignPropertyDetail] = useState([]);
  const [assignManagementCo, setAssignManagementCo] = useState([]);

  const { id, ManagementCo } = useParams();
  const { user_id } = useParams();

  const [userId, setUserId] = useState(user_id ? atob(user_id) : "");
  const [propertyId, setPropertyId] = useState(id ? atob(id) : "");
  const [managementCo, setManagementCoId] = useState(
    ManagementCo ? atob(ManagementCo) : ""
  );
  console.log("userId",location?.state?.userId);

  const getModifyProperty = async () => {
    setIsLoader(true);
    const { data } = await Api("get-unit", "POST", {
      id: location?.state?.id,
    });
    console.log("data>>>>>>.",data);
    if (data?.status) {
      setIsLoader(false);
      setUnitList(data?.data);
      setAssignPropertyDetail(data?.data?.property);
      setAssignManagementCo(data?.data?.users);
    }
  };
console.log("location?.state?.propertyId",location?.state?.propertyId);
  useEffect(() => {
    if (!!location?.state?.id) {
      getModifyProperty();
    }
  }, [location?.state?.id]);

  return (
    <>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Unit Details</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/"
                      className={location.pathname === "/" ? "breadcrumb-active" : "breadcrumb-not-active"}
                    >
                      Home
                    </Link>
                    {isAdmin ? (
                      <>
                        <span className="breadcrumb-arrow">&gt;</span>
                        <Link
                          to="/user/PropertyListing"
                          className={
                            location.pathname.startsWith("/user/PropertyListing")
                              ? "breadcrumb-active"
                              : "breadcrumb-not-active"
                          }
                        >
                          Property
                        </Link>
                      </>
                    ) : ("")}

                    <span className="breadcrumb-arrow">&gt;</span>
                    <Link to={isAdmin || Number(atob(roleId)) === 2 ? 
                    (Boolean(location?.state?.userId) === false ? (("/user/OwnerListing/" + btoa(location?.state?.propertyId))) : 
                      ("/user/ManageOwnerListing/"+ btoa(location?.state?.propertyId) + "/" + btoa(location?.state?.userId))) : 
                    ("/user/ManageOwnerListing/"+ btoa(location?.state?.propertyId) + "/" + btoa(location?.state?.userId))}



                      className={isAdmin || Number(atob(roleId)) === 2 ? (Boolean(location?.state?.userId) === false ?  (location.pathname.startsWith("/user/OwnerListing/" + btoa(location?.state?.propertyId)) ? "breadcrumb-active" : "breadcrumb-not-active") : (location.pathname.startsWith("/user/ManageOwnerListing/"+ btoa(location?.state?.propertyId) + "/" + btoa(location?.state?.userId)) ? "breadcrumb-active" : "breadcrumb-not-active")) : 
                      (location.pathname.startsWith("/user/ManageOwnerListing/"+ btoa(location?.state?.propertyId) + "/" + btoa(location?.state?.userId)) ? "breadcrumb-active" : "breadcrumb-not-active")}
                    >
                      Unit
                    </Link>
                  </li>
                  <span className="breadcrumb-arrow">&gt;</span>
                  <li className="breadcrumb-item active">
                    Unit Details
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>

        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card card-primary">
                  <div
                    className={`card-header ${isAdmin || Number(atob(roleId)) === 4 ? "adminColor" : "cambg-secondary"
                      }`}
                  >

                    <div className="d-flex justify-content-between">
                      <div>
                        <h3 className="card-title mt-2">Unit Details</h3>
                      </div>

                      <div className="">
                        <Link
                          className="btn btn-light text-dark btn-sm bg-light mx-2"
                          to={isAdmin || Number(atob(roleId)) === 2 ? ("/user/OwnerListing/" + btoa(location?.state?.propertyId)) : ("/user/ManageOwnerListing/"+ btoa(propertyId) + "/" + btoa(userId))}>
                          Back
                        </Link>
                        <Link
                          className="btn btn-light btn-sm text-dark bg-light"
                          to={"/user/OwnerForm"} state={{ id: unitList.id, isEdit: true }}>
                          Edit
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div className="bg-white p-3 rounded p-3 shadow">
                    <div>
                      <div className="mb-3">
                        <div className="row">
                          <div className="col-lg-6">
                            <form>
                              <div className="d-flex gap-2 justify-content-end">
                                <div className="flex-shrink-0">
                                  <span className="badge bg-warning">
                                  </span>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                      <div className="table-responsive">
                        <div>
                          <div
                            style={{
                              width: "100%",
                              padding: "0px 40px",
                              borderRadius: "10px",
                              backgroundColor: " rgb(255, 255, 255)",
                              margin: "0px auto",
                              border: "1px solid rgb(221, 221, 221)",
                              fontSize: "14px",
                              color: "rgb(0, 0, 0)",
                            }}
                          >
                            <div>
                              <table
                                style={{ width: "100%", marginBottom: "0px" }}
                              >
                                <tbody>
                                  <tr>
                                    <td>
                                      {isLoader ? (
                                        <Loader />
                                      ) : (
                                        <div className="mt-3">
                                          <table
                                            className="m-0"
                                            style={{ width: "100%" }}
                                          >
                                            <tbody>
                                              <tr>
                                                <td>
                                                  <div
                                                    style={{
                                                      fontStyle: "italic",
                                                      marginBottom: "10px",
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    Owner Details:
                                                  </div>
                                                  <table
                                                    className="table table-bordered table-striped table-sm m-0"
                                                    style={{ width: "100%" }}
                                                  >
                                                    <tbody>
                                                      <tr>
                                                        <td>Unit Name</td>
                                                        <th>
                                                          {unitList?.unit_name}
                                                        </th>
                                                        <td
                                                          rowSpan="24"
                                                          style={{
                                                            textAlign: "center",
                                                            verticalAlign:
                                                              "top",
                                                          }}
                                                        >
                                                          {/* <div
                                                            style={{
                                                              border:
                                                                "1px solid rgb(221, 221, 221)",
                                                              padding: "10px",
                                                              width: "150px",
                                                              textAlign:
                                                                "center",
                                                              margin:
                                                                "0px auto",
                                                            }}
                                                          > */}
                                                          {/* <img
                                                            className="w-100"
                                                            alt="Applicant"
                                                            src="https://developerteam.in/nsdmis-api/public/images/sdrf/118566703-1679303318.png"
                                                          /> */}
                                                          {/* </div> */}
                                                        </td>
                                                      </tr>

                                                      <tr>
                                                        <td
                                                          style={{
                                                            width: "270px",
                                                          }}
                                                        >
                                                          First Name
                                                        </td>
                                                        <td>
                                                          <b>
                                                            {unitList?.firstname}
                                                          </b>
                                                        </td>
                                                      </tr>
                                                      <tr>
                                                        <td> Last Name</td>
                                                        <td>
                                                          <b>
                                                            {unitList?.lastname}
                                                          </b>
                                                        </td>
                                                      </tr>
                                                      <tr>
                                                        <td>Email</td>
                                                        <td>
                                                          <b>
                                                            {unitList?.email}
                                                          </b>
                                                        </td>
                                                      </tr>

                                                      <tr>
                                                        <td>Phone</td>
                                                        <td>
                                                          <b>
                                                            {unitList?.phone}
                                                          </b>
                                                        </td>
                                                      </tr>


                                                      <tr>
                                                        <td>Address</td>
                                                        <td>
                                                          <b>
                                                            {unitList?.address}
                                                          </b>
                                                        </td>
                                                      </tr>
                                                      <tr>
                                                        <td>State</td>
                                                        <td>
                                                          <b>
                                                            {unitList?.state}
                                                          </b>
                                                        </td>
                                                      </tr>
                                                      <tr>
                                                        <td>City</td>
                                                        <td>
                                                          <b>
                                                            {unitList?.city}
                                                          </b>
                                                        </td>
                                                      </tr>
                                                      <tr>
                                                        <td>Pincode</td>
                                                        <td>
                                                          <b>{unitList?.zip}</b>
                                                        </td>
                                                      </tr>
                                                      <tr>
                                                        <td>Added Date</td>
                                                        <td>
                                                          <b>
                                                            {dayjs(
                                                              unitList?.created_at
                                                            ).format(
                                                              "MM/DD/YY"
                                                            )}
                                                          </b>
                                                        </td>
                                                      </tr>
                                                    </tbody>
                                                  </table>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </div>
                                      )}

                                      <div className="mt-3">
                                        <div
                                          className="mb-2"
                                          style={{
                                            fontStyle: "italic",
                                            fontWeight: "bold",
                                          }}
                                        >
                                          Properties
                                        </div>
                                        <table className="table table-bordered table-striped m-0">
                                          <thead>
                                            <tr>

                                              <th>Property Name</th>
                                              <th>Address</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {Boolean(assignPropertyDetail) ? (

                                              <tr >

                                                <td>
                                                  {
                                                    assignPropertyDetail
                                                      ?.name
                                                  }
                                                </td>


                                                <td>
                                                  {assignPropertyDetail?.city}
                                                </td>
                                              </tr>

                                            ) : (
                                              <tr>
                                                <td colSpan={4}>
                                                  <p className="text-center">
                                                    No Assign Property Yet.
                                                  </p>
                                                </td>
                                              </tr>
                                            )}
                                          </tbody>
                                        </table>
                                      </div>


                                      <div className="mt-3">
                                        <div
                                          className="mb-2"
                                          style={{
                                            fontStyle: "italic",
                                            fontWeight: "bold",
                                          }}
                                        >
                                          Management Co
                                        </div>
                                        <table className="table table-bordered table-striped mb-3">
                                          <thead>
                                            <tr>

                                              <th>Management Company Name</th>
                                              <th>Email</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {Boolean(assignManagementCo) ? (

                                              <tr >

                                                <td>
                                                  {
                                                    assignManagementCo?.name
                                                  }
                                                </td>


                                                <td>
                                                  {
                                                    assignManagementCo?.email
                                                  }
                                                </td>
                                              </tr>

                                            ) : (
                                              <tr>
                                                <td colSpan={4}>
                                                  <p className="text-center">
                                                    No Assign Manangement Co Yet.
                                                  </p>
                                                </td>
                                              </tr>
                                            )}
                                          </tbody>
                                        </table>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <div className="mt-4 text-center">
                        <div className="mb-3">
                          <button
                            type="button"
                            className="btn btn-success px-5"
                          >
                            <svg
                              stroke="currentColor"
                              fill="currentColor"
                              strokeWidth="0"
                              viewBox="0 0 512 512"
                              height="1em"
                              width="1em"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M448 192V77.25c0-8.49-3.37-16.62-9.37-22.63L393.37 9.37c-6-6-14.14-9.37-22.63-9.37H96C78.33 0 64 14.33 64 32v160c-35.35 0-64 28.65-64 64v112c0 8.84 7.16 16 16 16h48v96c0 17.67 14.33 32 32 32h320c17.67 0 32-14.33 32-32v-96h48c8.84 0 16-7.16 16-16V256c0-35.35-28.65-64-64-64zm-64 256H128v-96h256v96zm0-224H128V64h192v48c0 8.84 7.16 16 16 16h48v96zm48 72c-13.25 0-24-10.75-24-24 0-13.26 10.75-24 24-24s24 10.74 24 24c0 13.25-10.75 24-24 24z"></path>
                            </svg>{" "}
                            Print
                          </button>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default VendorDetail;
