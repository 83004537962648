import React, { useEffect, useState } from "react";
import { FaEdit, FaTrashAlt, FaPlus } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import Api from "../../../Config/Api";
import { FaEye } from "react-icons/fa";
import { useSelector } from "react-redux";
import { Tooltip } from "react-tooltip";
import Loader from "../../Elements/Loader";
import { BsSearch } from "react-icons/bs";
import InfiniteScroll from "react-infinite-scroll-component";
const CamListing = () => {

  const [isLoader, setIsLoader] = useState(true);
  const [camList, setCamList] = useState([]);
  const [subAdminList, setSubAdminList] = useState([]);
  const [perPage] = useState(10);
  const [page, setPage] = useState(1);
  const location = useLocation();
  const isAdmin = useSelector((state) => state.login.isAdmin);

  const getVendorDetails = async () => {
    setIsLoader(true);
    const { data } = await Api("cam-get-user", "POST", { isSubAdmin: true });
    if (data?.status) {
      setPage(1);
      var temparr = data?.data.slice(0, perPage);
      setSubAdminList(temparr);
      setCamList(data?.data);
      setIsLoader(false);
    }
  };

  const handleNext = () => {
    const start = page * perPage;
    const end = start + perPage;
    const temArr = subAdminList.concat(camList.slice(start, end));
    setPage((prev) => prev + 1);
    setSubAdminList(temArr);
  };

  useEffect(() => {
    getVendorDetails();
  }, []);

  const handleDelete = async (idToDelete) => {
    if (window.confirm("Are You Sure You Want to delete it?")) {
      const { data } = await Api("cam-delete-user", "POST", { id: idToDelete });
      if (data?.status) {
        toast.success(data?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }

      getVendorDetails();
    }
  };

  const [searchTerm, setSearchTerm] = useState("");

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
    const search = event.target.value;
    const filtered = camList.filter((item) => {

      return (
        item?.name?.toLowerCase()?.includes(search?.toLowerCase()) ||
        item?.lastname?.toLowerCase()?.includes(search?.toLowerCase()) ||
        item?.email?.toLowerCase()?.includes(search?.toLowerCase()) ||
        item?.phone?.toLowerCase()?.includes(search?.toLowerCase())
      );
    });
    setSubAdminList(filtered);
  };

  return (
    <>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                {<h1 className="m-0">Sub-Admin</h1>}
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link
                      to="/"
                      className={
                        location.pathname === "/"
                          ? "breadcrumb-active"
                          : "breadcrumb-not-active"
                      }
                    >
                      Home
                    </Link>
                  </li>
                  <span className="breadcrumb-arrow">&gt;</span>
                  <li className="breadcrumb-item active">Sub-Admin</li>
                </ol>
              </div>
            </div>
          </div>
        </div>

        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div
                    className={`card-header ${
                      isAdmin ? "adminColor" : "cambg-secondary"
                    }`}
                  >
                    <div className="add_new_vendor_listing">
                      <h3 className="card-title">Details</h3>
                      <Link
                        to={"/user/SubAdminFrom"}
                        state={{
                          isSubAdmin: true,
                        }}
                        className="btn btn-light text-dark bg-light"
                      >
                        Add Sub-Admin
                      </Link>
                    </div>
                  </div>

                  <div className="d-flex justify-content-end px-4 mt-2">
                    <div className="input-group w-25">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search..."
                        value={searchTerm}
                        onChange={handleInputChange}
                      />
                      <div className="input-group-append">
                        <span
                          className="input-group-text bg-primary border-0"
                          id="basic-addon2"
                        >
                          <BsSearch />
                        </span>
                      </div>
                    </div>
                  </div>

                  <>
                    {isLoader ? (
                      <Loader />
                    ) : (
                      <div className="card-body table-responsive">
                        <InfiniteScroll
                          dataLength={subAdminList.length}
                          next={handleNext}
                          hasMore={camList.length > subAdminList.length}
                        >
                          <table className="table table-bordered">
                            <thead className="border-top">
                              <tr>
                                <th>First Name</th>
                                <th>Last Name</th>
                                <th>Email</th>
                                <th>Phone</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {subAdminList.map((data, i) => (
                                <tr key={i}>
                                  <td>{data.name}</td>
                                  <td>{data.lastname}</td>
                                  <td>{data.email}</td>
                                  <td>{data.phone ? data.phone : "-"}</td>
                                  <td>
                                    <div className="d-flex justify-content-center">
                                      <div className="d-flex justify-content-center">
                                        <Link
                                          to="/user/SubAdminFrom"
                                          state={{ id: data?.id, isEdit: true }}
                                          className="btn  btn-primary btn-sm mr-1"
                                          data-tooltip-id="edit"
                                          data-tooltip-content="Edit"
                                        >
                                          <FaEdit />
                                        </Link>
                                        <Tooltip id="edit" place="bottom" />

                                        <button
                                          onClick={() => handleDelete(data.id)}
                                          className="btn  btn-danger btn-sm mr-1"
                                          data-tooltip-id="my-tooltip"
                                          data-tooltip-content="Delete"
                                        >
                                          <FaTrashAlt />
                                        </button>
                                        <Tooltip
                                          id="my-tooltip"
                                          place="bottom"
                                        />
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </InfiniteScroll>
                      </div>
                    )}
                  </>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default CamListing;
