import React, { useEffect, useState } from 'react';
import { Link, useLocation } from "react-router-dom";
import { BsPrinterFill } from "react-icons/bs";
import Card from "react-bootstrap/Card";
import Api from '../../../../Config/Api';
import dayjs from "dayjs";

const PassRequestView = () => {
    const location = useLocation();
    const [passInfo, setPassInfo] = useState([]);
    const sectionStyle = {};
            
    const getCheckOutDetails = async () => {
        const res = await Api("getAllpassInfoByUserID", "POST", {
            id: location.state.id,
        });
        if (res?.data?.status) {
            setPassInfo(res?.data?.passData);
        }
    };

    useEffect(() => {
        if (!!location?.state?.id) {
        getCheckOutDetails();
        }
    }, [location?.state?.id]);

    return (
        <>
            <main>
                <div className="content-wrapper">
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    {<h1 className="m-0">Payment/Pass Details</h1>}
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item">
                                            <Link to="/"
                                                className={location.pathname === "/" ? "breadcrumb-active" : "breadcrumb-not-active"}
                                            >
                                                Home
                                            </Link>
                                            <span className="breadcrumb-arrow">&gt;</span>
                                            <Link to="/user/PaymentHistory"
                                                className={location.pathname.startsWith("/user/PassRequest") ? "breadcrumb-active" : "breadcrumb-not-active"}
                                            >
                                                Passes Requests
                                            </Link>
                                        </li>
                                        <span className="breadcrumb-arrow">&gt;</span>
                                        <li className="breadcrumb-item active">Payment/Passes Details</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section
                        className=""
                        style={sectionStyle}
                    >
                        <div className="container-fluid" style={{
                            paddingLeft: "20px"
                        }}>
                            <div className="new_guest_form">
                                <div className="returning_heading">
                                    <div className="d-flex justify-content-between">
                                        <div>
                                            <h3 className="pb-3 text-center">Order Details</h3>
                                        </div>
                                        <div>
                                            <span className="btn btn-warning btn-sm text-light mx-1" onClick={() => window.print()}>
                                                <BsPrinterFill />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <Card>
                                    <Card.Body>
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col" style={{ "width": "13%" }}>Order Number :</th>
                                                    <td className="" >
                                                        {(passInfo?.billing_details?.vehiclepass?.order_uuid) ? (passInfo?.billing_details?.vehiclepass?.order_uuid) : ("_")}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="col" style={{ "width": "13%" }}>Date :</th>
                                                    <td >
                                                     
                                                        {dayjs(passInfo?.billing_details?.vehiclepass?.arrival_date).format("MM/DD/YYYY")}

                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="col" style={{ "width": "13%" }}>Email :</th>
                                                    <td >
                                                        {passInfo?.billing_details?.user?.email}

                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="col" style={{ "width": "13%" }}>Total :</th>
                                                    <td >
                                                        {" "}
                                                        ${Number.parseFloat(passInfo?.amount / 100).toFixed(2)}{" "}

                                                    </td>
                                                </tr>
                                            </thead>
                                        </table>

                                    </Card.Body>
                                </Card>
                                <Card>
                                    <Card.Header>
                                        <div className="d-flex justify-content-between">
                                            <div>
                                                <h6 className="text-dark">Product</h6>
                                            </div>
                                            <div className="text-dark">Total</div>
                                        </div>
                                    </Card.Header>
                                    <Card.Body>
                                        {/* {
                                            console.log('passInfo', passInfo?.billing_details?.vehiclepass?.warnings)
                                        } */}
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Parking Price</th>
                                                    <td >
                                                        <span >
                                                            ${Number.parseFloat(passInfo?.amount / 100).toFixed(2)}
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="col">Pass Code</th>
                                                    <td>
                                                        {/* {passInfo?.billing_details?.vehiclepass?.order_uuid} */}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="col">Phone</th>
                                                    <td>
                                                        {/* {passInfo?.billing_details?.user?.phone} */}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="col">Unit</th>
                                                    <td>
                                                        {/* {
                                                            passInfo?.billing_details?.vehiclepass?.unit
                                                                ?.unit_name
                                                        } */}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="col">Begin Date</th>
                                                    <td>
                                                        {/* {passInfo?.billing_details?.vehiclepass?.arrival_date} */}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="col">Departure Date</th>
                                                    <td>
                                                        {/* {
                                                            passInfo?.billing_details?.vehiclepass
                                                                ?.departure_date
                                                        } */}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="col">Full Name</th>
                                                    <td>
                                                        {/* {passInfo?.billing_details?.user?.name +
                                                            " " +
                                                            passInfo?.billing_details?.user?.lastname} */}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="col">Rules & Regulations</th>
                                                    <td>
                                                        {/* {
                                                            Boolean(passInfo?.billing_details?.vehiclepass?.rules) ? (
                                                                JSON.parse(passInfo?.billing_details?.vehiclepass?.rules).map((data, i) => (
                                                                    <div key={i}>
                                                                        <span>{data}</span> <span className="text-success mx-3 ">
                                                                            <BsFillCheckCircleFill className="h5 mt-2" />
                                                                        </span><br />
                                                                    </div>
                                                                ))
                                                            ) : (
                                                                "-"
                                                            )
                                                        } */}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="col">Beach Flag Warning System </th>
                                                    <td>
                                                        {/* {
                                                            Boolean(passInfo?.billing_details?.vehiclepass?.warnings) ? (
                                                                JSON.parse(passInfo?.billing_details?.vehiclepass?.warnings).map((data, i) => (
                                                                    <div key={i}>
                                                                        <span>{data}</span> <span className="text-success mx-3 ">
                                                                            <BsFillCheckCircleFill className="h5 mt-2" />
                                                                        </span><br />
                                                                    </div>
                                                                ))
                                                            ) : (
                                                                "-"
                                                            )
                                                        } */}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="col">Non-Refundable</th>
                                                    <td>
                                                        {/* {
                                                            Boolean(passInfo?.billing_details?.vehiclepass?.refundable_rules) ? (
                                                                JSON.parse(passInfo?.billing_details?.vehiclepass?.refundable_rules).map((data, i) => (
                                                                    <div key={i}>
                                                                        <span>{data}</span> <span className="text-success mx-3 ">
                                                                            <BsFillCheckCircleFill className="h5 mt-2" />
                                                                        </span><br />
                                                                    </div>
                                                                ))
                                                            ) : (
                                                                "-"
                                                            )
                                                        } */}
                                                    </td>
                                                </tr>
                                            </thead>
                                        </table>
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">SubTotal</th>
                                                    <td>
                                                        {/* $
                                                        {Number.parseFloat(
                                                            passInfo?.billing_details?.vehiclepass?.pass_fee
                                                        ).toFixed(2)} */}
                                                    </td>
                                                </tr>
                                                {/* <tr>
                                                    <th scope="col">Discount</th>
                                                    <td>${Number.parseFloat(-35).toFixed(2)}</td>
                                                </tr> */}
                                                <tr>
                                                    <th scope="col">Tax</th>
                                                    <td>
                                                        {/* ${Number.parseFloat(0).toFixed(2)} */}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="col">Total</th>
                                                    <td>
                                                        {/* ${Number.parseFloat(passInfo?.amount / 100).toFixed(2)} */}
                                                    </td>
                                                </tr>
                                            </thead>
                                        </table>
                                        {/* {passInfo?.billing_details?.vehiclepass?.issuccess && ( */}
                                        <div className="text-center my-3">
                                            <img
                                                // src={passInfo?.billing_details?.vehiclepass?.qr_code}
                                                width={160}
                                                alt=""
                                                srcSet=""
                                            />
                                        </div>
                                        {/* )} */}
                                        <h3 className="py-3">Billing Address</h3>
                                        <p className="m-1">
                                            {/* {(passInfo?.billing_details) ?
                                                (passInfo?.billing_details?.fname +
                                                    " " +
                                                    passInfo?.billing_details?.lname) : ("_")} */}
                                        </p>
                                        <p className="m-1">
                                            {/* {passInfo?.billing_details?.companyName} */}
                                        </p>
                                        <p className="m-1">
                                            {/* {passInfo?.billing_details?.country} */}
                                        </p>
                                        <p>
                                            {/* {passInfo?.billing_details?.phone} */}
                                        </p>
                                        <p>
                                            {/* {passInfo?.billing_details?.email} */}
                                        </p>
                                    </Card.Body>
                                </Card>
                            </div>
                        </div>
                    </section>
                </div>
            </main>
        </>
    )
}

export default PassRequestView