import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useLocation, Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Api from '../../../Config/Api';
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import Loader from "../../Elements/Loader";

const SubAdminManagForm = () => {
    const {
        register,
        handleSubmit,
        reset,
        setValue,
        formState: { errors },
    } = useForm();

    const { id } = useParams();
    console.log('atob(id', atob(id));
    const location = useLocation();
    const [isLoading, setLoading] = useState(false);
    const isAdmin = useSelector((state) => state.login.isAdmin);
    const roleId = useSelector((state) => state.login.roleId);
    const navigate = useNavigate();
    const [isLoader, setIsLoader] = useState(location?.state?.isEdit ? true : false);

    const onSubmit = async (d) => {


        const req = {
            "name": d?.name,
            "email": d?.email,
            "password": d?.password,
            "role_id": 4,
        }

        if (!Boolean(location?.state?.id) && !location?.state?.id) {
            try {
                req.created_by = atob(id);
                var { data } = await Api("add-management-co", "POST", req);
                console.log('addmanage', data);
                if (data?.status) {
                    setLoading(false);
                    toast.success('Management Company has been successfully created!', {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    navigate("/user/SubAdminManagListing/" + id);
                }
            } catch (error) {
                if (Boolean(error?.data?.errors)) {
                    toast.error(error?.data?.errors?.email[0])
                    setValue('email', '');
                }
            }
        } else {
            req.id = location?.state?.id;
            var res = await Api("update-management-co", "POST", req);
            console.log('res', res);
            if (res?.data?.status) {
                setLoading(false);
                toast.success('Management Company has been successfully updated!', {
                    position: toast.POSITION.TOP_RIGHT,
                });
                navigate("/user/SubAdminManagListing/" + id);
                reset();
            }
        }
    };

    const getModifyCamData = async () => {
        const { data } = await Api("get-management-co", "POST", {
            id: location?.state?.id,
        });
        const ManageCompanyData = data?.data;
        reset(ManageCompanyData);
        setIsLoader(false);
    };


    useEffect(() => {
        if (!!location?.state?.id) {
            getModifyCamData();
        }
    }, [location?.state?.id])


    return (
        <>
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-5">
                                <h1 className="m-0">
                                    {" "}
                                    {location?.state?.isEdit ? "Edit SubAdmin Management Co " : "Add SubAdmin Management Co "}{" "}
                                </h1>
                            </div>
                            <div className="col-sm-7">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item">
                                        <Link to="/"
                                            className={location.pathname === "/" ? "breadcrumb-active" : "breadcrumb-not-active"}
                                        >
                                            Home
                                        </Link>
                                        {isAdmin ? (
                                            <>
                                                <span className="breadcrumb-arrow">&gt;</span>
                                                <Link to="/user/ManagementCompanyListing"
                                                    className={location.pathname.startsWith("/user/ManagementCompanyListing") ? "breadcrumb-active" : "breadcrumb-not-active"}
                                                >
                                                    Management Co
                                                </Link>
                                            </>
                                        ) : ("")}
                                        <span className="breadcrumb-arrow">&gt;</span>
                                        <Link to="/user/SubAdminManagListing/MTEz"
                                            className={location.pathname.startsWith("/user/SubAdminManagListing/MTEz") ? "breadcrumb-active" : "breadcrumb-not-active"}
                                        >
                                            SubAdmin Management
                                        </Link>
                                    </li>
                                    <span className="breadcrumb-arrow">&gt;</span>
                                    <li className="breadcrumb-item active">
                                        {" "}
                                        {location?.state?.isEdit ? "Edit SubAdmin Management Co " : "Add SubAdmin Management Co "}{" "}
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>

                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card card-primary">
                                    <div className={`card-header ${isAdmin || Number(atob(roleId)) === 4 ? "adminColor" : "cambg-secondary"}`}>
                                        <h3 className="card-title">
                                            {" "}
                                            {location?.state?.isEdit ? "Edit SubAdmin Management Co" : "Add SubAdmin Management Co"}{" "}
                                        </h3>
                                    </div>

                                    {isLoader ? (
                                        <Loader />
                                    ) : (
                                        <form
                                            onSubmit={handleSubmit(onSubmit)}
                                            encType="multipart/form-data"
                                        >
                                            <div className="card-body">
                                                <div className="row align-items-center">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="fname">Management Company</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="fname"
                                                                placeholder="Enter Management Company"
                                                                {...register("name", { required: true })}
                                                            />

                                                            {errors.name && (
                                                                <p className="text-danger">
                                                                    Management Company is required
                                                                </p>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="email">Email</label>
                                                            <input
                                                                type="email"
                                                                className="form-control"
                                                                id="email"
                                                                placeholder="Enter Email"
                                                                {...register("email", { required: true })}
                                                                autoComplete="off"
                                                            />

                                                            {errors.email && (
                                                                <p className="text-danger">Email is required</p>
                                                            )}
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="password">Password</label>
                                                            <input
                                                                type="password"
                                                                className="form-control"
                                                                id="password"
                                                                placeholder="Enter Password"
                                                                autoComplete="off"
                                                                {...register("password", { required: true })}
                                                            />

                                                            {errors.password && (
                                                                <p className="text-danger">
                                                                    Password is required
                                                                </p>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card-footer">
                                                    <button className="btn btn-outline-light text-dark mx-3 bg-light" type="button" onClick={() => navigate(-1)}>Cancel</button>
                                                    <button
                                                        type="submit"
                                                        variant="primary"
                                                        className={`btn btn-primary`}
                                                        disabled={isLoading}
                                                    >
                                                        {isLoading ? (
                                                            <>
                                                                <Spinner animation="border" size="sm" /> Please
                                                                wait...
                                                            </>
                                                        ) : (
                                                            "Submit"
                                                        )}
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>

                </section>
            </div>
        </>

    )
}

export default SubAdminManagForm