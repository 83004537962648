import LoaderImg from "../../assets/img/Loader.gif";

const Loader = ({ type, text }) => {
  if (type === "full") {
    return (
      <div className="loading_icon_full">
        <div className="">
          <img src={LoaderImg} alt="Loading" />
          <p>{text ? text : "Loading..."}</p>
        </div>
      </div>
    );
  } else {
    return (
      <div className="loading_icon my-3">
        <img src={LoaderImg} alt="Loading" />
        <p>{text ? text : "Loading..."}</p>
      </div>
    );
  }
};

export default Loader;
