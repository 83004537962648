import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { toast } from "react-toastify";
import Api from "../../../Config/Api";
import { Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { capitalizeFirstLetter } from "../../../Utility/Helpers";
import { useDispatch } from "react-redux";
import { fetchState, getState } from "../../../Reducers/State"; // import your thunk action
import Select from "react-select";
import Loader from "../../Elements/Loader";

const AddNewVendor = () => {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();
  const [selectedState, setSelectedState] = useState("");
  const [stateCode, setStateCode] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);
  const [phone, setPhone] = useState();

  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [category, setCategory] = useState([]);
  const [updatePropertyData, setUpdatePropertyData] = useState([]);
  
  const navigate = useNavigate();
  const location = useLocation();
  const isAdmin = useSelector((state) => state.login.isAdmin);
  const stateData = useSelector(getState);
  const [isLoader, setIsLoader] = useState(location?.state?.isEdit ? true : false);
  const onSubmit = async (d) => {

    const PropertyFormData = {
      address: d.address,
      apt: d.apt,
      city: d.city,
      company_name: d.company_name,
      poc_email: d.poc_email,
      point_of_fname: d.point_of_fname,
      point_of_lname: d.point_of_lname,
      phone: d?.phone,
      service_category: d.service_category_name,
      state: d.state,
      state_code: d.state_code,
      zip: d.zip,
    };

    if (!Boolean(location?.state?.id) && !location?.state?.id) {
      PropertyFormData.property_id = location?.state?.propertyId;
    }
    if (!Boolean(location?.state?.id) && !location?.state?.id) {
      try {
        var { data } = await Api("add-vendor", "POST", PropertyFormData);
        if (data?.status) {
          setLoading(false);
          toast.success(data.messsage, {
            position: toast.POSITION.TOP_RIGHT,
          });

          navigate("/user/VendorListing/" + btoa(location?.state?.propertyId));
        }
      } catch (error) {
        toast.error("Something went wrong.", {
          position: toast.POSITION.TOP_RIGHT,
        });
        navigate("/user/VendorListing/" + btoa(location?.state?.propertyId));
      }
    } else {
      PropertyFormData.id = location?.state?.id;
      var res = await Api("update-vendor", "POST", PropertyFormData);
      if (res?.data?.status) {
        setLoading(false);
        toast.success(res?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });

        navigate("/user/VendorListing/" + btoa(updatePropertyData?.property_id));
        reset();
      }
    }
  };

  const getModifyProperty = async () => {
    const { data } = await Api("get-vendor", "POST", {
      id: location?.state?.id,
    });

    const ProData = data?.data;
    reset({"service_category_name": data?.data?.ser_category?.id || '',...ProData});
    setPhone(data?.data?.phone);
    setUpdatePropertyData(ProData);
    setIsLoader(false);
    
  };

  useEffect(() => {
    if (!!location?.state?.id) {
      getModifyProperty();
    }
  }, [location?.state?.id,category.length]);

  const getServiceCategory = async () => {
    const { data } = await Api("vendor-service-category", "POST", {});
    const serviceCategory = data?.data;
    setCategory(serviceCategory);
  };
  useEffect(() => {
    dispatch(fetchState());
    getServiceCategory();
  }, []);

  var options = [];
  stateData?.state?.map((state, i) => {
    options?.push({
      value: state?.name,
      label: state?.name,
      "data-key": state.code,
    });
  });
  const handleChange = (selectedOption) => {
    setSelectedState(selectedOption.value);
    setStateCode(selectedOption["data-key"]);
  };

  useEffect(() => {
    setPhone(phone || ''); // Use an empty string if 'phone' is undefined
  }, [phone]);

  const handleInputChange = (event) => {
    const rawPhoneNumber = event.target.value.replace(/\D/g, '');
    const truncatedPhoneNumber = rawPhoneNumber.slice(0, 10);
    const formattedPhoneNumber = truncatedPhoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
    
    // Set the phone value directly using setValue from react-hook-form
    setValue('phone', formattedPhoneNumber);
  
    // Update the state with the formatted phone number
    setPhone(formattedPhoneNumber);
  };

  return (
    <>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">
                  {" "}
                  {location?.state?.isEdit
                    ? "EDIT VENDOR"
                    : "ADD NEW VENDOR"}{" "}
                </h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/"
                      className={location.pathname === "/" ? "breadcrumb-active" : "breadcrumb-not-active"}
                    >
                      Home
                    </Link>
                    {isAdmin ? (
                      <>
                      <span className="breadcrumb-arrow">&gt;</span>
                    <Link to="/user/PropertyListing"
                      className={location.pathname.startsWith("/user/PropertyListing") ? "breadcrumb-active" : "breadcrumb-not-active"}
                    >
                      Property
                    </Link>
                      </>
                    ) : ("")}
                    <span className="breadcrumb-arrow">&gt;</span>
                  <Link to={"/user/VendorListing/" + btoa(location?.state?.propertyId)}
                      className={location.pathname.startsWith("/user/VendorListing/"+ btoa(location?.state?.propertyId)) ? "breadcrumb-active" : "breadcrumb-not-active"}
                    >
                      Vendor
                    </Link>
                  </li>
                  <span className="breadcrumb-arrow">&gt;</span>
                  <li className="breadcrumb-item active">
                    {location?.state?.isEdit ? "Edit Vendor" : "Add Vendor"}{" "}
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card card-primary">
                  <div className={`card-header ${isAdmin ? "adminColor" : "cambg-secondary"}`}>
                    <h3 className="card-title">
                      {" "}
                      {location?.state?.isEdit
                        ? "EDIT Details"
                        : "Add Details"}{" "}
                    </h3>
                  </div>

                  {isLoader ? (
                    <Loader />
                  ) : (

                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="card-body">
                        <div className="row align-items-center">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Service Category</label>
                              <select
                                className="form-control"
                                options={options}
                                defaultValue={selectedOption}
                                {...register("service_category_name", {
                                  required: true,
                                })}
                              >

                                {category?.length &&
                                  category?.map((sc_data, i) => (
                                    <option key={i} value={sc_data?.id}>
                                      {capitalizeFirstLetter(
                                        sc_data?.service_category_name
                                      )}
                                    </option>
                                  ))}
                              </select>
                              {errors.service_category && (
                                <p className="text-danger">
                                  Category is required
                                </p>
                              )}
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="enterVendorCompany">
                                Company Name
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="enterVendorCompany"
                                placeholder="Enter Vendor Company Name"
                                {...register("company_name", { required: true })}
                              />

                              {errors.company_name && (
                                <p className="text-danger">
                                  Vendor Company name is required
                                </p>
                              )}
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="enterStreetAddress">
                                Vendor Company Address
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="enterStreetAddress"
                                placeholder="Enter Company Street Address"
                                {...register("address", { required: true })}
                              />

                              {errors.address && (
                                <p className="text-danger">
                                  Street Address is required
                                </p>
                              )}
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="apt">Apt</label>
                              <input
                                type="text"
                                className="form-control"
                                id="apt"
                                placeholder="Enter Apt"
                                {...register("apt")}
                              />

                              {/* {errors.apt && (
                                <p className="text-danger">Apt is required</p>
                              )} */}
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="enterCity">
                                Vendor Company City
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="enterCity"
                                placeholder="Enter City"
                                {...register("city", { required: true })}
                              />

                              {errors.city && (
                                <p className="text-danger">
                                  City Name is required
                                </p>
                              )}
                            </div>
                          </div>

                          {/* <div className="col-md-6">
                          <div className="form-group">
                            <label>Vendor Company State</label>
                            <Select
                              options={options}
                              defaultValue={selectedOption}
                              {...register("state")}
                              onChange={handleChange}
                            /> */}

                          {/* <option value="">Select State</option> */}
                          {/* {
                                stateData?.state?.length && (
                                stateData?.state?.map((states,i)=>(
                                  <option key={i} value={states?.name}>{states?.name}</option>
                                ))
                              )
                              } */}
                          {/* </select> */}
                          {/* {errors.state && (
                              <p className="text-danger">
                                State Name is required
                              </p>
                            )}
                          </div>
                        </div>  */}

                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Vendor Company State</label>
                              <select
                                className="form-control"
                                options={options}
                                defaultValue={selectedOption}
                                {...register("state", { required: true })}
                              >
                                <option value="">Select State</option>
                                {stateData?.state?.length &&
                                  stateData?.state?.map((states, i) => (
                                    <option key={i} value={states?.name}>
                                      {states?.name}
                                    </option>
                                  ))}
                              </select>
                              {errors.state && (
                                <p className="text-danger">
                                  Owner State Name is required
                                </p>
                              )}
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="enterZip">Zip</label>
                              <input
                                type="text"
                                className="form-control"
                                id="enterZip"
                                placeholder="Enter Zip"
                                {...register("zip", { required: true })}
                              />

                              {errors.zip && (
                                <p className="text-danger">Zip is required</p>
                              )}
                            </div>
                          </div>

                          <div className="col-md-12">
                            <div className="point_of_contact pb-3 pt-1">
                              Vendor Point of Contact
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="enterFirstName">First Name</label>
                              <input
                                type="text"
                                className="form-control"
                                id="enterFirstName"
                                placeholder="Enter First Name"
                                {...register("point_of_fname", {
                                  required: true,
                                })}
                              />

                              {errors.point_of_fname && (
                                <p className="text-danger">
                                  First Name is required
                                </p>
                              )}
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="enterLastName">Last Name</label>
                              <input
                                type="text"
                                className="form-control"
                                id="enterLastName"
                                placeholder="Enter Last Name"
                                {...register("point_of_lname", {
                                  required: true,
                                })}
                              />

                              {errors.point_of_lname && (
                                <p className="text-danger">
                                  Last Name is required
                                </p>
                              )}
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="enterEmail">Email</label>
                              <input
                                type="email"
                                className="form-control"
                                id="enterEmail"
                                placeholder="Enter Email"
                                {...register("poc_email", { required: true })}
                              />

                              {errors.poc_email && (
                                <p className="text-danger">Email is required</p>
                              )}
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="enterNumber">Phone</label>
                              <input
                                type="text"
                                value={phone}
                                onChange={handleInputChange}
                                className="form-control"
                                id="enterNumber"
                                placeholder="Enter Phone Number"
                                required={true}
                              />
                            </div>
                          </div>

                          {/* <div className="col-md-12">
                          <div className="point_of_contact pb-3 pt-1">Manager</div>
                        </div> */}

                          {/* <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="enterFirstName">First Name</label>
                            <input
                              type="text"
                              className="form-control"
                              id="enterFirstName"
                              placeholder="Enter First Name"
                              {...register("manager_fname", { required: true })}
                            />

                            {errors.manager_fname && (
                              <p className="text-danger">
                                First Name is required
                              </p>
                            )}
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="enterLastName">Last Name</label>
                            <input
                              type="text"
                              className="form-control"
                              id="enterLastName"
                              placeholder="Enter Last Name"
                              {...register("manager_lname", { required: true })}
                            />

                            {errors.manager_lname && (
                              <p className="text-danger">
                                Last Name is required
                              </p>
                            )}
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="enterEmail">Email</label>
                            <input
                              type="email"
                              className="form-control"
                              id="enterEmail"
                              placeholder="Enter Email"
                              {...register("manager_email", { required: true })}
                            />

                            {errors.manager_email && (
                              <p className="text-danger">Email is required</p>
                            )}
                          </div>
                        </div> */}

                          {/* <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="enterNumber">Number</label>
                          <input type="number" className="form-control" id="enterNumber" placeholder="Enter Number"/>
                        </div>
                      </div> */}
                        </div>

                        <div className="card-footer">
                          <button
                            type="submit"
                            variant="primary"
                            className={`btn btn-primary  ${isAdmin ? "" : "cambg-secondary border-0"}`}
                            disabled={isLoading}
                          >
                            {isLoading ? (
                              <>
                                <Spinner animation="border" size="sm" /> Please
                                wait...
                              </>
                            ) : (
                              "Submit"
                            )}
                          </button>
                          <button
                            className="btn btn-outline-light text-dark mx-3 bg-light"
                            type="button"
                            onClick={() => navigate(-1)}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </form>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default AddNewVendor;
