import React, { useEffect, useState } from "react";
// import Cookies from "js-cookie";
// import beachWarning from "../../assets/img/beach-warning-flags.png";
import Form from "react-bootstrap/Form";
import { useSelector } from "react-redux";
import Select from "react-select";
import { fetchState, getState } from "../../../Reducers/State";
import { getProperty, fetchProperty } from "../../../Reducers/RenterProperty";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import { setUser } from "../../../Reducers/Login";
import { addItemToCart } from "../../../Reducers/CartSlice";
import dayjs from "dayjs";
import DatePicker from "react-multi-date-picker";
import Api from "../../../Config/Api";
import Loader from "../../Elements/Loader";

const VendorCreatePass = () => {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();
  const location = useLocation();
  const [selectedOption, setSelectedOption] = useState(null);
  const [stateCode, setStateCode] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedUnit, setSelectedUnit] = useState("");
  const [rentalVehicleChecked, setRentalVehicleChecked] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [vendorDetail, setVendorDetail] = useState([]);
  const [arrivalDate, setArrivalDate] = useState(new Date());
  const [departureDate, setDepartureDate] = useState(new Date());
  const [isLoader, setIsLoader] = useState(true);
  const propertyData = useSelector((state) => state.login.propertyData);
  const stateData = useSelector(getState);
  const navigate = useNavigate();
  const isLogin = useSelector((state) => state.login.isLogin);
  const userId = useSelector((state) => state.login.userId);
  const vendor = useSelector((state) => state.login.vendor);
  const [phone, setPhone] = useState();
  console.log('propertyData', propertyData);
  const RenterProperty = propertyData;
  if (!RenterProperty) {
    toast.error(
      // "We could not locate the specified name property. Please check try again."
    );
    // navigate("/Login");
  }
  const handleChange = (selectedOption) => {
    setSelectedState(selectedOption.value); // selected value
    setStateCode(selectedOption["data-key"]); // data-key attribute
  };
  const handleRentalVehicleChange = (event) => {
    setRentalVehicleChecked(event.target.checked);
  };
  useEffect(() => {
    dispatch(fetchState());
  }, []);
  var options = [];
  stateData?.state?.map((state, i) => {
    options?.push({
      value: state?.name,
      label: state?.name,
      "data-key": state.code,
      key: i,
    });
  });
  var Unitoptions = [];
  RenterProperty?.units?.map((unit, i) => {
    Unitoptions?.push({
      value: unit?.id,
      label: unit?.unit_name,
      key: i,
    });
  });
  const rentervehi = rentalVehicleChecked
    ? {
      backgroundColor: "#d9d9d9",
    }
    : {};
  const onSubmit = async (data) => {
    console.log('data', data);
    const passType = data.Passtype;
    setLoading(true);
    const renterRequest = {
      is_passes_for: 'Vendor',
      arrival_date: dayjs(arrivalDate).format("YYYY/MM/DD"),
      departure_date: dayjs(departureDate).format("YYYY/MM/DD"),
      email: data.email,
      phone: data?.phone,
      lastname: data.lastname,
      name: data.name,
      id: location?.state?.id,
      license_plate: data.license_plate,
      unit_id: selectedUnit,
      Is_rental_vehicle: rentalVehicleChecked ? 1 : 0,
      pass_fee: 0,
      property_id: RenterProperty?.id,
      created_by: userId,
      is_passes_for: 'Vendor'
    };
    if (!rentalVehicleChecked) {
      renterRequest.vehicle_color = data.vehicle_color;
      renterRequest.vehicle_make = data.vehicle_make;
      renterRequest.vehicle_model = data.vehicle_model;
      renterRequest.vehicle_tag_state = selectedState;
      renterRequest.vehicle_tag = data.vehicle_tag;
    }
    var res = await Api("CreateVendorPass", "POST", renterRequest);
    console.log('res', res?.data?.data?.id);
    if (res?.data?.status) {
      setLoading(false);
      toast.success("Successfully Add To Cart.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      dispatch(addItemToCart(res?.data?.data?.id));
      navigate("/user/SuccessPaymentView", { state: { id: res?.data?.data?.id } });
    } else {
      setLoading(false);
      toast.success("Some Error.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  useEffect(() => {
    async function fetchData() {
      setIsLoader(true);
      try {
        const { data } = await Api("get-vendor", "POST", {
          id: location?.state?.id,
        });
        if (data?.status) {
          const resetData = {
            name: data?.data?.point_of_fname,
            lastname: data?.data?.point_of_lname,
            email: data?.data?.poc_email,
            phone: data?.data?.phone,
          };
          setPhone(data?.data?.phone);
          reset(resetData);
          setVendorDetail(data?.data);
          setIsLoader(false);
        }
      } catch (error) {
        // Handle error here
      }
    }

    fetchData();
  }, [location?.state?.id]);


  useEffect(() => {
    setPhone(phone || ''); // Use an empty string if 'phone' is undefined
  }, [phone]);

  const handleInputChange = (event) => {
    const rawPhoneNumber = event.target.value.replace(/\D/g, '');
    const truncatedPhoneNumber = rawPhoneNumber.slice(0, 10);
    const formattedPhoneNumber = truncatedPhoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');

    // Set the phone value directly using setValue from react-hook-form
    setValue('phone', formattedPhoneNumber);

    // Update the state with the formatted phone number
    setPhone(formattedPhoneNumber);
  };



  return (
    <>
      <div className="content-wrapper">

        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">
                  Vendor Pass Form
                </h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/"
                      className={location.pathname === "/" ? "breadcrumb-active" : "breadcrumb-not-active"}
                    >
                      Home
                    </Link>
                    <span className="breadcrumb-arrow">&gt;</span>
                    <Link to="/user/VendorListing/MTI0"
                      className={location.pathname.startsWith("/user/VendorListing/MTI0") ? "breadcrumb-active" : "breadcrumb-not-active"}
                    >
                      Vendors
                    </Link>
                    <span className="breadcrumb-arrow">&gt;</span>
                    <Link to="/user/ManagePasses"
                      className={location.pathname.startsWith("/user/ManagePasses") ? "breadcrumb-active" : "breadcrumb-not-active"}
                    >
                      Vendor Passes
                    </Link>
                  </li>
                  <span className="breadcrumb-arrow">&gt;</span>
                  <li className="breadcrumb-item active">
                    Vendor Pass Form
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>

        {isLoader ? (
          <Loader />
        ) : (

          <section
            className="content"

          ><div
            className={"container-fluid"}

          >
              <div className="new_guest_form">
                <div className="returning_heading">
                  <h4 className="pb-3">Create { } Pass for - {vendorDetail?.point_of_fname + ' ' + vendorDetail?.point_of_lname}</h4>
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="row align-items-center">
                    <div className="col-md-4">
                      <div className="form_input_guest">
                        <label className="guest_label">First Name</label>
                        <input
                          type="text"
                          {...register("name", { required: true })}
                          placeholder="Enter First Name"
                        />

                        {errors.name && (
                          <p className="text-danger">First name is required</p>
                        )}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form_input_guest">
                        <label className="guest_label">Last Name</label>
                        <input
                          type="text"
                          {...register("lastname", { required: true })}
                          placeholder="Enter Last Name"
                        />

                        {errors.lastname && (
                          <p className="text-danger">Last name is required</p>
                        )}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form_input_guest">
                        <label className="guest_label">E-mail Address</label>
                        <input
                          type="email"
                          {...register("email", { required: true })}
                          placeholder="E-mail Address"
                          readOnly={isLogin ? true : false}
                        />

                        {errors.email && (
                          <p className="text-danger">
                            E-mail Address is required
                          </p>
                        )}
                      </div>
                    </div>
                    {!isLogin && (
                      <div className="col-md-6">
                        <div className="form_input_guest">
                          <label className="guest_label">Password</label>
                          <input
                            type="password"
                            autoComplete="new-password"
                            {...register("password", { required: true })}
                            placeholder="Enter Password"
                          />

                          {errors.password && (
                            <p className="text-danger">Password is required</p>
                          )}
                        </div>
                      </div>
                    )}

                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="enterNumber">Phone</label>
                        <input
                          type="text"
                          value={phone}
                          onChange={handleInputChange}
                          className="form-control"
                          id="enterNumber"
                          placeholder="Enter Phone Number"
                          required={true}
                        />
                      </div>
                    </div>


                    <div className="col-md-6">
                      <div className="form_input_guest">
                        <label className="guest_label">Condo#</label>
                        <Select
                          options={Unitoptions}
                          onChange={(selectedOption) =>
                            setSelectedUnit(selectedOption.value)
                          }
                        />
                      </div>
                    </div>



                    <div className="col-md-3">
                      <div className="form_input_guest">
                        <label className="guest_label"> License Plate #</label>
                        <input
                          type="text"
                          {...register("license_plate", { required: true })}
                          placeholder="Enter license plate"
                        />

                        {errors.license_plate && (
                          <p className="text-danger">License Plate is required</p>
                        )}
                      </div>
                    </div>


                    <div className="row">

                      <div className="col-md-6">
                        <div className="form_input_guest">
                          <div>
                            <label className="guest_label">Valid From</label>
                          </div>
                          <DatePicker value={arrivalDate} format="MM/DD/YYYY" onChange={(value) => setArrivalDate(value)} />

                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form_input_guest">
                          <div>
                            <label className="guest_label">Valid Upto</label>
                          </div>
                          <DatePicker value={departureDate} format="MM/DD/YYYY" onChange={(value) => setDepartureDate(value)} defaultValue={arrivalDate} minDate={arrivalDate} multiple={false} />

                        </div>
                      </div>
                    </div>

                    <div className="col-md-12 mx-2">
                      <Form.Group className="mb-3">
                        <Form.Check
                          label={
                            <span className="h5" style={{ fontWeight: "bold" }}>
                              Rental Vehicle
                            </span>
                          }
                          id="rentalvehicle"
                          feedback="You must agree before submitting."
                          feedbackType="invalid"
                          onClick={handleRentalVehicleChange}
                        />
                      </Form.Group>
                    </div>

                    <div className="col-md-4">
                      <div className="form_input_guest">
                        <label className="guest_label">Vehicle Make</label>
                        <input
                          type="text"
                          style={rentervehi}
                          readOnly={rentalVehicleChecked}
                          {...register("vehicle_make", {
                            required: rentalVehicleChecked ? false : true,
                          })}
                          placeholder="Vehicle Make"
                        />

                        {errors.vehicle_make && (
                          <p className="text-danger">Vehicle Make is required</p>
                        )}
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="form_input_guest">
                        <label className="guest_label">Vehicle Modal</label>
                        <input
                          type="text"
                          style={rentervehi}
                          readOnly={rentalVehicleChecked}
                          {...register("vehicle_model", {
                            required: rentalVehicleChecked ? false : true,
                          })}
                          placeholder="Vehicle Modal"
                        />

                        {errors.vehicle_model && (
                          <p className="text-danger">Vehicle Modal is required</p>
                        )}
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="form_input_guest">
                        <label className="guest_label">Vehicle Color</label>
                        <input
                          type="text"
                          style={rentervehi}
                          readOnly={rentalVehicleChecked}
                          {...register("vehicle_color", {
                            required: rentalVehicleChecked ? false : true,
                          })}
                          placeholder="Enter Vehicle Color"
                        />

                        {errors.vehicle_color && (
                          <p className="text-danger">Vehicle Color is required</p>
                        )}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form_input_guest">
                        <label className="guest_label">Vehicle Tag#</label>
                        <input
                          type="text"
                          style={rentervehi}
                          readOnly={rentalVehicleChecked}
                          {...register("vehicle_tag", {
                            required: rentalVehicleChecked ? false : true,
                          })}
                          placeholder="Enter Vehicle Tag"
                        />

                        {errors.vehicle_tag && (
                          <p className="text-danger">Vehicle Tag is required</p>
                        )}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form_input_guest">
                        <label className="guest_label">Vehicle Tag State</label>

                        <Select
                          style={rentervehi}
                          isDisabled={rentalVehicleChecked}
                          options={options}
                          defaultValue={selectedOption}
                          onChange={handleChange}
                          className="vehicle_tags"
                        />
                      </div>
                    </div>


                    <label className="guest_label">

                      Total Pass Fee <h4 className="d-inline">${Number(0).toFixed(2)}</h4>
                    </label>

                    <div className="col-md-3">
                      <div className="input_form_submit_btn mt-3">
                        <button
                          type="submit"
                          className="btn btn-primary cambg-secondary"
                          disabled={isLoading}
                        >
                          {isLoading ? (
                            <>
                              <Spinner animation="border" size="sm" /> Please
                              wait...
                            </>
                          ) : (
                            "Add To Cart"
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </section>
        )}
      </div>
      <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/js/bootstrap.bundle.min.js"></script>
    </>
  );
};

export default VendorCreatePass;
