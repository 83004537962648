import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Api from "../../Config/Api";
// import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
// import { Spinner } from "react-bootstrap";
import { getProperty } from "../../Reducers/RenterProperty";
import stripeBadge from "../../assets/img/stripe-badge.png";

const EditProfile = () => {
  const isAdmin = useSelector((state) => state.login.isAdmin);
  // const navigate = useNavigate();
  const RenterProperty = useSelector(getProperty);
  const userId = useSelector((state) => state.login.userId);
  // const [isLoading, setLoading] = useState(false);
  const [cardDetails, setCardDetails] = useState([]);
  const [Iscard, setIsCard] = useState(false);

  const getModifyCamData = async () => {
    const { data } = await Api("savedCardDetails", "POST", {
      user_id: userId,
    });
    if (data?.data) {
      setCardDetails(data?.data);
      setIsCard(true);
    }
  };

  useEffect(() => {
    if (!!userId) {
      getModifyCamData();
    }
  }, [userId]);

  const handleRemoveCard = async () => {
    const payload = {
      user_id: userId,
    };
    const { data } = await Api("handleRemoveCard", "POST", payload);
    if (data?.status) {
      setIsCard(false);
    }
  };

  const background_image = RenterProperty?.bg_image
    ? process.env.REACT_APP_AWS_S3_BUCKET_URL+ "images/" + RenterProperty?.bg_image
    : "";
  const property_logo = RenterProperty?.logo
    ? process.env.REACT_APP_AWS_S3_BUCKET_URL+
                        "images/" + RenterProperty?.logo
    : "";

  const sectionStyle = {
    backgroundImage: `url(${background_image})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  };

  return (
    <>
      <main>
        <section
          className="py-5 guest_section bannersection"
          style={sectionStyle}
        >
          <div
            className="container-fluid"
            style={{
              paddingLeft: "269px",
            }}
          >
            <div className="new_guest_form" style={{ marginBottom: "225px" }}>
              <section className="content">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="">
                        <h5
                          className={`py-2 px-4 text-light rounded-3 ${
                            isAdmin ? "adminColor" : "cambg-secondary"
                          }`}
                        >
                          Payments
                        </h5>

                        <div className="card-body">
                          <div className="row">
                            <div className="card">
                              <div className="card-header">
                                <div className="d-flex justify-content-between">
                                  <div>
                                    <h3 className="text-dark">Saved Card</h3>
                                  </div>
                                  <div>
                                    <img
                                      src={stripeBadge}
                                      className="w-50"
                                      alt=""
                                      srcset=""
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-12">
                                <div className="card-body">
                                  {Iscard ? (
                                  <div className="cardbox">
                                    <p className="depot_money">
                                      {" "}
                                      AkcessPass save your credit or debit card
                                      details securely with Stripe so that you
                                      can easily pay for memberships
                                      automatically.
                                    </p>

                                    <div className="savecards">
                                      <h5>
                                        xxxx xxxx xxxx{" "}
                                        {cardDetails?.last_digits}
                                      </h5>
                                      <p className="badge badge-success px-2">{cardDetails?.brand}</p><br />
                                      <button
                                        type="button"
                                        className="btn btn-primary btn-sm"
                                        onClick={handleRemoveCard}
                                      >
                                        Remove Card
                                      </button>

                                      <div className="clearfix"></div>
                                    </div>
                                  </div>
                                  ): (
                                    <>
                                      <p>Your Saved credit or debit card details securely with Stripe will appear here.</p>

                                      <button type="button" className="btn btn-primary">Add Card</button>
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default EditProfile;
