import React, { useEffect, useState } from "react";
import { Chart } from "react-google-charts";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Api from "../../Config/Api";
// import Cookies from "js-cookie";
import ResetPasswordModal from "../Elements/Forms/ResetPasswordModal";
import { useNavigate } from "react-router-dom";
import Loader from "../Elements/Loader";
import dayjs from "dayjs";


export const options = {
  chart: {
    title: "",
    subtitle: "",
  },
  colors: ["#007bff"],
};



export const options3 = {
  chart: {
    title: "",
    subtitle: "",
  },
  colors: ["#007bff"],
};

const UserDashboard = () => {
  const isAdmin = useSelector((state) => state.login.isAdmin);
  const propertyId = useSelector((state) => state.login.propertyId);
  const isLogin = useSelector((state) => state.login.isLogin);
  const userId = useSelector((state) => state.login.userId);
  const [modalShow, setModalShow] = useState(false);
  const [vendorC, setVendorC] = useState(0);
  const [ownerC, setownerC] = useState(0);
  const [propertyC, setpropertyC] = useState(0);
  const [passesC, setPassesC] = useState(0);
  const [vehiclePassCount, setVehiclePassCount] = useState([]);
  const [currectWeekPass, setCurrectWeekPass] = useState([]);
  const [todayPasses, setTodayPasses] = useState([]);
  const [camUnitCount, setCamUnitCount] = useState([]);
  const [isLoader, setIsLoader] = useState(true);
  const propertyData = useSelector((state) => state.login.propertyData);

  const getCountDetails = async () => {
    setIsLoader(true);
    try {
      const vendorCount = await Api("vendor-count", "post", !isAdmin ? { property_id: propertyId } : {});
      const ownerCount = await Api("unit-count", "post", !isAdmin ? { property_id: propertyId } : {});
      const propertyCount = await Api("property-count", "get", {});
      const passesCount = await Api('passes_count', "POST", { property_id: propertyId });

      const { data } = await Api('getCamGrapahPassInfo', "POST", { id: userId });
      if (data) {
        const transformedData = data?.map((data) => [data?.units?.id, data?.count]);
        const headerRow = ['Passes', 'Passes Count'];
        transformedData.unshift(headerRow);
        setVehiclePassCount(transformedData);
        setIsLoader(false);

      }

      const res = await Api('getCamGraphUnitInfo', "POST", { property_id: propertyId });
      if (res?.data) {
        const transformedData = res?.data?.unit_counts?.map((data) => [data?.property_id, data?.unit_count]);
        const headerRow = ['Unit', 'Unit Count'];
        transformedData.unshift(headerRow);
        setCamUnitCount(transformedData);
        setIsLoader(false);
      }

      if (vendorCount?.data?.status || ownerCount?.data?.status || propertyCount?.data?.status || passesCount?.data?.status) {
        setVendorC(vendorCount?.data?.data)
        setownerC(ownerCount?.data?.data)
        setpropertyC(propertyCount?.data?.data)
        const passes =  passesCount?.data?.passes?.filter((data) => (data?.is_passes_for != "Owner") && data?.is_passes_for != "Vendor") || [];
        // console.log("passesAAAAAAAAAAA",passes);
        setPassesC(passes.length)

      }


      const currentWeekRes = await Api('getCurrentWeekPurchases', "POST", { property_id: propertyId, type: 'week' });
      const chartData = currentWeekRes?.data?.week_passes.reduce((accumulator, pass) => {
        const date = new Date(pass.created_at);
        const dayName = dayjs(date).format('dddd');
        const dateStr = date.toDateString();
        const existingEntry = accumulator.find((entry) => entry[1].toDateString() === dateStr);

        if (existingEntry) {
          existingEntry[2]++;
        } else {
          accumulator.push([dayName, date, 1]);
        }

        return accumulator;
      }, []);


      const daychartData = currentWeekRes?.data?.today_passes.reduce((dayaccumulator, pass) => {
        const daydate = new Date(pass.created_at);
        const daydayName = dayjs(daydate).format('dddd');
        const daydateStr = daydate.toDateString();
        const dayexistingEntry = dayaccumulator.find((entry) => entry[1].toDateString() === daydateStr);

        if (dayexistingEntry) {
          dayexistingEntry[2]++;
        } else {
          dayaccumulator.push([daydayName, daydate, 1]);
        }

        return dayaccumulator;
      }, []);


      const chartHeader = [["Day", "Date", "Passes"]];
      setTodayPasses(chartHeader.concat(daychartData));
      setCurrectWeekPass(chartHeader.concat(chartData));
    } catch (error) {
      console.error('Error fetching APIs:', error);
    }
  };


  useEffect(() => {
    if (isLogin) { getCountDetails() };
  }, [userId]);


  const getModifyCamData = async () => {
    const { data } = await Api("cam-get-user", "POST", {
      id: userId,
    });
    const CamData = data?.data;
    setModalShow(
      Number(CamData?.isChanged) === 1 || Number(CamData?.isChanged) === 0 ? false : true
    );
  };

  useEffect(() => {
    getModifyCamData();
  }, [userId]);


  return (
    <>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">CAM Dashboard</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Dashboard v1</li>
                </ol>
              </div>
            </div>
          </div>
        </div>


        <section className="content">
          <ResetPasswordModal
            show={modalShow}
            onHide={() => setModalShow(false)}
          />

          <div className="container-fluid">
            <div className="row">
              {isAdmin && (
                <div className="col-lg-3 col-6">

                  <div className="small-box bg-info">
                    <div className="inner">
                      <h3>{propertyC}</h3>

                      <p>Property</p>
                    </div>
                    <div className="icon">
                      <i className="ion ion-bag"></i>
                    </div>
                    <span className="small-box-footer"> More info </span>
                  </div>
                </div>
              )}
              <div className={`col-lg-${isAdmin ? 3 : 4} col-6`}>

                <div className="small-box bg-success">
                  <div className="inner">
                    <h3>{vendorC}</h3>

                    <p>Vendor</p>
                  </div>
                  <div className="icon">
                    <i className="ion ion-stats-bars"></i>
                  </div>
                 
                  <span className="small-box-footer" > <Link
                    to={`/user/VendorListing/${btoa(propertyData?.id)}`}

                    data-tooltip-id="detail"
                    data-tooltip-content="Property Detail"
                    className="text-white"
                  >
                    More Info
                  </Link> </span>
                </div>
              </div>

              <div className={`col-lg-${isAdmin ? 3 : 4} col-6`}>

                <div className="small-box bg-warning">
                  <div className="inner">
                    <h3>{ownerC}</h3>

                    <p>Owner</p>
                  </div>
                  <div className="icon">
                    <i className="ion ion-person-add"></i>
                  </div>
                  <span className="small-box-footer" > <Link
                    to={`/user/OwnerListing/${btoa(propertyData?.id)}`}


                    data-tooltip-id="detail"
                    data-tooltip-content="Property Detail"
                    className="text-white"
                  >
                    More Info
                  </Link> </span>
                </div>
              </div>

              <div className={`col-lg-${isAdmin ? 3 : 4} col-6`}>
                <div className="small-box bg-danger">
                  <div className="inner">
                    <h3>{passesC}</h3>

                    <p>Passes</p>
                  </div>
                  <div className="icon">
                    <i className="ion ion-pie-graph"></i>
                  </div>
                  <a className="small-box-footer"> <Link
                       to={`/user/PassesList`}



                        data-tooltip-id="detail"
                        data-tooltip-content="Property Detail"
                        className="text-white"
                      >
                        More Info
                      </Link> <i className="fas fa-arrow-circle-right"></i></a>
                </div>
              </div>

            </div>

            <div className="row">
              <section className="col-lg-6 connectedSortable">
                <div className="card">
                  <div className={`card-header ${isAdmin ? 'adminColor' : ''}`}>
                    <h5 className="text-dark">
                      <i className="fas fa-chart-bar mr-1"></i>
                      Current Week
                    </h5>
                  </div>
                  <div className="card-body">
                    <div className="tab-content p-0">
                      {isLoader ? (
                        <Loader />
                      ) : (
                        <Chart
                          chartType="Bar"
                          width="100%"
                          height="400px"
                          data={currectWeekPass}
                          options={options}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </section>

              <section className="col-lg-6 connectedSortable">
                <div className="card">
                  <div className={`card-header ${isAdmin ? 'adminColor' : ''}`}>
                    <h5 className="text-dark">
                      <i className="fas fa-chart-bar mr-1"></i>
                      Today
                    </h5>
                  </div>
                  <div className="card-body">
                    <div className="tab-content p-0">
                      {isLoader ? (
                        <Loader />
                      ) : (
                        <Chart
                          chartType="Bar"
                          width="100%"
                          height="400px"
                          data={todayPasses}
                          options={options}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </section>

              {/* <section className="col-lg-6 connectedSortable">
                <div className="card">
                  <div className={`card-header ${isAdmin ? 'adminColor' : ''}`}>
                    <h5 className="text-dark">
                      <i className="fas fa-chart-bar mr-1"></i>
                      Total Units
                    </h5>
                  </div>
                  <div className="card-body">
                    <div className="tab-content p-0">
                      {isLoader ? (
                        <Loader />
                      ) : (
                        <Chart
                          chartType="Bar"
                          width="100%"
                          height="400px"
                          data={camUnitCount}
                          options={options3}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </section> */}

              {/* <section className="col-lg-6 connectedSortable">
                <div className="card">
                  <div className={`card-header ${isAdmin ? 'adminColor' : '' }`}>
                    <h3 className="card-title">
                      <i className="fas fa-chart-pie mr-1"></i>
                      Revenue
                    </h3>
                  </div>
                  <div className="card-body">
                    <div className="tab-content p-0">
                      <div className="table_div">
                        <table className="table w-100">
                          <tbody>
                            <tr>
                              <td>This Week (Forecast)</td>
                              <td>$4,600</td>
                            </tr>
                            <tr>
                              <td>Last Week</td>
                              <td>$4,260</td>
                            </tr>
                            <tr>
                              <td>This Week (ForeCast)</td>
                              <td>$31,395</td>
                            </tr>
                            <tr>
                              <td>Month to Date</td>
                              <td>$4,600</td>
                            </tr>
                            <tr>
                              <td>Month (Forecast)</td>
                              <td>$18,040</td>
                            </tr>
                            <tr>
                              <td>Year to Date</td>
                              <td>$146,840</td>
                            </tr>
                            <tr>
                              <td>YTD vs Last Year</td>
                              <td>2.40%</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </section> */}
            </div>
          </div>
        </section>
      </div>
    </>
  )
}

export default UserDashboard