import React from 'react'
import { Chart } from "react-google-charts";


export const data = [
    ["Task", "Hours per Day"],
    ["Company A", 11],
    ["Company B", 2],
    ["Company C", 2],
    ["Company D", 2],
    ["Company E", 3],
    ["Company F", 1],
    ["Company G", 5],
  ];
  
  export const options = {
    title: "Passes By Management Company",
    is3D: true,
  };



  export const chartData = [
    ["Task", "Hours per Day"],
    ["January", 11],
    ["February", 2],
    ["March", 2],
    ["April", 2],
    ["May", 3],
    ["Jun", 1],
    ["July", 5],
  ];
  
  export const chartOptions = {
    title: "Passes/Day By Month",
    is3D: true,
  };




  export const charData3 = [
    ["Year", "Sales", "Expenses", "Profit"],
    ["2014", 1000, 400, 200],
    ["2015", 1170, 460, 250],
    ["2016", 660, 1120, 300],
    ["2017", 1030, 540, 350],
    ["2015", 1170, 460, 250],
    ["2016", 660, 1120, 300],
    ["2017", 1030, 540, 350],
    ["2015", 1170, 460, 250],
    ["2016", 660, 1120, 300],
    ["2017", 1030, 540, 350],
    ["2015", 1170, 460, 250],
    ["2016", 660, 1120, 300],
    ["2017", 1030, 540, 350],
    ["2015", 1170, 460, 250],
    ["2016", 660, 1120, 300],
    ["2017", 1030, 540, 350],
    ["2014", 1000, 400, 200],
    ["2015", 1170, 460, 250],
    ["2016", 660, 1120, 300],
    ["2017", 1030, 540, 350],
    ["2015", 1170, 460, 250],
    ["2016", 660, 1120, 300],
    ["2017", 1030, 540, 350],
    ["2015", 1170, 460, 250],
    ["2016", 660, 1120, 300],
    ["2017", 1030, 540, 350],
    ["2015", 1170, 460, 250],
    ["2016", 660, 1120, 300],
    ["2017", 1030, 540, 350],
    ["2015", 1170, 460, 250],
    ["2016", 660, 1120, 300],
    ["2017", 1030, 540, 350],

  ];
  
  export const ChartOptions3 = {
    chart: {
      title: "Passes Sold 2023",
      subtitle: "Sales, Expenses, and Profit: 2014-2017",
    },
  };



const RunReport = () => {
    
  return (
   <>
   

<div className="content-wrapper">
    <div className="content-header">
      <div className="container-fluid">
        <div className="row mb-2">
          <div className="col-sm-6">
            <h1 className="m-0">REPORTS</h1>
          </div>
          <div className="col-sm-6">
            <ol className="breadcrumb float-sm-right">
              <li className="breadcrumb-item"><a href="#">Home</a></li>
              <li className="breadcrumb-item active">Reports</li>
            </ol>
          </div>
        </div>
      </div>
    </div>
   
    <section className="content">
      <div className="container-fluid">
        <div className="row">

            <section className="col-lg-6 connectedSortable">
            <div className="card">
              <div className="card-header">
                <h3 className="card-title">
                  <i className="fas fa-chart-bar mr-1"></i>
                 Passes By Management Company
                </h3>
              </div>
              <div className="card-body">
              <div className="tab-content p-0">
                        <Chart
                        chartType="PieChart"
                        data={data}
                        options={options}
                        width={"100%"}
                        height={"400px"}
                        />
              </div>
            </div>
            </div>
          </section>

          <section className="col-lg-6 connectedSortable">
            <div className="card">
              <div className="card-header">
                <h3 className="card-title"> 
                  <i className="fas fa-chart-bar mr-1"></i>
                  Passes/Day By Month
                </h3>
              </div>
              <div className="card-body">
                <div className="tab-content p-0">
                <Chart
                        chartType="PieChart"
                        data={chartData}
                        options={chartOptions}
                        width={"100%"}
                        height={"400px"}
                        />
                </div>
              </div>
            </div>
          </section>

          <section className="col-lg-12 connectedSortable">
            <div className="card">
              <div className="card-header">
                <h3 className="card-title">
                  <i className="fas fa-chart-bar mr-1"></i>
                  Passes Sold 2023
                </h3>
              </div>
              <div className="card-body">
                <div className="tab-content p-0">
                  <Chart
                    chartType="Bar"
                    width="100%"
                    height="400px"
                    data={charData3}
                    options={ChartOptions3}
                    />
                </div>
              </div>
            </div>
          </section>
        
        </div>
      
      </div>
    </section>
  </div>

   </>
  )
}

export default RunReport