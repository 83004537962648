import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Api from "../Config/Api";


export const fetchState = createAsyncThunk(
  "state/fetchState",
  async (postData) => {
    try {
        const results = await Api("get-state", "POST", "");
       return results.data;
    } catch (error) {
        console.log(error.message);
    }
  }
);

const initialState = {
  state: [],
  status: "loading", // idle | loading | succeeded | failed
};

const stateSlice = createSlice({
  name: "state",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchState.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchState.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.state = action?.payload?.data;
      })
      .addCase(fetchState.rejected, (state, action) => {
        state.status = "succeeded";
      });
  },
});

export const getState = (state) => state.state;
export default stateSlice.reducer;
