import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from "react-redux";
import { BsSearch } from "react-icons/bs";
import { useState } from 'react';
import dayjs from 'dayjs';
import { FaEye, FaTrashAlt } from 'react-icons/fa';
import { FcSms } from "react-icons/fc";
import Api from '../../../Config/Api';
import Loader from "../../Elements/Loader";
import { toast } from 'react-toastify';
import { Tooltip } from "react-tooltip";


const ManageOwnerPasses = () => {
    const location = useLocation();
    const isAdmin = useSelector((state) => state.login.isAdmin);
    const [passInfo, setPassInfo] = useState([]);
    const [ownerPassList, setOwnerPassList] = useState([]);
    const [recipientEmail, setRecipientEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const [isLoader, setIsLoader] = useState(true);
    const roleId = useSelector((state) => state.login.roleId);
    const [isEmailSending, setEmailSending] = useState(false);
    const [isDeleted, setIsDeleted] = useState([]);

    const getPassInfo = async () => {
        setIsLoader(true);
        const res = await Api("getVehiclepassById", "POST", {
            id: location?.state?.id,
            type: "by_user_id",
            for: 'owner',
        });
        console.log('res', res);
        if (res?.data?.status) {
            setPassInfo(res?.data?.data);
            setOwnerPassList(res?.data?.data);
            setIsLoader(false);
        }
    };
console.log(" location?.state?.propertyId", location?.state?.propertyId);
    const [searchTerm, setSearchTerm] = useState("");

    const handleInputChange = (event) => {
        setSearchTerm(event.target.value);
        const search = event.target.value;
        const filtered = ownerPassList?.filter((item) => {
            return (
                item?.order_uuid.toLowerCase().includes(search.toLowerCase()));
        });
        setPassInfo(filtered);
    };

    const handleSendEmail = async (vehiclePassId) => {

        setEmailSending(true);
        const shouldSend = window.confirm('Are you sure you want to send an email?');
        if (shouldSend) {
            try {
                const res = await Api('SendPasses', 'POST', { vehiclePassId, 'passfor': 'owner' });
                if (Boolean(res?.data.status)) {
                    toast.success('Pass Successfully send.');
                } else {
                    toast.error(`Error in sending the Pass`);
                }
                setEmailSending(false);
            } catch (error) {
                toast.error(`Server Error`);
            }
        }
    };

    const handleDelete = async (idToDelete) => {
        //  deletefun(idToDelete, "deletePass");

        if (isDeleted) {
        }
        if (window.confirm("Are You Sure You Want to delete it?")) {
            const { data } = await Api("deletePass", "POST", { id: idToDelete });
            if (data?.status) {
                toast.success("Pass has been deleted successfully!", {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
            getPassInfo();
        }
    };


    useEffect(() => {
        getPassInfo();
    }, [location?.state?.id]);

    return (
        <>
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                {<h1 className="m-0">{passInfo?.is_passes_for === null ? "Owner Guest pass" : "Owner Passes"}
                                </h1>}
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item">
                                        <Link to="/"
                                            className={location.pathname === "/" ? "breadcrumb-active" : "breadcrumb-not-active"}
                                        >
                                            Home
                                        </Link>
                                        <span className="breadcrumb-arrow">&gt;</span>
                                        <Link to={"/user/OwnerListing/" + btoa(location?.state?.propertyId)}
                                            className={location.pathname.startsWith("/user/OwnerListing/" + btoa(location?.state?.propertyId)) ? "breadcrumb-active" : "breadcrumb-not-active"}
                                        >
                                            Unit
                                        </Link>
                                    </li>
                                    <span className="breadcrumb-arrow">&gt;</span>
                                    <li className="breadcrumb-item active">Owners Pass
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className={`card-header ${isAdmin || Number(atob(roleId)) === 4 ? "adminColor" : "cambg-secondary"
                                        }`}>
                                        <div className="add_new_owner_listing">
                                            <div>
                                                <h3 className="card-title mt-2">Owner Passes</h3>
                                            </div>
                                            {Number(atob(roleId)) === 2 ? (
                                                <div className="d-flex justify-content-end mt-2">
                                                    <Link
                                                        to={"/user/OwnerCreatePass"}
                                                        className="btn btn-light text-dark bg-light"
                                                        state={{ id: location?.state?.id, for: 'owner' }}
                                                    >
                                                        Create Passes
                                                    </Link>
                                                </div>
                                            ) : ("")}
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-end px-4 mt-2">
                                        <div className="input-group w-25">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Search..."
                                                value={searchTerm}
                                                onChange={handleInputChange}
                                            />
                                            <div className="input-group-append">
                                                <span
                                                    className="input-group-text bg-primary border-0"
                                                    id="basic-addon2"
                                                >
                                                    <BsSearch />
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    {isLoader ? (
                                        <Loader />
                                    ) : (
                                        <div className="card-body table-responsive">
                                            <table className="table table-bordered">
                                                <thead className="border-top">
                                                    <tr>
                                                        <th>Order</th>
                                                        <th>Name</th>
                                                        <th>Email</th>
                                                        <th>Valid From</th>
                                                        <th>Valid To</th>
                                                        <th>Status</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {passInfo.length ? (
                                                        passInfo?.map((data, i) => (

                                                            <tr key={i}>
                                                                <td>{data?.order_uuid}</td>
                                                                <td>
                                                                    {data?.unit?.firstname + " " + data?.unit?.lastname}
                                                                </td>
                                                                <td>
                                                                    {data?.unit?.email}
                                                                </td>
                                                                <td>
                                                                    {dayjs(data?.arrival_date).format(
                                                                        "MM-DD-YYYY"
                                                                    )}
                                                                </td>
                                                                <td>
                                                                    {dayjs(data?.departure_date).format(
                                                                        "MM-DD-YYYY"
                                                                    )}
                                                                </td>

                                                                <td><span className='badge badge-success'>Success</span></td>

                                                                <td>
                                                                    <div className="d-flex justify-content-center">
                                                                        <Link to={"/user/SuccessPaymentView"}
                                                                            state={{ id: data?.id, propertyId: data?.property_id, }}
                                                                            className="btn btn-success btn-sm mr-1"
                                                                            data-tooltip-id="detail"
                                                                            data-tooltip-content={`View ${data?.vendor?.point_of_fname} Pass Detail`}
                                                                        >
                                                                            <FaEye />
                                                                        </Link>



                                                                        {
                                                                            Number(atob(roleId)) === 2 ? (
                                                                                <>
                                                                                    <div>
                                                                                        {/* Your Pass Details UI here */}

                                                                                        <button
                                                                                            onClick={() => handleSendEmail(data.id)}
                                                                                            className='btn btn-light btn-sm mx-2'
                                                                                            disabled={isEmailSending}
                                                                                        >
                                                                                            <FcSms />
                                                                                        </button>
                                                                                    </div>
                                                                                </>
                                                                            ) : ("")
                                                                        }

                                                                        <button
                                                                            onClick={() => handleDelete(data?.id)}
                                                                            className="btn  btn-danger btn-sm mr-1"
                                                                            data-tooltip-id="my-tooltip"
                                                                            data-tooltip-content="Delete"
                                                                        >
                                                                            <FaTrashAlt />
                                                                        </button>
                                                                        <Tooltip id="my-tooltip" place="bottom" />

                                                                        {/*                                     
                                    <Tooltip id="detail" place="bottom" />
                                      <Link to={'/user/SuccessPaymentView'}>hle
                                      </Link>
                                   */}
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        ))) : (
                                                        <tr>
                                                            <td colSpan={8}>
                                                                <p className="text-center">
                                                                    No record found.
                                                                </p>
                                                            </td>
                                                        </tr>
                                                    )
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        </>
    )
}

export default ManageOwnerPasses