import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import Api from "../../Config/Api";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { updateUser } from "../../Reducers/Login";
import { Spinner } from "react-bootstrap";
import { getProperty } from "../../Reducers/RenterProperty";
const EditProfile = () => {
  const isAdmin = useSelector((state) => state.login.isAdmin);
  const {
    reset,
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [phone, setPhone] = useState();

  const RenterProperty = useSelector(getProperty);
  const userId = useSelector((state) => state.login.userId);
  const email = useSelector((state) => state.login.email);
  const [isLoading, setLoading] = useState(false);

  const getModifyCamData = async () => {
    const { data } = await Api("cam-get-user", "POST", {
      id: userId,
    });
    const CamData = data?.data;
    setPhone(data?.data?.phone);
    reset(CamData);
  };

  useEffect(() => {
    if (!!userId) {
      getModifyCamData();
    }
  }, [userId]);

  const background_image = RenterProperty?.bg_image
    ? process.env.REACT_APP_AWS_S3_BUCKET_URL + "images/" + RenterProperty?.bg_image
    : "";
  const property_logo = RenterProperty?.logo
    ? process.env.REACT_APP_AWS_S3_BUCKET_URL +
    "images/" + RenterProperty?.logo
    : "";

  const sectionStyle = {
    backgroundImage: `url(${background_image})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  };

  const onSubmit = async (d) => {
    try {
      setLoading(true);
      const { data } = await Api("update-user", "POST", d);
      if (data?.status) {
        setLoading(false);
        toast.success("Update Successfully !", {
          position: toast.POSITION.TOP_RIGHT,
        });
        dispatch(updateUser(data.data));
        navigate("/RenterEditProfile");
      }
    } catch (error) {
      if (error?.status == 500) console.log("error", error?.statusText);
      toast.error(error?.statusText, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  useEffect(() => {
    setPhone(phone || ''); // Use an empty string if 'phone' is undefined
  }, [phone]);

  const handleInputChange = (event) => {
    const rawPhoneNumber = event.target.value.replace(/\D/g, '');
    const truncatedPhoneNumber = rawPhoneNumber.slice(0, 10);
    const formattedPhoneNumber = truncatedPhoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');

    // Set the phone value directly using setValue from react-hook-form
    setValue('phone', formattedPhoneNumber);

    // Update the state with the formatted phone number
    setPhone(formattedPhoneNumber);
  };

  return (
    <>
      <main>
        <section
          className="py-5 guest_section bannersection"
          style={sectionStyle}
        >
          <div className="container-fluid" style={{
            paddingLeft: "269px"
          }}>
            <div className="new_guest_form" style={{ marginBottom: "225px" }}>
              <section className="content">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="">
                        <h5
                          className={`py-2 px-4 text-light rounded-3 ${isAdmin ? "adminColor" : "cambg-secondary"
                            }`}
                        >
                          Update Profile
                        </h5>
                        <form
                          className="form-horizontal"
                          onSubmit={handleSubmit(onSubmit)}
                        >
                          <div className="card-body">
                            <div className="row align-items-center">
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label htmlFor="enterPass">First Name</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="enterPass"
                                    placeholder="Enter First Name"
                                    {...register("name", { required: true })}
                                  />
                                  {errors.name && (
                                    <div className="form_error text-danger">
                                      name is required.
                                    </div>
                                  )}
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className="form-group">
                                  <label htmlFor="lastname">Last Name</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="lastname"
                                    placeholder="Enter Last Name"
                                    {...register("lastname", {
                                      required: true,
                                    })}
                                  />
                                  {errors.name && (
                                    <div className="form_error text-danger">
                                      Last Name is required.
                                    </div>
                                  )}
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className="form-group">
                                  <label htmlFor="enterEmail">Email</label>
                                  <input
                                    type="email"
                                    className="form-control"
                                    id="enterEmail"
                                    placeholder="Enter Email"
                                    {...register("email", { required: true })}
                                  />
                                  {errors.name && (
                                    <div className="form_error text-danger">
                                      Email is required.
                                    </div>
                                  )}
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className="form-group">
                                  <label htmlFor="enterNumber">Phone</label>
                                  <input
                                    type="text"
                                    value={phone}
                                    onChange={handleInputChange}
                                    className="form-control"
                                    id="enterNumber"
                                    placeholder="Enter Phone Number"
                                    required={true}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="card-footer">
                            <button
                              className="btn btn-outline-light text-dark mx-3 bg-light"
                              type="button"
                              onClick={() => navigate(-1)}
                            >
                              Cancel
                            </button>
                            <button
                              type="submit"
                              variant="primary"
                              className={`btn btn-primary  ${isAdmin ? "" : "cambg-secondary border-0"
                                }`}
                              disabled={isLoading}
                            >
                              {isLoading ? (
                                <>
                                  <Spinner animation="border" size="sm" />{" "}
                                  Please wait...
                                </>
                              ) : (
                                "Update"
                              )}
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default EditProfile;
