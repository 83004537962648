import React from 'react'
import { FaEdit } from "react-icons/fa";

const Maintenance = () => {
  return (
    <>
     <div className="content-wrapper">
    <div className="content-header">
      <div className="container-fluid">
        <div className="row mb-2">
          <div className="col-sm-6">
            <h1 className="m-0">MAINTENANCE</h1>
          </div>
          <div className="col-sm-6">
            <ol className="breadcrumb float-sm-right">
              <li className="breadcrumb-item"><a href="#">Home</a></li>
              <li className="breadcrumb-item active">Maintenance</li>
            </ol>
          </div>
        </div>
      </div>
    </div>
   
    <section className="content">
      <div className="container-fluid">
        <div className="row">
        <div className="col-md-12">
            <div className="card">
              <div className="card-header">
                <h3 className="card-title">Maintenance List</h3>
              </div>
              <div className="card-body table-responsive">
                <table className="table table-bordered">
                  <thead className="border-top">
                    <tr>
                      <th>Pass Type</th>
                      <th>Start Date</th>
                      <th>Stop Date</th>
                      <th>Amount</th>
                      <th>Unit</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Standard</td>
                      <td>1/1/2023</td>
                      <td>12/31/2023</td>
                      <td>$35.00</td>
                      <td>Per Stay</td>
                      <td><a href="/EditMaintenance" className="btn btn-flat btn-primary btn-sm"><FaEdit/></a></td>
                    </tr>
                    <tr>
                      <td>Handicap</td>
                      <td>1/1/2023</td>
                      <td>12/31/2023</td>
                      <td>$15.00</td>
                      <td>Per Stay</td>
                      <td><a href="/EditMaintenance" className="btn btn-flat btn-primary btn-sm"><FaEdit/></a></td>
                    </tr>

                    <tr>
                        <td>Premium</td>
                        <td>1/1/2023</td>
                        <td>12/31/2023</td>
                        <td>$50.00</td>
                        <td>Per Stay</td>
                        <td><a href="/EditMaintenance" className="btn btn-flat btn-primary btn-sm"><FaEdit/></a></td>
                      </tr>
                      <tr>
                        <td>July 4th</td>
                        <td>7/3/2023</td>
                        <td>7/5/2023</td>
                        <td>$10.00</td>
                        <td>Date Specific</td>
                        <td><a href="/EditMaintenance" className="btn btn-flat btn-primary btn-sm"><FaEdit/></a></td>
                      </tr>

                      <tr>
                        <td>Owner</td>
                        <td>1/1/2023</td>
                        <td>12/31/2023</td>
                        <td>$0.00</td>
                        <td>Per Stay</td>
                        <td><a href="/EditMaintenance" className="btn btn-flat btn-primary btn-sm"><FaEdit/></a></td>
                      </tr>

                      <tr>
                        <td>Owner Guest</td>
                        <td>1/1/2023</td>
                        <td>12/31/2023</td>
                        <td>$0.00</td>
                        <td>Per Stay</td>
                        <td><a href="/EditMaintenance" className="btn btn-flat btn-primary btn-sm"><FaEdit/></a></td>
                      </tr>

                      <tr>
                        <td>Vendor</td>
                        <td>1/1/2023</td>
                        <td>12/31/2023</td>
                        <td>$0.00</td>
                        <td>Per Stay</td>
                        <td><a href="/EditMaintenance" className="btn btn-flat btn-primary btn-sm"><FaEdit/></a></td>
                      </tr>

                      <tr>
                        <td>High Season</td>
                        <td>6/1/2023</td>
                        <td>9/1/2023</td>
                        <td>$5.00</td>
                        <td>Date Specific</td>
                        <td><a href="/EditMaintenance" className="btn btn-flat btn-primary btn-sm"><FaEdit/></a></td>
                      </tr>

                      <tr>
                        <td>Weekend Pass</td>
                        <td>1/1/2023</td>
                        <td>12/31/2023</td>
                        <td>$15.00</td>
                        <td>Fri-Sun</td>
                        <td><a href="/EditMaintenance" className="btn btn-flat btn-primary btn-sm"><FaEdit/></a></td>
                      </tr>

                      <tr>
                        <td>Weekly Pass</td>
                        <td>1/1/2023</td>
                        <td>12/31/2023</td>
                        <td>$25.00</td>
                        <td>7 Days</td>
                        <td><a href="/EditMaintenance" className="btn btn-flat btn-primary btn-sm"><FaEdit/></a></td>
                      </tr>

                      <tr>
                        <td>Snow bird</td>
                        <td>1/1/2023</td>
                        <td>12/31/2023</td>
                        <td>$50.00</td>
                        <td>Monthly</td>
                        <td><a href="/EditMaintenance" className="btn btn-flat btn-primary btn-sm"><FaEdit/></a></td>
                      </tr>

                  </tbody>
                </table>
              </div>
                    </div>
           
          </div>
      
      </div>
      </div>
    </section>
  </div>
    
    </>
  )
}

export default Maintenance