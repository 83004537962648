import React, { useEffect, useState } from 'react';
import { BsSearch } from "react-icons/bs";
import { useSelector } from "react-redux";
import { Link, useLocation } from 'react-router-dom';
import { FaEye, FaDollarSign, FaUser } from "react-icons/fa";
import { Tooltip } from "react-tooltip";
import Api from '../../../Config/Api';
import { useParams } from "react-router-dom";
import dayjs from 'dayjs';
import Loader from "../../Elements/Loader";
import InfiniteScroll from "react-infinite-scroll-component";

const ManagementCoPropertyList = () => {
    const location = useLocation();
    const isAdmin = useSelector((state) => state.login.isAdmin);
    const roleId = useSelector((state) => state.login.roleId);
    const userId = useSelector((state) => state.login.userId);
    const [managCompPropList, setManagCompPropList] = useState([]);
    const [perPage] = useState(10);
    const [page, setPage] = useState(1);

    const [isLoader, setIsLoader] = useState(true);
    const [camList, setCamList] = useState([]);
    const { id } = useParams();
    console.log("id>>>>", atob(id));
    // console.log("user_id");

    const getOwnerDetails = async () => {
        setIsLoader(true);

        const { data } = await Api("getunitByManagementId", "POST", { "managementCo": atob(id) });
        console.log("data>>>>>>>>>", data);
        if (data?.status) {
            setPage(1);
            const temparr = data?.response.slice(0, perPage);
            setCamList(data?.response);
            setManagCompPropList(temparr);
            // console.log("managCompPropList>>>>>>>>>", managCompPropList);
        }

        setIsLoader(false); // Set loader to false after data fetching
    };

    const handleNext = () => {
        const start = page * perPage;
        const end = start + perPage;
        const temArr = managCompPropList.concat(camList.slice(start, end));
        setPage((prev) => prev + 1);
        setManagCompPropList(temArr);
    };

    const [searchTerm, setSearchTerm] = useState("");

    const handleInputChange = (event) => {
        setSearchTerm(event.target.value);
        const search = event.target.value;
        const filtered = camList.filter((item) => {
            return (
                item?.units?.property?.name.toLowerCase().includes(search.toLowerCase()) ||
                item?.units?.property?.address.toLowerCase().includes(search.toLowerCase()) ||
                item?.units?.property?.city.toLowerCase().includes(search.toLowerCase()) ||
                item?.units?.property?.state.toLowerCase().includes(search.toLowerCase())
            );
        });
        setManagCompPropList(filtered);
    };

    useEffect(() => {
        getOwnerDetails();
    }, [id]);

    console.log("managCompPropList", managCompPropList);
    return (
        <>
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                {<h1 className="m-0">Management Co Property List</h1>}
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item">
                                        <Link to="/"
                                            className={location.pathname === "/" ? "breadcrumb-active" : "breadcrumb-not-active"}
                                        >
                                            Home
                                        </Link>
                                        {isAdmin ? (
                                            <>
                                                <span className="breadcrumb-arrow">&gt;</span>
                                                <Link
                                                    to="/user/ManagementCompanyListing"
                                                    className={
                                                        location.pathname.startsWith("/user/ManagementCompanyListing")
                                                            ? "breadcrumb-active"
                                                            : "breadcrumb-not-active"
                                                    }
                                                >
                                                    Management Co
                                                </Link>
                                            </>
                                        ) : ("")}
                                    </li>
                                    <span className="breadcrumb-arrow">&gt;</span>
                                    <li className="breadcrumb-item active">Property
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className={`card-header ${isAdmin || Number(atob(roleId)) === 4 ? "adminColor" : "cambg-secondary"}`}>
                                        <div className="row">
                                            <div className="col-lg-8">
                                                <h3 className="card-title mt-2">Details </h3>
                                            </div>
                                            <div className="col-lg-2">

                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-end px-4 mt-2">
                                        <div className="input-group w-25">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Search..."
                                                value={searchTerm}
                                                onChange={handleInputChange}
                                            />
                                            <div className="input-group-append">
                                                <span
                                                    className="input-group-text bg-primary border-0"
                                                    id="basic-addon2"
                                                >
                                                    <BsSearch />
                                                </span>
                                            </div>
                                        </div>
                                    </div>


                                    {isLoader ? (
                                        <Loader />
                                    ) : (
                                        <div className="card-body table-responsive">
                                            <InfiniteScroll
                                                dataLength={managCompPropList.length}
                                                next={handleNext}
                                                hasMore={camList.length > managCompPropList.length}
                                                loader={<Loader />}
                                            >
                                                <table className="table table-bordered">
                                                    <thead className="border-top">
                                                        <tr>
                                                            <th>Property Name</th>
                                                            <th>Address</th>
                                                            <th>City</th>
                                                            <th>State</th>
                                                            <th>Zip</th>
                                                            <th>Added Date</th>
                                                            <th>CAM Details</th>
                                                            <th>Actions</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {managCompPropList?.length ? (

                                                            managCompPropList.reduce((uniqueData, data) => {
                                                                const isDuplicate = uniqueData.some(item => item?.units?.property?.id === data?.units?.property?.id);

                                                                if (!isDuplicate) {
                                                                    uniqueData.push(data);
                                                                }

                                                                return uniqueData;
                                                            }, []).map((data, i) => (
                                                                data?.units !== null ? (
                                                                    <tr key={i}>
                                                                        <td>{data?.units?.property?.name}</td>
                                                                        <td>{data?.units?.property?.address}</td>
                                                                        <td>{data?.units?.property?.city}</td>
                                                                        <td>{data?.units?.property?.state}</td>
                                                                        <td>{data?.units?.property?.zip}</td>
                                                                        <td>{dayjs(data?.units?.property?.created_at).format("MM/DD/YYYY")}</td>
                                                                        <td>
                                                                            {
                                                                                Boolean(data?.units?.property?.cam_data) &&
                                                                                    data?.units?.property?.cam_data?.role_id == 2 ? (
                                                                                    <p>
                                                                                        {"Name: " +
                                                                                            data?.units?.property?.cam_data?.name +
                                                                                            " " +
                                                                                            data?.units?.property?.cam_data?.lastname}{" "}
                                                                                        <br />
                                                                                        {"Email: " +
                                                                                            data?.units?.property?.cam_data?.email}
                                                                                        <br />
                                                                                        {"Phone: " +
                                                                                            data?.units?.property?.cam_data?.phone}
                                                                                    </p>
                                                                                ) : (
                                                                                    <p>-</p>
                                                                                )
                                                                            }
                                                                        </td>
                                                                        <td>
                                                                            <div className="d-flex justify-content">
                                                                                <Link
                                                                                    to={"/user/PropertyDetail"}
                                                                                    state={{
                                                                                        id: data?.units?.property.id,
                                                                                        isEdit: true,
                                                                                    }}
                                                                                    className="btn btn-success btn-sm mr-1"
                                                                                    data-tooltip-id="detail"
                                                                                    data-tooltip-content="Show Property Details"
                                                                                >
                                                                                    <FaEye />
                                                                                </Link>
                                                                                <Tooltip id="detail" place="bottom" />

                                                                                {/* <Link
                                                                                to={"/user/PassPricingListing/" + btoa(data?.units?.property?.id)}
                                                                                className="btn btn-primary btn-sm mr-1"
                                                                                data-tooltip-id="detail"
                                                                                data-tooltip-content="Pricing Listing"
                                                                            >
                                                                                <FaDollarSign />
                                                                            </Link>
                                                                            <Tooltip id="detail" place="bottom" /> */}

                                                                                <Link
                                                                                    to={`/user/ManageOwnerListing/${btoa(
                                                                                        data?.units?.property?.id
                                                                                    )}/${btoa(data?.user_id)}`}
                                                                                    className="btn btn-info btn-sm mr-1"
                                                                                    data-tooltip-id="detail"
                                                                                    data-tooltip-content="Manage Unit"
                                                                                >
                                                                                    <FaUser />
                                                                                </Link>
                                                                                <Tooltip id="detail" place="bottom" />
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                ) : (""))
                                                            )) : (
                                                            <tr>
                                                                <td colSpan={8}>
                                                                    <p className="text-center">No record found.</p>
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>

                                                </table>
                                            </InfiniteScroll>
                                        </div>
                                    )}

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>

    )
}

export default ManagementCoPropertyList