import { useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { FiCheckCircle, FiAlertCircle } from "react-icons/fi";
import Api from "../../../Config/Api";


const Success = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const success = searchParams.get("redirect_status");
  const paymentIntentParam = searchParams.get("payment_intent");
  const [msg, setMsg] = useState("");
  const [paymentIntent, setPaymentIntent] = useState(null);
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const paymentIntentParam = urlParams.get("payment_intent");
    const success = urlParams.get("redirect_status");
    setPaymentIntent(paymentIntentParam);
    setMsg(success);
    navigate("/ConfirmPayment");
  }, []);

  useEffect(() => {
    if (paymentIntent !== null) {
      console.log('paymentIntentParam', paymentIntent);
      SavePaymentUsingPaymentIntent();
    }
  }, [paymentIntent]);


const SavePaymentUsingPaymentIntent = async()=>{
    const req = {
        paymentIntentId:paymentIntent
    }
    const res =await Api('retrievePaymentIntent','POST',req);
    console.log('res',res);
}


  return (
    <>
      <section className="py-3 py-lg-4 bg-light">
        <div className="container">
          <div className="mb-4">
            <div className="bg-white text-center shadow-sm p-3 p-lg-5 rounded-3">
              <div className="row justify-content-center">
                <div className="col-md-7">
                  {msg?.indexOf("succeeded") !== -1 ? (
                    <>
                      <FiCheckCircle className="display-2 text-success mb-3" />
                      <h2 className="text-success">Buy Pass has been Created successful</h2>
                      <p className="small">
                        Please wait! We are redirecting you...
                      </p>
                    </>
                  ) : (
                    <>
                      <FiAlertCircle className="display-2 text-danger mb-3" />
                      <h2 className="text-danger">
                        Sorry for the inconvenience caused! Please try again.
                      </h2>
                    </>
                  )}

                  <div
                    className={`alert alert-${
                      msg?.indexOf("succeeded") !== -1 ? "success" : "danger"
                    } m-0`}
                  >
                    <p
                      dangerouslySetInnerHTML={{
                        __html: msg?.replace("AkcessPass TEAM", "<br>AkcessPass TEAM"),
                      }}
                    ></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Success;
