import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { BsFillEyeFill, BsPrinterFill } from "react-icons/bs";
import { useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import { BsSearch } from "react-icons/bs";
import Api from "../../../Config/Api";
import dayjs from "dayjs";
import Loader from "../../Elements/Loader";

const PassesList = () => {

    const location = useLocation();
    const isAdmin = useSelector((state) => state.login.isAdmin);
    const propertyId = useSelector((state) => state.login.propertyId);
    const isLogin = useSelector((state) => state.login.isLogin);
    const [passData, setPassData] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const [pList, setPList] = useState([]);

    const getAllpassData = async () => {
        setLoading(true);

        const passesCount = await Api('passes_count', "POST", { property_id: propertyId });
        console.log("passesCount", passesCount);

        if (passesCount?.data?.status) {
            // setPage(1);
            // var temparr = data?.passData.slice(0, perPage);
            
            const passes = passesCount?.data?.passes?.filter((data) => (data?.is_passes_for != "Owner") && data?.is_passes_for != "Vendor");
            setPassData(passes);
            setPList(passes);
            setLoading(false);
            
        }
    };
    console.log("pList",pList);
    
    useEffect(() => {
        getAllpassData();
    }, []);
    
    const [searchTerm, setSearchTerm] = useState("");
    
    const handleInputChange = (event) => {
        setSearchTerm(event.target.value);
        const search = event.target.value;
        console.log("search",search);
        const filtered = pList.filter((item) => {
            return item?.billingdetail?.fname?.toLowerCase()?.includes(search?.toLowerCase()) || item?.billingdetail?.lname?.toLowerCase()?.includes(search?.toLowerCase()) || item?.billingdetail?.vehiclepass?.order_uuid?.toLowerCase()?.includes(search?.toLowerCase());
        });
        console.log("filtered", filtered);
        setPassData(filtered);
        setLoading(false);

    };

    return (
        <>
            <main>
                <section className="">
                    <div
                        className="container-fluid"
                    // style={{
                    //     paddingLeft: "269px",
                    // }}
                    >
                        <Modal
                            size="lg"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                        // show={modalShow}
                        // onHide={() => setModalShow(false)}
                        >
                            <Modal.Header closeButton>
                                <Modal.Title id="contained-modal-title-vcenter">
                                    Passes & Printouts
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                            </Modal.Body>
                        </Modal>


                        <div className="content-wrapper">
                            <div className="content-header">
                                <div className="container-fluid">
                                    <div className="row mb-2">
                                        <div className="col-sm-6">
                                            {<h1 className="m-0">Payment/Pass History</h1>}
                                        </div>
                                        <div className="col-sm-6">
                                            <ol className="breadcrumb float-sm-right">
                                                <li className="breadcrumb-item">
                                                    <Link to="/"
                                                        className={location.pathname === "/" ? "breadcrumb-active" : "breadcrumb-not-active"}
                                                    >
                                                        Home
                                                    </Link>
                                                </li>
                                                <span className="breadcrumb-arrow">&gt;</span>
                                                <li className="breadcrumb-item active">Payment History</li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <section className="content">
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="card">
                                                <div className={`card-header ${isAdmin ? "adminColor" : "cambg-secondary"}`}>
                                                    <div className="add_new_vendor_listing">
                                                        <h3 className="card-title">Details</h3>

                                                    </div>
                                                </div>

                                                <div className="d-flex justify-content-end px-4 mt-2">
                                                    <div className="input-group w-25">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Search..."
                                                            value={searchTerm}
                                                            onChange={handleInputChange}
                                                        />
                                                        <div className="input-group-append">
                                                            <span
                                                                className="input-group-text bg-primary border-0"
                                                                id="basic-addon2"
                                                            >
                                                                <BsSearch />
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                {isLoading ? (
                                                    <Loader />
                                                ) : (

                                                    <div className="card-body table-responsive">
                                                        {/* <InfiniteScroll
                                                            dataLength={passData.length}
                                                            next={handleNext}
                                                            hasMore={camList?.length > passData.length}

                                                        // loader={<Loader />}

                                                        > */}
                                                        <table className="table table-bordered">
                                                            <thead className="border-top">
                                                                <tr>
                                                                    <th>Pass ID</th>
                                                                    <th>Name</th>
                                                                    <th>Date</th>
                                                                    <th>Status</th>
                                                                    <th>Total</th>
                                                                    <th>Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {passData.length > 0 ? (

                                                                    passData.map((Order, i) => (
                                                                        <tr key={i}>
                                                                            <th>
                                                                                {Order?.order_uuid}
                                                                            </th>
                                                                            <td>
                                                                                {(Order?.billingdetail) ?
                                                                                    (Order?.billingdetail?.fname + " " + Order?.billingdetail?.lname) : ("_")}
                                                                            </td>
                                                                            <td>
                                                                                {
                                                                                    dayjs(Order?.created_at).format("MM/DD/YYYY")}
                                                                            </td>
                                                                            <td>
                                                                                <span className="badge badge-success">
                                                                                    {Order?.order_payments?.status}
                                                                                </span>
                                                                            </td>
                                                                            <td>
                                                                                ${Order?.order_payments ? (Number(Order?.order_payments?.amount / 100).toFixed(2)) : "-"}
                                                                            </td>
                                                                            <td>
                                                                                <Link
                                                                                    to={"/user/PaymentHistoryView"}
                                                                                // state={{ id: Order?.id }}
                                                                                >
                                                                                    <span className="btn btn-primary btn-sm mx-">
                                                                                        <BsFillEyeFill />
                                                                                    </span>
                                                                                </Link>

                                                                                {/* <button
                                                                                    className="btn btn-primary btn-sm mx-2"
                                                                                    onClick={() =>
                                                                                        printParkingPass(Order?.id)
                                                                                    }
                                                                                >
                                                                                    {printPassLoading ? (
                                                                                        <>
                                                                                            <Spinner
                                                                                                animation="border"
                                                                                                size="sm"
                                                                                            />
                                                                                        </>
                                                                                    ) : (
                                                                                        <BsPrinterFill />
                                                                                    )}
                                                                                </button> */}
                                                                            </td>
                                                                        </tr>
                                                                    ))
                                                                ) : (
                                                                    <>
                                                                        <tr>
                                                                            <td colSpan={6}>
                                                                                <h4 className="text-center text-secondary">
                                                                                    {" "}
                                                                                    No Order Found Yet.
                                                                                </h4>
                                                                            </td>
                                                                        </tr>
                                                                    </>
                                                                )}

                                                            </tbody>
                                                        </table>
                                                        {/* </InfiniteScroll> */}
                                                    </div>
                                                )}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </section>
            </main>
        </>
    )
}

export default PassesList