import React, { useEffect, useState } from "react";
import { FaEdit, FaTrashAlt, FaPlus } from "react-icons/fa";
import { Link, useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import Api from "../../../Config/Api";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { FaEye } from "react-icons/fa";
import { useSelector } from "react-redux";
import { Tooltip } from "react-tooltip";
import Loader from "../../Elements/Loader";
import { capitalizeFirstLetter } from "../../../Utility/Helpers";
import { Spinner } from "react-bootstrap";
import SecuritySample from "../../../sampleCSV/SecuritySample.csv";
import { BsSearch } from "react-icons/bs";
import InfiniteScroll from "react-infinite-scroll-component";
import dayjs from "dayjs";
const Activity = () => {
  const location = useLocation();
  const [isLoader, setIsLoader] = useState(true);
  const [isChecked, setIsChecked] = useState(false);
  const [camList, setCamList] = useState([]);
  const [securityList, setSecurityList] = useState([]);
  const [perPage] = useState(10);
  const [page, setPage] = useState(1);

  const [isDisable, setIsDisable] = useState(false);
  const [deleteId, setDeleteId] = useState([]);
  // const [propertyIsLoader, setPropertyIsLoader] = useState(false);
  // const [isLoading, setLoading] = useState(false);
  const { id } = useParams();
  // console.log("id", atob(id));

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const handleShow = (camData) => {
    setShow(true);
  };

  const isAdmin = useSelector((state) => state.login.isAdmin);


  const getSecurityDetails = async () => {
    setIsLoader(true);

    try {
      const { data } = await Api("getAllActivityLogs", "GET", { property_id: id ? atob(id) : "" });
      console.log("data", data?.data);

      if (data?.status) {
        setPage(1);

        // Filter data based on property_id matching id
        if (id) {
          const filteredData = data?.data?.filter(item => item.property_id === (id ? atob(id) : ""));

          var temparr = filteredData.slice(0, perPage);

          setCamList(filteredData);
          setSecurityList(temparr);
          console.log("securityList>>>>>>>>>>>>", securityList);
        } else {

          var temparr = data?.data?.slice(0, perPage);
          // setPropertyIsLoader(false);
          setCamList(data?.data);
          setSecurityList(temparr);
          setIsLoader(false);
        }
      }

    } catch (error) {
      console.error("Error fetching data:", error);
      // Handle error (e.g., set an error state, show an error message)
    } finally {
      setIsLoader(false);
    }
    // console.log('data', camList.length);
    // console.log('hal', securityList.length);
  };

  const handleNext = () => {
    const start = page * perPage;
    const end = start + perPage;
    const temArr = securityList.concat(camList.slice(start, end));
    setPage((prev) => prev + 1);
    setSecurityList(temArr);

  };

  useEffect(() => {
    getSecurityDetails();
  }, []);

  const handleDelete = async (type, idToDelete) => {
    if (window.confirm("Are You Sure You Want to delete it?")) {
      var { data } = await Api("deleteLogs", "POST", {
        type: idToDelete ? "single" : "",
        id: idToDelete ? idToDelete : "",
      });
      if (data?.status) {
        toast.success("Activity Successfully Deleted.");
        getSecurityDetails();
      } else {
        toast.error("An error occurred while deleting logs");
      }
    }
  };

  const [searchTerm, setSearchTerm] = useState("");

  const handleInputChange = (event) => {

    let searchValue = event.target.value;
    setSearchTerm(searchValue);
    let searchData = camList.filter(
      (data) =>
        data?.user?.name.toLowerCase().includes(searchValue.toLowerCase()) ||
        data?.property?.name.toLowerCase().includes(searchValue.toLowerCase())
    );
    setSecurityList(searchData);
    // console.log('data', camList.length);
    // console.log('hal', securityList.length);

  };


  const toggleSelectAll = (e) => {

    const checked = e.target.checked;
    // console.log("checked>>>>>>>>>>>>>>>>>>>>",checked);
    setIsChecked(checked);

    if (checked) {
      const allIds = securityList.map(data => data.id);
      setDeleteId(allIds);
      setIsDisable(true);
      console.log("securityList", securityList);
    } else {
      setDeleteId([]);
      setIsDisable(false);
    }
  };

  // setIsDisable(true);
  const AllCheckedActivityDeleted = async () => {
    const { data } = await Api("deleteLogs", "POST", {
      id: deleteId,
      type: "multiple",
    });
    if (data?.status) {
      setIsDisable(false);
      toast.success("Activity Successfully Deleted.");
      getSecurityDetails();
      setIsChecked(false);
    } else {
      toast.error("An error occurred while deleting logs");
      setIsChecked(false);
    }
  };

  return (
    <>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Activity</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link
                      to="/"
                      className={
                        location.pathname === "/"
                          ? "breadcrumb-active"
                          : "breadcrumb-not-active"
                      }
                    >
                      Home
                    </Link>

                  </li>
                  {id ? (
                    <>
                      <span className="breadcrumb-arrow">&gt;</span>
                      <Link to="/user/PropertyListing"
                        className={location.pathname.startsWith("/user/PropertyListing") ? "breadcrumb-active" : "breadcrumb-not-active"}
                      >
                        Property
                      </Link>
                    </>
                  ) : ("")}
                  <span className="breadcrumb-arrow">&gt;</span>

                  <li className=" active">Activity</li>
                </ol>
              </div>
            </div>
          </div>
        </div>

        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div
                    className={`card-header ${isAdmin ? "adminColor" : "cambg-secondary"
                      }`}
                  >
                    <div className="add_new_vendor_listing">
                      <h3 className="card-title mt-2">Activity</h3>
                    </div>
                  </div>

                  <div className="d-flex justify-content-end px-3 mt-2">
                    {/* <div>
                      <button
                        className="btn btn-danger"
                        onClick={AllCheckedActivityDeleted}
                        disabled={!isDisable}
                      >
                        Delete All checked
                      </button>
                    </div> */}
                    {/* <div>
                      <button
                        className="btn btn-danger mx-3"
                        onClick={handleDelete}
                      >
                        Delete All
                      </button>
                    </div> */}
                    <div className="input-group w-25">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search by Name, Property Name..."
                        value={searchTerm}
                        onChange={handleInputChange}
                      />
                      <div className="input-group-append">
                        <span
                          className="input-group-text bg-primary border-0"
                          id="basic-addon2"
                        >
                          <BsSearch />
                        </span>
                      </div>
                    </div>
                  </div>                  <>
                    {isLoader ? (
                      <Loader />
                    ) : (
                      <div className="card-body table-responsive">
                        <InfiniteScroll
                          dataLength={securityList.length}
                          next={handleNext}
                          hasMore={camList.length > securityList.length}
                        // loader={<Loader />}
                        // endMessage={<p>No more data to load</p>}
                        >
                          <table className="table table-bordered">
                            <thead className="border-top">
                              <tr>
                                {/* <th> */}
                                {/* <input
                                          type="checkbox"
                                          className="mx-2"
                                             onChange={(e) => {
                                            if (e.target.checked) {
                                              seIsChecked(true);
                                            } else {
                                              seIsChecked(false);
                                            }
                                          }}
                                        /> */}
                                {/* <input
                                    type="checkbox"
                                    className="mx-2"
                                    checked={isChecked}
                                    onChange={toggleSelectAll}
                                  /> */}
                                {/* </th> */}
                                <th>Logs</th>
                                {/* <th>Action</th> */}
                              </tr>
                            </thead>
                            <tbody>

                              {
                                securityList?.length > 0 ? (
                                  securityList?.map((data, i) => (
                                    <tr key={i}>
                                      {/* <td>
                                      <input
                                          type="checkbox"
                                          className="mx-2"
                                          checked={deleteId.includes(data?.id)}
                                          
                                          onChange={(e) => {
                                            const isChecked = e.target.checked;
                                            if (isChecked) {
                                              setIsDisable(deleteId.length+1 >= 1 ? true:false)
                                              setDeleteId((value) => [...value, data?.id]);
                                            } else {
                                              setIsDisable(deleteId.length-1 <= 0 ? false:true)
                                              setDeleteId((value) =>
                                                value.filter((id) => id !== data?.id)
                                              );
                                            }
                                          }}
                                        />
                                      </td> */}

                                      <td>
                                        {data?.user?.name} {data?.user?.lastname} {data?.msg} for Property{" "}
                                        {data?.property?.name || "-"} at{" "}
                                        {dayjs(data?.created_at).format("MM/DD/YYYY")}
                                      </td>

                                      {/* <td>
                                        <div className="d-flex justify-content-center">
                                          <button
                                            onClick={() =>
                                              handleDelete("single", data.id)
                                            }
                                            className="btn  btn-danger btn-sm mr-1"
                                            data-tooltip-id="my-tooltip"
                                            data-tooltip-content="Delete"
                                          >
                                            <FaTrashAlt />
                                          </button>
                                          <Tooltip id="my-tooltip" place="bottom" />
                                        </div>
                                      </td> */}
                                    </tr>
                                  ))
                                ) : (
                                  <tr>
                                    <td colSpan={5}>
                                      <div className="d-flex justify-content-center">
                                        <h5 className="text-secondary">No Data Found</h5>
                                      </div>
                                    </td>
                                  </tr>

                                )

                              }
                            </tbody>
                          </table>
                        </InfiniteScroll>
                      </div>
                    )}
                  </>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Activity;
