import React from 'react'
import { useSelector } from 'react-redux';
import logoImg from "../../../assets/img/AdminLTELogo.png"
import adminlogoImg from "../../../assets/img/logo-white.png";
const Footer = () => {
  const propertyData = useSelector((state) => state.login.propertyData);
  const isAdmin = useSelector((state)=>state.login.isAdmin);
  const roleId = useSelector((state) => state.login.roleId);
  return (
    <>
    <footer className={`main-footer ${isAdmin || Number(atob(roleId)) === 4 ? 'adminColor' : Number(atob(roleId)) === 5 ? 'cambg-primary m-0 py-4' : 'cambg-primary mx-0'}`}>
    <div className="text-center">
    {isAdmin || Number(atob(roleId)) === 4 ? (
      <img src={adminlogoImg} alt="AdminLTE Logo" className="brand-image" style={{"height":"30px"}}/>

    ) : ( propertyData && ( <img src={ propertyData?.logo
      ? process.env.REACT_APP_AWS_S3_BUCKET_URL +
        "images/" +
        propertyData?.logo
      : logoImg} alt="AdminLTE Logo" className="brand-image" style={{"height":"55px"}}/>
    ))}

    </div>
  </footer>
    </>
  )
}

export default Footer