import React, { useEffect, useState } from "react";
import logoImg from "../../../assets/img/logo-white.png";
import { FaUserAlt, FaKey } from "react-icons/fa";
import Api from "../../../Config/Api";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from 'react-redux';
import { setUser } from '../../../Reducers/Login';
import { useNavigate } from "react-router-dom";
import { Spinner } from 'react-bootstrap';
import Cookies from "js-cookie";
const Login = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [isLoading, setLoading] = useState(false);
  const isLogin = useSelector((state) => state.login.isLogin)
  const roleId = useSelector((state) => state.login.roleId)
  useEffect(() => {
    if (!!isLogin) {
      if (Number(atob(roleId)) === 3) {
        window.location.href = "/ViewPrintPass";
      } else if (Number(atob(roleId)) === 5) {
        window.location.href = "/Security";
      } else {
        window.location.href = "/user";
      }
    }
  }, [isLogin]);


  const onSubmit = async (d) => {
    Cookies.remove("userDetail", {
      expires: new Date("May 16, 1970"),
      path: "/",
    });
    setLoading(true);
    const { data } = await Api("user-login", "POST", d);

    if (data.status) {
        if(Boolean(data?.data?.details.login_status)){
      setLoading(false);
      dispatch(setUser(data?.data));
      toast.success("Welcome Back to Akcess Pass!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      if (Number(data?.data?.role_id) === 3) {
        window.location.href = "/ViewPrintPass";
      }
      else {
        window.location.href = "/user";
      }
    }else{
      setLoading(false);
      toast.error("You are not allowed to log in at this moment. Your account has been deactivated.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    } else {
      setLoading(false);
      toast.error("Invalid Credentails", {
        position: toast.POSITION.TOP_RIGHT,
      });

    }



  }



  return (
    <>
      <div className="loginformpage">
        <div className="loginform">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="">
              <div className="row justify-content-center">
                <div className="col-12">
                  <div className="loginheader">
                    <img src={logoImg} alt="logo" />
                    <h2>Log In</h2>
                    <p>login here using your username and password</p>
                  </div>

                  <div className="">
                    <div className="row g-3">
                      <div className="col-12">
                        <div className="position-relative">
                          <span className="inputicon">
                            <FaUserAlt />
                          </span>

                          <input
                            className="form-control"
                            type="text"
                            placeholder="UserName"
                            required
                            {...register('email', { required: true })}
                          />

                        </div>
                        {errors.email && (
                          <div className="form_error text-danger">
                            Email ID is required.
                          </div>
                        )}
                      </div>
                      <div className="col-12">
                        <div className="position-relative">
                          <span className="inputicon">
                            <FaKey />
                          </span>

                          <input
                            className="form-control"
                            type="password"
                            placeholder="Password"
                            required
                            {...register('password', { required: true })}
                          />

                        </div>
                        {errors.email && (
                          <div className="form_error text-danger" >
                            Password is required.
                          </div>
                        )}
                      </div>
                      <div className="col-12">
                        <div>
                          <button className="btn btn-primary w-100" variant="success" type="submit" disabled={isLoading}>
                            {" "}

                            {isLoading ? (
                              <>
                                <Spinner animation="border" size="sm" /> Please wait...
                              </>
                            ) : (
                              'Log In'
                            )}



                          </button>
                        </div>
                      </div>
                      {/* <div className="col-6">
                        <div>
                          <button className="btn p-0">
                            Forgot Password
                          </button>
                        </div>
                      </div> */}
                      <div className="col-6">
                        <div className="text-end">
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="other"></div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Login;
