import React, { useState } from "react";
import aqua_white from "../../../assets/img/index2_logo.png";
import { Dropdown } from "react-bootstrap";
import userImg from "../../../assets/img/user.svg";
import { getRoleName } from "../../../Utility/Helpers";
import { useSelector, useDispatch } from "react-redux";
import { removeUser } from "../../../Reducers/Login";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { capitalizeFirstLetter } from "../../../Utility/Helpers";
import { BsFillCartPlusFill } from "react-icons/bs";
import { useForm } from "react-hook-form";
import Cookies from "js-cookie";
import Api from "../../../Config/Api";
import { setUser } from '../../../Reducers/Login';
import { Button, Spinner } from "react-bootstrap";
const RenterHeader = ({ propertyData }) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLogin = useSelector((state) => state.login.isLogin);
  const userName = useSelector((state) => state.login.name);
  const roleId = useSelector((state) => state.login.roleId);
  const cart = useSelector(state => state.cart);
  const logo = useSelector((state) => state.login.logo);
  const [isLoading, setLoading] = useState(false);
  const logout = () => {
    console.log('Management Logout ');
    dispatch(removeUser());
    toast.success("Logout Successfully!", {
      position: toast.POSITION.TOP_RIGHT,
    });
    navigate("/Login");
  };

  const onSubmit = async (d) => {
    Cookies.remove("userDetail", {
      expires: new Date("May 16, 1970"),
      path: "/",
    });
    setLoading(true);
    const { data } = await Api("user-login", "POST", d);
    console.log('user_login_data', data);
    if (data.status) {
      setLoading(false);
      dispatch(setUser(data?.data));
      toast.success("Welcome Back to Akcess Pass!", {
        position: toast.POSITION.TOP_RIGHT,
      });
     
    } else {
      setLoading(false);
      toast.error("Invalid Credentails", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }

  return (
    <>
      <header className="renter_header cambg-primary">
        <div className={`${isLogin ? "container-fluid px-5" : "container"}`}>
          <div className="row align-items-center">
            <div className="col-md-5">
              <div className={`logo py-2`}>
                <img
                  src={
                    propertyData?.logo
                      ? process.env.REACT_APP_AWS_S3_BUCKET_URL +
                      "images/" +
                      propertyData?.logo
                      : aqua_white
                  }
                  className="img-fluid"
                  width={isLogin ? 80 : 160}
                />
              </div>
            </div>
            {isLogin ? (
              <>
                {/* <div className="col-lg-7">
                  <div className="">
                    <ul className="list-unstyled d-flex justify-content-end align-items-center gap-2 m-0">
                      <li className="nav-item mx-4">
                        <Link to={cart?.cartItem ? '/RenterCheckout' : '/ViewPrintPass'} state={{ data: { id: btoa(cart?.cartItem) } }} className="btn btn-primary p-0 bg-transparent position-relative border-0" >
                          <BsFillCartPlusFill className="fs-4 text-light" />
                          <span className="position-absolute top-0 start-100 translate-middle p-2 bg-danger border border-light rounded-circle d-flex align-items-center justify-content-center" style={{ "width": "20px", "height": "22px" }}>
                            <span style={{ fontSize: 10 }}> {cart?.cartItem ? 1 : 0}</span>
                          </span>
                        </Link>

                      </li>

                      <li className="nav-item">
                        <Dropdown align="end">
                          <Dropdown.Toggle variant="" className="p-0">
                            <div className="row">
                              <div className="col-lg-6">
                                <img
                                  src={logo ? process.env.REACT_APP_AWS_S3_BUCKET_URL + "images/" + logo : userImg}
                                  height={40}
                                  width={40}
                                  alt="user"
                                  style={{ borderRadius: "30px" }}
                                />
                              </div>
                              <div className="col-lg-6">
                                <span>
                                  <b> {capitalizeFirstLetter(userName)} </b>
                                </span>
                                <small className="d-block">
                                  {getRoleName(atob(roleId))}
                                </small>
                              </div>
                            </div>
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item to="/RenterEditProfile">
                              <Link to="/RenterEditProfile" className="text-dark">
                                <p>Update Profile</p>
                              </Link>
                            </Dropdown.Item>
                            <Dropdown.Item as="button" onClick={logout}>
                              <p>Logout</p>
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </li>
                    </ul>
                  </div>
                </div> */}

                <div className="col-lg-7 col-9">
                  <div className="d-flex justify-content-end align-items-center">
                    <Dropdown align="end" className="border-0">
                      {/* bsPrefix */}
                      <Dropdown.Toggle variant="" className="p-3 rounded-pill btn btn-outline-light renter_profile_drop" id="dropdown-basic">
                        <img
                          src={
                            logo
                              ? process.env.REACT_APP_AWS_S3_BUCKET_URL + "images/" + logo
                              : userImg
                          }
                          alt="User"
                          style={{
                            width: "30px",
                            height: "30px",
                            borderRadius: "50%",
                            marginRight: "10px",
                          }}
                        />
                        <b> {capitalizeFirstLetter(userName)} </b> ({" "}
                        {getRoleName(atob(roleId))})
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item to="/RenterEditProfile">
                          <Link to="/RenterEditProfile" className="text-dark">
                            <p>Update Profile</p>
                          </Link>
                        </Dropdown.Item>
                        <Dropdown.Item as="button" onClick={logout}>
                          <p>Logout</p>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>


              </>
            ) : (
              <>
                <div className="col-md-7">
                  <div className="login_div">
                    <div className="returning_heading">
                      <h2 className="pb-3 text-white">
                        Login For Returning Guests
                      </h2>
                    </div>

                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="row align-items-center">
                        <div className="col-md-5">
                          <div className="form_input_guest">
                            <label className="text-white">E-mail Address</label>
                            <input
                              className="form-control"
                              type="text"
                              placeholder="UserName"
                              required
                              {...register('email', { required: true })}
                            />
                          </div>
                          {errors.email && (
                            <div className="form_error text-danger">
                              Email ID is required.
                            </div>
                          )}
                        </div>



                        <div className="col-md-5">
                          <div className="form_input_guest">
                            <label className="text-white">Password</label>

                            <input
                              className="form-control"
                              type="password"
                              placeholder="Password"
                              required
                              {...register('password', { required: true })}
                            />
                          </div>
                          {errors.email && (
                            <div className="form_error text-danger" >
                              Password is required.
                            </div>
                          )}
                        </div>


                        <div className="col-md-2">
                          <div className="form_btn">
                            <button
                              type="submit"
                              variant="primary"
                              className={`btn btn-primary cambg-secondary`}
                              disabled={isLoading}
                            >
                              {isLoading ? (
                                <>
                                  <Spinner animation="border" size="sm" /> Please wait...
                                </>
                              ) : (
                                "Submit"
                              )}
                            </button>
                          </div>
                        </div>
                      </div>

                    </form>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </header>
    </>
  );
};

export default RenterHeader;
