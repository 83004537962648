import React, { useEffect, useState } from "react";
import { FaEdit, FaTrashAlt, FaPlus, FaMoneyBill, } from "react-icons/fa";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import Api from "../../../Config/Api";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { FaEye } from "react-icons/fa";
import { useSelector } from "react-redux";
import { Tooltip } from "react-tooltip";
import Loader from "../../Elements/Loader";
import vendorSample from "../../../sampleCSV/vendorSample.csv";
import { Spinner } from "react-bootstrap";
import { capitalizeFirstLetter } from "../../../Utility/Helpers";
import { BsSearch } from "react-icons/bs";
import InfiniteScroll from "react-infinite-scroll-component";
const VendorListing = () => {
  const {
    register,
    handleSubmit,
    // reset,
    formState: { errors },
  } = useForm();

  const {
    register: register1,
    handleSubmit: handleSubmit1,
    // reset: reset1,
    formState: { errors: errors1 },
  } = useForm();


  const navigate = useNavigate();
  const location = useLocation();
  const [camList, setCamList] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const [propertyIsLoader, setPropertyIsLoader] = useState(true);
  const roleId = useSelector((state) => state.login.roleId);
  const [vendorList, setVendorList] = useState([]);
  const [userId, setUserId] = useState();
  const [vendorName, setvendorName] = useState();
  const param = useParams();
  const proid = param?.id ? atob(param?.id) : "";
  console.log("proid",proid);
  const [propertyId, setPropertyId] = useState(proid);
  const [isLoading, setLoading] = useState(false);
  const [propertyDetail, setPropertyDetail] = useState([]);
  const [file, setFile] = useState(null);
  const [perPage] = useState(10);
  const [page, setPage] = useState(1);
  const propertyData = useSelector((state) => state.login.propertyData);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };
  const [showBulk, setShowBulk] = useState(false);
  const handleCloseBulk = () => {
    setShowBulk(false);
    setPropertyId(null);
  }

  const handleShowBulk = () => {
    setShowBulk(true);
    setPropertyId(atob(param?.id))
  }

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const handleShow = (vendorData) => {
    setShow(true);
    setUserId(vendorData?.id);
    setvendorName(
      vendorData?.point_of_fname + " " + vendorData?.point_of_lname
    );
  };

  const isAdmin = useSelector((state) => state.login.isAdmin);
  const [property, setProperty] = useState([]);
  const getPropertyDetails = async () => {
    
    const { data } = await Api("get-property", "POST", "");
    if (data?.status) {
      setPropertyIsLoader(false)
      const propertyList = data?.data;
      setProperty(propertyList);
    }
  };

  const getVendorDetails = async () => {
    setIsLoader(true);
    const reqProData = {
      propertyId: atob(param?.id)
        ? atob(param?.id)
        : propertyData?.id,
    };

    const { data } = await Api("vendor-list", "POST", reqProData);
    setIsLoader(true);
    if (data?.status) {
      setPage(1);
      var temparr = data?.data.slice(0, perPage);
      setPropertyDetail(data?.property)
      setIsLoader(false);
      setVendorList(temparr);
      setCamList(data?.data);
    }
  };

  const handleNext = () => {
    const start = page * perPage;
    const end = start + perPage;
    const temArr = vendorList.concat(camList.slice(start, end));
    setPage((prev) => prev + 1);
    setVendorList(temArr);
  };

  useEffect(() => {
    getPropertyDetails();
    getVendorDetails();
  }, []);

  const handleDelete = async (idToDelete) => {
    if (window.confirm("Are You Sure You Want to delete it?")) {
      const { data } = await Api("delete-vendor", "POST", { id: idToDelete });
      if (data?.status) {
        toast.success(data?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }

      getVendorDetails();
    }
  };



  const onSubmit = async (d) => {
    const vendorPropertyData = {
      property_id: d?.property_id,
      vendor_id: userId,
    };
    const { data } = await Api("property-vendors", "POST", vendorPropertyData);
    if (Number(data?.status) === 1) {
      toast.success("Property has been Assigned Successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setShow(false);
    } else if (Number(data?.status) === 2) {
      toast.success(data?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      setShow(false);
    }
  };

  const onSubmit1 = async (d) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("csv_file", file);

    formData.append("propertyId", propertyId);
    try {
      const { data } = await Api("store-vendor-csv", "POST", formData);
      if (Boolean(data?.status)) {
        setLoading(false);
        toast.success(data?.data, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setShowBulk(false);
        getVendorDetails();
        navigate('/user/VendorListing/' + param?.id);

      }
    } catch (error) {
      toast.error('Something went wrong.', {
        position: toast.POSITION.TOP_RIGHT,
      });
      getVendorDetails();
      navigate("/user/VendorListing/" + param?.id);
    }

  };

  const [searchTerm, setSearchTerm] = useState("");
  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
    const search = event.target.value;
    const filtered = camList.filter((item) => {
      console.log("item",item);
      const serviceName = item?.ser_category === null ? item?.service_category : item?.ser_category?.service_category_name;
      console.log("serviceName",serviceName);
      return item.point_of_fname.toLowerCase().includes(search.toLowerCase()) || item.poc_email.toLowerCase().includes(search.toLowerCase()) || item.phone.toLowerCase().includes(search.toLowerCase()) || item.company_name.toLowerCase().includes(search.toLowerCase()) || serviceName.toLowerCase().includes(search.toLowerCase());
    });    
    setVendorList(filtered);    
  };



  return (
    <>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                {<h1 className="m-0">Vendors</h1>}
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/"
                      className={location.pathname === "/" ? "breadcrumb-active" : "breadcrumb-not-active"}
                    >
                      Home
                    </Link>
                    {isAdmin ? (
                      <>
                      <span className="breadcrumb-arrow">&gt;</span>
                    <Link to="/user/PropertyListing"
                      className={location.pathname.startsWith("/user/PropertyListing") ? "breadcrumb-active" : "breadcrumb-not-active"}
                    >
                      Property
                    </Link>
                      </>
                    ) : ("")}
                  </li>
                  <span className="breadcrumb-arrow">&gt;</span>
                  <li className="breadcrumb-item active">Vendors
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>

        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className={`card-header ${isAdmin ? "adminColor" : "cambg-secondary"}`}>
                    <div className="add_new_vendor_listing">
                      <div>
                        <h3 className="card-title mt-2">Property :
                          {
                            propertyIsLoader ? (<Spinner as="span"
                              animation="grow"
                              size="sm"
                              role="status"
                              aria-hidden="true" />) : (
                              <span className="px-1">
                                {" "}
                                {Boolean(propertyDetail)
                                  ? capitalizeFirstLetter(
                                    propertyDetail?.name +
                                    ', ' +
                                    propertyDetail?.city +
                                    ', ' +
                                    propertyDetail?.state
                                  ) : ""}
                              </span>
                            )}

                        </h3>
                      </div>

                      <div>
                        <button
                          className="btn btn-light text-dark bg-light mx-2"
                          state={{ propertyId: propertyId }}
                          onClick={handleShowBulk}
                        >
                          Import Vendors
                        </button>

                        <Link
                          to={"/user/VendorFrom"}
                          className="btn btn-light text-dark bg-light"
                          state={{ propertyId: propertyId }}
                        >
                          Add Vendor
                        </Link>
                      </div>

                    </div>
                  </div>
                  <div className="d-flex justify-content-end px-4 mt-2">
                    <div className="input-group w-25">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search..."
                        value={searchTerm}
                        onChange={handleInputChange}
                      />
                      <div className="input-group-append">
                        <span
                          className="input-group-text bg-primary border-0"
                          id="basic-addon2"
                        >
                          <BsSearch />
                        </span>
                      </div>
                    </div>
                  </div>


                  {isLoader ? (
                    <Loader />
                  ) : (
                    <div className="card-body table-responsive">
                      <InfiniteScroll
                        dataLength={vendorList.length}
                        next={handleNext}
                        hasMore={camList.length > vendorList.length}
                        loader={<Loader />}
                      >
                        <table className="table table-bordered">
                          <thead className="border-top">
                            <tr>
                              <th>Service Category</th>
                              <th>Vendor Company Name</th>
                              <th>Vendor Company City</th>
                              <th>Vendor Company State</th>
                              <th>POC Name</th>
                              <th>POC Email</th>
                              <th>POC Phone</th>
                              <th>Property</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {vendorList.length > 0 ? (
                              vendorList?.map((data, i) => (

                                <tr key={i}>
                                  <td>
                                    {data?.ser_category ?
                                      (data?.ser_category?.service_category_name) : (data?.service_category)}
                                  </td>
                                  <td>{data?.company_name}</td>
                                  <td>{data?.city}</td>
                                  <td>{data?.state_code ? data?.state_code : data?.state}</td>


                                  <td>
                                    {data?.point_of_fname +
                                      " " +
                                      data?.point_of_lname}
                                  </td>
                                  <td>{data?.poc_email}</td>
                                  <td>{data?.phone}</td>

                                  <td>{data?.property_data?.name}</td>

                                  <td>
                                    <div className="d-flex justify-content-around">
                                    {Number(atob(roleId)) === 2 ? ("") : (
                                      <>
                                      <Button
                                        className="btn btn-info btn-sm mr-1"
                                        onClick={() => handleShow(data)}
                                        data-tooltip-id="assign"
                                        data-tooltip-content="Property"
                                      >
                                        <FaPlus />
                                      </Button>
                                      <Tooltip id="assign" place="bottom" />
                                      </>
                                    )}
                                     

                                      <Link
                                        to={"/user/VendorDetail"}
                                        state={{ id: data?.id, propertyId: proid, isEdit: true }}
                                        className="btn btn-success btn-sm mr-1"
                                        data-tooltip-id="detail"
                                        data-tooltip-content="Show Vendor Detail"
                                      >
                                        <FaEye />
                                      </Link>
                                      <Tooltip id="detail" place="bottom" />
                                      <Link
                                        to="/user/VendorFrom"
                                        state={{ id: data?.id, propertyId: proid, isEdit: true }}
                                        className="btn  btn-primary btn-sm mr-1"
                                        data-tooltip-id="edit"
                                        data-tooltip-content="Edit"
                                      >
                                        <FaEdit />
                                      </Link>
                                      <Tooltip id="edit" place="bottom" />

                                     
                                      {
                                        isAdmin || Number(atob(roleId)) === 4 || Number(atob(roleId)) === 2 ? (
                                         <>
                                         <Link
                                        to={"/user/ManagePasses"}
                                        state={{ id: data?.id, propertyId: data?.property_id}}
                                        className="btn btn-info btn-sm mr-1"
                                        data-tooltip-id="detail"
                                        data-tooltip-content="Manage Vendor Passes"
                                      >
                                        <FaMoneyBill />
                                      </Link>
                                      <Tooltip id="detail" place="bottom" />
                                         </>
                                        ) : ("")
                                      }

                                      <button
                                        onClick={() => handleDelete(data.id)}
                                        className="btn  btn-danger btn-sm mr-1"
                                        data-tooltip-id="my-tooltip"
                                        data-tooltip-content="Delete"
                                      >
                                        <FaTrashAlt />
                                      </button>
                                      <Tooltip id="my-tooltip" place="bottom" />
                                    </div>
                                  </td>
                                </tr>
                              ))) : (
                              <tr>
                                <td colSpan={8}>
                                  <p className="text-center">
                                    No record found.
                                  </p>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </InfiniteScroll>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Assign Property to {vendorName}</Modal.Title>
        </Modal.Header>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label>Select Property</label>
                  <select
                    className="form-control"
                    {...register("property_id", {
                      required: true,
                    })}
                  >
                    {property?.map((propertydata, i) => (
                      <option key={i} value={propertydata?.id}>
                        {propertydata?.name}
                      </option>
                    ))}
                  </select>
                  {errors.property_id && (
                    <p className="text-danger">Property is required</p>
                  )}
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" type="submit">
              Save
            </Button>
          </Modal.Footer>
        </form>
      </Modal>

      <Modal show={showBulk} onHide={handleCloseBulk}>
        <Modal.Header closeButton>
          <Modal.Title>Bulk Upload Vendors</Modal.Title>
        </Modal.Header>
        <form onSubmit={handleSubmit1(onSubmit1)} encType="multipart/form-data">
          <Modal.Body>
            {" "}
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label>Select Vendor's CSV File</label>
                  <input
                    type="file"
                    className="form-control mb-1"
                    {...register1("csv_file", { required: true })}
                    accept="csv"
                    onChange={handleFileChange}
                  />
                  {errors1.csv_file && (
                    <p className="text-danger">CSV fIle  is required</p>
                  )}
                  <a href={vendorSample} download={'vendor-Sample'} className="btn-sm " >Download the sample CSV for vendors</a>

                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseBulk}>
              Close
            </Button>
            <Button variant="primary" type="submit" disabled={isLoading}
            >
              {isLoading ? (
                <>
                  <Spinner animation="border" size="sm" /> Please wait...
                </>
              ) : (
                "Submit"
              )}
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
      </div>
    </>
  );
};

export default VendorListing;
