import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { BsSearch } from "react-icons/bs";
import { useState } from "react";
import Api from "../../../Config/Api";
import { useEffect } from "react";
// import { toast } from "react-toastify";
import dayjs from "dayjs";
// import { FaEye } from "react-icons/fa";
import { Tooltip } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import { FaEdit, FaTrashAlt, FaPlus, FaEllipsisV } from "react-icons/fa";
import useSweetAlert from "../../../Hooks/useSweetAlert";
import Loader from "../../Elements/Loader";
import { FaStickyNote } from "react-icons/fa";
import { Modal, Button } from "react-bootstrap";

const ManagePassRequest = () => {
  const [changePassRequest, setChangePassRequest] = useState([]);
  const location = useLocation();
  const isAdmin = useSelector((state) => state.login.isAdmin);
  const { deletefun, isDeleted } = useSweetAlert();
  const [isLoader, setIsLoader] = useState(true);
  const [camList, setCamList] = useState([]);
  const [showNotesModal, setShowNotesModal] = useState(false);
  const [notesContent, setNotesContent] = useState("");

  const getPassRequest = async (id) => {
    setIsLoader(true);
    const { data } = await Api("getChangePassDetailMgmtCoByCAMId", "POST", { id });
    // console.log("data", data?.response);
    if (Boolean(data?.status)) {
      setChangePassRequest(data?.response);
      setNotesContent(data?.notes);
      setCamList(data?.response);
      setIsLoader(false);
    } else {
      setChangePassRequest([]);
      setIsLoader(false);
    }
  };
  useEffect(() => {
    getPassRequest();
  }, []);

  const handleDelete = (id, url, status = "", unitId = "", userId = "") => {
    deletefun(id, url, status, unitId, userId);
  };

  const [searchTerm, setSearchTerm] = useState("");

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
    const search = event.target.value;
    const filtered = camList.filter((item) => {
      return item?.mgmt_data?.name.toLowerCase().includes(search.toLowerCase());
    });

    setChangePassRequest(filtered);
  };

  useEffect(() => {
    // console.log("inside the useeffect");
    if (isDeleted) {
      getPassRequest();
    }
  }, [isDeleted]);

  const handleShowNotes = (data, message) => {
    setNotesContent(message);
    setShowNotesModal(true);
  };
  // console.log("notesContent",notesContent);

  const getStatusForPass = (data) => {
    console.log("data>>>>>>>>>>", data);
    const departureDate = dayjs(data?.old_departure_date);
    const currentDate = dayjs();

    if (currentDate.isAfter(departureDate) || Number(data?.old_pass_status) === 0) {
      return "Inactive";
    } else {
      return "Active";
    }
  };

  const getNewStatusForPass = (data) => {
    console.log("data>>>>>>>>>>", data);
    const departureDate = dayjs(data?.departure_date);
    const currentDate = dayjs();

    if (currentDate.isAfter(departureDate) || Number(data?.pass_status) === 0) {
      return "Inactive";
    } else {
      return "Active";
    }
  };


  return (
    <>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                {<h1 className="m-0">Pass Requests</h1>}
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link
                      to="/"
                      className={
                        location.pathname === "/"
                          ? "breadcrumb-active"
                          : "breadcrumb-not-active"
                      }
                    >
                      Home
                    </Link>
                  </li>
                  <span className="breadcrumb-arrow">&gt;</span>
                  <li className="breadcrumb-item active">Pass Requests</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div
                    className={`card-header ${isAdmin ? "adminColor" : "cambg-secondary"
                      }`}
                  >
                    <div className="row">
                      <div className="col-lg-8">
                        <h3 className="card-title mt-2">
                          Change Pass Details Requests{" "}
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-end px-4 mt-2">
                    <div className="input-group w-25">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search..."
                        value={searchTerm}
                        onChange={handleInputChange}
                      />
                      <div className="input-group-append">
                        <span
                          className="input-group-text bg-primary border-0"
                          id="basic-addon2"
                        >
                          <BsSearch />
                        </span>
                      </div>
                    </div>
                  </div>

                  {isLoader ? (
                    <Loader />
                  ) : (
                    <div className="card-body table-responsive">
                      <table className="table table-bordered">
                        <thead className="border-top">
                          <tr>
                            <th>Management Co Name</th>
                            <th>Old Arrival Date</th>
                            <th>Old Departure Date</th>
                            <th>Old Pass Status</th>
                            <th>New Arrival Date</th>
                            <th>New Departure Date</th>
                            <th>New Pass Status</th>
                            <th>Request Status</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {changePassRequest?.length > 0 ? (
                            changePassRequest?.map((data, i) => (
                              <tr key={i}>
                                <td>{data?.mgmt_data?.name}</td>
                                <td>
                                  {" "}
                                  {dayjs(data?.old_arrival_date).format("MM/DD/YYYY")}
                                </td>
                                <td>
                                  {" "}
                                  {dayjs(data?.data?.old_departure_date).format(
                                    "MM/DD/YYYY"
                                  )}
                                </td>
                                <td>
                                  <span className={`badge badge-${(getStatusForPass(data) === "Active") ? "success" : "danger"}`}>
                                    {getStatusForPass(data)}
                                  </span>
                                </td>

                                <td>
                                  {" "}
                                  {dayjs(data?.arrival_date).format(
                                    "MM/DD/YYYY"
                                  )}
                                </td>

                                <td>
                                  {" "}
                                  {dayjs(data?.departure_date).format(
                                    "MM/DD/YYYY"
                                  )}
                                </td>

                                <td>
                                  <span className={`badge badge-${(getNewStatusForPass(data) === "Active") ? "success" : "danger"}`}>
                                    {getNewStatusForPass(data)}
                                  </span>
                                </td>

                                {(data?.status === null) ? (
                                  <td>
                                    <span className="badge badge-success">
                                      Acitve
                                    </span>
                                  </td>
                                ) : Number(data?.status) === 1 ? (
                                  <td>
                                    <span className="badge badge-primary">
                                      Approved
                                    </span>
                                  </td>
                                ) : Number(data?.status) === 2 ? (
                                  <td>
                                    <span className="badge badge-danger">
                                      Rejected
                                    </span>
                                  </td>
                                ) : (
                                  <td>
                                    <span className="badge badge-warning">
                                      Under Review
                                    </span>
                                  </td>
                                )
                                }

                                <td>
                                  <div className="d-flex justify-content-center">
                                    <Dropdown>
                                      <Dropdown.Toggle
                                        bsPrefix
                                        variant="primary"
                                        className="btn-sm"
                                      >
                                        <FaEllipsisV />
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu>
                                        <Dropdown.Item
                                          onClick={() =>
                                            handleDelete(
                                              "rejectPassRequest",
                                              data?.id,
                                              2
                                            )
                                          }
                                        >
                                          <span className="text-primary">
                                            Reject
                                          </span>
                                        </Dropdown.Item>

                                        <Dropdown.Item
                                          onClick={() =>
                                            handleDelete(
                                              "approvedPassRequest",
                                              data?.vehicle_pass?.id,
                                              1,
                                              data?.id
                                            )
                                          }
                                        >
                                          <span className="text-primary">
                                            Approved
                                          </span>
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>

                                    <FaStickyNote
                                      className="ml-2 text-primary"
                                      onClick={() => handleShowNotes(data?.id, data?.message)}
                                    />
                                  </div>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan={8}>
                                <p className="text-center">
                                  No Management Company Yet.
                                </p>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Modal show={showNotesModal} onHide={() => setShowNotesModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Notes</Modal.Title>
        </Modal.Header>
        <Modal.Body>{notesContent !== null ? notesContent : "No Message"}


        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowNotesModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ManagePassRequest;
