import axios from "axios";
import Cookies from "js-cookie";
import { useSelector } from "react-redux";
const parseUser = Boolean(Cookies.get('userDetail')) ? JSON.parse(Cookies.get('userDetail')) : null;
let cancelToken;
const header = {
  Accept: "application/json",
  // "Content-Type": "application/json",
 // "Content-Type": "multipart/form-data" ,
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Headers": "*",
}



const Api = async (endpoint, method, body, cancelTokenVar = false) => {
  if(parseUser){
    header.Authorization = `Bearer ${ parseUser?.token}`;
  }
  const options = {
    url: process.env.REACT_APP_API_URL+ 'api/' + endpoint,
    method: method || "GET",
    data: body,
    headers:header,
    responseType: "json",
  };

  if (cancelTokenVar) {
    //Check if there are any previous pending requests
    if (typeof cancelToken !== typeof undefined) {
      cancelToken.cancel("Operation canceled due to new request.");
    }

    //Save the cancel token for the current request
    cancelToken = axios.CancelToken.source();

    options.cancelToken = cancelToken.token;
  }

  if (method === "GET") {
    options.data = undefined;
  }

  // console.log("options=>>", options);

  return axios(options)
    .then((response) => {
      //  console.log(`response from ${endpoint} >> ${response}`);
      return response;
    })
    .catch((error) => {
      // console.log(`Error from ${endpoint} >> ${error}`);
      throw error.response;
    });
};


export default Api;
