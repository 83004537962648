import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { updateUser } from "../../../Reducers/Login";
import { Spinner } from 'react-bootstrap';
import Api from "../../../Config/Api";
import Loader from "../../Elements/Loader";

const EditProfile = () => {
  const {
    reset,
    setValue,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isAdmin = useSelector((state) => state.login.isAdmin);
  const name = useSelector((state) => state.login.name);
  const roleId = useSelector((state) => state.login.roleId);
  const userId = useSelector((state) => state.login.userId);
  const isSubMgmt = useSelector((state) => state.login.isSubMgmt);
  const subMgmtUserId = useSelector((state) => state.login.subMgmtUserId);
  const [isLoading, setLoading] = useState(false);
  const [isLoader, setIsLoader] = useState(true);
  const [phone, setPhone] = useState();

  const getUserDtails = async () => {
    const { data } = await Api("cam-get-user", "POST", {
      id:isSubMgmt ? subMgmtUserId : userId
    });
    console.log("data",data);
    setPhone(data?.data?.phone);
    reset(data?.data);
    setIsLoader(false);
  };

  useEffect(() => {
    getUserDtails();
  }, [name]);

  const onSubmit = async (d) => {
    setLoading(true);
    const { data } = await Api("update-user", "POST", d);
    if (data?.status) {
      setLoading(false);
      toast.success("Update Successfully !", {
        position: toast.POSITION.TOP_RIGHT,
      });

      dispatch(updateUser(data.data));
      navigate('/user/');
    }
  };

  useEffect(() => {
    setPhone(phone || ''); // Use an empty string if 'phone' is undefined
  }, [phone]);

  const handleInputChange = (event) => {
    const rawPhoneNumber = event.target.value.replace(/\D/g, '');
    const truncatedPhoneNumber = rawPhoneNumber.slice(0, 10);
    const formattedPhoneNumber = truncatedPhoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
    
    // Set the phone value directly using setValue from react-hook-form
    setValue('phone', formattedPhoneNumber);
  
    // Update the state with the formatted phone number
    setPhone(formattedPhoneNumber);
  };

  return (
    <>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Update Profile</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/"
                      className={location.pathname === "/" ? "breadcrumb-active" : "breadcrumb-not-active"}
                    >
                      Home
                    </Link>
                  </li>
                  <span className="breadcrumb-arrow">&gt;</span>
                  <li className="breadcrumb-item active">Update Profile</li>
                </ol>
              </div>
            </div>
          </div>
        </div>

        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card card-info">
                  <div className={`card-header ${isAdmin || Number(atob(roleId)) === 4 ? "adminColor" : "cambg-secondary"}`}>
                    <h3 className="card-title">Update Profile</h3>
                  </div>

                  {isLoader ? (
                    <Loader />
                  ) : (

                    <form
                      className="form-horizontal"
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <div className="card-body">
                        <div className="row align-items-center">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="enterPass">Name</label>
                              <input
                                type="text"
                                className="form-control"
                                id="enterPass"
                                placeholder="Enter Name"
                                {...register("name", { required: true })}
                              />
                              {errors.name && (
                                <div className="form_error text-danger">
                                  name is required.
                                </div>
                              )}
                            </div>
                          </div>
                          {isAdmin || Number(atob(roleId)) === 2 ? (
                            <>
                            <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="enterLastname">Last Name</label>
                              <input
                                type="text"
                                className="form-control"
                                id="enterLastname"
                                placeholder="Enter Last Name"
                                {...register("lastname", { required: true })}
                              />
                              {errors.name && (
                                <div className="form_error text-danger">
                                  Last Name is required.
                                </div>
                              )}
                            </div>
                          </div>
                            </>
                          ) : ("")}
                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="enterEmail">Email</label>
                              <input
                                type="email"
                                className="form-control"
                                id="enterEmail"
                                placeholder="Enter Email"
                                {...register("email", { required: true })}
                              />
                              {errors.name && (
                                <div className="form_error text-danger">
                                  Email is required.
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="enterNumber">Phone</label>
                              <input
                                type="text"
                                value={phone}
                                onChange={handleInputChange}
                                className="form-control"
                                id="enterNumber"
                                placeholder="Enter Phone Number"
                                required={true}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card-footer">
                        <button className="btn btn-outline-light text-dark mx-3 bg-light" type="button" onClick={() => navigate(-1)}>Cancel</button>
                        <button type="submit" variant="primary" className={`btn btn-primary`} disabled={isLoading}>
                          {isLoading ? (
                            <>
                              <Spinner animation="border" size="sm" /> Please wait...
                            </>
                          ) : (
                            'Update'
                          )}

                        </button>
                      </div>
                    </form>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default EditProfile;
