import { createSlice } from '@reduxjs/toolkit';
import Cookies from "js-cookie";

const CartSlice = createSlice({
  name: 'cart',
  initialState: {
   cartItem: Boolean(Cookies.get("cartItem")) && Cookies.get("cartItem")
    ? JSON.parse(Cookies.get("cartItem"))
    : null
  },
  reducers: {
    addItemToCart: (state, action) => {
      console.log('action.payload',action.payload);
      const newItem = action.payload;
      state.cartItem = newItem;
      Cookies.set("cartItem", JSON.stringify(newItem), {
        expires: 1 * 30 * 6,
        path: "/",
      });
    },
    PaymentSuccess:(state,payload)=>{
      state.cartItem = null;
      Cookies.remove("cartItem", {
        expires: new Date("May 16, 1970"),
        path: "/",
      });
    },
  },
});

export const { addItemToCart ,PaymentSuccess} = CartSlice.actions;
export default CartSlice.reducer;
