import React from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation, useParams } from 'react-router-dom';
import Api from '../../../Config/Api';
import { useEffect } from 'react';
import dayjs from 'dayjs';
import { FaEdit, FaEye, FaTrashAlt } from 'react-icons/fa';
import { Tooltip } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Loader from "../../Elements/Loader";
import InfiniteScroll from "react-infinite-scroll-component";

const PassPricingListing = () => {
  const { id } = useParams();
  const location = useLocation();
  const isAdmin = useSelector((state) => state.login.isAdmin);
  const roleId = useSelector((state) => state.login.roleId);
  
  const [priceData, setPriceData] = useState([]);
  const [camList, setCamList] = useState([]);
  const [propertyData, setPropertyData] = useState([]);

  const [isLoader, setIsLoader] = useState(false);
  const [perPage] = useState(10);
  const [page, setPage] = useState(1);
  const getPriceDetails = async () => {
    setIsLoader(true)
    const {data} = await Api('getPassPrice', "POST", { id: atob(id) });
    if (data?.status) {
      setPage(1);
      var temparr = data?.priceData.slice(0, perPage);
      setIsLoader(false)
      setPropertyData(data?.property)
      setPriceData(temparr);
      setCamList(data?.priceData);
    }
  };

  const handleNext = () => {
    const start = page * perPage;
    const end = start + perPage;
    const temArr = priceData.concat(camList.slice(start, end));
    setPage((prev) => prev + 1);
    setPriceData(temArr);
  };

  useEffect(() => {
    getPriceDetails();
  }, [id])

  const handleDelete = async (idToDelete) => {
    if (window.confirm("Are You Sure You Want to delete it?")) {
      const { data } = await Api("deletePassPrice", "POST", { id: idToDelete });
      if (data?.status) {
        toast.success(data?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }else{
        toast.error(data?.message || 'Something went wrong!')
      }
      getPriceDetails();
    }
  };

  return (
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              {<h1 className="m-0">Pricing Listing</h1>}
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/"
                    className={location.pathname === "/" ? "breadcrumb-active" : "breadcrumb-not-active"}
                  >
                    Home
                  </Link>
                  {isAdmin || Number(atob(roleId)) === 4 ?(
                    <>
                    <span className="breadcrumb-arrow">&gt;</span>
                  <Link to={isAdmin ? ("/user/PropertyListing") : ("/user/ManagementCoPropertyList/MTEz")}
                    className={isAdmin ? (location.pathname.startsWith("/user/PropertyListing") ? "breadcrumb-active" : "breadcrumb-not-active") : (location.pathname.startsWith("/user/ManagementCoPropertyList/MTEz") ? "breadcrumb-active" : "breadcrumb-not-active")}
                  >
                    Property
                  </Link>
                    </>
                  ) : ("")}
                </li>
                <span className="breadcrumb-arrow">&gt;</span>
                <li className="breadcrumb-item active">Pass Pricing
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>

      <section className="content">
        <div className="container-fluid">

          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className={`card-header ${isAdmin || Number(atob(roleId)) === 4  ? "adminColor" : "cambg-secondary"}`}>
                  <div className="add_new_vendor_listing">
                    <div>
                      <h3 className="card-title">Pricing : {propertyData?.name}</h3>
                    </div>
                    <div className="">
                    {isAdmin ? (
                      <Link
                        to={"/user/PriceForm"} state={{ propertyId: atob(id) }}
                        className="btn btn-light text-dark btn-sm bg-light mx-2"
                      >
                        Add Pricing
                      </Link>
                    ) : ("")
                    }
                    </div>
                  </div>
                </div>
                {isLoader ? (
                  <Loader />
                ) : (
                  <div className="card-body table-responsive">
                    <InfiniteScroll
                      dataLength={camList.length}
                      next={handleNext}
                      hasMore={camList.length > priceData.length}
                      loader={<Loader />}
                    >
                      <table className="table table-bordered">
                        <thead className="border-top">
                          <tr>
                            <th>Pass Name</th>
                            <th>Start Date</th>
                            <th>End Date</th>
                            <th>Amount</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {priceData.length?(
                           priceData?.map((price, i) => (
                              <tr key={i}>
                                <td>{price?.pass_name}</td>
                                <td>{dayjs(price?.start_date).format("MM/DD/YYYY")}</td>
                                <td>{dayjs(price?.stop_date).format("MM/DD/YYYY")}</td>
                                <td>${Number(price?.base_amount ).toFixed(2)}</td>
                                <td>

                                  <div className="">

                                    <Link
                                      to="/user/PriceForm"
                                      state={{
                                        id: price?.id,
                                        propertyId: price?.property_id,
                                        isEdit: true,
                                      }}
                                      className="btn  btn-primary btn-sm mr-1"
                                      data-tooltip-id="edit"
                                      data-tooltip-content="Edit"
                                    >
                                      <FaEdit />
                                    </Link>
                                    <Tooltip id="edit" place="bottom" />
                                    <button
                                      onClick={() => handleDelete(price.id)}
                                      className="btn  btn-danger btn-sm mr-1"
                                      data-tooltip-id="my-tooltip"
                                      data-tooltip-content="Delete"
                                    >
                                      <FaTrashAlt />
                                    </button>
                                    <Tooltip id="my-tooltip" place="bottom" />
                                  </div>

                                </td>
                              </tr>
                            ))) : (
                            <tr>
                              <td colSpan={8}>
                                <p className="text-center">
                                  No record found.
                                </p>
                              </td>
                            </tr>
                          )
                          }

                        </tbody>
                      </table>
                    </InfiniteScroll>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default PassPricingListing;
