import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Api from "../Config/Api";
import Cookies from "js-cookie";


export const fetchProperty = createAsyncThunk(
  "state/fetchProperty",
  async (postData) => {
    try {
        const results = await Api("getpropertyByPassURL", "POST", postData);
       return results.data;
    } catch (error) {
        console.log(error.message);
    }
  }
);

const initialState = {
  property: !!Cookies.get('propertyData') ? JSON.parse(Cookies.get('propertyData')) : {} ,
  units: [],
  status: "loading", // idle | loading | succeeded | failed
};

const propertySlice = createSlice({
  name: "property",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchProperty.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchProperty.fulfilled, (state, action) => {
        let propertyData = {...action?.payload?.data}
        delete propertyData.units
        Cookies.set("propertyData",JSON.stringify(propertyData), {
          expires: 7,
          path: "/",
        });
        state.status = "succeeded";
        state.property = action?.payload?.data;
      })
      .addCase(fetchProperty.rejected, (state, action) => {
        state.status = "succeeded";
      });
  },
});
// export const isAuthenticated = (state) => state.authentication.isAuthenticate;
export const getProperty = (state) => state.property.property;
export default propertySlice.reducer;
