import React from 'react'
import logo1 from "../../../assets/img/logo1.png";
import logo2 from "../../../assets/img/logo2.png";
import logo3 from "../../../assets/img/logo3.png";
import logo4 from "../../../assets/img/logo4.png";
import logo5 from "../../../assets/img/logo5.png";
import logo6 from "../../../assets/img/logo6.png";
import logo7 from "../../../assets/img/logo7.png";
import logo8 from "../../../assets/img/logo8.png";
const Footer = () => {

  return (
    <>
 
 <section className="py-5 discount_section cambg-primary">
          <div className="container">
            <div className="offer pb-4">
              <h4 className="">Special Offers And Discount</h4>
            </div>
            <div className="row g-4">
              <div className="col-md-3 col-sm-6">
                <div className="guest_brand_logo">
                  <img src={logo1} className="img-fluid" />
                </div>
              </div>

              <div className="col-md-3 col-sm-6">
                <div className="guest_brand_logo">
                  <img src={logo2} className="img-fluid" />
                </div>
              </div>

              <div className="col-md-3 col-sm-6">
                <div className="guest_brand_logo">
                  <img src={logo3} className="img-fluid" />
                </div>
              </div>

              <div className="col-md-3 col-sm-6">
                <div className="guest_brand_logo">
                  <img src={logo4} className="img-fluid" />
                </div>
              </div>

              <div className="col-md-3 col-sm-6">
                <div className="guest_brand_logo">
                  <img src={logo5} className="img-fluid" />
                </div>
              </div>

              <div className="col-md-3 col-sm-6">
                <div className="guest_brand_logo">
                  <img src={logo6} className="img-fluid" />
                </div>
              </div>

              <div className="col-md-3 col-sm-6">
                <div className="guest_brand_logo">
                  <img src={logo7} className="img-fluid" />
                </div>
              </div>

              <div className="col-md-3 col-sm-6">
                <div className="guest_brand_logo">
                  <img src={logo8} className="img-fluid" />
                </div>
              </div>
            </div>
          </div>
        </section>

<footer className="guest_footer py-3">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="ftr_text text-center">
                <a href="#" className="text-white pe-3">Terms & Conditions</a>
                <a href="#" className="text-white">Privacy Policy</a>
              </div>
            </div>
          </div>
        </div>
      </footer>

    </>
  )
}

export default Footer