import React from 'react';
import { Link, useLocation, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { BsSearch } from "react-icons/bs";
import { Modal, ModalHeader, ModalTitle } from 'react-bootstrap';
import Button from "react-bootstrap/Button";
import { useState } from 'react';
import { useEffect } from 'react';
import Api from '../../../Config/Api';
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { FaDownload, FaTrashAlt, FaEye } from "react-icons/fa";
import { Tooltip } from "react-tooltip";
import Loader from "../../Elements/Loader";
import { Spinner } from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroll-component";
import QRLocationDownload from "./ManageQRLocation"; 

const ManageQRLocation = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const [isLoader, setIsLoader] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const location = useLocation();
  const isAdmin = useSelector((state) => state.login.isAdmin);
  const propertyId = useSelector((state) => state.login.propertyId);
  const [QrListing, setQrListing] = useState([]);
  const [QrList, setQrList] = useState([]);
  const [qrData, setQRData] = useState("");
  const QRLocationURL = QRLocationDownload;

  const [searchTerm, setSearchTerm] = useState('');
  const [perPage] = useState(10);
  const [page, setPage] = useState(1);

  // const [filteredQrListing, setFilteredQrListing] = useState(QrListing);

  const [showBulk, setShowBulk] = useState(false);
  const handleCloseBulk = () => {
    setShowBulk(false);
    // console.log('hello-handleCloseBulk');
  };
  const handleShowBulk = () => {
    setShowBulk(true);
    // console.log('hello-handleShowBulk');
  };


  const [showQR, setShowQR] = useState(false);
  const handleCloseQR = () => {
    setShowQR(false);
    // console.log('hello-handleCloseQR');
  };
  const handleShowQR = (data) => {
    // console.log("id>>>>>>>>>>>", data);
    setQRData(data);
    // console.log("qrData>>>>>>>>", qrData);

    setShowQR(true);

    // console.log('hello-handleShowBulk');
  };
  



  const getQRCodeData = async () => {
    setIsLoader(true);
    const { data } = await Api('getAllAreaQRCode', 'POST', '');
    // console.log('data', data);
    if (data?.status) {
      setPage(1);
      var temparr = data?.data.slice(0, perPage);
      setQrListing(temparr);
      setQrList(data?.data);
      setQRData(data?.data);
      setIsLoader(false);
    }
  };

  const handleNext = () => {
    const start = page * perPage;
    const end = start + perPage;
    const temArr = QrListing.concat(QrList.slice(start, end));
    setPage((prev) => prev + 1);
    setQrListing(temArr);
  }

  useEffect(() => {
    getQRCodeData();
  }, []);

  const handleDelete = async (idToDelete) => {
    if (window.confirm("Are You Sure You Want to delete it?")) {
      const { data } = await Api("deleteAreaQRCode", "POST", { id: idToDelete });
      if (data?.status) {
        toast.success('Location has been deleted', {
          position: toast.POSITION.TOP_RIGHT,
        });
      }

      getQRCodeData();
    }
  };

  const onSubmit = async (d) => {
    setLoading(true);
    const reqData = {
      area_name: d?.area_name,
      propertyId: propertyId,
    };

    const { data } = await Api('generateAreaLocationQRCode', 'POST', reqData);
    try {
      toast.success('Location QR Code has been generated', {
        position: toast.POSITION.TOP_RIGHT,
      });
      reset();
      getQRCodeData();
      setLoading(false);
    } catch (error) {
      toast.error(data?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
    }
    setShowBulk(false);
  };

  const handleInputChange = (event) => {
    const newSearchTerm = event.target.value;
    setSearchTerm(newSearchTerm);
    const filteredData = QrList.filter((item) => {
      return (
        item?.area_name.toLowerCase().includes(newSearchTerm.toLowerCase())
      );
    });
    setQrListing(filteredData);
  };

  // console.log('QrListing', QrListing);

  return (
    <>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6"><h1 className="m-0">QR Location</h1></div>

              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link
                      to="/"
                      className={
                        location.pathname === "/"
                          ? "breadcrumb-active"
                          : "breadcrumb-not-active"
                      }
                    >
                      Home
                    </Link>
                  </li>
                  <span className="breadcrumb-arrow">&gt;</span>
                  <li className="breadcrumb-item active">QR Location</li>
                </ol>
              </div>
            </div>
          </div>
        </div>

        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div
                    className={`card-header ${isAdmin ? "adminColor" : "cambg-secondary"
                      }`}
                  >
                    <div className="add_new_vendor_listing">
                      <div>
                        <h3 className="card-title mt-2">Request</h3>
                      </div>
                      <div> <Button
                        className="btn btn-light text-dark bg-light mx-2"
                        state={{ propertyId: propertyId }}
                        onClick={handleShowBulk}
                      >
                        Add Location
                      </Button></div>
                    </div>

                  </div>
                  <div className="d-flex justify-content-end px-4 mt-2">
                    <div className="input-group w-25">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search..."
                        value={searchTerm}
                        onChange={handleInputChange}
                      />
                      <div className="input-group-append">
                        <span
                          className="input-group-text bg-primary border-0"
                          id="basic-addon2"
                        >
                          <BsSearch />
                        </span>
                      </div>
                    </div>
                  </div>
                  {isLoader ? (
                    <Loader />
                  ) : (
                    <div className="card-body table-responsive">
                      <InfiniteScroll
                        dataLength={QrListing.length}
                        next={handleNext}
                        hasMore={QrList.length > QrListing.length}
                      >
                        <table className="table table-bordered">
                          <thead className="border-top">
                            <tr>
                              <th>Location Name</th>
                              <th>QR Code</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {QrListing?.map((data, i) => (
                              <tr key={i}>
                                <td>{data?.area_name}</td>
                                <td>
                                  <img src={process.env.REACT_APP_AWS_S3_BUCKET_URL + "qr_codes/" + data?.qr_code} alt="" width={40} />
                                </td>
                                <td>
                                  <div className="">
                                    <button
                                      onClick={() => handleDelete(data.id)}
                                      className="btn  btn-danger btn-sm mr-1"
                                      data-tooltip-id="my-tooltip"
                                      data-tooltip-content="Delete"
                                    >
                                      <FaTrashAlt />
                                    </button>
                                    <Tooltip id="my-tooltip" place="bottom" />

                                    {/* <button
                                      onClick={() => handleDelete(data.id)}
                                      className="btn  btn-primary btn-sm mr-1"
                                      download={'QR_Code'}
                                      data-tooltip-id="my-tooltip"
                                      data-tooltip-content="Download QR Code"
                                    >
                                      <FaDownload />
                                    </button> */}
                                 
                                    <a href={process.env.REACT_APP_AWS_S3_BUCKET_URL + "qr_codes/" + data?.qr_code} className='btn btn-primary btn-sm mr-1' download={'QR_Code'}><FaDownload /></a>

                                    <Tooltip id="my-tooltip" place="bottom" />

                                    <button
                                      onClick={() => handleShowQR(data)}
                                      className="btn btn-success btn-sm mr-1"
                                      data-tooltip-id="detail"
                                      data-tooltip-content="View QR Code"
                                    >
                                      <FaEye />
                                    </button>

                                    <Tooltip id="detail" place="bottom" />
                                  </div>
                                </td>
                              </tr>
                            ))}

                          </tbody>
                        </table>
                      </InfiniteScroll>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>

      </div>
      <Modal
        show={showQR}
        onHide={handleCloseQR}
        backdrop="static"
        keyboard={false}
      >
        <ModalHeader>
          <ModalTitle>QR Code</ModalTitle>
        </ModalHeader>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">

                <div className='text-center'>
                  <img src={process.env.REACT_APP_AWS_S3_BUCKET_URL + "qr_codes/" + qrData?.qr_code} alt="" width={200} />
                </div>

              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseQR}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showBulk} onHide={handleCloseBulk}
        backdrop="static"
        keyboard={false}
      >
        <ModalHeader>
          <ModalTitle>Add QR Location</ModalTitle>
        </ModalHeader>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label>Add Location</label>
                  <input className="form-control"
                    type='text'
                    placeholder='Enter Area Name'
                    {...register("area_name", { required: true })} />

                  {errors.area_name && (
                    <p className="text-danger">
                      Area Name is required
                    </p>
                  )}

                  {/* </input> */}
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseBulk}>
              Close
            </Button>
            <Button variant="primary" type="submit" disabled={isLoading}
            >
              {isLoading ? (
                <>
                  <Spinner animation="border" size="sm" /> Please wait...
                </>
              ) : (
                "Submit"
              )}
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  )
}

export default ManageQRLocation
