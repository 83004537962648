import { useNavigate, Outlet } from "react-router-dom";

import Header from "../Views/Elements/Header/Header";
import Footer from "../Views/Elements/Footer/Footer";
import Sidebar from "../Views/Elements/Sidebar/Sidebar";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";

const DashboardLayout = () => {
  const Navigate = useNavigate();
  const isLogin = useSelector((state) => state.login.isLogin);

  useEffect(() => {
    if (!isLogin) {
      Navigate("/login");
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="wrapper">
        <Header />
        <Sidebar />
        <Outlet />
        <Footer />
      </div>
    </>
  );
};

export default DashboardLayout;
