import React, { useEffect, useState } from 'react';
import { Controller, useForm, useFieldArray } from "react-hook-form";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { BsFillCameraFill } from "react-icons/bs";
import upload_image from "../../../assets/img/upload_image.png";

const SpecialOfferListing = () => {
  const [warnSelectedImage, setWarnSelectedImage] = useState(null);

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    control,
    formState: { errors },
  } = useForm();
  const [warnImage, setWarnImage] = useState([]);

  const {
    fields: warningFields,
    append: appendWarningFields,
    remove: removeWarningFields,
  } = useFieldArray({
    control,
    name: "warning",
  });

  const handleWarningImageChange = (e) => {
    const file = e.target.files[0];
    setWarnImage(file);
    const reader = new FileReader();
    reader.onload = (event) => {
      setWarnSelectedImage(event.target.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  return (
    <>
      <div className="content-wrapper">

        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">
                  Add Special Offers
                </h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link
                      to="/"
                    // className={
                    //   location.pathname === "/"
                    //     ? "breadcrumb-active"
                    //     : "breadcrumb-not-active"
                    // }
                    >
                      Home
                    </Link>
                    <span className="breadcrumb-arrow">&gt;</span>
                    <Link
                      to="/user/PropertyListing"
                    // className={
                    //   location.pathname.startsWith("/user/PropertyListing")
                    //     ? "breadcrumb-active"
                    //     : "breadcrumb-not-active"
                    // }
                    >
                      Property
                    </Link>
                  </li>
                  <span className="breadcrumb-arrow">&gt;</span>
                  <li className="breadcrumb-item active">
                    Special Offers
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <div className="card card-primary">
          <form
          // onSubmit={handleSubmit(onSubmit)}
          >
            <div className="card-body">
              <div className="row align-items-center">
                <div className="col-md-12">
                  <div className="form-group">
                    <div className="card p-3">

                      <div className="card-body">
                        <div className="card">
                          <div className="card-header">
                            <div className="col-md-12">
                              <div className="point_of_contact pt-1 text-dark">
                                Add Offers ANd Discount
                              </div>
                              <span className="text-secondary">
                                These are the Special Offers and Discount for Users.
                              </span>
                            </div>
                          </div>

                          <div className="card-body">
                            <div className="col-lg-12">
                              {/* {warningFields.map((field, index) => ( */}
                              <div
                              // key={field.id}
                              >
                                <div className="row">

                                  <div className="row">
                                    <div className="col-md-3">
                                      <div className="form-group my-3">
                                        <label htmlFor="warnImage">
                                          Select Warning Image
                                        </label>
                                        <div className="imagebox">
                                          {warnSelectedImage ? (
                                            <label htmlFor="warnImage">
                                              <img
                                                className="user-profile-pic user-img"
                                                src={warnSelectedImage}
                                              />
                                              <div className="floatbtn">
                                                <BsFillCameraFill />
                                              </div>
                                            </label>
                                          ) : (
                                            <label htmlFor="warnImage">
                                              <img
                                                className="user-profile-pic user-img"
                                                src={upload_image}
                                              />
                                            </label>
                                          )}

                                          <input
                                            className="user-file-upload"
                                            id="warnImage"
                                            type="file"
                                            accept="image/*"
                                          {...register(`warningImg`, {
                                            onChange: (e) =>
                                              handleWarningImageChange(e),
                                          })}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  
                                  </div>

                                  <div
                                    className="col-lg-1"
                                    style={{ paddingTop: "31px" }}
                                  >
                                    <button
                                      type="button"
                                      className="btn btn-primary"
                                    // onClick={() =>
                                    //   removeWarningFields(index)
                                    // }
                                    >
                                      Remove
                                    </button>
                                  </div>
                                </div>
                              </div>
                              {/* ))} */}

                              <div className="mb-3">
                                <button
                                  type="button"
                                  className="btn btn-primary"
                                // onClick={() =>
                                //   appendWarningFields({
                                //     id: uuidv4(),
                                //     name: "",
                                //   })
                                // }
                                >
                                  Add Warning
                                </button>


                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default SpecialOfferListing