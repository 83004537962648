import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { Controller } from "react-hook-form";
import { toast } from "react-toastify";
import Select from "react-select";
import Api from "../../../Config/Api";
import { Spinner } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { fetchState, getState } from "../../../Reducers/State";
import Loader from "../../Elements/Loader";
const OwnerForm = () => {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    control,
    formState: { errors },
  } = useForm();
  const isAdmin = useSelector((state) => state.login.isAdmin);
  const roleId = useSelector((state) => state.login.roleId);
  const [isLoading, setLoading] = useState(false);
  const stateData = useSelector(getState);
  const userId = useSelector((state) => state.login.userId);
  const [phone, setPhone] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [managementCompList, setManagementCompList] = useState([]);
  const [options, setOptions] = useState([]);
  const [propertyId, setPropertyId] = useState(location?.state?.propertyId ? location?.state?.propertyId : null);
  const [isLoader, setIsLoader] = useState(location?.state?.isEdit ? true : false);
  const onSubmit = async (data) => {
    setLoading(true);
    const addOwnerData = {
      unit_name: data.unit_name,
      firstname: data.firstname,
      lastname: data.lastname,
      email: data.email,
      phone: data.phone,
      address: data.address,
      city: data.city,
      state: data.state,
      zip: data.zip,
      property_id: !Boolean(location?.state?.id) && !location?.state?.id && location?.state?.propertyId,
      user_id: data.managementcoId.value,
    };
    try {
      if (!Boolean(location?.state?.id) && !location?.state?.id) {
        var res = await Api("add-unit", "POST", addOwnerData);
      } else {
        addOwnerData.id = location?.state?.id;
        res = await Api("update-unit", "POST", addOwnerData);
      }
      if (res?.data?.status) {
        setLoading(false);
        toast.success(res?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        if (location?.state?.managementCo !== "" && location?.state?.managementCo !== undefined) {
          navigate("/user/ManageUnit/" + btoa(location?.state?.managementCo));
        } else {
          navigate("/user/OwnerListing/" + btoa(propertyId));
        }
        reset();
      }
    } catch (error) {
      toast.error('Some Error!', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const getModifyOwnerInfo = async () => {
    const { data } = await Api("get-unit", "POST", {
      id: location?.state?.id,
    });
    console.log("data",data);
    setPhone(data?.data?.phone)
    if (data?.status) {
      if (data?.data?.users) {
        reset({
          ...data?.data,
          managementcoId: {
            label: data?.data?.users?.name,
            value: data?.data?.users?.id,
          }
        });
      } else {
        reset(data?.data);
      }
      setPropertyId(data?.data?.property_id);
      setIsLoader(false);
    }
  };



  const getManagementCompanyDetails = async () => {
    const { data } = await Api("get-management-co", "POST", "");
    if (data?.status) {
      const newOptions = [];
      data?.data?.map((management, i) => {
        newOptions?.push({
          value: management?.id,
          label: management?.email
        });
      });

      setOptions(newOptions)
      setManagementCompList(data?.data);
    }
  };

console.log("location?.state?.propertyId",location?.state?.propertyId);

  useEffect(() => {
    if (!!location?.state?.id) {
      getModifyOwnerInfo();
    }
  }, [location?.state?.id]);

  useEffect(() => {
    getManagementCompanyDetails();
    dispatch(fetchState());
  }, []);


  useEffect(() => {
    setPhone(phone || ''); // Use an empty string if 'phone' is undefined
  }, [phone]);

  const handleInputChange = (event) => {
    const rawPhoneNumber = event.target.value.replace(/\D/g, '');
    const truncatedPhoneNumber = rawPhoneNumber.slice(0, 10);
    const formattedPhoneNumber = truncatedPhoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
    
    // Set the phone value directly using setValue from react-hook-form
    setValue('phone', formattedPhoneNumber);
  
    // Update the state with the formatted phone number
    setPhone(formattedPhoneNumber);
  };
console.log("phone>>>>>>>>",phone);
  return (
    <>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">
                  {location?.state?.isEdit ? "Edit Unit" : "Add Unit"}{" "}
                </h1>

              </div>

              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/"
                      className={location.pathname === "/" ? "breadcrumb-active" : "breadcrumb-not-active"}
                    >
                      Home
                    </Link>
                    {isAdmin ? (
                      <>
                      <span className="breadcrumb-arrow">&gt;</span>
                    <Link
                      to="/user/PropertyListing"
                      className={
                        location.pathname.startsWith("/user/PropertyListing")
                          ? "breadcrumb-active"
                          : "breadcrumb-not-active"
                      }
                    >
                      Property
                    </Link>
                      </>
                    ) : ("")}
                    <span className="breadcrumb-arrow">&gt;</span>
                    <Link to={isAdmin || Number(atob(roleId)) === 2 ? ("/user/OwnerListing/" + btoa(propertyId)) :(Number(atob(roleId)) === 4 ? ("/user/ManageOwnerListing/"+ btoa(propertyId) + "/" + btoa(userId)) : ("/user/ManageUnit/MTEz"))}
                      className={isAdmin || Number(atob(roleId)) === 2 ? (location.pathname.startsWith("/user/OwnerListing/" + btoa(propertyId)) ? "breadcrumb-active" : "breadcrumb-not-active") : ( Number(atob(roleId)) === 4 ? (location.pathname.startsWith("/user/ManageOwnerListing/" + btoa(propertyId)+  "/"  + btoa(userId)) ? "breadcrumb-active" : "breadcrumb-not-active") :(location.pathname.startsWith("/user/ManageUnit/MTEz") ? "breadcrumb-active" : "breadcrumb-not-active"))}
                    >
                      Unit
                    </Link>
                  </li>
                  <span className="breadcrumb-arrow">&gt;</span>

                  <li className="breadcrumb-item active">
                    {location?.state?.isEdit ? "Edit Unit" : "Add Unit"}{" "}
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>

        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card card-primary">
                  <div className={`card-header ${isAdmin || Number(atob(roleId)) === 4 ? "adminColor" : "cambg-secondary"}`}>
                    <h3 className="card-title">
                      {location?.state?.isEdit ? "EDIT Unit" : "Add Unit "}{" "}
                    </h3>

                  </div>

                  {isLoader ? (
                    <Loader />
                  ) : (

                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="card-body">
                        <div className="row align-items-center">


                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="unit_name">Unit Name</label>

                              <input
                                type="text"
                                className="form-control"
                                {...register("unit_name", { required: true })}
                                placeholder="Enter Unit Name"
                              />

                              {errors.unit_name && (
                                <p className="text-danger">
                                  Unit Name is required
                                </p>
                              )}
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="enterEnterName">Owner First Name</label>

                              <input
                                type="text"
                                className="form-control"
                                {...register("firstname", { required: true })}
                                placeholder="First Name"
                              />

                              {errors.firstname && (
                                <p className="text-danger">
                                  Owner First name is required
                                </p>
                              )}
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="enterLastName">Owner Last Name</label>
                              <input
                                type="text"
                                className="form-control"
                                id="enterLastName"
                                placeholder="Last Name"
                                {...register("lastname", { required: true })}
                                aria-invalid={errors.lastname ? "true" : "false"}
                              />

                              {errors.lastname?.type === "required" && (
                                <p role="alert" className="text-danger">
                                  Owner Last name is required
                                </p>
                              )}
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="enterEmail">Owner Email</label>
                              <input
                                type="email"
                                className="form-control"
                                id="enterEmail"
                                placeholder="Email"
                                {...register("email", { required: true })}
                                aria-invalid={errors.email ? "true" : "false"}
                              />

                              {errors.email?.type === "required" && (
                                <p role="alert" className="text-danger">
                                  Owner Email is required
                                </p>
                              )}
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="enterNumber">Phone</label>
                              <input
                                type="text"
                                value={phone}
                                onChange={handleInputChange}
                                className="form-control"
                                id="enterNumber"
                                placeholder="Enter Phone Number"
                                required={true}
                              />
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="enterStreetAddress">
                                Owner Address
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="enterStreetAddress"
                                placeholder="Address"
                                {...register("address", { required: true })}
                                aria-invalid={errors.address ? "true" : "false"}
                              />

                              {errors.address?.type === "required" && (
                                <p role="alert" className="text-danger">
                                  Owner Address is required
                                </p>
                              )}
                            </div>
                          </div>


                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="enterCity">Owner City</label>
                              <input
                                type="text"
                                className="form-control"
                                id="enterCity"
                                placeholder="City"
                                {...register("city", { required: true })}
                                aria-invalid={errors.city ? "true" : "false"}
                              />

                              {errors.city?.type === "required" && (
                                <p role="alert" className="text-danger">
                                  Owner City is required
                                </p>
                              )}
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Owner State</label>
                              <select
                                className="form-control"
                                {...register("state", { required: true })}
                              >
                                <option value="">Select State</option>
                                {stateData?.state?.length &&
                                  stateData?.state?.map((states, i) => (
                                    <option key={i} value={states?.name}>
                                      {states?.name}
                                    </option>
                                  ))}
                              </select>
                              {errors.state && (
                                <p className="text-danger">
                                  Owner State Name is required
                                </p>
                              )}
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="enterZip">Owner Zip</label>
                              <input
                                type="text"
                                className="form-control"
                                id="enterZip"
                                placeholder="Zip"
                                {...register("zip", { required: true })}
                                aria-invalid={errors.zip ? "true" : "false"}
                              />

                              {errors.zip?.type === "required" && (
                                <p role="alert" className="text-danger">
                                  Owner Zip is required
                                </p>
                              )}
                            </div>
                          </div>


                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Management Co</label>

                              <Controller
                                control={control}
                                name="managementcoId"
                                rules={{ required: true }}
                                render={({ field }) => (
                                  <Select
                                    {...field}
                                    options={options}
                                    onChange={field.onChange}
                                    value={field.value}
                                  />
                                )}
                              />
                              {errors.state && (
                                <p className="text-danger">Management Co is required</p>
                              )}
                            </div>
                          </div>


                        </div>

                        <div className="card-footer">
                          <button
                            type="submit"
                            variant="primary"
                            className={`btn btn-primary  ${isAdmin || location?.state?.managementCo !== undefined ? "" : "cambg-secondary border-0"}`}
                            disabled={isLoading}
                          >
                            {isLoading ? (
                              <>
                                <Spinner animation="border" size="sm" /> Please
                                wait...
                              </>
                            ) : (
                              "Submit"
                            )}
                          </button>
                          <button
                            className="btn btn-outline-light text-dark mx-3 bg-light"
                            type="button"
                            onClick={() => navigate(-1)}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </form>
                  )}

                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default OwnerForm;
