import { useNavigate, Outlet } from "react-router-dom";
import Header from "../Views/Elements/Header/RenterHeader";
import Footer from "../Views/Elements/Footer/RenterFooter";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchProperty, getProperty } from "../Reducers/RenterProperty";
import Loader from "../Views/Elements/Loader";
import RenterSidebar from "../Views/Elements/Sidebar/RenterSidebar";


const RenterLayout = () => {
  const dispatch  = useDispatch();
  const { property_name } = useParams();
  const [isLoading, setLoading] = useState(false);
  const RenterProperty = useSelector(getProperty);
  const isLogin = useSelector((state) => state.login.isLogin);
  useEffect(() => {
    if(!!property_name){
      setLoading(true)
      dispatch(fetchProperty( {
        buy_pass_url: property_name,
      }));
    }
    setLoading(false)
  }, [property_name]);

  return (
    <>
      <style>
        {`
        :root{
          --primary:${RenterProperty?.primary_color ? RenterProperty?.primary_color : "#17a2b8"};
          --secondary:${RenterProperty?.secondary_color ? RenterProperty?.secondary_color : '#5f9ea0'};
        }
        `}
      </style>

      <div className="wrapper">
        {isLoading ? (<>
          <Loader type={'full'}/>
        </>) : (
        <>
        <Header propertyData={RenterProperty} />
        {isLogin && (
        <RenterSidebar  propertyData={RenterProperty} />
        )}
        <Outlet/>
        <Footer />
        </>
        )}
      </div>
    </>
  );
};

export default RenterLayout;
