import React from "react";
import { Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { useParams } from "react-router-dom";
import PageNotFound from "../Views/PageNotFound";
import DashboardLayout from "../Layout/DashboardLayout";
import RenterLayout from "../Layout/RenterLayout";
import SecurityLayout from "../Layout/SecurityLayout";
import CamDashboard from "../Views/Dashboard/Index";
// import AdminDashboard from "../Views/Admindashboard/index";

import PassDetailByDate from "../Views/Graphs/PassDetailByDate";
// import CondoListing from "../Views/Dashboard/CondoListing";
import RunReport from "../Views/Dashboard/RunReport";
import LocateOwner from "../Views/Dashboard/Owner/LocateOwner";
import OwnerListing from "../Views/Dashboard/Owner/OwnerListing";
import GraphOwnerListing from "../Views/Dashboard/Owner/GraphOwnerListing";
import ManageOwnerListing from "../Views/Dashboard/Owner/ManageOwnerListing";

import OwnerDetail from "../Views/Dashboard/Owner/OwnerDetail";
import IssueRefund from "../Views/Dashboard/IssueRefund";
import Maintenance from "../Views/Dashboard/Maintenance";
// import EditMaintenance from "../Views/Dashboard/EditMaintenance";
import PrintPass from "../Views/Dashboard/PrintPass";

import PassesPrintout from "../Views/Dashboard/Passes/PassesPrintout";
// import CreatePass from "../Views/Dashboard/CreatePass";
// import Revenue from "../Views/Dashboard/Revenue";
// import OtherReport from "../Views/Dashboard/OtherReport";
// import Stats from "../Views/Dashboard/Stats";
import VendorListing from "../Views/Dashboard/Vendor/VendorListing";
import GraphVendorListing from "../Views/Dashboard/Vendor/GraphVendorListing";
import VendorCreatePass from "../Views/Dashboard/Passes/VendorCreatePass";
import OwnerCreatePass from "../Views/Dashboard/Passes/OwnerCreatePass";

import SuccessPaymentView from "../Views/Dashboard/Passes/SuccessPaymentView";
import VendorDetail from "../Views/Dashboard/Vendor/VendorDetail";
// import LocateVendor from "../Views/Camdashboard/LocateVendor";
import VendorFrom from "../Views/Dashboard/Vendor/VendorFrom";
import OwnerForm from "../Views/Dashboard/Owner/OwnerForm";
// import ModifyOwnerInfo from "../Views/Dashboard/Owner/ModifyOwnerInfo@old";
// import VerifyPassToPrint from "../Views/Dashboard/VerifyPassToPrint";
import LoginFrom from "../Views/Elements/Forms/Login";
import EditProfile from "../Views/Elements/Forms/EditProfile";
import ChangePassword from "../Views/Elements/Forms/ChangePassword";

import PropertyListing from "../Views/Dashboard/Property/PropertyListing";
import PropertyForm from "../Views/Dashboard/Property/PropertyForm";
import CamFrom from "../Views/Dashboard/Cam/CamFrom";
import CamListing from "../Views/Dashboard/Cam/CamListing";
import SubAdminListing from "../Views/Dashboard/SubAdmin/SubAdminListing";
import SubAdminFrom from "../Views/Dashboard/SubAdmin/SubAdminFrom";

import CamDetail from "../Views/Dashboard/Cam/CamDetail";
import PropertyDetail from "../Views/Dashboard/Property/PropertyDetail";
import LookupUnit from "../Views/Dashboard/Lookup/Lookupunit";

import OnboardingFrom from "../Views/Dashboard/Onboarding/OnboardingFrom";

import ManagementCompanyListing from "../Views/Dashboard/ManagementCompany/ManagementCompanyListing";
import ManagementCompanyFrom from "../Views/Dashboard/ManagementCompany/ManagementCompanyFrom";
import ManagementCompanyDetail from "../Views/Dashboard/ManagementCompany/ManagementCompanyDetail";

import PassRequest from "../Views/Dashboard/ManagementCompany/Requests/PassRequest";
import PassRequestView from "../Views/Dashboard/ManagementCompany/Requests/PassRequestView";

import SubAdminManagListing from "../Views/Dashboard/SubAdminManagement/SubAdminManagListing";
import SubAdminManagForm from "../Views/Dashboard/SubAdminManagement/SubAdminManagForm";
import SubAdminManagDetail from "../Views/Dashboard/SubAdminManagement/SubAdminManagDetail";

import TowCompanyListing from "../Views/Dashboard/TowCompany/TowCompanyListing";
import TowCompanyForm from "../Views/Dashboard/TowCompany/TowCompanyForm";
import TowCompanyDetail from "../Views/Dashboard/TowCompany/TowCompanyDetail";


import SecurityFrom from "../Views/Dashboard/Security/SecurityFrom";
import SecurityListing from "../Views/Dashboard/Security/SecurityListing";
import SecurityDetail from "../Views/Dashboard/Security/SecurityDetail";

import Resources from "../Views/Dashboard/Resources/Index";
import UploadedCSV from "../Views/Dashboard/Resources/UploadedCSV";

import PaymentHistory from "../Views/Dashboard/Payment/PaymentHistory";
import PassesList from "../Views/Dashboard/Payment/PassesList";

import PaymentHistoryView from "../Views/Dashboard/Payment/PaymentHistoryView";

import RenterGuest from "../Views/Renter/Index";
import RenterHome from "../Views/Renter/Home";
import RenterCheckout from "../Views/Renter/RenterCheckout";
import SuccessPayment from "../Views/Renter/SuccessPayment";
// import ScannerComponent from "../Views/Security/ScannerComponent";
import ConfirmPayment from "../Views/Dashboard/Stripe/ConfirmPayment";
import RenterCard from "../Views/Renter/RenterCard";
import RenterChangePassword from "../Views/Renter/RenterChangePassword";
import RenterEditProfile from "../Views/Renter/RenterEditProfile";
import ViewPrintPass from "../Views/Renter/ViewPrintPass";
import ViewPass from "../Views/Renter/ViewPass";
import PassPricingListing from "../Views/Dashboard/Pricing/PassPricingListing";
import Index from "../Views/Security/Index";
import SecurityEditProfile from "../Views/Security/SecurityEditProfile";
import SearchResult from "../Views/Security/SearchResult";
import ScanLocationQR from "../Views/Security/ScanLocationQR";
import PriceForm from "../Views/Dashboard/Pricing/PriceForm";
import Activity from "../Views/Dashboard/Activity/Activity";
import UnitRequest from "../Views/Dashboard/ManagementCompany/Requests/UnitRequest"

import ManagePasses from "../Views/Dashboard/Passes/ManagePasses";
import ManageOwnerPasses from "../Views/Dashboard/Passes/ManageOwnerPasses";

import Vend_OwnPassesPrintout from "../Views/Dashboard/Passes/Vend_OwnPassesPrintout";

// import SamplePassListing from "../Views/Dashboard/Passes/SamplePassListing";
// import CreateSamplePass from "../Views/Dashboard/Passes/CreateSamplePass";
// import SamplePass from "../Views/Dashboard/Passes/SamplePass";

import ManageRequest from "../Views/Dashboard/Request/ManageRequest";
import ManagePassRequest from "../Views/Dashboard/Request/ManagePassRequest";
import ManagementCoPropertyList from "../Views/Dashboard/PropertyList/ManagementCoPropertyList";
import ManageQRLocation from "../Views/Dashboard/Request/ManageQRLocation";
import ViewQRLocation from "../Views/Dashboard/Request/ViewQRLocation";


import ServiceCategory from "../Views/ServiceCategory";

import SpecialOfferListing from "../Views/Dashboard/SpecialOffer/SpecialOfferListing";
import EmailTemplateView from "../Views/Dashboard/EmailTemplates/EmailTemplateView";
import EmailTemplateListing from "../Views/Dashboard/EmailTemplates/EmailTemplateListing";

const Routing = () => {
  return (
    <>
      <ToastContainer />
      <Routes>


        <Route index element={<RenterHome />} />
        <Route path="/" element={<RenterLayout />}>

          <Route path="/ServiceCategory" element={<ServiceCategory />} />

          <Route path="/:property_name" element={<RenterGuest />} />
          <Route path="/RenterCheckout" element={<RenterCheckout />} />
          <Route path="/SuccessPayment" element={<SuccessPayment />} />
          <Route path="/ConfirmPayment" element={<ConfirmPayment />} />
          <Route path="/SavedCard" element={<RenterCard />} />
          <Route
            path="/RenterChangePassword"
            element={<RenterChangePassword />}
          />
          <Route path="/RenterEditProfile" element={<RenterEditProfile />} />
          <Route path="/ViewPrintPass" element={<ViewPrintPass />} />
          <Route path="/ViewPass" element={<ViewPass />} />
          <Route path="PassesPrintout" element={<PassesPrintout />} />
        </Route>

        <Route path="/Security" element={<SecurityLayout />}>
          <Route index element={<Index />} />
          {/* <Route path="ScannerComponent" element={<ScannerComponent />} /> */}
          <Route path="Editprofile" element={<SecurityEditProfile />} />
          <Route path="Result" element={<SearchResult />} />
          <Route path="ScanLocationQR" element={<ScanLocationQR />} />
          
        </Route>


        <Route path="*" element={<PageNotFound />} />
        <Route path="/Login" element={<LoginFrom />} />
        <Route path="User/" element={<DashboardLayout />}>
          <Route index element={<CamDashboard />} />
          <Route path="PassDetailByDate" element={<PassDetailByDate />} />
          <Route path="OnboardingFrom" element={<OnboardingFrom />} />
          <Route path="RunReport" element={<RunReport />} />
          <Route path="activity" element={<Activity />} />
          <Route path="activity/:id" element={<Activity />} />
          <Route path="LocateOwner" element={<LocateOwner />} />
          <Route path="OwnerListing/:id" element={<OwnerListing />} />
          <Route path="GraphOwnerListing" element={<GraphOwnerListing />} />

          <Route path="ManageOwnerListing/:id/:user_id" element={<ManageOwnerListing />} />
          <Route path="ManageUnit/:ManagementCo" element={<OwnerListing />} />
          <Route path="EmailTemplateView" element={<EmailTemplateView />} />
          <Route path="EmailTemplateListing" element={<EmailTemplateListing />} />

          <Route path="ManageSubAdmin/:ManagementCo" element={<OwnerListing />} />

          <Route path="IssueRefund" element={<IssueRefund />} />
          <Route path="Maintenance" element={<Maintenance />} />
          <Route path="PrintPass" element={<PrintPass />} />
          <Route path="VendorListing" element={<VendorListing />} />
          <Route path="VendorListing/:id" element={<VendorListing />} />
          <Route path="GraphVendorListing" element={<GraphVendorListing />} />
          <Route path="VendorCreatePass" element={<VendorCreatePass />} />
          <Route path="OwnerCreatePass" element={<OwnerCreatePass />} />
          <Route path="SuccessPaymentView" element={<SuccessPaymentView />} />
          <Route path="VendorDetail" element={<VendorDetail />} />
          <Route path="VendorFrom" element={<VendorFrom />} />

          <Route path="OwnerForm" element={<OwnerForm />} />
          <Route path="OwnerForm/:user_id" element={<OwnerForm />} />
          <Route path="OwnerDetail" element={<OwnerDetail />} />
          <Route path="OwnerDetail/:user_id" element={<OwnerDetail />} />


          <Route path="EditProfile" element={<EditProfile />} />
          <Route path="ChangePassword" element={<ChangePassword />} />
          <Route path="PropertyListing" element={<PropertyListing />} />
          <Route path="PropertyForm" element={<PropertyForm />} />
          <Route path="CamFrom" element={<CamFrom />} />
          <Route path="CamListing" element={<CamListing />} />
          <Route path="SubAdminListing" element={<SubAdminListing />} />
          <Route path="SubAdminFrom" element={<SubAdminFrom />} />
          <Route path="CamDetail" element={<CamDetail />} />
          <Route path="PropertyDetail" element={<PropertyDetail />} />
          <Route path="PassPricingListing/:id" element={<PassPricingListing />} />

          <Route path="ManagePasses" element={<ManagePasses />} />
          <Route path="ManageOwnerPasses" element={<ManageOwnerPasses />} />

          <Route path="Vend_OwnPassesPrintout" element={<Vend_OwnPassesPrintout />} />

          {/* <Route path="SamplePassListing/:id" element={<SamplePassListing />} />
          <Route path="CreateSamplePass" element={<CreateSamplePass />} />
          <Route path="SamplePass" element={<SamplePass />} /> */}

          <Route path="PriceForm" element={<PriceForm />} />
          <Route path="ManageRequest" element={<ManageRequest />} />
          <Route path="ManagePassRequest" element={<ManagePassRequest />} />
          <Route path="ManageQRLocation" element={<ManageQRLocation />} />
          <Route path="ViewQRLocation" element={<ViewQRLocation />} />

          <Route path="UnitRequest" element={<UnitRequest />} />
          <Route
            path="ManagementCompanyListing"
            element={<ManagementCompanyListing />}
          />
          <Route
            path="ManagementCompanyFrom"
            element={<ManagementCompanyFrom />}
          />
          <Route
            path="ManagementCompanyDetail"
            element={<ManagementCompanyDetail />}
          />

          <Route
            path="PassRequest/:id"
            element={<PassRequest />}
          />
          <Route
            path="PassRequestView"
            element={<PassRequestView />}
          />

          <Route
            path="SubAdminManagListing/:id"
            element={<SubAdminManagListing />}
          />
          <Route
            path="SubAdminManagForm/:id"
            element={<SubAdminManagForm />}
          />
          <Route
            path="SubAdminManagDetail/:id"
            element={<SubAdminManagDetail />}
          />

          <Route path="TowCompanyListing" element={<TowCompanyListing />} />
          <Route path="TowCompanyForm" element={<TowCompanyForm />} />
          <Route path="TowCompanyDetail" element={<TowCompanyDetail />} />

          <Route path="SecurityFrom" element={<SecurityFrom />} />
          <Route path="SecurityListing" element={<SecurityListing />} />
          <Route path="SecurityListing/:id" element={<SecurityListing />} />
          <Route path="SecurityDetail" element={<SecurityDetail />} />
          <Route path="Resources" element={<Resources />} />
          <Route path="UploadedCSV/:id" element={<UploadedCSV />} />
          <Route path="PaymentHistory" element={<PaymentHistory />} />
          <Route path="PaymentHistory/:id" element={<PaymentHistory />} />
          <Route path="PassesList" element={<PassesList />} />

          <Route path="PaymentHistoryView" element={<PaymentHistoryView />} />
          <Route path="LookupUnit" element={<LookupUnit />} />
          <Route path="ManagementCoPropertyList/:id" element={<ManagementCoPropertyList />} />
          <Route path="SpecialOfferListing" element={<SpecialOfferListing />} />
        </Route>
      </Routes>

      {/* <Footer /> */}
    </>
  );
};

export default Routing;
