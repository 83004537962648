import React, { useEffect, useState } from "react";
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from "react-redux";
import { BsSearch } from "react-icons/bs";
import InfiniteScroll from "react-infinite-scroll-component";
import Api from '../../../Config/Api';
import Button from "react-bootstrap/Button";
import { FaEdit, FaTrashAlt, FaPlus, FaMoneyBill, } from "react-icons/fa";
import { Tooltip } from "react-tooltip";
import { FaEye } from "react-icons/fa";
import Loader from "../../Elements/Loader";

const GraphVendorListing = () => {

    const location = useLocation();

    const isAdmin = useSelector((state) => state.login.isAdmin);
    const isLogin = useSelector((state) => state.login.isLogin);
    const roleId = useSelector((state) => state.login.roleId);
    const [vendorList, setVendorList] = useState([]);
    const [isLoader, setIsLoader] = useState(false);
    const [vList, setVList] = useState([]);
    const { vendor, searchBy, propertyId } = location.state ? location.state : '';
    console.log('Vendor:', vendor);
    console.log('SearchBy:', searchBy);
    console.log('PropertyId:', propertyId);

    const getVendorDetails = async () => {
        setIsLoader(true);
        try {

            const vendorCount = await Api("vendor-count", "post", { company_name: vendor ? vendor : "" });
            console.log("vendorCount", vendorCount);
            setIsLoader(true);
            if (vendorCount?.data?.status) {
                setIsLoader(false);
                // Assuming vendorCount.data.vendor is an array of vendors
                if (searchBy && vendor) {
                    if (propertyId) {
                        const filteredVendors = vendorCount?.data?.vendor.filter(vendorItem => vendorItem.property_id === propertyId);
                        console.log("filteredVendors", filteredVendors);

                        setVendorList(filteredVendors);
                        setVList(filteredVendors);
                    } else {
                        const filteredVendors = vendorCount?.data?.vendor.filter(vendorItem => {
                            console.log("searchBy????????????", searchBy);
                            return vendorItem[searchBy] === vendor;
                        });
                        console.log("filteredVendors", filteredVendors);

                        setVendorList(filteredVendors);
                        setVList(filteredVendors);
                    }
                } else {
                    // setPage(1);
                    // var temparr = data?.z.slice(0, perPage);
                    // setPropertyDetail(data?.property)
                    setIsLoader(false);
                    setVendorList(vendorCount?.data?.vendor);
                    console.log("vendorList", vendorList);
                    setVList(vendorCount?.data?.vendor);
                }


            }
        } catch (error) {
            console.error('Error fetching APIs:', error);
        }
    }

    useEffect(() => {
        if (isLogin) { getVendorDetails() };
    }, []);

    const [searchTerm, setSearchTerm] = useState("");
    const handleInputChange = (event) => {
        setSearchTerm(event.target.value);
        const search = event.target.value;
        const filtered = vList.filter((item) => {
            return item.point_of_fname.toLowerCase().includes(search.toLowerCase()) || item.poc_email.toLowerCase().includes(search.toLowerCase()) || item.phone.toLowerCase().includes(search.toLowerCase()) || item.company_name.toLowerCase().includes(search.toLowerCase());
        });
        setVendorList(filtered);
    };
    console.log("vendorList", vendorList);
    return (
        <>
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                {<h1 className="m-0">Vendors</h1>}
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item">
                                        <Link to="/"
                                            className={location.pathname === "/" ? "breadcrumb-active" : "breadcrumb-not-active"}
                                        >
                                            Home
                                        </Link>
                                        {searchBy ? (
                                            <>
                                                <span className="breadcrumb-arrow">&gt;</span>
                                                <Link to="/user/Lookupunit"
                                                //   className={location.pathname.startsWith("/user/Lookupunit") ? "breadcrumb-active" : "breadcrumb-not-active"}
                                                >
                                                    Look-Up
                                                </Link>
                                            </>
                                        ) : ("")}
                                    </li>
                                    <span className="breadcrumb-arrow">&gt;</span>
                                    <li className="breadcrumb-item active">Vendors
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className={`card-header ${isAdmin ? "adminColor" : "cambg-secondary"}`}>
                                        <div className="add_new_vendor_listing">
                                            <div>
                                                <h3 className="card-title mt-2">Vendor Listing :
                                                </h3>
                                            </div>


                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-end px-4 mt-2">
                                        <div className="input-group w-25">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Search..."
                                                value={searchTerm}
                                                onChange={handleInputChange}
                                            />
                                            <div className="input-group-append">
                                                <span
                                                    className="input-group-text bg-primary border-0"
                                                    id="basic-addon2"
                                                >
                                                    <BsSearch />
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                    {isLoader ? (
                                        <Loader />
                                    ) : (
                                        <div className="card-body table-responsive">
                                            {/* <InfiniteScroll
                                            dataLength={vendorList.length}
                                            next={handleNext}
                                            hasMore={camList.length > vendorList.length}
                                            loader={<Loader />}
                                        > */}
                                            <table className="table table-bordered">
                                                <thead className="border-top">
                                                    <tr>
                                                        <th>Service Category</th>
                                                        <th>Vendor Company Name</th>
                                                        <th>Vendor Company City</th>
                                                        <th>Vendor Company State</th>
                                                        <th>POC Name</th>
                                                        <th>POC Email</th>
                                                        <th>POC Phone</th>
                                                        <th>Property</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {vendorList.length > 0 ? (
                                                        vendorList?.map((data, i) => (
                                                            <tr key={i}>

                                                                <td>{data?.ser_category ?
                                                                    (data?.ser_category?.service_category_name) : (data?.service_category)}</td>
                                                                <td>{data?.company_name}</td>
                                                                <td>{data?.city}</td>
                                                                <td>{data?.state_code ? data?.state_code : data?.state}</td>
                                                                <td> {data?.point_of_fname +
                                                                    " " +
                                                                    data?.point_of_lname}</td>
                                                                <td>{data?.poc_email}</td>
                                                                <td>{data?.phone}</td>
                                                                <td>{data?.property_data?.name}</td>
                                                                <td>
                                                                    <div className="d-flex justify-content-around">
                                                                        {Number(atob(roleId)) === 2 ? ("") : (
                                                                            <>
                                                                                <Button
                                                                                    className="btn btn-info btn-sm mr-1"
                                                                                    // onClick={() => handleShow(data)}
                                                                                    data-tooltip-id="assign"
                                                                                    data-tooltip-content="Property"
                                                                                >
                                                                                    <FaPlus />
                                                                                </Button>
                                                                                <Tooltip id="assign" place="bottom" />
                                                                            </>
                                                                        )}


                                                                        <Link
                                                                            to={"/user/VendorDetail"}
                                                                            // state={{ id: data?.id, propertyId: proid, isEdit: true }}
                                                                            className="btn btn-success btn-sm mr-1"
                                                                            data-tooltip-id="detail"
                                                                            data-tooltip-content="Show Vendor Detail"
                                                                        >
                                                                            <FaEye />
                                                                        </Link>
                                                                        <Tooltip id="detail" place="bottom" />
                                                                        <Link
                                                                            to="/user/VendorFrom"
                                                                            // state={{ id: data?.id, propertyId: proid, isEdit: true }}
                                                                            className="btn  btn-primary btn-sm mr-1"
                                                                            data-tooltip-id="edit"
                                                                            data-tooltip-content="Edit"
                                                                        >
                                                                            <FaEdit />
                                                                        </Link>
                                                                        <Tooltip id="edit" place="bottom" />


                                                                        {
                                                                            isAdmin || Number(atob(roleId)) === 4 || Number(atob(roleId)) === 2 ? (
                                                                                <>
                                                                                    <Link
                                                                                        to={"/user/ManagePasses"}
                                                                                        // state={{ id: data?.id, propertyId: data?.property_id }}
                                                                                        className="btn btn-info btn-sm mr-1"
                                                                                        data-tooltip-id="detail"
                                                                                        data-tooltip-content="Manage Vendor Passes"
                                                                                    >
                                                                                        <FaMoneyBill />
                                                                                    </Link>
                                                                                    <Tooltip id="detail" place="bottom" />
                                                                                </>
                                                                            ) : ("")
                                                                        }

                                                                        <button
                                                                            // onClick={() => handleDelete(data.id)}
                                                                            className="btn  btn-danger btn-sm mr-1"
                                                                            data-tooltip-id="my-tooltip"
                                                                            data-tooltip-content="Delete"
                                                                        >
                                                                            <FaTrashAlt />
                                                                        </button>
                                                                        <Tooltip id="my-tooltip" place="bottom" />
                                                                    </div>
                                                                </td>

                                                            </tr>
                                                        ))) : (
                                                        <tr>
                                                            <td colSpan={8}>
                                                                <p className="text-center">
                                                                    No record found.
                                                                </p>
                                                            </td>
                                                        </tr>
                                                    )}

                                                </tbody>
                                            </table>
                                            {/* </InfiniteScroll> */}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default GraphVendorListing