import React, { useEffect, useState } from "react";
import { FaEdit, FaTrashAlt, FaPlus, FaHouseUser } from "react-icons/fa";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import Api from "../../../Config/Api";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { FaEye, FaUserPlus } from "react-icons/fa";
import { useSelector } from "react-redux";
import { Tooltip } from "react-tooltip";
import Loader from "../../Elements/Loader";
import { Spinner } from "react-bootstrap";
import useSweetAlert from "../../../Hooks/useSweetAlert";
import { BsSearch } from "react-icons/bs";
import InfiniteScroll from "react-infinite-scroll-component";
import MngmtCoSample from "../../../sampleCSV/MngmtCoSample.csv";

const MangementCompanyListing = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const {
    register: register1,
    handleSubmit: handleSubmit1,
    // reset: reset1,
    formState: { errors: errors1 },
  } = useForm();


  const { deletefun, isDeleted } = useSweetAlert();
  const navigate = useNavigate();
  const [managementCompList, setManagementCompList] = useState([]);
  const [perPage] = useState(10);
  const [page, setPage] = useState(1);
  const [isLoader, setIsLoader] = useState(true);
  const [camList, setCamList] = useState([]);
  const [comId, setComId] = useState();
  const [comName, setComName] = useState();
  const location = useLocation();
  const [isLoading, setLoading] = useState(false);
  const [file, setFile] = useState(null);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const [showBulk, setShowBulk] = useState(false);
  const handleCloseBulk = () => {
    setShowBulk(false);
    // setPropertyId(null);
  }

  const handleShowBulk = () => {
    setShowBulk(true);
    // setPropertyId(atob(param?.id))
  }
  
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = (camData) => {
    setShow(true);
    setComId(camData?.id);
    setComName(camData?.name);
  };

  const [propertyId, setPropertyId] = useState(null);

  const handlePropertyChange = (event) => {
    setPropertyId(event.target.value);
    getUnitDetails(event.target.value);
  };

  const isAdmin = useSelector((state) => state.login.isAdmin);
  const userId = useSelector((state) => state.login.userId);
  console.log("userId", userId);
  const [unitlist, setUnitList] = useState([]);
  const getUnitDetails = async (property_Id) => {
    setIsLoader(true);
    const reqData = {
      propertyId: property_Id
    }
    const { data } = await Api("getunitByPropertyId", "POST", reqData);
    if (data?.status) {
      const unitrep = data?.data;
      setUnitList(unitrep);
      setIsLoader(false);
    }
  };

  const [property, setProperty] = useState([]);
  const getPropertyDetails = async () => {
    const { data } = await Api("get-property", "POST", "");
    if (data?.status) {
      const propertyList = data?.data;
      setProperty(propertyList);
    }
  };

  const getManagementCompanyDetails = async () => {
    setIsLoader(true);
    const { data } = await Api("get-management-co", "POST", "");
    if (data?.status) {
      const filterData = data?.data.filter((d) => d.created_by === null)
      setPage(1);
      var temparr = filterData.slice(0, perPage);
      setCamList(filterData);
      setManagementCompList(temparr);
      setIsLoader(false);
    }
  };

  const handleNext = () => {
    const start = page * perPage;
    const end = start + perPage;
    const temArr = managementCompList.concat(camList.slice(start, end));
    setPage((prev) => prev + 1);
    setManagementCompList(temArr);
  };

  useEffect(() => {
    getPropertyDetails();
    getManagementCompanyDetails();
  }, []);

  const handleDelete = async (idToDelete) => {
    //  deletefun(idToDelete, "delete-management-co");

    if (isDeleted) {
    }
    if (window.confirm("Are You Sure You Want to delete it?")) {
      const { data } = await Api("delete-management-co", "POST", { id: idToDelete });
      if (data?.status) {
        toast.success("Management Company has been deleted successfully!", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      getManagementCompanyDetails();
    }
  };

  const onSubmit = async (d) => {
    setLoading(true);
    const assignUnitData = {
      unit_id: d?.unit_id,
      user_id: comId,
    };
    try {
      const { data } = await Api("mgmt_co_managed_unit", "POST", assignUnitData);
      if (Number(data?.status) === 0) {
        toast.success(data?.messsage, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setShow(false);
        setLoading(false);
      } else if (Number(data?.status) == 1) {
        toast.success("Unit has been Assigned Successfully", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setShow(false);
        setLoading(false);
      } else if (Number(data?.status) == 2) {
        toast.error("The unit is already being managed by someone else.", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setShow(false);
        setLoading(false);
      }
    } catch (error) {
      toast.error(error, {
        position: toast.POSITION.TOP_RIGHT,
      });
      setShow(false);
      setLoading(false);
    }
  };

  const onSubmit1 = async (d) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("csv_file", file);

    // formData.append("propertyId", propertyId);
    try {
      const { data } = await Api("store-managementco-csv", "POST", formData);
      if (Boolean(data?.status)) {
        setLoading(false);
        toast.success(data?.data, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setShowBulk(false);
        getManagementCompanyDetails();
        navigate('/user/ManagementCompanyListing');

      }
    } catch (error) {
      toast.error('Something went wrong.', {
        position: toast.POSITION.TOP_RIGHT,
      });
      getManagementCompanyDetails();
      navigate("/user/ManagementCompanyListing");
    }

  };


  const [searchTerm, setSearchTerm] = useState("");

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
    const search = event.target.value;
    const filtered = camList.filter((item) => {
      return item.name.toLowerCase().includes(search.toLowerCase()) || item.email.toLowerCase().includes(search.toLowerCase());
    });
    setManagementCompList(filtered);
  };


  return (
    <>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                {<h1 className="m-0">Management Company</h1>}
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/"
                      className={location.pathname === "/" ? "breadcrumb-active" : "breadcrumb-not-active"}
                    >
                      Home
                    </Link>
                  </li>
                  <span className="breadcrumb-arrow">&gt;</span>
                  <li className="breadcrumb-item active">Management Company</li>
                </ol>
              </div>
            </div>
          </div>
        </div>

        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className={`card-header ${isAdmin ? "adminColor" : "cambg-secondary"}`}>
                    <div className="add_new_vendor_listing">
                      <div>
                        <h3 className="card-title">Details</h3>
                      </div>
                      <div>
                        <button
                          className="btn btn-light text-dark bg-light mx-2"
                        // state={{ propertyId: propertyId }}
                        onClick={handleShowBulk}                          
                        >
                          Import Management Co
                        </button>
                        <Link
                          to={"/user/ManagementCompanyFrom"}
                          className="btn btn-light text-dark bg-light"
                        >
                          Add Management Company
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div className="d-flex justify-content-end px-4 mt-2">
                    <div className="input-group w-25">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search..."
                        value={searchTerm}
                        onChange={handleInputChange}
                      />
                      <div className="input-group-append">
                        <span
                          className="input-group-text bg-primary border-0"
                          id="basic-addon2"
                        >
                          <BsSearch />
                        </span>
                      </div>
                    </div>
                  </div>

                  {isLoader ? (
                    <Loader />
                  ) : (
                    <div className="card-body table-responsive">
                      <InfiniteScroll
                        dataLength={managementCompList.length}
                        next={handleNext}
                        hasMore={camList.length > managementCompList.length}
                      >
                        <table className="table table-bordered">
                          <thead className="border-top">
                            <tr>
                              <th>Management Company Name</th>
                              <th>Email</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {managementCompList?.length ? (
                              managementCompList?.map((data, i) => (

                                <tr key={i}>
                                  <td>{data?.name}</td>
                                  <td>
                                    {data?.email}
                                  </td>

                                  <td>
                                    <div className="d-flex justify-content ">
                                      <Button
                                        className="btn btn-info btn-sm mr-1"
                                        onClick={() => handleShow(data)}
                                        data-tooltip-id="assign"
                                        data-tooltip-content="Assign Property"
                                      >
                                        <FaPlus />
                                      </Button>
                                      <Tooltip id="assign" place="bottom" />

                                      <Link
                                        to={"/user/ManagementCompanyDetail"}
                                        state={{ id: data?.id, isEdit: true }}
                                        className="btn btn-success btn-sm mr-1"
                                        data-tooltip-id="detail"
                                        data-tooltip-content="Show Management Company Detail"
                                      >
                                        <FaEye />
                                      </Link>
                                      <Tooltip id="detail" place="bottom" />
                                      <Link
                                        to="/user/ManagementCompanyFrom"
                                        state={{ id: data?.id, isEdit: true }}
                                        className="btn  btn-primary btn-sm mr-1"
                                        data-tooltip-id="edit"
                                        data-tooltip-content="Edit"
                                      >
                                        <FaEdit />
                                      </Link>
                                      <Tooltip id="edit" place="bottom" />

                                      <button
                                        onClick={() => handleDelete(data?.id)}
                                        className="btn  btn-danger btn-sm mr-1"
                                        data-tooltip-id="my-tooltip"
                                        data-tooltip-content="Delete"
                                      >
                                        <FaTrashAlt />
                                      </button>
                                      <Tooltip id="my-tooltip" place="bottom" />

                                      <Link
                                        to={`/user/ManagementCoPropertyList/${btoa(
                                          data?.id
                                        )}`}
                                        className="btn  btn-info btn-sm"
                                        data-tooltip-id="property"
                                        data-tooltip-content="Managing Property"
                                      >
                                        <FaHouseUser className="text-light" />
                                      </Link>
                                      <Tooltip id="property" place="bottom" />
                                    </div>
                                  </td>
                                </tr>
                              ))) : (
                              <tr>
                                <td colSpan={8}>
                                  <p className="text-center">
                                    No Management Company Yet.
                                  </p>
                                </td>
                              </tr>
                            )
                            }
                          </tbody>
                        </table>
                      </InfiniteScroll>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Assign Management Company to Unit ({comName})</Modal.Title>
        </Modal.Header>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
            <div className="row">

              <div className="col-md-12">
                <div className="form-group">
                  <label>Select Property</label>
                  <select
                    className="form-control"
                    {...register("property_id", {
                      required: true,
                    })}
                    onChange={handlePropertyChange}
                  >  {" "}
                    <option value="">Select Property</option>
                    {property?.map((propertydata, i) => (
                      <option key={i} value={propertydata?.id}>
                        {propertydata?.name}
                      </option>
                    ))}
                  </select>
                  {errors.property_id && (
                    <p className="text-danger">Property is required</p>
                  )}
                </div>
              </div>

              {unitlist.length > 0 && (
                <div className="col-md-12">
                  <div className="form-group">
                    <label>Select Unit</label>
                    <select
                      className="form-control"
                      {...register("unit_id", {
                        required: true,
                      })}
                    >
                      <option value={""}>Select Unit</option>
                      {unitlist?.map((unitData, i) => (
                        <option key={i} value={unitData?.id}>
                          {unitData?.unit_name}
                        </option>
                      ))}
                    </select>
                    {errors.unit_id && (
                      <p className="text-danger">Unit is required</p>
                    )}

                  </div>
                </div>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" type="submit" disabled={isLoading}>
              {isLoading ? (
                <>
                  <Spinner animation="border" size="sm" /> Please wait...
                </>
              ) : (
                "Save"
              )}
            </Button>
          </Modal.Footer>
        </form>
      </Modal>

      <Modal show={showBulk} onHide={handleCloseBulk}>
        <Modal.Header closeButton>
          <Modal.Title>Bulk Upload Management Co </Modal.Title>
        </Modal.Header>
        <form onSubmit={handleSubmit1(onSubmit1)} encType="multipart/form-data">
          <Modal.Body>
            {" "}
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label>Select ManagementCo's CSV File</label>
                  <input
                    type="file"
                    className="form-control mb-1"
                    {...register1("csv_file", { required: true })}
                    accept="csv"
                    onChange={handleFileChange}
                  />
                  {errors1.csv_file && (
                    <p className="text-danger">CSV fIle  is required</p>
                  )}
                  <a href={MngmtCoSample} download={'ManagementCo-Sample'} className="btn-sm " >Download the sample CSV for Managemnet Co</a>

                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseBulk}>
              Close
            </Button>
            <Button variant="primary" type="submit" disabled={isLoading}
            >
              {isLoading ? (
                <>
                  <Spinner animation="border" size="sm" /> Please wait...
                </>
              ) : (
                "Submit"
              )}
            </Button>
          </Modal.Footer>
        </form>
      </Modal>

    </>
  );
};

export default MangementCompanyListing;
