import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import { FaEllipsisV, FaPlus } from "react-icons/fa";
import Api from "../../../Config/Api";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import dayjs from "dayjs";
import { Tooltip } from "react-tooltip";
// import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Loader from "../../Elements/Loader";
import Dropdown from "react-bootstrap/Dropdown";
import Cookies from "js-cookie";
import { BsSearch } from "react-icons/bs";
import InfiniteScroll from "react-infinite-scroll-component";
import { Link, useLocation } from 'react-router-dom';

// import DropdownButton from "react-bootstrap/DropdownButton";
const PropertyListing = () => {

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const location = useLocation();

  const isAdmin = useSelector((state) => state.login.isAdmin);
  const [camList, setCamList] = useState([]);
  const [propertyList, setPropertyList] = useState([]);
  const [property, setProperty] = useState({});
  const [isLoader, setIsLoader] = useState(true);
  const [renewalPropertyId, setRenewalPropertyId] = useState("");
  const [show, setShow] = useState(false);
  const [perPage] = useState(10);
  const [page, setPage] = useState(1);
  const handleClose = () => setShow(false);
  const handleShow = async (propertyData) => {
    const { data } = await Api("get-property", "POST", "");
    if (data) {
      reset({
        'expiration_date': propertyData?.expiration_date
      })
      setRenewalPropertyId(propertyData?.id);
      setShow(true);
    }

  };

  const getPropertyDetails = async () => {
    setIsLoader(true);
    const { data } = await Api("get-property", "POST", "");
    console.log('data', data?.data);
    if (data?.status) {
      setPage(1);
      var temparr = data?.data.slice(0, perPage);
      const propertyList = data?.data;
      setProperty(propertyList);
      setIsLoader(false);
      setPropertyList(temparr);
      setCamList(data?.data);

    }
  };

  const handleNext = () => {
    const start = page * perPage;
    const end = start + perPage;
    const temArr = propertyList.concat(camList.slice(start, end));
    setPage((prev) => prev + 1);
    setPropertyList(temArr);
  };

  useEffect(() => {
    getPropertyDetails();
  }, []);

  const handleDelete = async (idToDelete) => {
    if (window.confirm("Are You Sure You Want to delete it?")) {
      const { data } = await Api("delete-property", "POST", { id: idToDelete });
      if (data?.status) {
        // Cookies.remove("currentStep", {
        //   expires: new Date("May 16, 1970"),
        //   path: "/",
        // });
        localStorage.setItem('currentStep', '')
        toast.success(data?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }

      getPropertyDetails();
    }
  };


  const onSubmit = async (data) => {
    setIsLoader(true);
    const PropertyFormData = {
      expiration_date: data?.expiration_date,
      id: renewalPropertyId
    };

    var res = await Api("update-property-renewal-date", "POST", PropertyFormData);
    if (res?.data?.status) {
      reset({
        'expiration_date': res?.data?.expiration_date
      })
      toast.success(res?.data?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      setShow(false);
      setIsLoader(false);
    }

  };

  const [searchTerm, setSearchTerm] = useState("");

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
    const search = event.target.value;
    const filtered = camList.filter((item) => {
      return item?.name?.toLowerCase()?.includes(search?.toLowerCase()) || item?.address?.toLowerCase()?.includes(search?.toLowerCase()) || item?.cam_data?.name?.toLowerCase()?.includes(search?.toLowerCase()) || item?.cam_data?.lastname?.toLowerCase()?.includes(search?.toLowerCase()) || item?.cam_data?.email?.toLowerCase()?.includes(search?.toLowerCase()) || item?.cam_data?.phone?.toLowerCase()?.includes(search?.toLowerCase());
    });
    setPropertyList(filtered);
  };


  return (
    <>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                {<h1 className="m-0">Property</h1>}
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/"
                      className={location.pathname === "/" ? "breadcrumb-active" : "breadcrumb-not-active"}
                    >
                      Home
                    </Link>
                  </li>
                  <span className="breadcrumb-arrow">&gt;</span>
                  <li className="breadcrumb-item active">Property
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>

        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className={`card-header ${isAdmin ? "adminColor" : "cambg-secondary"}`}>
                    <div className="row">
                      <div className="col-lg-8">
                        <h3 className="card-title mt-2">Details </h3>
                      </div>
                      <div className="col-lg-2">
                      </div>
                      <div className="col-lg-2 text-end">
                        <Link
                          className="btn btn-light text-dark"
                          to={"/user/PropertyForm"}
                        >
                          Add Property
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div className="d-flex justify-content-end px-4 mt-2">
                    <div className="input-group w-25">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search..."
                        value={searchTerm}
                        onChange={handleInputChange}
                      />
                      <div className="input-group-append">
                        <span
                          className="input-group-text bg-primary border-0"
                          id="basic-addon2"
                        >
                          <BsSearch />
                        </span>
                      </div>
                    </div>
                  </div>
                  <>
                    {isLoader ? (
                      <Loader />
                    ) : (
                      <div className="card-body table-responsive">
                        <InfiniteScroll
                          dataLength={propertyList.length}
                          next={handleNext}
                          hasMore={camList.length > propertyList.length}
                        >
                          <table className="table table-bordered">
                            <thead className="border-top">
                              <tr>

                                <th>Property Name</th>
                                <th>Address</th>
                                <th>City</th>
                                <th>State</th>
                                <th>Start Date</th>
                                <th>Renewal Date</th>
                                <th>CAM Details</th>
                                <th>Actions</th>
                              </tr>
                            </thead>
                            <tbody>
                              {propertyList.length ? (
                                propertyList?.map((propertyData, i) => (
                                  <tr key={i}>

                                    <td>{propertyData?.name}</td>
                                    <td>{propertyData?.address}</td>
                                    <td>{propertyData?.city}</td>
                                    <td>{propertyData?.state_code}</td>
                                    <td>
                                      {dayjs(propertyData?.created_at).format("MM/DD/YYYY")}
                                    </td>
                                    <td>
                                      {dayjs(propertyData?.expiration_date).format("MM/DD/YYYY")}
                                    </td>
                                    <td>
                                      {
                                        Boolean(propertyData?.cam_data) &&
                                          propertyData?.cam_data?.role_id == 2 ? (
                                          <p>
                                            {"Name: " +
                                              propertyData?.cam_data?.name +
                                              " " +
                                              propertyData?.cam_data?.lastname}{" "}
                                            <br />
                                            {"Email: " +
                                              propertyData?.cam_data?.email}
                                            <br />
                                            {"Phone: " +
                                              propertyData?.cam_data?.phone}
                                          </p>
                                        ) : (
                                          <p>-</p>
                                        )
                                      }
                                    </td>
                                    <td>
                                      <Dropdown>
                                        <Dropdown.Toggle
                                          bsPrefix
                                          variant="primary"
                                          className=""
                                        >
                                          <FaEllipsisV />
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                          <Dropdown.Item>
                                            <Link
                                              to="/user/PropertyForm"
                                              state={{
                                                id: propertyData.id,
                                                isEdit: true,
                                              }}
                                              data-tooltip-id="edit"
                                              data-tooltip-content="Edit"
                                              className="d-block"
                                            >
                                              Edit
                                            </Link>
                                          </Dropdown.Item>

                                          <Dropdown.Item
                                            onClick={() =>
                                              handleDelete(propertyData.id)
                                            }
                                            className="text-info"
                                          >
                                            Delete
                                          </Dropdown.Item>

                                          <Dropdown.Item>
                                            <Link
                                              to="/user/PropertyDetail"
                                              state={{
                                                id: propertyData.id,
                                                isEdit: true,
                                              }}
                                              data-tooltip-id="detail"
                                              data-tooltip-content="Property Detail"
                                              className="d-block"
                                            >
                                              Property Detail
                                            </Link>
                                          </Dropdown.Item>

                                          <Dropdown.Item>
                                            <Link
                                              to={`/user/UploadedCSV/${btoa(
                                                propertyData.id
                                              )}`}
                                              className="d-block"
                                            >
                                              View Uploaded CSV
                                            </Link>
                                          </Dropdown.Item>

                                          <Dropdown.Item>
                                            <span
                                              onClick={() =>
                                                handleShow(propertyData)
                                              }
                                              className="d-block text-info"
                                            >
                                              Update Renewal Date
                                            </span>
                                          </Dropdown.Item>

                                          <NavDropdown.Divider />

                                          <Dropdown.Item>
                                            <Link
                                              to={`/user/VendorListing/${btoa(
                                                propertyData.id
                                              )}`}
                                              className="d-block"
                                            >
                                              Manage Vendor
                                            </Link>
                                          </Dropdown.Item>

                                          <Dropdown.Item>
                                            <Link
                                              to={`/user/OwnerListing/${btoa(
                                                propertyData.id
                                              )}`}
                                              className="d-block"
                                            >
                                              Manage Unit
                                            </Link>
                                          </Dropdown.Item>

                                          <Dropdown.Item>
                                            <Link
                                              to={`/user/SecurityListing/${btoa(
                                                propertyData.id
                                              )}`}
                                              className="d-block"
                                            >
                                              Manage Security Guard
                                            </Link>
                                          </Dropdown.Item>
                                          <Dropdown.Item>
                                            <Link
                                              to={`/user/PassPricingListing/${btoa(
                                                propertyData.id
                                              )}`}
                                              className="d-block"
                                            >
                                              Manage Pass Prices
                                            </Link>
                                          </Dropdown.Item>
                                          <Dropdown.Item>
                                            <Link
                                              to={`/user/activity/${btoa(
                                                propertyData.id
                                              )}`}
                                              className="d-block"
                                            >
                                              Manage Activity
                                            </Link>
                                          </Dropdown.Item>
                                          <Dropdown.Item>
                                            <Link
                                              to={`/user/PaymentHistory/${btoa(
                                                propertyData.id
                                              )}`}
                                              className="d-block"
                                            >
                                              Manage Payment List
                                            </Link>
                                          </Dropdown.Item>
                                          <Dropdown.Item>
                                            <Link
                                              to={`/user/SpecialOfferListing`}
                                              className="d-block"
                                            >
                                              Special Offers
                                            </Link>
                                          </Dropdown.Item>
                                          {/* <Dropdown.Item>
                                            <Link
                                              to={`/user/SamplePassListing/${btoa(
                                                propertyData.id
                                              )}`}
                                              state={{ propertyData: propertyData }}
                                              className="d-block"
                                            >
                                              Sample Passes
                                            </Link>
                                          </Dropdown.Item> */}
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </td>

                                    <Tooltip id="edit" place="bottom" />
                                    <Tooltip id="delete" place="bottom" />
                                  </tr>
                                ))) : (
                                <tr>
                                  <td colSpan={8}>
                                    <p className="text-center">
                                      No record found.
                                    </p>
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </InfiniteScroll>
                      </div>
                    )}
                  </>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Update Membership Renewal Date</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label htmlFor="status">Membership Renewal Date:</label>

                    <input
                      type="date"
                      className="form-control"
                      {...register("expiration_date", { required: true })}
                    />

                    {errors.status && (
                      <p role="alert" className="text-danger">
                        Membership Renewal date is required
                      </p>
                    )}

                  </div>
                </div>
              </div>

              <div className="text-end">
                <Button variant="primary" type="submit">
                  Save Changes
                </Button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default PropertyListing;
