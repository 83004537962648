import React, { useEffect, useState } from "react";
import { FaEdit, FaTrashAlt, FaPlus } from "react-icons/fa";
import { Link, useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import Api from "../../../Config/Api";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { FaEye } from "react-icons/fa";
import { useSelector } from "react-redux";
import { Tooltip } from "react-tooltip";
import Loader from "../../Elements/Loader";
import { capitalizeFirstLetter } from "../../../Utility/Helpers";
import { Spinner } from "react-bootstrap";
import SecuritySample from "../../../sampleCSV/SecuritySample.csv";
import { BsSearch } from "react-icons/bs";
import InfiniteScroll from "react-infinite-scroll-component";
const CamListing = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const location = useLocation();
  const [isLoader, setIsLoader] = useState(true);
  const [camList, setCamList] = useState([]);
  const [securityList, setSecurityList] = useState([]);
  const [perPage] = useState(10);
  const [page, setPage] = useState(1);
  const [propertyDetail, setPropertyDetail] = useState([]);
  const [propertyIsLoader, setPropertyIsLoader] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const { id } = useParams();
  const propertyId = id ? atob(id) : "";

  const { security, searchBy, property_Id } = location.state ? location.state : '';
  console.log('security:', security);
  console.log('SearchBy:', searchBy);
  console.log('property_Id:', property_Id);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const handleShow = (camData) => {
    setShow(true);
  };

  const isAdmin = useSelector((state) => state.login.isAdmin);

  const [file, setFile] = useState(null);
  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const getSecurityDetails = async () => {
    setIsLoader(true);

    const reqProData = {
      propertyId: propertyId ? propertyId : "",
      isSecurity: 'yes',
    };


    const { data } = await Api("get-security-user", "POST", reqProData ? reqProData : { name: security });
    console.log("data",data);
    if (data?.status) {
      setPage(1);
      if (security && searchBy) {
        setIsLoader(false);
        setPropertyIsLoader(false);
        if (propertyId) {
          const filteredVendors = data?.data.filter(ownerItem => ownerItem.property_id === propertyId);
          console.log("filteredVendors", filteredVendors);

          setSecurityList(filteredVendors);
          setCamList(filteredVendors);
        } else {
          const filteredVendors = data?.data.filter(ownerItem => {
            console.log("searchBy????????????", searchBy);
            return ownerItem[searchBy] === security;
          });
          console.log("filteredVendors", filteredVendors);

          setSecurityList(filteredVendors);
          setCamList(filteredVendors);
        }
      } else {
        var temparr = data?.data.slice(0, perPage);
        setPropertyIsLoader(false);
        setPropertyDetail(data?.property);
        setCamList(data?.data);
        setSecurityList(temparr);
        setIsLoader(false);
      }

    }
  };

  const handleNext = () => {
    const start = page * perPage;
    const end = start + perPage;
    const temArr = securityList.concat(camList.slice(start, end));
    setPage((prev) => prev + 1);
    setSecurityList(temArr);
  };

  useEffect(() => {
    getSecurityDetails();
  }, []);

  const handleDelete = async (idToDelete) => {
    if (window.confirm("Are You Sure You Want to delete it?")) {
      const { data } = await Api("cam-delete-user", "POST", { id: idToDelete });
      if (data?.status) {
        toast.success(data?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }

      getSecurityDetails();
    }
  };

  const onSubmit = async (d) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("csv_file", file);
    formData.append("propertyId", propertyId);
    formData.append("role_id", 5);
    try {
      const { data } = await Api("store-security-csv", "POST", formData);
      if (Boolean(data?.status)) {
        setLoading(false);
        toast.success(data?.data, {
          position: toast.POSITION.TOP_RIGHT,
        });
        getSecurityDetails();
        setShow(false);
      }
    } catch (error) {
      toast.error("Something went wrong.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setShow(false);
      getSecurityDetails();
    }
  };

  const [searchTerm, setSearchTerm] = useState("");

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
    const search = event.target.value;
    const filtered = camList?.filter((item) => {
      return (
        item.name.toLowerCase().includes(search.toLowerCase()) ||
        item.email.toLowerCase().includes(search.toLowerCase()) ||
        item.phone.toString().includes(search)
      );
    });
    setSecurityList(filtered);
  };



  return (
    <>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Security Guards</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/"
                      className={location.pathname === "/" ? "breadcrumb-active" : "breadcrumb-not-active"}
                    >
                      Home
                    </Link>
                    {isAdmin && searchBy? (
                      <>
                        <span className="breadcrumb-arrow">&gt;</span>
                        <Link to="/user/Lookupunit"
                          className={location.pathname.startsWith("/user/Lookupunit") ? "breadcrumb-active" : "breadcrumb-not-active"}
                        >
                          Look-Up
                        </Link>
                      </> 
                    ) : (!searchBy ? (
                      <>
                        <span className="breadcrumb-arrow">&gt;</span>
                        <Link to="/user/PropertyListing"
                          className={location.pathname.startsWith("/user/PropertyListing") ? "breadcrumb-active" : "breadcrumb-not-active"}
                        >
                          Property
                        </Link>
                      </>
                    ) : (""))}
                  </li>
                  <span className="breadcrumb-arrow">&gt;</span>
                  <li className=" active">Security
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>

        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div
                    className={`card-header ${isAdmin ? "adminColor" : "cambg-secondary"
                      }`}
                  >
                    <div className="add_new_vendor_listing">
                      <h3 className="card-title mt-2">
                        Property :
                        {searchBy ?("") :
                          (propertyIsLoader ? (
                          <Spinner
                            as="span"
                            className="mx-3"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        ) : (
                          <span className="px-1">
                            {" "}
                            {Boolean(propertyDetail)
                              ? capitalizeFirstLetter(
                                propertyDetail?.name +
                                ", " +
                                propertyDetail?.city +
                                ", " +
                                propertyDetail?.state
                              )
                              : ""}
                          </span>
                        ))}
                      </h3>
                      <div>
                        <button
                          className="btn btn-light text-dark bg-light mx-2"
                          onClick={handleShow}
                        >
                          Upload Security
                        </button>

                        <Link
                          to={"/user/SecurityFrom"}
                          className="btn btn-light text-dark bg-light"
                          state={{ propertyId: propertyId }}
                        >
                          Add Security
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div className="d-flex justify-content-end px-3 mt-2">
                    <div className="input-group w-25">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search by name, email, phone "
                        value={searchTerm}
                        onChange={handleInputChange}
                      />
                      <div className="input-group-append">
                        <span
                          className="input-group-text bg-primary border-0"
                          id="basic-addon2"
                        >
                          <BsSearch />
                        </span>
                      </div>
                    </div>
                  </div>
                  <>
                    {isLoader ? (
                      <Loader />
                    ) : (
                      <div className="card-body table-responsive">
                        <InfiniteScroll
                          dataLength={securityList.length}
                          next={handleNext}
                          hasMore={camList.length > securityList.length}
                        >
                          <table className="table table-bordered">
                            <thead className="border-top">
                              <tr>
                                <th>First Name</th>
                                <th>Last Name</th>
                                <th>Email</th>
                                <th>Phone</th>
                                <th>Assigned Property</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {securityList?.length ? (
                                securityList?.map((data, i) => (
                                  <tr key={i}>
                                    <td>{data?.name}</td>
                                    <td>{data?.lastname}</td>
                                    <td>{data?.email}</td>
                                    <td>{data?.phone}</td>
                                    <td>
                                      {data?.property?.name
                                        ? data?.property?.name
                                        : "-"}
                                    </td>

                                    <td>
                                      <div className="d-flex justify-content-center">

                                        <Link
                                          to={"/user/SecurityDetail"}
                                          state={{ id: data?.id, propertyId: data?.property_id, isEdit: true }}
                                          className="btn btn-success btn-sm mr-1"
                                          data-tooltip-id="detail"
                                          data-tooltip-content="Show Security Detail"
                                        >
                                          <FaEye />
                                        </Link>
                                        <Tooltip id="detail" place="bottom" />
                                        <Link
                                          to="/user/SecurityFrom"
                                          state={{
                                            id: data?.id,
                                            propertyId: data?.property_id,
                                            isEdit: true,
                                            propertyId: propertyId,
                                          }}
                                          className="btn  btn-primary btn-sm mr-1"
                                          data-tooltip-id="edit"
                                          data-tooltip-content="Edit"
                                        >
                                          <FaEdit />
                                        </Link>
                                        <Tooltip id="edit" place="bottom" />

                                        <button
                                          onClick={() => handleDelete(data.id)}
                                          className="btn  btn-danger btn-sm mr-1"
                                          data-tooltip-id="my-tooltip"
                                          data-tooltip-content="Delete"
                                        >
                                          <FaTrashAlt />
                                        </button>
                                        <Tooltip id="my-tooltip" place="bottom" />
                                      </div>
                                    </td>
                                  </tr>
                                ))) : (
                                <tr>
                                  <td colSpan={8}>
                                    <p className="text-center">
                                      No record found.
                                    </p>
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </InfiniteScroll>
                      </div>
                    )}
                  </>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Upload Security CSV</Modal.Title>
        </Modal.Header>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label>Select CSV File</label>
                  <input
                    type="file"
                    className="form-control mb-1"
                    {...register("csv_file")}
                    accept="csv"
                    onChange={handleFileChange}
                  />
                  {errors.csv_file && (
                    <p className="text-danger">Security CSV file is required</p>
                  )}

                  <a
                    href={SecuritySample}
                    download={"security-Sample"}
                    className="btn-sm "
                  >
                    Download the Sample CSV
                  </a>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" type="submit" disabled={isLoading}>
              {isLoading ? (
                <>
                  <Spinner animation="border" size="sm" /> Please wait...
                </>
              ) : (
                "Submit"
              )}
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default CamListing;
