import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
const parseUser = !!Cookies.get('userDetail') ? JSON.parse(Cookies.get('userDetail')) : null;
  console.log('parseUser',parseUser);
const userSlice = createSlice({
  name: "user",
  initialState: {
    isAdmin: Boolean(Cookies.get('userDetail')) && atob(parseUser?.roleId) ==1 ? true : false,
    isSubMgmt: Boolean(Cookies.get('userDetail')) && Number(parseUser?.isSubMgmt) === 1 ? true : false,
    roleId:  Boolean(Cookies.get('userDetail')) &&  parseUser?.roleId ? parseUser?.roleId : null,
    userId:  Boolean(Cookies.get('userDetail')) &&  Number(parseUser?.isSubMgmt) === 0 ? parseUser?.userId : parseUser?.userId,
    subMgmtUserId: Boolean(Cookies.get('userDetail')) &&  Number(parseUser?.isSubMgmt) === 1 ? parseUser.subMgmId : null,
    logo:  Boolean(Cookies.get('userDetail')) &&  parseUser?.logo ? parseUser?.logo : null,
    propertyId:  Boolean(Cookies.get('userDetail')) &&  parseUser?.propertyId ? parseUser?.propertyId : null,
    name:  Boolean(Cookies.get('userDetail')) &&  parseUser?.name ? parseUser?.name : null,
    email: Boolean(Cookies.get('userDetail')) &&  parseUser?.email ? parseUser?.email : null,
    isLogin:  Boolean(Cookies.get('userDetail')) &&  parseUser ? true : false,
    token:  Boolean(Cookies.get('userDetail')) &&  parseUser?.token ? parseUser?.token : null,
    propertyData:Boolean(Cookies.get('propertyDetail')) &&  Cookies.get('propertyDetail') ? JSON.parse(Cookies.get('propertyDetail')) : null,
  },

  reducers: {
    setUser: (state, action) => {
      // console.log('state',state);
      if (action.payload) {
        const userDetail = {
          userId:Number(action?.payload?.details?.isSubMgmt) === 0 ?  action?.payload.id : action?.payload?.details?.created_by,
          subMgmId:Number(action?.payload?.details?.isSubMgmt) === 1 ?  action?.payload.id : null,
          logo:action?.payload?.details?.logo,
          isSubMgmt:action?.payload?.details?.isSubMgmt,
          roleId: btoa(action.payload.role.id),
          propertyId:action?.payload.details?.cam_managing_property_id,
          name: action.payload.details.name,
          email: action.payload.details.email,
          token: action.payload.token,
        };
        Cookies.set("userDetail", JSON.stringify(userDetail), {
          expires: 1 * 30 * 6,
          path: "",
        });
        Cookies.set("propertyDetail", JSON.stringify(action.payload.role.id == 2 || action.payload.role.id == 5 ? action.payload.propertyData : null), {
          expires: 1 * 30 * 6,
          path: "",
        });
        state.isSubMgmt = action.payload.isSubMgmt;
        state.userId = Number(action?.payload?.details?.isSubMgmt) === 0 ? action.payload.id : action?.payload?.details?.created_by;
        state.subMgmtUserId = Number(action?.payload?.details?.isSubMgmt) === 1 ? action.payload.id : null;
        state.name = action.payload.details.name;
        state.email = action.payload.details.email;
        state.logo = action.payload.details.logo;
        state.token = action.payload.token;
        state.roleId = btoa(action.payload.role.id);
        state.propertyId = action?.payload.details?.cam_managing_property_id;
        state.isAdmin = action.payload.role.id == 1 ? true : false;
        state.isLogin = action.payload ? true : false;
        state.propertyData = action.payload.role.id == 2 || action.payload.role.id == 5  ? action.payload.propertyData : null
      }
    },
    removeUser: (state) => {
      state.name = null;
      state.subMgmtUserId = null;
      state.isSubMgmt = null;
      state.email = null;
      state.token =  null;
      state.userId = null;
      state.roleId =  null;
      state.logo =  null;
      state.isAdmin =  null;
      state.isLogin =  null;
      state.propertyId = null;
      state.propertyData= null;
      Cookies.remove("userDetail", {
        expires: new Date("May 16, 1970"),
        path: "/",
      });
      Cookies.remove("propertyData", {
        expires: new Date("May 16, 1970"),
        path: "/",
      });

      Cookies.remove("propertyDetail", {
        expires: new Date("May 16, 1970"),
        path: "/",
      });
      setTimeout(() => {
        window.location.reload();
      }, 500);
  
    },
    updateUser:(state,action)=>{
      if (action.payload) {
        const userDetail = JSON.parse(Cookies.get('userDetail'));
        state.logo = action.payload.logo;
        state.name = action.payload.name;
        state.email = action.payload.email;
        state.propertyId = action?.payload.details?.cam_managing_property_id;
        const updatedData = {...userDetail,name: action.payload.name, email: action.payload.email,logo:action.payload.logo}

        Cookies.set("userDetail", JSON.stringify(updatedData), {
          expires: 7,
          path: "",
        });
      }
    } 
  },
});

export const { setUser ,removeUser,updateUser} = userSlice.actions;
export default userSlice.reducer;
