import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from "react-redux";
import { BsSearch } from "react-icons/bs";
import Api from '../../Config/Api';
import Loader from "../Elements/Loader";
import dayjs from 'dayjs';

const PassDetailByDate = () => {
  const location = useLocation();
  const isAdmin = useSelector((state) => state.login.isAdmin);
  const roleId = useSelector((state) => state.login.roleId);
  const [managPropPassList, setManagPropPassList] = useState([]);
  const [camList, setCamList] = useState([]);
  const [isLoader, setIsLoader] = useState(true);

  const getPropertyPassDetails = async () => {
    setIsLoader(true);
    const currentDate = dayjs();
    const currentYear = currentDate.year();
    const formattedDate = dayjs(location?.state?.created_at, 'dddd, MMMM D').year(currentYear).format("MM/DD/YYYY");
    console.log('location?.state?.created_at', location?.state?.created_at);
    const { data } = await Api(isAdmin ? "getPassListByDate" : "getPassSalesLastSevenDays", "POST", { created_at: formattedDate});

    console.log('data>>>>>>>>>', data);

    if (data?.status) {
      // setPage(1);
      // var temparr = data?.response.slice(0, perPage);
      setCamList(data?.response);
      setManagPropPassList(data?.response);
      console.log("managPropPassList",managPropPassList);
      setIsLoader(false);
    }
  };

  useEffect(() => {
    getPropertyPassDetails();
  }, [location?.state?.created_at]);

  return (
    <>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                {<h1 className="m-0">Purchased Pass Details By Date</h1>}
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/"
                      className={location.pathname === "/" ? "breadcrumb-active" : "breadcrumb-not-active"}
                    >
                      Home
                    </Link>
                  </li>
                  <span className="breadcrumb-arrow">&gt;</span>
                  <li className="breadcrumb-item active">Pass Details
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>

        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className={`card-header ${isAdmin || Number(atob(roleId)) === 4 ? "adminColor" : "cambg-secondary"}`}>
                    <div className="row">
                      <div className="col-lg-8">
                        <h3 className="card-title mt-2">Passes Listing </h3>
                      </div>
                      <div className="col-lg-2">
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-end px-4 mt-2">
                    <div className="input-group w-25">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search..."
                      />
                      <div className="input-group-append">
                        <span
                          className="input-group-text bg-primary border-0"
                          id="basic-addon2"
                        >
                          <BsSearch />
                        </span>
                      </div>
                    </div>
                  </div>
                  {isLoader ? (
                    <Loader />
                  ) : (
                    <div className="card-body table-responsive">
                      {/* <InfiniteScroll
                          dataLength={propertyList.length}
                          next={handleNext}
                          hasMore={camList.length > propertyList.length}
                        // loader={<Loader />}
                        // endMessage={<p>No more data to load</p>}
                        > */}
                      <table className="table table-bordered">
                        <thead className="border-top">

                          <tr>
                            <th>Property Name</th>
                            <th>Unit</th>
                            <th>Renter Name</th>
                            <th>Renter Email</th>
                            <th>Renter Phone</th>
                          </tr>
                        </thead>

                        <tbody>
                          {managPropPassList?.length ? (
                            managPropPassList?.map((data, i) => (
                              <tr key={i}>
                                <td>{data?.propety?.name}</td>
                                <td>{data?.unit?.unit_name}</td>
                                <td>{data?.user?.name + " " + data?.user?.lastname}</td>
                                <td>{data?.user?.phone}</td>
                                <td>{data?.user?.email}</td>
                              </tr>
                            ))) : (



                            <tr>
                              <td colSpan={8}>
                                <p className="text-center">
                                  No record found.
                                </p>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                      {/* </InfiniteScroll> */}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>


  )
}

export default PassDetailByDate