import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { toast } from "react-toastify";
import Api from "../../../Config/Api";
import { Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { fetchState, getState } from "../../../Reducers/State";
import vendorSample from "../../../sampleCSV/vendorSample.csv";
import Cookies from "js-cookie";
const AddNewVendor = ({ propertyId, setcurrentStep }) => {
  // const onboardingdata  =  Cookies.get('currentStep') ? JSON.parse(Cookies.get('currentStep')) : '';
  const onboardingLocaldata  =  localStorage.getItem('currentStep') ? JSON.parse(localStorage.getItem('currentStep')) : '';
  const property_Id = onboardingLocaldata ? onboardingLocaldata.propertyId : propertyId;
  console.log('propertyId',property_Id);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [category, setCategory] = useState([]);
  const isAdmin = useSelector((state) => state.login.isAdmin);
  const getServiceCategory = async () => {
    const { data } = await Api("vendor-service-category", "POST", {});
    const serviceCategory = data?.data;
    setCategory(serviceCategory);
  };
  useEffect(() => {
    dispatch(fetchState());
    getServiceCategory();
  }, []);
  const [file, setFile] = useState(null);
  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const onSubmit = async (d) => {
    const formData = new FormData();
    formData.append("csv_file", file);
    formData.append("propertyId", property_Id);
    if(property_Id){
    try {
      const { data } = await Api("store-vendor-csv", "POST", formData);
      if (Boolean(data?.status)) {
        toast.success('Vendor has been imported', {
          position: toast.POSITION.TOP_RIGHT,
        });
        setcurrentStep(5);
      }
    } catch (error) {
        setLoading(false);
      toast.error("Something went wrong.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
}else{
    setLoading(false);
    toast.error("Something went wrong.", {
        position: toast.POSITION.TOP_RIGHT,
      });
}
  };

  useEffect(()=>{
    const OnboardingData = {
      currentStep:4,
      propertyId:property_Id
    }
    // Cookies.set("currentStep", JSON.stringify(OnboardingData));
    localStorage.setItem('currentStep', JSON.stringify(OnboardingData));
  },[])

  return (
    <>
      <div className="card card-primary py-3 px-3">
        <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
          {" "}
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <label>Select CSV File</label>
                <input
                  type="file"
                  className="form-control mb-1"
                  {...register("csv_file", { required: true })}
                  accept="csv"
                  onChange={handleFileChange}
                />
                {errors.csv_file && (
                  <p className="text-danger">CSV fIle is required</p>
                )}
                <a
                  href={vendorSample}
                  download={"vendor-Sample"}
                  className="btn-sm "
                >
                  Download the Vendor Sample CSV
                </a>
              </div>
              <div className="text-end">
            <button
              variant="primary"
              className={`btn btn-primary  ${isAdmin ? "" : "cambg-secondary border-0"}`} 
              type="submit"
              disabled={isLoading}
            >
              {isLoading ? (
                <>
                  <Spinner animation="border" size="sm" /> Please wait...
                </>
              ) : (
                "Submit"
              )}
            </button>
          </div>
            </div>

           
          </div>
         
        </form>
      </div>
    </>
  );
};

export default AddNewVendor;
