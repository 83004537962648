import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation, Link } from "react-router-dom";
import Api from "../../../Config/Api";
import Loader from "../../Elements/Loader";
const CamDetail = () => {
  const location = useLocation();
  const isAdmin = useSelector((state) => state.login.isAdmin);
  const [isLoader, setIsLoader] = useState(true);
  const [camData, setCamData] = useState([]);
  const [assignPropertyDetail, setAssignPropertyDetail] = useState([]);

  const getCamData = async () => {
    const { data } = await Api("cam-get-user", "POST", {
      id: location?.state?.id,
    });
    console.log('data', data);
    if (data?.status) {
      setIsLoader(false);
      const VendorData = data?.data;
      setCamData(VendorData);
    }
  };

  useEffect(() => {
    if (!!location?.state?.id) {
      getCamData();
    }
  }, [location?.state?.id]);

  return (
    <>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">CAM Details</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/"
                      className={location.pathname === "/" ? "breadcrumb-active" : "breadcrumb-not-active"}
                    >
                      Home
                    </Link>
                    <span className="breadcrumb-arrow">&gt;</span>
                    <Link to="/user/CamListing"
                      className={location.pathname.startsWith("/user/CamListing") ? "breadcrumb-active" : "breadcrumb-not-active"}
                    >
                      Cam
                    </Link>
                  </li>
                  <span className="breadcrumb-arrow">&gt;</span>

                  <li className="breadcrumb-item active">CAM Details</li>
                </ol>
              </div>
            </div>
          </div>
        </div>

        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card card-primary">
                  <div className={`card-header ${isAdmin ? "adminColor" : "cambg-secondary"}`}>
                    <h3 className="card-title">CAM Details</h3>
                  </div>

                  <div className="bg-white p-3 rounded p-3 shadow">
                    <div>
                      <div className="mb-3">
                        <div className="row">
                          {/* <div className="col-lg-6">
                            <h5 className="text-dark">CAM Details</h5>
                          </div> */}
                          <div className="col-lg-6">
                            <form>
                              <div className="d-flex gap-2 justify-content-end">
                                <div className="flex-shrink-0">
                                  <span className="badge bg-warning">
                                    {/* Pending */}
                                  </span>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                      <div className="table-responsive">
                        <div>
                          <div
                            style={{
                              width: "100%",
                              padding: "0px 40px",
                              borderRadius: "10px",
                              backgroundColor: " rgb(255, 255, 255)",
                              margin: "0px auto",
                              border: "1px solid rgb(221, 221, 221)",
                              fontSize: "14px",
                              color: "rgb(0, 0, 0)",
                            }}
                          >
                            <div>
                              <table
                                style={{ width: "100%", marginBottom: "0px" }}
                              >
                                <tbody>
                                  <tr>
                                    <td>
                                      {isLoader ? (
                                        <Loader />
                                      ) : (
                                        <div className="mt-3">
                                          <table
                                            className="m-0"
                                            style={{ width: "100%" }}
                                          >
                                            <tbody>
                                              <tr>
                                                <td>
                                                  <div
                                                    style={{
                                                      fontStyle: "italic",
                                                      marginBottom: "10px",
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    CAM Details:
                                                  </div>
                                                  <table
                                                    className="table table-bordered table-striped table-sm m-0"
                                                    style={{ width: "100%" }}
                                                  >
                                                    <tbody>
                                                      <tr>
                                                        <td>First Name</td>
                                                        <th>
                                                          {
                                                            camData?.name
                                                          }
                                                        </th>
                                                        <td
                                                          rowSpan="24"
                                                          style={{
                                                            textAlign: "center",
                                                            verticalAlign:
                                                              "top",
                                                          }}
                                                        >
                                                          {/* <div
                                                            style={{
                                                              border:
                                                                "1px solid rgb(221, 221, 221)",
                                                              padding: "10px",
                                                              width: "150px",
                                                              textAlign:
                                                                "center",
                                                              margin:
                                                                "0px auto",
                                                            }}
                                                          > */}
                                                          {/* <img
                                                            className="w-100"
                                                            alt="Applicant"
                                                            src="https://developerteam.in/nsdmis-api/public/images/sdrf/118566703-1679303318.png"
                                                          /> */}
                                                          {/* </div> */}
                                                        </td>
                                                      </tr>
                                                      <tr>
                                                        <td
                                                          style={{
                                                            width: "270px",
                                                          }}
                                                        >
                                                          Last Name
                                                        </td>
                                                        <td>
                                                          <b>
                                                            {camData?.lastname}
                                                          </b>
                                                        </td>
                                                      </tr>
                                                      <tr>
                                                        <td
                                                          style={{
                                                            width: "270px",
                                                          }}
                                                        >
                                                          Email
                                                        </td>
                                                        <td>
                                                          <a href={`mailto:${camData?.email}`}>
                                                            <b>
                                                              {
                                                                camData?.email
                                                              }
                                                            </b>
                                                          </a>
                                                        </td>
                                                      </tr>
                                                      <tr>
                                                        <td
                                                          style={{
                                                            width: "270px",
                                                          }}
                                                        >
                                                          Phone
                                                        </td>
                                                        <td>
                                                          <b>
                                                            {camData?.phone}
                                                          </b>
                                                        </td>
                                                      </tr>

                                                    </tbody>
                                                  </table>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </div>
                                      )}

                                      <div className="mt-3">
                                        <div
                                          className="mb-2"
                                          style={{
                                            fontStyle: "italic",
                                            fontWeight: "bold",
                                          }}
                                        >
                                          Assigned Property
                                        </div>
                                        <table className="table table-bordered table-striped m-0 mb-3">
                                          <thead>
                                            <tr>

                                              <th>Property Name</th>
                                              <th>Address</th>
                                              <th>City</th>
                                              <th>State</th>
                                              <th>Assigned Date</th>

                                            </tr>
                                          </thead>
                                          <tbody>
                                            {camData?.property ? (
                                              <tr >

                                                <td>
                                                  {
                                                    camData?.property?.name
                                                  }
                                                </td>
                                                <td>
                                                  {
                                                    camData?.property?.address
                                                  }
                                                </td>
                                                <td>
                                                  {
                                                    camData?.property?.city
                                                  }
                                                </td>
                                                <td>
                                                  {
                                                    camData?.property?.state
                                                  }
                                                </td>

                                                <td>
                                                  {dayjs(
                                                    camData?.property?.created_at
                                                  ).format("MM/DD/YYYY")}
                                                </td>
                                              </tr>


                                            ) : (
                                              <tr>
                                                <td colSpan={4}>
                                                  <p className="text-center">
                                                    No Assign Property Yet.
                                                  </p>
                                                </td>
                                              </tr>
                                            )}
                                          </tbody>
                                        </table>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default CamDetail;
