import React, { useEffect, useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
// import { BsSearch } from "react-icons/bs";
import QrReader from "react-qr-scanner";
import { useNavigate } from "react-router-dom";
// import Modal from "react-bootstrap/Modal";
import ResetPasswordModal from "../Elements/Forms/ResetPasswordModal";
import { useSelector } from "react-redux";
import Api from "../../Config/Api";
import { toast } from "react-toastify";
import scannericon from "../../assets/img/scannericon.jpg"
const Index = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();
  const [delay, setDelay] = useState(100);
  const [result, setResult] = useState({ text: "No result" });
  const [scannerOpen, setScannerOpen] = useState(false);
  const userId = useSelector((state) => state.login.userId);
  const [modalShow, setModalShow] = useState(false);

  const handleScan = (data) => {
    setResult(data);
  };

  const handleError = (err) => {
    console.error(err);
  };

  const previewStyle = {
    width: "100%",
  };

  const cameraStyle = {
    objectFit: "cover",
    transform: "scaleX(-1)",
  };

  const onSubmit = (data) => {
    // data?.passId ?
    // (navigate("/Security/Result", { state: { UUID: data?.passId, userId: userId, searchBy: 'uuid' } })) :(navigate("/Security/Result", { state: { UUID: data?.licencePlate, userId: userId, searchBy: 'licencePlate' } }));
    // navigate("/Security/Result", { state: { UUID: data?.licencePlate, userId: userId, searchBy: 'licencePlate' } });

    if (data?.passId) {

      navigate("/Security/Result", { state: { UUID: data?.passId, userId: userId, searchBy: 'uuid' } });
    } else if (data.licencePlate) {
      navigate("/Security/Result", { state: { UUID: data?.licencePlate, userId: userId, searchBy: 'licencePlate' } });
    } else {
      toast.error(" Pass Id or Licence Plate No. is required.", {
        position: toast.POSITION.TOP_RIGHT,
      });

    }

  };

  // const onSubmit1 = (data) => {
  //   navigate("/Security/Result", { state: { UUID: data?.licencePlate, userId: userId, searchBy: 'licencePlate' } });
  // };


  const handleOpenScanner = () => {
    setResult({ text: "No result" });
    setScannerOpen(true);
  };

  const handleCloseScanner = () => {
    setScannerOpen(false);
  };

  const getModifyCamData = async () => {
    const { data } = await Api("cam-get-user", "POST", {
      id: userId,
    });
    const CamData = data?.data;
    setModalShow(
      Number(CamData?.isChanged) === 1 || Number(CamData?.isChanged) === 0 ? false : true
    );
  };

  useEffect(() => {
    getModifyCamData();
  }, [userId]);

  useEffect(() => {
    console.log('split ', Number(result?.text?.split('+').length));
    if (result?.text != "No result" && result?.text != undefined) {

      if (Number(result?.text?.split('+').length) > 1) {
        navigate("/Security/ScanLocationQR", { state: { UUID: result?.text, userId: userId } });
      } else {
        setResult(result?.text);
        setTimeout(() => {
          navigate("/Security/Result", { state: { UUID: result?.text, userId: userId, searchBy: 'scanner' } });
        }, 600);
      }
    }
  }, [result?.text]);

  return (
    <>

      <div className="container">
        <div className="row justify-content-center ">
          <div className="col-lg-9">
            <ResetPasswordModal
              show={modalShow}
              onHide={() => setModalShow(false)}
            />
            <div className="new_guest_form text-center">
              <h3>Vehicle Owner/Renter or Condo Info</h3>
              <div className="mt-3">
                <Button
                  type="button"
                  variant="transparent"
                  onClick={handleOpenScanner}
                >
                  {/* Open Scanner */}
                  <img src={scannericon} alt="scanner Img" width={200} />

                </Button>

                {scannerOpen && (
                  <QrReader
                    delay={delay}
                    onError={handleError}
                    onScan={handleScan}
                    style={previewStyle}
                    facingMode="user"
                    showViewFinder={true}
                    facingModeMatcher="user"
                    constraints={{
                      video: {
                        facingMode: { exact: "user" },
                      },
                    }}
                    cameraStyle={cameraStyle}
                  />
                )}
                {result?.text && <p>{result?.text}</p>}

                {scannerOpen && (
                  <Button
                    type="button"
                    className="btn btn-primary my-3"
                    onClick={handleCloseScanner}
                  >
                    Close Scanner
                  </Button>
                )}
              </div>



              <Form onSubmit={handleSubmit(onSubmit)}>

                <div className="input-group mb-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search by Pass Id"
                    {...register("passId")}
                  />
                </div>

                <div className="mb-3">
                  <h6>OR</h6></div>
                <div className="input-group mb-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search by Licence Plate No."
                    {...register("licencePlate")}
                  />
                </div>


                <div className="card-footer">
                  {/* <Link
                    to={"/user"}
                    // className="btn btn-primary"
                    className="btn btn-outline-light text-dark mx-3 bg-light"
                  >
                    Back
                  </Link> */}

                  <button
                    type="submit"
                    className="btn btn-primary"
                    id="basic-addon2"
                  >
                    Search
                  </button>
                </div>

              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;
